@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
  font-family: Questrial, sans-serif;
}

.subtitle {
  @include subtitle();
}

.description {
  @include description();
}

@media #{$mobile} {
  .header {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px 15px;

    .title {
      display: block;
      font-size: 20px;
    }

    .description {
      color: $warm-grey;
    }
  }
}

.alert {
  @include font-style($color: #fff, $font-size: 10);
  align-items: center;
  background-image: linear-gradient(to bottom, #5fe8c3, #43b395);
  border-radius: 100%;
  display: inline-flex;
  height: 15px;
  justify-content: center;
  position: relative;
  top: -2px;
  width: 15px;
}

.bell>svg {
  height: 15px;
  position: relative;
  top: 1px;
  width: 20px;
}

.notification-title {
  color: $dark-grey;
  font-size: 16px;
  margin-bottom: 8px;
}

.notification-description {
  @include description();
  font-size: 14px;
  line-height: 1.4em;
}

.notification-description,
.row-title,
.col-title,
.collapsable-description {
  color: $warm-grey;
}

.col-title,
.collapsable-description,
.reset-config {
  font-size: 12px;
}

.collapsable-description {
  line-height: normal;
  padding-bottom: 10px;
}

.card {
  @include card();

  .notification-content {
    display: flex;
    justify-content: space-between;
  }

  .button {
    @include button($deep-teal, $white);
    padding: 3px 25px;
  }

  .button-outlined {
    @include outlined-button($deep-teal);
    padding: 2px 18px;
  }

  .button,
  .button-outlined {
    font-size: 14px;
    height: fit-content;
    margin-left: 10px;
  }

  .notification-button {
    display: flex;
  }
}

.notification-card {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.table {
  padding: 10px 0;
}

.table-row {
  display: inline-flex;
  padding: 20px 0;
  width: 100%;

  border: {
    bottom: 1px solid #dedede;
    top: 1px solid #dedede;
  }

  ;

  .row-title {
    font-size: 16px;
    justify-content: flex-start;
    text-align: left;
  }

  &>div {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
  }
}

.reset-config {
  color: $deep-teal;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 34px;
}
