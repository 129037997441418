@import "../../styles/colors";
@import "../../styles/media";

.wrapper {
  background-color: $flat-white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);

  display: grid;
  grid-template-rows: min-content auto;

  max-height: 60px;
  overflow: hidden;

  padding: 15px 10px;

  transition: all 0.3s linear;
  transition-property: max-height, height;
  width: 100%;

  &.opened {
    bottom: 0;
    max-height: 50vh;
    //position: absolute;
  }
}

.header {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-between;
}

.controls {
  display: flex;
  justify-content: space-between;
  width: 60px;

  > div {
    cursor: pointer;
  }

  svg {
    height: 30px;
    width: 30px;
  }
}

.content {
  line-height: 20px;
  overflow: hidden;
  position: relative;

  > div {
    height: 100%;
    top: 0;
    width: 100%;
    will-change: transform;

    &.grid_template {
      display: grid;
      grid-template-rows: auto min-content;
    }

    @media #{$desktop} {
      > span {
        width: 80%;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 25px 0 0;

  > div {
    align-items: center;
    border: 1px solid $deep-teal;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex: 0 0 49%;
    justify-content: center;
    margin-bottom: 10px;
    padding: 8px 10px;

    svg {
      height: 30px;
      padding-right: 8px;
      width: 30px;
    }

    &:hover {
      background-color: $white;
    }
  }

  @media #{$desktop} {
    flex-flow: row;
    justify-content: start;
    margin-top: 20px;

    > div {
      flex: 1 1 80px;
      margin: 5px;
    }
  }
}

.list {
  margin-top: 25px;

  > div {
    margin-top: 5px;

    :first-child {
      margin-right: 10px;
    }
  }
}

.field {
  background-color: $pastel-grey;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 15px;
  padding: 25px 20px 35px;

  .input {
    height: 30px;
    margin-top: 5px;
    padding: 4px 10px;
    transition: all 0.3s linear;

    &.active {
      height: 20vh;
    }
  }

  @media #{$desktop} {
    padding-bottom: 20px;
  }
}

.report_buttons {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 10px;

  button {
    margin-left: 10px;
  }
}

.text {
  margin-top: 15px;
}

.choose_wrapper {
  overflow: hidden;
}

.choose_content_wrapper {
  display: flex;
  min-height: auto;
  transition: transform 0.3s linear;

  &.input {
    transform: translateX(-100%);
  }
}

.options_wrapper {
  margin-top: 15px;
}

.choose_content {
  min-width: 100%;
}
