@import "styles/mixins/main";

.wrapper {
  @include body-style($dark-grey, 15);
  border-radius: 20px;
  padding: 1em;

  h2 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}

.completed {
  @extend .wrapper;
  align-items: center;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 1.5em;
  }
}

.buttons {
  display: inline-grid;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  margin: 2em 0 1em;
  width: 100%;

  button {
    flex: 1;
  }

  @media #{$desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.input-wrapper {
  display: flex;
  flex-flow: column;

  .input {
    @include body-style($dark-grey, 15);
    border: 1px solid $simple-grey;
    border-radius: 4px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
    flex: 1;
    margin: 0 0 0.5em;
    padding: 0.7em 0.8em;

    @media #{$desktop} {
      margin: 0 0.5em 0 0;
    }
  }

  @media #{$desktop} {
    flex-flow: row nowrap;
  }
}
