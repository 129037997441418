@mixin achievement($height, $max-font-size: 24) {
  .achievement_card {
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
    display: flex;
    flex: 1;
    margin: 10px;
    padding: 30px 15px;
    position: relative;

    .list_item__text {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 110px;
    }

    .icon {
      height: #{$height}px;
    }

    .icon > img {
      height: 100%;
    }

    h1, p, span {
      font-family: Questrial, sans-serif;
    }

    h1 {
      color: $warm-grey;
      font-size: #{$max-font-size}px;
      padding: 0;
    }

    p {
      align-items: center;
      color: $warm-grey;
      display: flex;
      flex: 1;
      font-size: #{$max-font-size - 4}px;
      padding: 10px 0;
    }

    span {
      color: $greyish-teal;
      font-size: #{$max-font-size - 2}px;
      margin-bottom: 10px;
    }
  }

  .achievement_card:only-child {
    align-self: center;
    flex-direction: row;
    margin: 0 auto;
    max-width: 600px;
  }

  @media all and (min-width: 361px) and (max-width: 430px) {
    .achievement_card {
      align-items: center;
      flex-direction: column-reverse;
      max-width: 45%;

      padding: 15px;
    }

    .achievement_card:nth-child(2n) {
      margin-left: auto;
    }

    .achievement_card:last-child:nth-child(2n+1) {
      flex-direction: row;
      max-width: 100%;

      padding: 15px;
    }
  }

  @media all and (max-width: 360px) {
    .achievement_card {
      flex-direction: row;
      margin: 5px 0;
      max-width: 100%;

      padding: 15px;
    }
  }
}

@mixin credits-wrapper() {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .background-decoration {
    background-color: $greyish-teal;
    border-radius: 0 0 100% 100%;
    height: 100%;
    left: -120px;
    max-height: 500px;
    position: absolute;
    right: -120px;
    top: -160px;

    @media #{$mobile} {
      height: 64%;
    }
  }

  @media #{$mobile} {
    padding-bottom: 50px;
  }
}

@mixin title() {
  color: $warm-grey;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  margin-bottom: 15px;
}

@mixin card() {
  background: $white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  margin: 10px 15px 20px;
  padding: 0;
  position: relative;
}

@mixin search() {
  form {
    padding: 26px 15px 40px;
  }

  label {
    font-size: 16px;
  }

  [class*="validated-select_select"] > button {
    color: $dark-grey;
    min-height: 20px;
  }


  [class*="box"] > svg {
    display: none;
  }
}
