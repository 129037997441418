@import "colors";
@import "media";

$header-height-desktop: 134px;
$header-height-mobile: 124px;
$header-offset-y-desktop: 60px;
$header-offset-y-mobile: 60px;
$contract-duration: 0.3s;
$sidebar-width: 80px;

#body {
  padding-top: $header-height-desktop;

  @media #{$mobile} {
    padding-top: $header-height-mobile;
  }
}

#search {
  top: $header-height-desktop;

  @media #{$mobile} {
    top: $header-height-mobile;
  }
}

#header {
  background-color: $deep-teal;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
  justify-content: space-between;
  left: 0;
  padding: 15px 20px;
  position: fixed;
  right: 0;
  transition: transform $contract-duration;
  z-index: 2;

  @media #{$desktop} {
    left: $sidebar-width;
  }

  &.contracted {
    transform: translate(0, -$header-offset-y-desktop);

    @media #{$mobile} {
      transform: translate(0, -$header-offset-y-mobile);
    }
  }
}

#header-top {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  #header-collective {
    display: flex;
    flex: 1,;
  }

  #header-search {
    flex: 0 1 400px;
  }

  #header-profile {
    margin-left: 20px;
  }

  > div {
    height: fit-content;
  }
}

#header-bottom {
  display: flex;
  font-size: 16px;
  width: 100%;

  #header-profile {
    margin-right: 15px;
  }

  &-left {
    align-items: center;
    display: flex;
    flex: 3;

    @media #{$mobile} {
      flex: 5;
    }

    > div {
      height: fit-content;
      margin-right: 10px;

      @media #{$mobile} {
        margin-right: 0;
      }
    }

    #header-search {
      flex: 1;
      width: 100%;
    }
  }

  &-right {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    > div, w-header-menu-module , w-header-alerts-module {
      height: fit-content;
      margin-left: 10px;

      @media #{$mobile} {
        margin-left: 0;
      }
    }
  }
}
