@import "../styles/colors";

.base {
  background: none;
  border: 0;
  padding: 0;
  position: relative;

  &:hover:not(:disabled) {
    background-color: $white;
    cursor: pointer;
  }

  &:focus:not(:disabled) {
    outline: none;
  }
}

.primary {
  background-color: $deep-teal;
  border-radius: 10px;
  color: $flat-white;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 25px;

  &:hover:not(:disabled) {
    background-color: lighten($deep-teal, 10%);
  }

  &:focus:not(:disabled) {
    background-color: lighten($deep-teal, 10%);
  }
}

.link {
  background-color: transparent;
  color: $deep-teal;
  font-size: 14px;
  font-weight: 600;
  padding: 0 5px;

  &:hover:not(:disabled) {
    color: rgba($deep-teal, 0.7);
  }

  &:focus:not(:disabled) {
    color: rgba($deep-teal, 0.7);
  }
}

.primary-outline {
  background-color: transparent;
  border: 1px solid $deep-teal;
  border-radius: 10px;
  color: $deep-teal;
  font-size: 14px;
  padding: 3px 25px;
}

.secondary {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 3px $deep-teal;
  color: $deep-teal;
  font-size: 14px;
  padding: 2px 25px;

  &:hover:not(:disabled) {
    background-color: rgba($white, 0.3);
    box-shadow: 0 0 3px rgba($deep-teal, 0.5);
  }

  &:focus:not(:disabled) {
    background-color: rgba($white, 0.3);
    box-shadow: 0 0 3px rgba($deep-teal, 0.5);
  }
}

.light {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba($very-light-pink, 0.5);
  color: $very-light-pink;
  font-size: 14px;
  padding: 2px 25px;

  &:hover:not(:disabled) {
    background-color: rgba($deep-teal, 0.2);
  }

  &:focus:not(:disabled) {
    background-color: rgba($deep-teal, 0.2);
  }
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
