@import "styles/mixins/main";
@import "styles/media";

.wrapper {
  form {
    @include validation-block();

    display: flex;
    flex-direction: column;
  }

  p {
    @include font-style($dark-grey, 14);

    background-color: rgba($warm-grey, 0.2);
    padding: 20px;
  }

  a {
    text-decoration: underline;
  }

  a:active,
  a:focus {
    outline: none;
  }
}

.error {
  background-color: rgba($pastel-red, 0.2);
  color: $pastel-red;
  flex: 1;
  padding: 20px;
}

.input {
  @include v-input();

  input:focus {
    box-shadow: 0 0 4px $greyish-teal;
  }

  label {
    color: $dark-grey;
    font-size: 14px;
  }

  input {
    display: block;
    font-size: 14px;
    height: 28px;
    line-height: 14px;
  }

  svg {
    height: 18px;
  }

  .box {
    position: relative;
  }

  .show {
    @include icon-button();

    bottom: 1px;
    margin: auto 0;

    position: absolute;
    right: 38px;

    &:hover {
      svg {
        width: 22px;
      }
    }
  }
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
