@import "styles/mixins/main";

.wrapper {
  align-items: center;
  align-self: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: -50px auto 0;
  width: 90%;

  section {
    width: 100%;
  }

  @media #{$desktop} {
    flex: 1;
    width: 40%;
  }
}


.looking-wrapper,
.commitment-wrapper {
  background-color: $flat-white;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;

  h1 {
    @include font-style($warm-grey, 20);

    font-family: "Questrial", sans-serif;
  }
}


.looking-wrapper {
  li {
    margin-top: 20px;
  }
}

.commitment-wrapper {
  display: flex;
  flex-direction: column;

  .loading {
    align-self: flex-end;
    margin-right: 20px;
    padding: 5px 20px;
  }

  h2 {
    @include font-style($dark-grey);
    margin: 20px 0 10px;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    @include font-style($dark-grey, 14);

    background-color: rgba($greyish-teal, 0.2);
    margin-bottom: 10px;
    padding: 20px;
  }

  button {
    @include button($deep-teal, $flat-white);

    align-self: flex-end;
    padding: 5px 20px;
  }
}
