@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
  font-family: Questrial, sans-serif;
}

@media #{$mobile} {
  .cards-wrapper & .mobile-header {
    display: inline-flex;
  }
}

.mobile-title {
  @include subtitle();
  display: none;
  font-family: Questrial, sans-serif;
  margin-bottom: 20px;
}

.card {
  @include card();

  .card-title,
  .card-content,
  .mentoring,
  .area-label {
    @include description();
  }

  .card-title {
    height: 22px;
  }

  .share {
    align-self: baseline;
    margin-left: auto;
  }

  .card-title,
  .mentoring {
    display: flex;
    justify-content: space-between;
  }

  .card-content,
  .mentoring {
    color: $warm-grey;
    margin-top: 10px;

    button {
      align-self: center;
      max-height: 24px;
    }
  }

  @media #{$mobile} {
    .card-title {
      font-size: 16px;
      line-height: 16px;
    }

    .cards-wrapper & .mobile-title {
      display: block;
    }
  }
}

.link {
  color: $deep-teal;
  cursor: pointer;
}

.area-info {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  .area-label,
  .area-goal {
    color: $warm-grey;
  }

  .area-label {
    font-size: 15px;
  }

  .button {
    padding: 4px 11px;
  }
}

.setgoals_wrapper {
  display: flex;
  justify-content: flex-end;

  button {
    @include button($deep-teal, $flat-white);
    font-size: 14px;
    padding: 6px 12px;
  }
}
