$tabbar-height: 50px;

#layout {
  background-color: $white;
  display: flex;
  height: 100%;
  max-width: 100%;

  @media #{$mobile} {
    flex-direction: column;
    overflow-y: hidden;
  }
}

#layout-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}

#upsert-layout {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  width: 100vw;
}

#main {
  background-color: $white;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  position: relative;

  @media #{$mobile} {
    overflow-y: auto;
  }
}

#body {
  display: flex;
  height: 100vh;
  overscroll-behavior: none;
}

#search {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

#left {
  max-height: 100%;
  position: relative;
  width: 320px;

  @media #{$tablet} {
    display: none;
  }

  @media #{$mobile} {
    display: none;
  }
}

#main-center {
  flex: 1;
  max-width: 100%;
  overscroll-behavior: none;

  @media #{$desktop} {
    display: flex;
  }
}

#calendar-center {
  display: flex;
  flex: 1;
  overscroll-behavior: none;

  @media #{$mobile} {
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;

    #events, #calendar {
      flex: 1;
    }
  }
}

#calendar-actions {
  display: flex;

  @media #{$desktop} {
    flex: 0 0 320px;
  }
}

#center {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  position: relative;
}

w-sidebar-module {
  height: 100vh;
  width: 100%;

  @media #{$desktop} {
    position: relative;
    width: 80px;
  }

  @media #{$mobile} {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 403;
  }
}

#activity_body {
  animation: show 0.5s;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

#messages_body {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

#info_body {
  animation: show 0.5s;
  display: flex;
  flex: 1;
  overflow-y: hidden;
  position: relative;
}

#chat_messages {
  display: flex;
  flex: 1 1;
  position: relative;
}

#tabbar {
  bottom: 0;
  height: $tabbar-height;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;

  @media (max-height: 400px) {
    display: none;
  }
}

#sub_nav {
  background-color: $white;
  border-left: 1px solid $very-light-pink;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-width: 80px;

  @media #{$mobile} {
    border-left: 0;
    height: calc(100% - 60px);
  }
}

#schedule {
  background-color: $white;
  border-left: 1px solid $very-light-pink;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 320px;

  @media #{$mobile} {
    width: 100%;
  }
}

#report { flex: 1; }

#events {
  height: 100%;
  min-width: 320px;
  //height: calc(100% - 60px);
  overflow: hidden;

  @media #{$desktop} {
    max-width: 320px;
    overflow: scroll;
  }
}

#poll {
  position: relative;
  width: 100%;
}

.with-polls-center {
  bottom: 50px;
  display: flex;
  position: absolute;
  width: 100%;

  @media #{$desktop} {
    padding: 0 320px;
  }
}

.main-tab-container {

  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.custom-polls-center {
  display: flex;
  margin-top: -50px;
  width: 100%;

  @media #{$desktop} {
    max-width: 1120px;
    padding-left: 320px;
  }
}

.tab-wrapper {
  display: flex;
  height: 100%;
  width: 100%;

  .container {
    min-width: 460px;
  }
}

.container {
  background-color: #f6f6f6;
  border-left: solid 1px #b9b9b9;
  border-right: solid 1px #b9b9b9;
  border-top: solid 1px #b9b9b9;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  .profile-content {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
  }

  .mobile-profile-content {
    background-color: transparent;
    margin-top: -40px;
    overflow: auto;
    -ms-overflow-style: none;
    position: relative;
    scrollbar-width: thin;
    z-index: 1;
  }

  .public-profile {
    max-height: 100%;
  }
}

.empty-right {
  flex: 1;
  height: 100%;
  max-width: 320px;
}

.tab-wrapper-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .cards_wrapper {
    display: flex;
    padding: 16px;
  }
}

#navigation {
  @media #{$desktop} {
    width: 320px;
  }
}

w-public-profile-module {
  height: 100%;
}

#chat {
  height: 100%;
  width: 100%;
}

w-credits-achievements-module,
w-credits-leaderboards-module,
w-credits-faqs-module,
w-credits-streaks-module {
  flex: 1;
  height: 100%;
  width: 100%;

  @media #{$mobile} {
    margin-bottom: 50px;
  }
}

.credits-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
  position: relative;

  &-background {
    background-color: #ffc866;
    background-color: linear-gradient(to bottom, #ffe08b 0%, #ffc866 100%);
    border-radius: 0 0 100% 100%;
    height: 500px;
    left: -120px;
    max-height: 500px;
    min-height: 500px;
    position: absolute;
    right: -120px;
    top: -160px;
  }

  .search-card {
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
    margin: 10px 15px 0;
    padding: 20px;
    position: relative;
    z-index: 1;
  }

  @media #{$mobile} {
    padding-bottom: 50px;

    &-mobile {
      height: 64%;
    }
  }
}
