@import "styles/mixins/main";

.validation {
  display: flex;
  padding: 20px;

  .section {
    flex: 1;
  }

  .val_field {
    align-items: center;
    display: flex;
    line-height: 2;

    svg {
      height: 15px;
      margin-right: 5px;
      min-width: 15px;
    }
  }

  @media (max-width: 450px) {
    display: block;
  }
}
