@import "styles/colors";

.wrapper {
  align-items: center;
  background: linear-gradient(90deg, #026684 0%, #014660 35%);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.collective {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 10px;
  display: flex;
  margin: 5px 0 0;
  padding: 10px;
  width: 100%;

  div {
    color: $deep-teal;
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin-left: 10px;
  }

  &.active {
    background-color: $greyish-teal;
  }

  &:focus {
    background-color: darken($white, 5);
  }

  &.active:focus {
    background-color: darken($greyish-teal, 5);
  }

  &:hover {
    background-color: darken($white, 5);
  }

  &.active:hover {
    background-color: darken($greyish-teal, 5);
  }
}

.collectives_list {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;

  h1 {
    color: $greyish-teal;
    font-family: "Questrial", sans-serif;
    font-size: 28px;
  }

  hr {
    color: $deep-teal;
  }
}

.loading {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
}
