@import "styles/colors";

@mixin z($color) {
  align-items: center;
  color: $color;
  content: "z";
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.multi_direct {
  align-items: center;
  background-color: $warm-grey;
  border-radius: 50%;
  color: $simple-grey;
  display: flex;
  font-size: 13px;
  height: 15px;
  justify-content: center;
  padding-top: 2px;
  width: 15px;
}

.user_status {
  border-radius: 50%;
  height: 14px;
  position: relative;
  width: 15px;

  &.online {
    background-image: linear-gradient(to bottom, #5fe8c3 4%, $greyish-teal 99%);

    &.do_not_disturb, &.muted {
      &::after {
        @include z($white);
      }
    }
  }

  &.offline {
    background-image: linear-gradient(to bottom, $simple-grey 4%, $very-light-grey 99%);

    &.do_not_disturb, &.muted {
      background-image: none;
      border: 2px solid $very-light-grey;

      &::after {
        @include z($very-light-grey); }
    }
  }
}
