/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
@mixin font-style ($color: #{$warm-grey}, $font-size: 16, $limit: 6, $height: normal, $unit: "px") {
  //@include fluid-size-property("font-size", $font-size, $limit, $unit);

  color: $color;
  font-family: OpenSans, sans-serif;
  font-size: #{$font-size}#{$unit};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: $height;
}

@mixin body-style ($color: #{$dark-grey}, $font-size: 16, $limit: 6, $height: normal, $unit: "px") {
  color: $color;
  font-family: "Inter", sans-serif;
  font-size: #{$font-size}#{$unit};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: $height;
}
