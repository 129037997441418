.d-flex {
  display: flex;
}

.flex-direction {
  &-row { flex-direction: row; }
  &-column { flex-direction: column; }
  &-row-reverse { flex-direction: row-reverse; }
  &-column-reverse { flex-direction: column-reverse; }
}

.flex {
  &-wrap { flex-wrap: wrap; }
  &-nowrap { flex-wrap: nowrap; }
  &-wrap-reverse { flex-wrap: wrap-reverse; }
  &-fill { flex: 1 1 auto; }
  &-grow-0 { flex-grow: 0; }
  &-grow-1 { flex-grow: 1; }
  &-shrink-0 { flex-shrink: 0; }
  &-shrink-1 { flex-shrink: 1; }
}

.justify-content {
  &-start { justify-content: flex-start; }
  &-end { justify-content: flex-end; }
  &-center { justify-content: center; }
  &-between { justify-content: space-between; }
  &-around { justify-content: space-around; }
}

.align-items {
  &-start { align-items: flex-start; }
  &-end { align-items: flex-end; }
  &-center { align-items: center; }
  &-baseline { align-items: baseline; }
  &-stretch { align-items: stretch; }
}

.align-content {
  &-start { align-content: flex-start; }
  &-end { align-content: flex-end; }
  &-center { align-content: center; }
  &-between { align-content: space-between; }
  &-around { align-content: space-around; }
  &-stretch { align-content: stretch; }
}

.align-self {
  &-auto { align-self: auto; }
  &-start { align-self: flex-start; }
  &-end { align-self: flex-end; }
  &-center { align-self: center; }
  &-baseline { align-self: baseline; }
  &-stretch { align-self: stretch; }
}

