@import "styles/colors";
@import "styles/mixins/main";

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 40%;
  text-align: center;

  svg {
    height: 100px;
    margin-bottom: 30px;
    width: 100px;
  }

  h1 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
    font-size: 18px;
  }
}

.feed_list {
  padding: 0.8em;
}

.feed_item {
  background: $flat-white;
  border-radius: 4px;
  margin-bottom: 0.8em;
}

.item_content {
  padding: 0.8em;
}

.item_text {
  p {
    @include font-style($dark-grey, 14);
    line-height: 1.2em;
    word-break: break-word;
  }

  a {
    color: #014660;
  }
}

.item_date {
  @include font-style($dark-grey, 13);
  display: block;
  padding-top: 0.5em;
}

.item_footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.item_button_group {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.8em 0.8em 0;
}

.item_button {
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  color: $flat-white;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 5px;
  padding: 0.3em 0.8em;
  text-align: center;
}

.social_wrapper {
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 0.8em 0.8em;
}

.social_item {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;

  svg {
    margin-right: 0.3em;
  }
}

.badge_wrapper {
  margin-bottom: 1em;
  overflow: hidden;
  position: relative;
}

.badge_background {
  background: #014660;
  border-radius: 50%;
  bottom: 0;
  height: 300px;
  position: absolute;
  transform: translateX(-16%);
  width: 140%;
}

.badge_content {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  padding: 2em 3em;
  position: relative;
}

.badge_value {
  align-items: center;
  background-color: #4cb49c;
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
  color: #fff;
  display: flex;
  font-size: 2em;
  height: 34.64px;
  justify-content: center;
  margin: 17.32px 0;
  position: relative;
  width: 60px;
}

.badge_value::before,
.badge_value::after {
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  content: "";
  left: 0;
  position: absolute;
  width: 0;
}

.badge_value::before {
  border-bottom: 17.32px solid #4cb49c;
  bottom: 100%;
}

.badge_value::after {
  border-top: 17.32px solid #4cb49c;
  top: 100%;
  width: 0;
}

.badge_caption {
  color: #fff;
  line-height: 1.2em;
  padding-top: 1em;
  text-align: center;
}
