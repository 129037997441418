#match {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;

  #match-header {
    align-items: center;
    background-color: $deep-teal;
    display: flex;
    height: 450px;
    justify-content: center;

    @media #{$desktop} {
      height: 400px;
      min-height: 400px;
    }

    @media #{$mobile} {
      display: block;
      padding-bottom: 50px;
    }
  }

  .circle {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.04));
    border-radius: 100%;
    height: 400px;
    left: 50%;
    margin-left: -200px;
    opacity: 0.64;
    position: absolute;
    transform: scale(3);

    width: 400px;

    @media (max-width: 1220px) {
      transform: scale(2);
    }

    @media #{$mobile} {
      height: 430px;
      margin-left: -215px;
      transform: translateY(-50px);
      width: 430px;
    }
  }
}
