@import "styles/colors";
@import "styles/media";


.wrapper {
  background-color: rgba($white, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100vh;
  max-width: 100%;
  overflow-y: auto;
  padding: 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.container {
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  margin: auto;
  max-height: max-content;
  max-width: 96%;
  min-height: min-content;
  position: relative;
  z-index: 401;
}

.close_btn {
  border-radius: 100%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px;
}

.back_panel {
  align-items: center;
  color: $deep-teal;
  display: none;
  height: 5vh;
  padding-left: 10px;

  svg {
    height: 20px;
    margin-right: 10px;
    width: 30px;
  }
}

.back_btn {
  align-items: center;
  cursor: pointer;
  display: flex;
}

@media #{$mobile} {
  .wrapper {
    border-radius: 0;
    padding: 0;
  }

  .close_btn {
    display: none;
  }

  .back_panel {
    display: flex;
  }
}
