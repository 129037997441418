@import "styles/mixins/main";

.wrapper {
  background-color: rgba($white, 0.5);
  border-right: 1px solid $very-light-pink;
  height: 100%;

  h1 {
    @include font-style($warm-grey, 22);
    padding: 20px;

  }
}
