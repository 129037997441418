@import "styles/media";

.modal {
  padding: 10px 15px;

  @media #{$desktop} {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
  }
}
