$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

.alerts {
  &-enter {
    opacity: 0.01;
    transform: translate3d(0, -10px, 0);
    transition: all 0.4s $ease-out-quart;
  }

  &-enter-active {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transition: all 0.4s $ease-out-quart;
  }

  &-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s $ease-out-quart;
  }

  &-exit-active {
    opacity: 0.01;
    transform: translate3d(0, -10px, 0);
    transition: all 0.4s $ease-out-quart;
  }
}
/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
.slide-out-left {
  animation: slide-out-left 0.5s $ease-out-quart both;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    // opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    // opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.slide-in-left {
  animation: slide-in-left 0.5s $ease-out-quart both;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */

.wobble-hor-bottom {
  animation: wobble-hor-bottom 0.8s both;
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-5px) rotate(-2deg);
  }

  30% {
    transform: translateX(5px) rotate(2deg);
  }

  45% {
    transform: translateX(-3px) rotate(-1.2deg);
  }

  60% {
    transform: translateX(3px) rotate(0.8deg);
  }

  75% {
    transform: translateX(-2px) rotate(-0.4deg);
  }
}

/**
 * ----------------------------------------
 * animation Show-Hide by changing opacity
 * ----------------------------------------
 */

@keyframes show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hide {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

