@import "styles/colors";

w-textarea {
  flex: 1;
}

.w-textarea {
  border: 0;
  border-radius: 18.5px;
  box-shadow: 0 0 2px #4d4d4d;
  color: $dark-grey;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  height: 100%;
  min-height: 20px;
  padding: 10px;
  position: relative;
  resize: none;
  width: 100%;

  &:focus {
    box-shadow: 0 0 5px rgba($greyish-teal, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: rgba($warm-grey, 0.1);
  }
}
