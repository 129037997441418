@import "styles/mixins/main";
@import "styles/colors";

.wrapper {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: 0 0 2px #4d4d4d;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &.opened,
  &:focus-within {
    border-bottom: 1px solid #4cb49c;
    border-left: 1px solid #4cb49c;
    border-right: 1px solid #4cb49c;
    border-top: 1px solid #4cb49c;
    box-shadow: none;
    outline: none;
  }

  &.opened .btn svg {
    transform: rotate(180deg);
  }

  &.opened .placeholder svg {
    transform: rotate(180deg);
  }

  &.top {
    border-radius: 0 0 13px 13px;
  }

  &.bottom {
    border-radius: 13px 13px 0 0;
  }

  &.disabled {
    background-color: rgba(147, 146, 146, 0.1);
    pointer-events: none;
  }

  select {
    display: none;
  }
}

.selectmenu {
  background-color: #fff;
  border: 1px solid $greyish-teal;
  color: #4d4d4d;
  left: -1px;
  overflow: hidden;
  padding: 12px 0;
  position: absolute;
  right: 0;
  width: calc(100% + 2px);
  z-index: 100;

  &.top {
    border-bottom-width: 0;
    border-radius: 13px 13px 0 0;
    bottom: 100%;
  }

  &.bottom {
    border-radius: 0 0 13px 13px;
    border-top-width: 0;
    margin-bottom: 20px;
    top: 100%;
  }
}

.input {
  background-color: transparent;
  border: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.options {
  display: flex;
  flex-direction: column;
  max-height: 144px;
  overflow-y: auto;

  .option {
    color: $deep-teal;
    cursor: pointer;
    padding: 10px;
    text-align: left;
  }

  .selected,
  .option:hover {
    background-color: rgba($greyish-teal, 0.5);
  }

  .selected {
    pointer-events: none;
  }
}

.btn,
.placeholder {
  color: #4d4d4d;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 1.3em;
  padding: 6px 8px 6px 13px;

  svg {
    flex: 0 0 12px;
    height: 20px;
    margin-left: 5px;
    transition: transform 0.1s linear;
  }
}

.placeholder {
  color: #797979;
  font-family: "Open Sans", sans-serif;
  padding: 6px 8px 6px 13px;
}

.search {
  padding: 10px;

  svg {
    margin: 5px;
    position: absolute;
    width: 20px;
  }

  input {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 4px $greyish-teal;
    font-size: 16px;
    height: 28px;
    line-height: 16px;
    padding-left: 32px;
    width: 100%;
  }
}

.empty {
  color: #4d4d4d;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 20px 10px;
}
