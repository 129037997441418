@import "styles/colors";
@import "styles/media";

.wrapper {
  background: rgba($white, 0.95);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding: 20px 25% 0;

  @media #{$mobile} {
    padding: 10px 0 0;
  }
}

.tabs {
  display: flex;
  margin-bottom: 20px;
  min-height: fit-content;

  > div {
    align-items: center;
    background-color: $very-light-grey;
    border-radius: 20px;
    color: $white;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 10px;
    padding: 0 10px;

    &:hover {
      background-color: #9b9b9b;
      cursor: pointer;
    }

    &.selected {
      background-color: $greyish-teal;
    }
  }

  @media #{$mobile} {
    margin-bottom: 10px;
    padding: 0 5px;

    > div {
      font-size: 12px;
      height: 24px;
      margin-right: 5px;
      padding: 2px 5px;
      text-align: center;
    }
  }
}

.title {
  color: $warm-grey;
  font-family: "Questrial",  sans-serif;
  font-size: 26px;
  margin: 0 0 15px 15px;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 5px;

  > div {
    background-color: $flat-white;
    box-shadow: 0 0 2px rgba($dark-grey, 0.1);
    display: flex;
    line-height: 18px;
    min-height: fit-content;
    padding: 20px;

    &:hover {
      background-color: rgba($flat-white, 0.5);
      cursor: pointer;
    }

    > div {
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
      }
    }

    h1 {
      color: $deep-teal;
      font-family: "Questrial", sans-serif;
      font-size: 22px;
    }

    span {
      color: $warm-grey;
      font-family: "OpenSans", sans-serif;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}

.loader_wrapper {
  align-items: center;
  background: rgba($white, 0.95);
  display: flex;
  height: 100%;
  justify-content: center;
}

.search {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20%;

  > svg {
    height: 78px;
    margin-bottom: 15px;
    width: 78px;
  }

  > h4 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    line-height: 26px;
  }

  @media #{$mobile} {
    padding-top: 40%;

    > svg {
      height: 60px;
      width: 60px;
    }

    > h4 {
      font-size: 20px;
    }
  }
}
