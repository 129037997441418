@import "styles/mixins/main";

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px 20px 110px;
  position: relative;
  width: 100%;

  @media #{$mobile} {
    margin-bottom: 60px;
  }

  @media #{$desktop} {
    border-right: 1px solid $simple-grey;
    height: 100%;
    padding-bottom: 20px;
    width: 320px;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 130px;
  }
}

.direct_block {
  margin-bottom: 20px;
  margin-top: 20px;
}

.title_wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  @include button();
  color: $deep-teal;
  font-family: "Questrial", sans-serif;
  font-size: 20px;
  font-weight: normal;

  &:hover {
    color: darken($warm-grey, 10);
  }
}

.list {
  display: flex;
  flex: 1;
  flex-direction: column;

  a {
    align-items: center;
    border-radius: 18.5px;
    color: $deep-teal;
    display: block;
    font-size: 14px;
    max-width: 320px;
    padding: 6px;
    position: relative;

    .item-inner {
      display: flex;
      flex-flow: row nowrap;
    }

    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
      text-decoration: none;
    }

    &:hover {
      background-color: #eceaea;

      &.active {
        background-color: $greyish-teal;
      }
    }

    @media #{$mobile} {
      max-width: 100%;
    }
  }

  svg {
    height: 12px;
    position: relative;
    top: 1px;
    width: 12px;
  }
}

.status {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  width: 15px;
}

.chat_name {
  @include body-style($dark-grey, 15);
  display: flex;
  flex: 1;
  overflow: hidden;

  span {
    flex: 1;
    line-height: 1.4;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.unread {
    .unread-marker {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.active {
  background-color: $greyish-teal;
  color: $deep-teal;
  transition: all 300ms ease-in-out 0s;

  .chat_name {
    color: $deep-teal;
    font-weight: 600;
  }
}

.unread-marker {
  align-items: center;
  background-color: $greyish-teal;
  border-radius: 10px;
  color: $flat-white;
  display: flex;
  flex: 0 0 auto;
  font-size: 11px;
  height: 20px;
  justify-content: center;
  opacity: 0;
  padding: 0 7px;
  transform: translateX(20px);
  transition: transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s;
}

.create_btn {
  background-color: transparent;
  border: 0;
  display: flex;

  svg {
    height: 22px;
    width: 22px;
  }
}

.unread {
  font-weight: bold;
}
