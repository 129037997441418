@import "styles/mixins/main";
@import "../../styles/mixins/credits";

$decoration-color: linear-gradient(to bottom, #ffe08b 0%, #ffc866 100%);

.credits-achievements {
  @include credits-wrapper();

  .background-decoration {
    background-image: $decoration-color;
    top: -100px;
  }

  .background-decoration-bottom {
    height: 330px;
    left: -80px;
    margin-top: -330px;
    overflow: hidden;
    position: absolute;
    right: -80px;
    z-index: 1;

    .background {
      background-image: $decoration-color;
      border-radius: 100%;
      bottom: -160px;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

.card {
  @include card();
}

.search {
  @include search();
}

.streaks-title {
  display: inline-flex;
  margin: 10px auto 14px;
  padding: 0 14px;
  position: relative;

  .icon>svg {
    height: 120px;
    margin-right: 12px;
    width: 120px;

    @media #{$mobile} {
      height: 100px;
      margin-right: 0;
      width: 100px;
    }
  }

  .title {
    @include title();
    align-items: center;
    color: $deep-teal;
    display: flex;
    font-size: 36px;
    text-transform: uppercase;

    @media #{$mobile} {
      font-size: 26px;
    }
  }
}

.streaks {
  display: flex;
  position: relative;
}

.streak-counter {
  flex: 1;

  .label {
    color: $dark-grey;
    font-size: 16px;
    margin: 10px 14px 0;
    text-align: center;

    @media #{$mobile} {
      align-items: center;
      display: flex;
      height: 32px;
    }
  }
}

.content {
  background: #fff;
  border-top: 16px solid $greyish-teal;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  margin: 14px;
  padding: 10px 20px;
  position: relative;
}

.labels {
  color: $dark-grey;
  margin: -30px 14px 0;
  position: relative;
  text-align: right;

  >span {
    min-width: 80px;
    padding: 0 10px;
  }
}

.line {
  align-items: center;
  color: $greyish-teal;
  display: flex;
  font-size: 22px;
  padding: 10px 0;

  >div {
    min-width: 80px;
    padding: 0 10px;
    text-align: right;
  }

  >div:nth-of-type(1) {
    color: $warm-grey;
    flex: 1;
    text-align: left;
  }
}

.value {
  color: $greyish-teal;
  font-size: 36px;
  margin: 20px auto;
  width: fit-content;
}

.description {
  color: $dark-grey;
  font-size: 16px;
  line-height: 24px;
  margin: 14px 14px 80px;
}

.footer-section {
  margin-top: auto;
}

.footer {
  background: #fff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  margin: auto 14px 20px;
  padding: 20px;
  position: relative;
  z-index: 2;

  @media #{$mobile} {
    padding: 10px;
  }

  .icon-person {
    margin: -85px 12px -20px 0;
    width: fit-content;

    @media #{$mobile} {
      margin: -36px 10px -10px -4px;
    }

    img {
      height: 165px;

      @media #{$mobile} {
        height: 130px;
      }
    }
  }

  .label {
    color: $dark-grey;
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .quote {
    color: $greyish-teal;
    font-size: 26px;

    @media #{$mobile} {
      font-size: 22px;
    }
  }
}
