@import "styles/mixins/main";

%card {
  @include font-style($dark-grey, 14);

  background-color: $flat-white;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;

  a {
    display: inline-block;
    text-decoration: underline;
  }

  w-button {
    margin-left: 10px;
  }
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  h2 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
  }

  h3 {
    @include font-style($deep-teal);

    font-family: "Questrial", sans-serif;
    margin-bottom: 10px;
  }

  h5 {
    @include font-style($dark-grey, 13);

    font-family: "Questrial", sans-serif;
  }

  @media #{$desktop} {
    max-width: 320px;
  }
}

.chat-info-header {
  display: flex;
  margin-bottom: 20px;
  min-height: 24px;

  h2 {
    flex: 1;
    font-size: 20px;
  }

}

.access {
  border: $greyish-teal 1px solid;
  border-radius: 5px;
  color: $greyish-teal;
  padding: 3px 15px;
  text-transform: capitalize;
}

.chat-info-body,
.chat-info-body-center,
.body-center {
  display: flex;
}

.chat-info-body-center {
  flex-direction: column;
  width: 100%;
}

.chat-info-details {
  flex: 1;
  padding: 0 10px;

  h2 {
    font-size: 16px;
    margin: 10px 0;
  }

  p {
    font-size: 14px;
  }
}

.chat-info {
  display: block;
  padding: 20px;
}

.tags-list {
  color: $deep-teal;
  display: flex;
  font-size: 14px;
  margin-top: 10px;
}

.members-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  min-height: 400px;
}

.members {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  padding: 5px 5px 20px;
}

.members-header {
  align-items: center;
  display: flex;
  padding: 0 20px 10px;

  h2 {
    flex: 1;
    font-size: 16px;
    padding-right: 10px;
  }
}

.member {
  @include member-card();
  box-shadow: 0 0 1px rgba($black, 0.5);

  margin: 10px 0 0;

  .avatar_wrapper {
    position: relative;
  }

  [class*="user_status"] {
    position: absolute;
    right: -1px;
    top: 30px;
  }
}

.close {
  button {
    background-color: transparent;
    border: 1px solid $deep-teal;

    border-radius: 100%;
    display: flex;
    max-height: 27px;
    max-width: 27px;
    padding: 5px;
    position: absolute;
    right: 20px;
  }

  button:hover {
    background-color: darken($flat-white, 10);
  }

  svg {
    min-height: 10px;
    min-width: 10px;
  }
}

.user-info {
  display: block;
  padding: 20px;

  h1 {
    margin: 0 0 20px;
  }

  h2 {
    margin-right: 5px;
  }

  h1,
  h2 {
    font-size: 16px;
  }

  .name {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px;
  }

  span {
    color: $warm-grey;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .profile {
    padding: 5px 20px;
  }

  .details {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .ops {
    margin-top: 20px;
    max-height: 100%;
    overflow-y: auto;
  }

  .share-card {
    @extend %card;

    align-items: center;
    display: flex;
  }

  .collectives-card {
    @extend %card;
  }

  .groups-card {
    @extend %card;
  }

  w-avatar {
    margin-right: 10px;
  }

}

.close_goals {
  align-items: center;
  color: $flat-white;
  display: flex;
  font-size: 20px;
  line-height: 1.1em;
  margin-bottom: 35px;

  button {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: $flat-white;
    display: flex;
    flex: 1;
    font-size: 0.75em;
    line-height: 1.1em;
    padding: 0;
  }

  span {
    flex: 1 0 auto;
    text-align: center;
  }

  svg {
    flex: 0 0 25px;
    height: 20px;
    margin-right: 5px;
  }

  &::after {
    content: "";
    flex: 1;
  }
}

.goals-details-wrapper {
  background-color: $deep-teal;
  height: 100%;
  padding: 20px;
  width: 100%;

  h2 {
    color: $flat-white;
    display: block;
    font-size: 18px;
  }

  .not-updated {
    color: $pastel-yellow;
    display: block;
    font-size: 12px;
    padding: 10px 0;
  }
}

.goal-card {
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 20px;

  .goal-header {
    background-color: $flat-white;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $warm-grey;

    font-family: "Questrial", sans-serif;
    font-size: 17px;
    line-height: 1.3em;
    padding: 10px 15px;

    @media #{$mobile} {
      font-size: 16px;
    }
  }

  .goal-body {
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    padding: 20px 15px;

    .goals_text {
      font-size: 14px;

      @media #{$mobile} {
        font-size: 14px;
      }
    }

    .goal_body_text {
      color: $deep-teal;
      margin-top: 10px;
    }
  }
}

.goals {
  margin-top: 20px;
}
