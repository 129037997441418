@import "styles/mixins/main";

.leaders {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;

  .leader {
    align-items: center;
    display: flex;
    flex: 0 0 25%;
    flex-direction: column;
    padding-top: 20px;
    text-align: center;

    &.first {
      padding-top: 0;
    }

    &.second {
      order: -1;
    }
  }

  .name {
    @include body-style($deep-teal, 16);
    font-weight: bold;
    margin-bottom: 0.3em;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .score {
    @include body-style($dark-grey, 15);
  }

  .avatar {
    background: $white;
    border-radius: 50%;
    width: fit-content;

    img {
      padding: 8px;
    }
  }

  .me.avatar {
    background: linear-gradient(to bottom, #5fe8c4, #43b395);
  }

  .position-badge {
    @include body-style($dark-grey, 15);
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    height: 30px;
    margin: -30px auto 10px;
    padding: 6px;
    transform: translateX(20px);
    width: 30px;
  }

  .badge-1 {
    background: linear-gradient(#ffe08b, #ffc866);
  }

  .badge-2 {
    background: #ddd;
  }

  .badge-3 {
    background: #e19f6a;
  }
}

.boost-button {
  @include body-style($flat-white, 15);
  background: $deep-teal;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  padding: 0.5em;
  transition: all 300ms ease-in-out 0s;

  &:hover {
    color: $greyish-teal;
  }
}

.message {
  margin-bottom: 0 !important;
  text-align: center;
}

.empty {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  padding: 1em;
  text-align: center;
}
