.load_btn {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 10px;
  display: flex;
  height: 35px;
  justify-content: center;
  outline: none;
  padding: 5px;
  width: 35px;

  &:hover {
    background-color: hsla(0, 0%, 96.5%, 0.1);
    cursor: pointer;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
