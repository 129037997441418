@import "styles/mixins/main";
@import "../../styles/mixins/credits";

.title {
  @include title();
}

.card {
  @include card();
}

.content {
  overflow: auto;
  padding-top: 20px;
  position: relative;
}

.name {
  color: $deep-teal;
  font-size: 21px;
  margin-bottom: 12px;
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blurb {
  color: $dark-grey;
  font-size: 0.95em;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.score {
  color: $greyish-teal;
  font-size: 21px;
}

.leaders {
  display: flex;
  margin: auto;
  max-width: 700px;
  position: relative;
  width: 100%;

  .leader {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 74px auto 0;
    text-align: center;
    width: 26%;

    @media #{$mobile} {
      margin: 20px auto 0;
    }
  }

  .leader:nth-child(2) {
    margin-top: 34px;

    @media #{$mobile} {
      margin-top: 0;
    }
  }

  .avatar {
    background: $white;
    border-radius: 50%;
    width: fit-content;

    img {
      padding: 8px;
    }
  }

  .me {
    box-shadow: 0 2px 40px 0 #fccf6b;
  }

  .me.avatar {
    background: linear-gradient(to bottom, #5fe8c4, #43b395);
  }

  .place-badge {
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    color: #014660;
    font-size: 20px;
    height: 40px;
    margin: -22px auto 16px;
    padding: 11px;
    width: 40px;
  }

  .badge-1 {
    background: linear-gradient(#ffe08b, #ffc866,);
  }

  .badge-2 {
    background: #ddd;
  }

  .badge-3 {
    background: #e19f6a;
  }
}

.individuals-list {
  .individual-item {

    align-items: center;
    background: #fff;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
    display: flex;
    margin: 20px;
    padding: 14px 20px;
  }

  .me {
    background: linear-gradient(to bottom, #5fe8c3 4%, #43b395 99%);
    box-shadow: 0 0 20px -1px #fccf6b;
    display: block;
    margin: 20px;

    .individual-item {
      display: flex;
      flex: 1;
      margin: 0 8px 0 50px;
      max-width: 100%;

      .avatar {
        border: solid 6px #fff;
        border-radius: 50%;
        margin-left: -52px;
      }
    }
  }

  .name {
    margin: 0 20px;
  }

  .score {
    margin: 0 10px 0 auto;
    text-align: center;
  }
}
