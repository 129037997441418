@import "styles/mixins/main";
@import "../../styles/mixins/credits";

.title {
  @include title();
}

.card {
  @include card();
}

.list {
  @include achievement(160, 24);
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
  z-index: 0;

  h1,
  p {
    margin-bottom: 18px;
  }

  @media all and (max-width: 1360px) {
    @include achievement(140, 20);

    .achievement_card {
      padding: 20px 10px;
    }

    h1,
    p {
      margin-bottom: 18px;
    }
  }
}

.empty-result {
  margin-top: 55px;
  position: relative;
  text-align: center;

  .icon>svg {
    height: 180px;
    width: 180px;
  }

  .title {
    color: $deep-teal;
    font-size: 36px;
  }

  .description {
    color: $deep-teal;
    font-size: 26px;
    margin: auto;
    width: 82%;
  }

  .button {
    @include button($deep-teal, $white);

    font-size: 14px;
    margin-top: 30px;
    padding: 3px 25px;
  }
}
