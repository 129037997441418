@import "styles/mixins/main";
@import "styles/media";

.wrapper {
  form {
    display: flex;
    flex-direction: column;
  }

  button {
    align-self: flex-end;

    margin: {
      bottom: 20px;
      left: auto;
      right: 20px;
      top: 20px;
    }

    ;
  }
}
