@import "styles/colors";

.wrapper {
  form {
    display: flex;
    flex-direction: column;
  }

  w-validation-box {
    margin-bottom: 1px;
  }

  button {
    align-self: flex-end;
    margin: {
      bottom: 20px;
      left: auto;
      right: 20px;
      top: 20px;
    };
  }

  .error {
    background-color: rgba($pastel-red, 0.2);
    color: $pastel-red;
    font-size: 14px;
    padding: 20px;
  }
}
