@import "styles/mixins/main";

.preloader {
  align-items: center;
  background-color: $deep-teal;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  z-index: 4000000000;

  img {
    width: 150px;
  }
}

.progress_bar {
  background-color: $greyish-teal;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 100ms;
  width: var(--progressBarWidth);
  z-index: 11;

}

.preload_animation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 56px;
}

.loading_bar {
  animation: loading 1s ease-in-out infinite;
  border-radius: 8px;
  display: inline-block;
  height: 16px;
  width: 8px;

  &:nth-child(1) {
    animation-delay: 0;
    background-color: #fff;
  }

  &:nth-child(2) {
    animation-delay: 0.09s;
    background-color: #4cb49c;
  }

  &:nth-child(3) {
    animation-delay: 0.18s;
    background-color: #e55e50;
  }

  &:nth-child(4) {
    animation-delay: 0.27s;
    background-color: #dda700;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.2);
  }

  40% {
    transform: scale(1);
  }
}

.status {
  @include body-style($flat-white, 16);
  bottom: 30px;
  color: #fff;
  position: absolute;
  text-align: center;
  width: 100%;
}
