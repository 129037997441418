@import "styles/mixins/main";

.wrapper {
  background-color: $flat-white;
  border-radius: 40px;
  box-shadow: 0 0 0 1px $deep-teal;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  height: 100%;
  padding: 10px 20px 0;
  width: 100%;

  @media #{$mobile} {
    border-radius: 20px;
    padding: 10px 10px 0;
  }

  input {
    border: 0;
    flex: 1;
    margin-bottom: 10px;
  }

  input:disabled {
    display: none;
  }

  &:focus-within {
    box-shadow: 0 0 0 2px $greyish-teal;
  }
}

.tag {
  align-items: center;
  background-color: rgba($simple-grey, 0.2);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 5px;

  span {
    margin: 0 10px;
  }

  button {
    background-color: transparent;
    border: 0;
  }

  svg {
    height: 10px;
    width: 10px;
  }

  @media #{$mobile} {
    font-size: 12px;
  }
}
