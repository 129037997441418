@import "styles/colors";

.wrapper {
  background-color: $flat-white;
  border: 1px solid rgba($warm-grey, 0.3);
  border-radius: 20px;
  box-shadow: 0 0 5px rgba($dark-grey, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  width: 100%;
}

.emoji-header {
  border-bottom: 1px solid rgba($warm-grey, 0.3);
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;

  button {
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    display: flex;
    flex: 1;
    height: 30px;
    padding: 0;
  }

  button:hover {
    background-color: darken($flat-white, 5);
  }

  button.active-category {
    background-color: darken($flat-white, 10);
  }

  svg {
    fill: $deep-teal;
    margin: auto;
    max-height: 30px;
    max-width: 30px;
  }
}

.emoji-body {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow-y: auto;

  span {
    cursor: pointer;
    margin: 5px;
  }

  img {
    max-height: 28px;
    max-width: 28px;
  }

  span:hover {
    background-color: darken($flat-white, 10);
  }
}

.list {
  background-color: $flat-white;
  height: 300px;
  position: absolute;
  right: 30px;
  top: -300px;
  width: 300px;
}

.emoji {
  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.textarea-emoji-control {
  height: 25px;
  position: absolute;
  right: 4px;
  top: 10px;
  width: 25px;
}
