@import "styles/mixins/main";
@import "styles/media";

.wrapper {
  form {
    display: flex;
    flex-direction: column;
  }

  ;

  a:active,
  a:focus {
    outline: none;
  }

  w-input {
    margin-right: 30px;
    width: 100%;
  }
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
