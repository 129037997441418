@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.nav {
  align-items: center;
  border-radius: 30px;
  display: flex;
  padding: 8px 15px;
  position: relative;
  transition: all 300ms ease-in-out 0s;

  @media #{$mobile} {
    padding: 5px 20px;
  }

  &:hover {
    background-color: hsla(0, 0%, 96.5%, 0.1);
    cursor: pointer;
    text-decoration: none;
  }

  >svg {
    height: 25px;
    margin-right: 10px;
    width: 25px;

    @media #{$mobile} {
      margin-right: 0;
    }
  }

  >span {
    @include body-style($flat-white);

    @media #{$mobile} {
      display: none;
    }
  }
}

.active {
  background-color: $flat-white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $flat-white;
    text-decoration: none;
  }

  >span {
    color: $deep-teal;
  }
}
