@import "styles/colors";
@import "styles/mixins/main";

$border: 1px solid $very-light-pink;
$tabbar-height: 50px;

.wrapper {
  border-top: $border;

  bottom: 0;
  display: flex;
  height: $tabbar-height;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 2;

  @media (max-height: 400px) {
    display: none;
  }
}

.tab {
  @include font-style($dark-grey);
  @include icon-button();

  align-items: center;
  background-color: $white;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  padding: 5px 0;

  svg {
    height: 20px;
    margin-bottom: 5px;
    width: 20px;
  }

  &.active {
    background-color: darken($white, 5);
    color: $deep-teal;
  }
}

.badge {
  align-items: center;

  background: linear-gradient(#5fe8c3, #43b395);
  border-radius: 18.5px;

  color: $flat-white;
  display: flex;
  font-size: 12px;

  height: 18px;
  justify-content: center;
  margin-left: 10px;

  position: absolute;
  top: 3px;
  width: 18px;
  z-index: 3;
}
