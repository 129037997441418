@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
}

@media #{$mobile} {
  .cards-wrapper>.title {
    background: #fff;
    display: block;
    font-size: 20px;
    margin: 0;
    padding: 20px 15px 0;
  }
}

.card {
  @include card();

  .card-title {
    @include description();
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
  }

  @media #{$mobile} {
    .card-title {
      font-size: 16px;
    }
  }
}

.card>div:first-of-type {
  margin-top: 10px;
}

.button,
.save-button {
  @include button($deep-teal, $white);
  padding: 4px 16px;
}

.button-outlined {
  @include outlined-button($deep-teal);
  padding: 3px 14px;
}

.button,
.button-outlined,
.save-button {
  display: flex;
  font-size: 14px;
  height: fit-content;
  margin-left: 10px;
}

.save-button {
  margin: 0 0 30px auto;
  padding: 4px 12px;
}

@media #{$desktop} {
  .save-button {
    margin-top: 20px;
  }
}

.switch-input {
  @include switch-input("slider");
  margin-left: 20px;
  min-width: 40px;
}
