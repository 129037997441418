@import "styles/colors";
@import "styles/mixins/main";
@import "styles/media";

.wrapper {
  form {
    @include validation-block();

    display: flex;
    flex-direction: column;

    @media #{$mobile} {
      padding-bottom: 80px;
    }
  }

  p {
    @include font-style($dark-grey, 14);

    background-color: rgba($warm-grey, 0.2);
    padding: 20px;
  }

  a {
    text-decoration: underline;
  }

  a:focus,
  a:visited,
  a:active {
    outline: none;
  }
}

.error_link {
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: underline;
}

.error {
  background-color: rgba($pastel-red, 0.2);
  color: $pastel-red;
  flex: 1;
  padding: 20px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
