@import "styles/mixins/main";

.wrapper {
  align-items: center;
  background: $flat-white;
  border-top: 1px solid $simple-grey;
  display: flex;
  padding: 0.5em 1em;

  a {
    @include button($deep-teal, $flat-white);
    margin-right: 5px;

    padding: 8px 15px;
  }

  .test_button {
    background-color: $pastel-yellow;
    border: $dark-grey 1px solid;
    color: $dark-grey;
  }
}
