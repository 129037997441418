@import "styles/colors";
@import "styles/mixins/main";

.base {
  background-color: $flat-white;
  border: 1px solid $very-light-grey;
  border-radius: 18px;
  color: $deep-teal;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  resize: none;
  width: 100%;

  &:focus {
    border-color: $greyish-teal;
  }

  &::placeholder {
    @include font-style($warm-grey);
  }
}
