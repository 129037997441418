@import "styles/mixins/main";

.loading {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center;
}

.list_label {
  border-bottom: 1px solid $simple-grey;
  color: $warm-grey;
  font-family: "Questrial", sans-serif;
  font-size: 16px;
  margin: 20px 10px 0;
  padding-bottom: 5px;
}

.list {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding: 10px 10px 0;
}

.chat {
  align-items: center;
  border-bottom: 1px solid rgba($warm-grey, 0.2);
  display: flex;
  padding: 10px;

  .last_updated,
  .go_to {
    color: $warm-grey;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
  }

  .go_to {
    display: none;
  }
}


.info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;

  span {
    @include font-style($warm-grey, 11);
  }

  span:first-of-type {
    color: $deep-teal;
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.chat:hover {
  background-color: darken($flat-white, 5);

  .go_to {
    display: block;
  }

  .last_updated {
    display: none;
  }
}

.top-block {
  border-bottom: 1px solid rgba($warm-grey, 0.2);
  padding: 20px;

  @media #{$mobile} {
    padding: 10px;
  }
}

.search {
  @include input();

  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;

  @media #{$mobile} {
    margin: 0;
  }
}

.box {
  position: relative;
  width: 100%;

  input {
    border: 1px solid rgba($warm-grey, 0.2);
    padding: 0 10px;
    width: 100%;
  }

  input:focus {
    box-shadow: 0 0 5px rgba($greyish-teal, 0.7);
  }
}

.clear {
  @include icon-button();

  position: absolute;
  right: 10px;
  top: 8px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.no_results {
  @include font-style($dark-grey);

  align-items: center;
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: center;
  padding: 20px;
  text-align: center;

  svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px;
  }
}
