@import "styles/mixins/main";

.actions {
  >div {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      @include body-style($dark-grey, 15);
      flex: 1;
      margin-bottom: 0;
    }

    button {
      margin-left: 10px;
      width: 100px;
    }
  }
}


.done {
  background: $greyish-teal;
  border-radius: 4px;
  color: $deep-teal;
  margin-left: 10px;
  padding: 0.4em 0.6em;
  text-align: center;
  width: 100px;
}
