.base {
  cursor: pointer;
  height: 16px;
  width: 16px;

  svg {
    height: 100%;
    width: 100%;
  }
}

.disabled {
  cursor: none;
  opacity: 0.7;
  pointer-events: none;
}
