@import "styles/mixins/main";

%time {
  @include body-style($warm-grey, 12);
  margin-bottom: 5px;
}

.alert_message {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 20px;
  }
}

.alert_message_buttons {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 -20px;
  width: 100%;

  button {
    font-size: 14px;
    padding: 5px 25px;
  }

  button:not(:last-of-type) {
    margin-right: 20px;
  }

  button:last-of-type {
    margin-right: 10px;
  }

  button:first-of-type {
    padding: 3px 25px;
  }
}

.outbox_message_wrapper {
  display: flex;

  .time {
    @extend %time;
  }

  .outbox_info {
    margin-left: 5px;
  }

  @media #{$desktop} {
    max-width: 550px;
  }
}

.message {
  @include message();

  flex-direction: column;

  h1,
  h2 {
    font-family: "Questrial", sans-serif;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  a {
    word-break: break-all;
  }
}

.outbox_message {
  @include outbox-message();
  @include body-style();
}

.inbox_message_wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  min-width: 80px;

  .time {
    @extend %time;

    margin-right: 5px;
    text-align: end;
  }

  .inbox_info {
    margin-right: 10px;
  }
}

.inbox_message {
  @include body-style();
  background-color: $greyish-teal;
  display: flex;
  justify-content: flex-end;

  &::after {
    background-color: transparent;
    box-shadow: 9px 1px 0 0 $greyish-teal;
    right: -22px;
    transform: scale(-1, 1);
  }

  &[state="pending"] {
    background: #ccc;
  }

  &[state="deleted"] {
    background: #ccc;
    color: #7c7c7c;
  }
}

.preview_wrapper {
  border-radius: 10px 10px 0 0;
  margin: -10px -15px 5px;
  overflow: hidden;
}

.spotify {
  position: relative;
}

.youtube {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
