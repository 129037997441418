@import "styles/mixins/main";

.avatars {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1em;
  padding-left: 20px;

  >w-avatar:last-of-type {
    left: -20px;
    position: relative;
  }
}

.active {
  align-items: center;
  display: flex;
  flex-flow: column;
  text-align: center;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  button {
    flex: 0 0 48%;

    &:last-of-type {
      flex: 0 0 100%;
      margin-top: 1em;
    }
  }
}

.no-match {
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  text-align: center;

  button {
    margin-bottom: 0.5em;
  }
}
