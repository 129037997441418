@import "styles/colors";

.underline {
  text-decoration: underline;
}

.header {
  align-items: center;
  border-bottom: 1px solid #ddd;

  border-top: 1px solid #ddd;
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  min-height: 50px;

  .icon {
    height: 15px;
    width: 15px;
  }
}

.title {
  line-height: 20px;
  max-width: 80%;
}

.collapsible {
  overflow: hidden;
  position: relative;

  &.toggled {
    display: block;
  }
}
