@import "styles/colors";
@import "styles/mixins/main";

.wrapper {
  @include font-style($dark-grey, 14);

  display: flex;
  flex-direction: column;
}

.request,
.mandatory {
  align-items: center;
  background-color: $white;
  color: $dark-grey;
  display: flex;
  padding: 20px;
}

.request {
  justify-content: center;
}

.desc {
  background-color: rgba($simple-grey, 0.2);
  padding: 20px;
}

.email {
  background-color: rgba($simple-grey, 0.2);
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.loading {
  align-items: center;
  background-color: rgba($simple-grey, 0.2);
  display: flex;
  height: 80px;
  justify-content: center;
}
