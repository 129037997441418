@import "styles/mixins/main";

$dropdown-border: 1px solid $greyish-teal;
$radius: 20px;

.select-wrapper {
  background-color: $flat-white;
  border-radius: $radius;
  box-shadow: 0 0 2px rgb(77, 77, 77);
  margin-right: 10px;
  position: relative;
  width: 100%;

  &:focus-within {
    box-shadow: 0 0 4px $greyish-teal;
  }
}

.select {
  display: flex;
  padding: 6px 8px 6px 13px;
  width: 100%;

  button {
    @include icon-button();

    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }

  button>svg {
    fill: $black;
    height: 12px;
    margin-left: auto;
  }
}

.opened-select-wrapper {
  box-shadow: none !important;

  border: {
    bottom: 0;
    left: $dropdown-border;
    right: $dropdown-border;
    top: $dropdown-border;
  }


  border-bottom: {
    left-radius: 0;
    right-radius: 0;
  }

  ;

  button>svg {
    transform: rotate(180deg);
  }
}

.placeholder {
  color: rgba($warm-grey, 0.99);
}

.list {
  background-color: $flat-white;
  box-sizing: content-box;
  color: $dark-grey;
  display: none;
  left: -1px;
  margin-bottom: 20px;
  position: absolute;
  right: 0;
  width: 100%;

  z-index: 100;

  border: {
    bottom: $dropdown-border;
    left: $dropdown-border;
    right: $dropdown-border;
  }

  ;

  border-bottom: {
    left-radius: $radius;
    right-radius: $radius;
  }

  ;
}

.opened {
  display: block;
}

.list-content {
  font-size: 14px;

  &>div {
    display: flex;
  }

  input {
    display: flex;
    padding-left: 34px;
    width: 100%;
  }

  svg {
    display: flex;
    height: 20px;
    margin: auto -30px auto 20px;
    z-index: 1;
  }
}

.options {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0;
  max-height: 205px;
  overflow-y: auto;

  button {
    @include icon-button();
    color: $deep-teal;

    padding: 10px;
    text-align: left;
  }

  .focused {
    background-color: $pastel-grey;
  }

  button:hover {
    background-color: $pastel-grey;
    font-weight: bold;
  }

  .selected,
  .selected:hover {
    background-color: rgba($greyish-teal, 0.5);
    font-weight: bold;
  }
}

.options-placeholder {
  color: $dark-grey;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 20px 10px;
}

.search-label {
  display: flex;
  margin: 0 !important;
}

.hidden {
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
