@import "styles/mixins/main";

w-actions-list-module {
  flex: 1 1 auto;
  overflow-y: auto;

  @media #{$desktop} {
    max-width: 320px;
  }
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.selected-date {
  @include font-style($warm-grey, 18);
  font-family: "Questrial", sans-serif;

  padding: 20px;
}

.datepicker {
  background-color: rgba($simple-grey, 0.2);
  padding: 0 0 20px;
}

.list {
  padding: 15px;
  padding-bottom: 65px;

  @media #{$desktop} {
    padding-bottom: 15px;
  }
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;

  svg {
    width: 100px;
  }

  h2 {
    @include font-style($warm-grey, 22);

    margin: 20px 0;
    text-align: center;
  }

  p {
    @include font-style($dark-grey);

    text-align: center;
  }
}

.event {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  &-header {
    @include font-style($warm-grey, 18px);
    margin-bottom: 10px;
  }

  &-body {
    display: flex;
  }

  &-icon {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  &-info {
    flex: 1;
    min-width: 0;
    padding: 0 20px;
  }

  &-info>span {
    @include font-style($warm-grey, 12);
  }

  &-info>span:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &-info>span:first-of-type {
    @include font-style($deep-teal, 14);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-info>span:last-of-type {
    @include font-style($pastel-red, 12);
  }

  &-info,
  &-buttons {
    display: flex;
    flex-direction: column;
  }

  .primary,
  .outline {
    padding: 3px 40px;
    width: 75px;
  }

  button {
    padding: 5px 20px;
  }

  button:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .primary {
    @include button($deep-teal, $flat-white);
  }

  .outline {
    @include outlined-button($deep-teal, $deep-teal);
  }
}
