@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";
@import "styles/transitions";

.wrapper {
  background: $flat-white;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  [class*="loader"] {
    left: 50%;
    position: absolute;
    top: 50%;
  }
}

.group_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  width: 100%;
}

.day {
  color: $warm-grey;
  font-size: 22px;
  margin: 10px 0 30px;
  text-align: center;
  width: 100%;
}

.group_item {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.own_group_item {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
}

.date_wrapper {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1;

  .date {
    @include body-style($dark-grey, 15);
    background: #fff;
    border: 1px solid #e0dada;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
    padding: 6px 12px;
    user-select: none;
  }
}

.fetch_wrapper {
  background: #eceaea;
  border-bottom: 1px solid #e0dada;
  display: flex;

  .fetch_button {
    @include font-style($deep-teal, 15);
    align-items: center;
    background: none;
    border: 0;
    box-shadow: none;
    display: flex;
    flex: 1;
    font-weight: bold;
    justify-content: center;
    padding: 0.8em;

    svg {
      transform: rotate(180deg);
    }
  }

  .fetching_message {
    @include font-style($deep-teal, 15);
    align-items: center;
    display: flex;
    flex: 1;
    font-weight: bold;
    justify-content: center;
    margin: 0;
    padding: 0.8em;
    user-select: none;
  }

  svg {
    height: 14px;
    margin-left: 8px;
    width: 14px;
  }
}

.loader_wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  left: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  svg {
    align-self: flex-start;
    flex: 0 1 300px;

    &:last-of-type {
      align-self: flex-end;
    }
  }
}

.wave {
  align-items: center;
  background: $flat-white;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  div>*:not(:last-child) {
    margin-bottom: 30px;

    @media #{$mobile} {
      margin-bottom: 20px;
    }
  }

  svg {
    height: 150px;
    width: 150px;

    @media #{$mobile} {
      height: 100px;
      width: 100px;
    }
  }

  span {
    @include body-style();
    max-width: 300px;
  }
}

.wave_button {
  @include button($deep-teal, $white);
  padding: 8px 15px;
}

.context-item:not(:disabled) {
  color: $deep-teal;
}


.status,
.status_btn {
  align-items: center;
  color: $warm-grey;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-right: 5px;
  margin-top: 7px;
}

.status_btn {
  background-color: transparent;
  border: 0;
  padding: 0;

  span {
    align-self: flex-end;
  }

  &:hover:not(:disabled) {
    color: $deep-teal;
  }
}

.multiply_status {
  font-size: 10px;
}

.status_images_wrapper {
  display: flex;
  margin-left: 5px;

  [class^="avatar"]:not(:first-child) {
    font-size: 100%;
    margin-left: -8px;
  }
}
