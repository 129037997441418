@import "styles/mixins/main";

.collapsable-wrapper {
  border-top: 1px solid $deep-teal;
  padding: 0 10px;
}

.grouped-mobile {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 61px;
}

.collapsable-wrapper:nth-last-child(1) {
  border-bottom: 1px solid $deep-teal;
}

.header-collapsable {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px;

  .title {
    @include font-style($deep-teal);
  }

  .collapsable-button>svg {
    height: 20px;
    width: 20px;
  }
}

.content {
  display: block;
  overflow: auto;
}
