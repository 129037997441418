@import "styles/mixins/main";
@import "styles/colors";

.wrapper {
  background-color: rgba($simple-grey, 0.2);
  height: 100%;
  width: 100%;
}

.body {
  background-color: $white;
  height: 100%;
  max-width: 800px;
  padding: 30px;

  form {
    padding-bottom: 60px;
  }

  @media #{$mobile} {
    max-width: 100%;
  }

  h1 {
    @include font-style();

    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin: 20px 0;
  }

  svg {
    align-self: center;
    height: 15px;
    margin-right: 10px;
    width: 20px;
  }

  button {
    @include icon-button();
    @include button();
    @include font-style($deep-teal);

    display: flex;
  }
}

.block {
  @include input();

  background-color: rgba($simple-grey, 0.2);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 40px 20px;

  label {
    margin-left: 15px;
  }

  textarea {
    height: 150px;
    margin: 10px 20px 0;
  }
}

.valid {
  background-color: rgba($greyish-teal, 0.1);
  color: $deep-teal;

  textarea {
    border: $greyish-teal;
  }
}

.invalid {
  background-color: rgba($pastel-red, 0.1);

  textarea {
    border: $pastel-red;
  }
}

.error {
  color: $pastel-red;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px;
}

.message_wrapper {
  display: flex;
}

.user_avatar {
  align-self: center;
  margin-right: 10px;
}

.right_info {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.message {
  @include message();
  @include outbox-message();
  margin-top: 5px;
}

.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;

  button {}

  button:first-child {
    @include button();
    border: 1px solid $deep-teal;
    font-size: 14px;

    margin-right: 20px;
    padding: 5px 20px;

    &:disabled {
      background-color: $flat-white;
      color: $deep-teal;
      opacity: 0.5;
    }
  }

  button:last-child {
    @include button($deep-teal, $flat-white);
    font-size: 14px;

    padding: 5px 20px;

    &:disabled {
      background-color: $deep-teal;
      opacity: 0.5;
    }
  }
}
