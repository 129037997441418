/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
@mixin fluid-size-property ($property-name, $desktop-size, $limit, $unit: "px") {
  #{$property-name}: $desktop-size#{$unit};

  @media #{$tablet} {
    #{$property-name}: calc(#{$desktop-size - $limit}#{$unit} - #{($limit / 2)}#{$unit} + #{$limit / 2} * ((100vw - 426px) / 599));
  }

  @media #{$mobile} {
    #{$property-name}: calc(#{$desktop-size - $limit}#{$unit} - #{$limit}#{$unit} + #{$limit} * ((100vw - 320px) / 695));
  }
}

@mixin hide-on-small-height {
  @media #{$limit-height} {
    display: none;
  }
}

@mixin ma-init($length) {
  @for $i from 0 through $length {
    .mt-#{$i} { margin-top: $i+px !important; }
    .mr-#{$i} { margin-right: $i+px !important; }
    .mb-#{$i} { margin-bottom: $i+px !important; }
    .ml-#{$i} { margin-left: $i+px !important; }
  }
}

@mixin card-layout ($color: #{$warm-grey}) {
  @include font-style($color);
  align-items: center;

  background-color: $flat-white;
  box-shadow: 0 0 1px rgba($black, 0.5);
  box-sizing: border-box;
  display: flex;
  height: auto;
  margin: 10px 0 0;
  max-height: 120px;
  min-height: 80px;
  padding: 15px;
  width: 100%;

  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  .name {
    color: $deep-teal;
  }

  .sub_info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 5px;
  }
}

@mixin member-card () {
  background-color: $flat-white;
  cursor: pointer;
  display: flex;

  margin-bottom: 10px;
  padding: 15px 20px;

  [class*="avatar"] {
    margin-top: 10px;
  }

  [class*="checkbox"] {
    align-self: center;
  }

  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 10px;

    .name {
      color: $deep-teal;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .sub_info {
      p {
        @include font-style($warm-grey, 12);

        padding: 0 0 5px;
      }
    }
  }
}

@mixin message() {
  border-radius: 12px 12px 0;
  line-height: 1.3;
  padding: 10px 15px;
  width: fit-content;
}

@mixin outbox-message() {
  background-color: $simple-grey;
  border-radius: 12px 12px 12px 0;
  color: $dark-grey;
}

@mixin loader($bg: $deep-teal, $opacity: 0.65) {
  align-items: center;
  background-color: rgba($bg, $opacity);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  div:first-of-type {
    z-index: 5;
  }
}
