@import "styles/colors";
@import "styles/media";

.icon {
  height: 60px;
  margin-right: 10px;
  min-width: 60px;
  position: relative;
  width: 60px;

  @media #{$mobile} {
    height: 45px;
    min-width: 45px;
    width: 45px;
  }

  .primary, .secondary {
    border-radius: 50%;
    object-fit: contain;
    object-position: bottom;
  }

  div.primary, div.secondary {
    align-items: center;
    background-color: $flat-white;
    box-shadow: 0 0 2px rgba(1, 70, 96, 0.4);
    color: $greyish-teal;
    display: flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    text-transform: uppercase;

    &:not(.primary) {
      font-size: 12px;
    }
  }

  .primary {
    height: 100%;
    width: 100%;
  }

  .secondary {
    bottom: -5px;
    height: 55%;
    position: absolute;
    right: -5px;
    width: 55%;
  }
}
