@import "styles/colors";
@import "styles/media";

.logout, .delete_message, .outer {
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 40vw;

  padding: 20px;
  z-index: 6;
}

.wide {
  @extend .outer;

  @media #{$desktop} {
    max-width: 700px;
    width: 55vw;
  }
}

.modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  margin: 1em 0;
}

.title {
  color: #4cb49c;
  font-family: "Questrial", sans-serif;
  font-size: 22px;
  font-weight: 400;
  grid-area: title;

  @media #{$mobile} {
    font-size: 17px;
  }
}

.close_btn {
  cursor: pointer;
  grid-area: close;
  justify-self: end;
  margin-left: 6px;

  svg {
    height: 22px;
    width: 22px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  grid-area: text;
  justify-content: space-between;
  line-height: 1.4em;
  margin: 0.8em 0;

  span {
    color: #4d4d4d;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 21px;

    @media #{$mobile} {
      font-size: 15px;
    }
  }
}

.buttons_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  button {
    background-color: $flat-white;
    border: $deep-teal 1px solid;
    border-radius: 20px;
    color: $deep-teal;
    height: 27px;

    margin-left: 10px;

    padding: 3px 0;
    width: 83px;
  }

  .button {
    background-color: $deep-teal;
    color: $flat-white;
  }
}

