@import "styles/mixins/main";

.empty {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  justify-content: center;
  padding: 1em;
  text-align: center;
}

.emoji {
  display: block;
  font-size: 4em;
  margin-bottom: 0.5em;
}
