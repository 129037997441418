@import "styles/colors";

.faq {
  padding: 25px 15px;
}

.questions_wrapper {
  display: grid;
  grid-row-gap: 5px;
  grid-template-columns: 1fr;
}

.question {
  background-color: rgba($simple-grey, 0.2);

  &_header {
    border: 0 !important;
    padding: 10px 15px;
  }

  &_title {
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
  }
}

.text_container {
  color: $dark-grey;
  font-family: "OpenSans", sans-serif;
  line-height: 20px;
  padding: 10px 30px 30px 15px;
}
