@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.card {
  background: $white;
}

.title {
  color: $warm-grey;
  font-size: 26px;
}

.error {
  background-color: rgba($pastel-red, 0.2);
  color: $pastel-red;
  flex: 1;
  padding: 20px;
}

.inputs {
  padding: 20px 10px;

  label {
    font-size: 16px;
  }

  input {
    height: 40px;
    width: 100%;
  }
}

.cards-wrapper {
  .validation {
    color: $dark-grey;
    font-family: OpenSans, sans-serif;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

@media #{$mobile} {
  .validation {
    font-size: 14px;
  }
}

.button-wraper {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  width: fit-content;
}
