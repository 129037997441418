@import "../../styles/media";

.error {
  align-items: center;
  background-image: url("./error_page_desktop.svg");
  background-position: center;
  background-size: cover;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  .title {
    font-size: 40px;
    margin-top: 13vh;
    max-width: 90%;
  }

  .suggestion {
    font-size: 18px;
    margin-top: 7vh;
    max-width: 90%;
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  @media (max-width: 600px) {
    background-image: url("./error_page_mobile.svg");

    .title {
      margin-top: 5vh;
    }

    .suggestion {
      margin-top: 3vh;
    }
  }
}

