@import "styles/mixins/main";

$editor-border: 1px solid $simple-grey;
$min-editor-border: 1px solid $simple-grey;

w-chat-input-module {
  background: $flat-white;
  padding: 0 1em 1em;
}

.editor-wrapper {
  border: $editor-border;
  border-radius: 20px;
  box-shadow: 0 10px 20px $white;
  position: relative;
  width: 100%;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  [class*="ql-editor"] {
    padding: 12px 30px 12px 15px;
  }

  [class*="ql-mention-list-container"] {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-height: 240px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
  }

  [class*="ql-toolbar"] {
    z-index: 1;

    border: {
      bottom: $editor-border !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
    }
  }

  @media #{$mobile} {
    [class*="ql-toolbar"] {
      display: none;
    }
  }

  [id*="editor-container"] {
    @include body-style($dark-grey);

    border: 0 !important;

    flex: 1;
    width: 100%;

    @media #{$mobile} {
      border-radius: 20px;
      min-height: 40px;
    }
  }

  [id*="textarea-emoji"] {
    border-radius: 20px !important;
    min-width: 300px !important;
    top: -260px !important;
  }

  [id*="textarea-emoji"] > [id*="tab-toolbar"] {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  [id*="textarea-emoji"] > [id*="tab-toolbar"] > ul {
    display: flex;
    justify-content: space-between;
  }

  [id*="textarea-emoji"] > [id*="tab-panel"] {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  [id*="textarea-emoji"] > [id*="tab-panel"]::-webkit-scrollbar {
    max-width: 2px;
    width: 2px;
  }

  [id*="textarea-emoji"] > [id*="tab-panel"] > [class*="bem"] {
    margin: 10px;
  }
}

[class*="mention"] {
  color: $deep-teal;
  cursor: pointer;
}

.mentions-menu {
  background-color: $flat-white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;

  @media #{$mobile} {
    max-height: 120px;
  }
}

.mention-item {
  @include body-style($deep-teal);

  align-items: center;
  display: flex;
  height: 40px;
}

.highlight {
  background-color: darken($flat-white, 5);
  color: $deep-teal;
}

.avatar,
.avatar > img {
  align-items: center;
  background-color: rgba($pastel-orange, 0.3);
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0 10px;
  min-height: 30px;
  min-width: 30px;
  width: 30px;
}

.alias {
  @include body-style($dark-grey);

  width: 200px;
}

.mention_user {
  @include body-style($deep-teal);

  color: $deep-teal;
  flex: 1;
  font-weight: bold;
}
