@import "styles/colors";
@import "styles/media";

.w-input {
  border: 0;
  border-radius: 20px;
  box-shadow: 0 0 2px #4d4d4d;
  color: $dark-grey;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  min-height: 20px;
  padding: 3px 10px;
  position: relative;
  resize: none;
  width: 100%;

  &textarea {
    padding: 15px;
  }

  &:focus {
    box-shadow: 0 0 5px rgba($greyish-teal, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: rgba($warm-grey, 0.1);
  }
}

.eye-wrapper {
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  right: 10px;
  top: 0;
  width: 22px;
  z-index: 1;

  svg {
    width: 100%;
  }
}

.w-input-wrapper {
  margin-right: 30px;
  position: relative;
  width: 100%;
}

.after {
  position: absolute;
  right: 0;
}

.w-search-wrapper {
  $icon-size: 21px;
  $icon-right-margin: 7px;

  display: block;


  &.default {
    background-color: rgba($simple-grey, 0.2);
    padding: 20px 20px 40px;
  }

  &.simple {
    background-color: transparent;
  }


  label {
    display: block;
    margin-bottom: 10px;
  }

  div {
    position: relative;
  }

  .clear-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    position: absolute;
    right: $icon-right-margin;
    top: 0;
    z-index: 1;
  }

  svg {
    height: $icon-size;
    min-height: $icon-size;
    min-width: $icon-size;
    width: $icon-size;
  }

  input {
    height: 35px;
    padding-right: $icon-size + $icon-right-margin + $icon-right-margin;
  }

  @media #{$mobile} {
    padding: 15px 15px 25px;
  }
}
