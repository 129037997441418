@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
  font-family: Questrial, sans-serif;
}

@media #{$mobile} {
  .cards-wrapper & .mobile-header {
    display: inline-flex;
  }
}

.mobile-header {
  display: none;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.mobile-title {
  @include subtitle();
  font-family: Questrial, sans-serif;
}

.card {
  @include card();

  .card-title,
  .card-line {
    @include description();
    margin-bottom: 10px;
  }

  .card-title {
    display: flex;
    height: 22px;
    justify-content: space-between;
  }

  .line-content {
    display: inline-block;
    max-width: calc(100% - 105px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .line-label {
    display: inline-block;
    min-width: 100px;
  }
}

.info {
  @include font-style($dark-grey);
  background-color: rgba(246, 246, 246, 0.5);
  font-size: 12px;
  line-height: 16px;
  margin: 15px -15px -20px;
  padding: 15px;
}

.button-outlined {
  @include outlined-button($deep-teal);
}
