@import "styles/mixins/main";

.loading {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%;
}

.desc,
.mandatory {
  @include font-style($dark-grey);
  background-color: rgba($simple-grey, 0.2);

  padding: 20px;

}

.loaderWrapper {
  display: flex;
  justify-content: center;
  padding: 3em 0;
}

.mandatory {
  background-color: $white;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .next {
    margin-left: auto;
    width: 30%;
  }

  .back {
    border-radius: 18.5px;
  }
}

.added_skill_card {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px;

  button {
    @include icon-button();
    margin: 0 10px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .experience {
    align-items: center;

    color: $greyish-teal;
    display: flex;
    font-size: 25px;
  }
}

.added_skill_card_label {
  flex: 1;

  @media #{$desktop} {
    text-align: center;
  }
}

.no_results {
  @include font-style($dark-grey);

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px;
  }
}

.search {
  @include input();
  background-color: rgba($simple-grey, 0.4);

  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.box {
  position: relative;
  width: 100%;

  input {
    padding: 0 10px;
    width: 100%;
  }

  input:focus {
    box-shadow: 0 0 5px rgba($greyish-teal, 0.7);
  }
}

.clear {
  @include icon-button();

  position: absolute;
  right: 10px;
  top: 8px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.results {
  max-height: 400px;
  overflow-y: auto;
}

.skill_card {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px;

  button {
    @include button($deep-teal, $flat-white);

    padding: 5px 20px;
  }
}

.kpis {
  padding: 40px 20px;

  @media #{$desktop} {
    padding: 2em;
  }
}

.kpi-header {
  margin-bottom: 1em;

  h2 {
    color: $deep-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    margin-bottom: 0.5em;
  }

  p {
    @include font-style($dark-grey, 16);
  }
}

.questions-wrapper {
  position: relative;
}

.question {
  background: #fff;
  border: 1px solid #ececec;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(60px);
  transition: all 400ms ease-in 0s;
  width: 100%;

  &.hiddenq {
    opacity: 0;
  }

  &.activeq {
    display: block;
    opacity: 1;
    pointer-events: visible;
    position: relative;
    transform: translateX(0);
    z-index: 2;
  }

  h4 {
    color: $deep-teal;
    font-size: 18px;
    line-height: 1.4em;
    padding: 1em 1em 0;
  }
}

.singles {
  padding: 1em;
}

.single {
  @include font-style($dark-grey, 16);
  align-items: center;
  border: 1px solid $simple-grey;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  padding: 1em;
  transition: all 300ms ease-in-out 0s;
  user-select: none;

  &:hover {
    background: $simple-grey;
  }

  &.active {
    background: $deep-teal;
    border-color: $deep-teal;
    color: $flat-white;
    font-weight: bold;
  }

  span {
    flex: 1;
  }

  svg {
    height: 22px;
    width: 22px;
  }
}

.hint {
  @include font-style($dark-grey, 14);
  display: flex;
  justify-content: center;
  padding-top: 1em;
}
