@import "styles/colors";
@import "styles/mixins/main";

.wrapper {
  display: flex;
  max-height: 100%;
  width: 100%;
}

.current {
  @include card-layout($greyish-teal);
}

.items_wrapper {
  display: flex;
  flex: 1;
  height: auto;
  margin-right: 10px;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.items {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 15px 10px;
  position: relative;
  scrollbar-width: thin;
  width: 100%;

  input {
    @include checkbox();
  }

  //Firefox
  & {
    overflow: -moz-scrollbars-none;
  }

  //Internet Explorer, Edge
  & {
    -ms-overflow-style: none;
  }

  //Chrome, Safari, Opera
  &::-webkit-scrollbar {
    max-height: 150px;
    min-height: 6px;
    width: 1px;
  }

}

.avatar {
  margin-right: 10px;
}

.letters {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;

  //Firefox
  & {
    overflow: -moz-scrollbars-none;
  }

  //Internet Explorer, Edge
  & {
    -ms-overflow-style: none;
  }

  //Chrome, Safari, Opera
  &::-webkit-scrollbar {
    max-height: 150px;
    min-height: 6px;
    width: 2px;
  }
}

.letter {
  @include font-style($dark-grey);

  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 5px;
  min-height: 20px;
  width: 20px;

  &:hover:not(.active):not(:disabled) {
    background-color: $flat-white;
    box-shadow: 0 0 1px $warm-grey;
    color: $black;
  }

  &:disabled {
    color: $warm-grey;
  }
}

.active {
  background-color: $greyish-teal;
  color: $flat-white;
}
