@import "styles/mixins/main";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;

  max-width: 800px;
  width: 100%;
}

.left {
  @include font-style($dark-grey, 12);

  padding: 10px 100px 0;

  @media #{$mobile} {
    padding-left: 20px;
  }
}

.top-block {
  border-bottom: 1px solid rgba($warm-grey, 0.2);
  display: block;
  padding: 20px;
  position: sticky;

  .create {
    border-radius: 20px;
  }

  @media #{$mobile} {
    padding: 10px;

    .create {
      display: none;
    }
  }
}

.search-wrapper {
  display: flex;

  @media #{$mobile} {
    margin: 0;
  }
}

.create {
  @media #{$desktop} {
    @include button($deep-teal, $flat-white);
    font-size: 16px;
    height: 40px;
    margin-left: 10px;
    padding: 0 10px;
  }

  @media #{$mobile} {
    @include button($flat-white);

    font-size: 14px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 50px;
  }
}

.list-wrapper {
  display: block;
  margin: 0 80px;

  overflow-y: auto;
  padding-right: 10px;

  @media #{$mobile} {
    margin: 0;
  }
}

.avatars {
  display: flex;
  margin-right: 20px;
  max-width: 75px;
  position: relative;

  .avatar:nth-child(2) {
    margin-left: -20px;
    margin-top: 9px;
  }

  .status {
    background-color: $flat-white;
    border-radius: 100%;
    bottom: 0;
    left: 30px;
    position: absolute;
  }
}

.add-symbol {
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-left: auto;
  padding-left: 10px;

  svg {
    height: 25px;
    opacity: 0.3;
    width: 25px;
  }
}

.user-card {
  @include font-style($deep-teal);

  align-items: center;

  border-bottom: 1px solid rgba($warm-grey, 0.2);
  cursor: pointer;
  display: flex;
  padding: 10px;

  .info {
    display: flex;
    flex-direction: column;
  }

  .position {
    color: $warm-grey;
    font-size: 11px;
    margin-top: 5px;
  }
}

.user-card:hover {
  background-color: darken($flat-white, 3);

  .add-symbol {
    svg {
      opacity: 1;
    }
  }
}
