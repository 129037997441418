@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.header-wrapper {
  align-items: flex-start;
  background-image: linear-gradient(to top, #0399be, $deep-teal);
  display: flex;
  height: 170px;
  padding: 15px;
  position: relative;

  @media #{$mobile} {
    height: auto;
    padding-bottom: 8vh;
    padding-top: 2vh;
  }

  input[type="file"] {
    display: none;
  }

  .avatar {
    position: relative;
  }

  .pencil {
    align-items: center;
    background-color: $flat-white;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 22px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
    z-index: 1;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .remove {
    left: 0;
    position: absolute;
    top: 0;

    svg {
      cursor: pointer;
      height: 22px;
      width: 22px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .username {
    color: $flat-white;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    margin: 10px 0;

    @media #{$mobile} {
      font-size: 18px;
    }
  }

  .pts {
    align-items: center;
    align-self: flex-start;
    background: none;
    border: 0;
    color: $greyish-teal;
    display: flex;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      margin-left: 6px;
    }

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .button {
    @include outlined-button($flat-white);

    border-radius: 20px;
    color: $flat-white;
    font-size: 14px;
    font-weight: normal;
    height: 28px;
    padding: 3px 25px;
    width: max-content;

    &:hover:not(:disabled) {
      background-color: lighten($deep-teal, 10%);
    }

    &:focus:not(:disabled) {
      background-color: lighten($deep-teal, 10%);
    }
  }
}
