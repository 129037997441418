@import "styles/mixins/main";

.dialog-wrapper {
  min-width: 550px;

  @media #{$mobile} {
    min-width: 60vw;
  }
}

.inputs {
  padding: 10px 0;

  [class*="field"] {
    padding-bottom: 30px;

    input {
      height: 40px;
      width: 100%;
    }
  }

  [class*="validated-select_select"]>button {
    min-height: 28px;
  }

  [class*="box"]>svg {
    height: 28px;
    width: 28px;
  }

  [class*="clear"] {
    svg {
      height: 28px;
      width: 28px;
    }
  }

  label {
    @include font-style();
  }

  .checkbox {
    align-items: center;
    display: flex;

    span {
      font-size: 12px;
    }

    input {
      display: none;
    }

    svg {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }
  }
}
