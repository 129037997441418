@import "reset";
@import url("https://fonts.googleapis.com/css?family=Questrial");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
@import "media";
@import "transitions";
@import "flexbox";
@import "mixins/main";
@import "layouts/layout";
@import "layouts/auth";
@import "layouts/match";
@import "header";
@import "alerts";
@import "colors";

html,
body {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

body {
  font-family: "Questrial", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::after {
    background-color: transparent;
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color 0.2s ease-out;
    width: 100%;
    z-index: -100;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  a:focus {
    box-shadow: 0 1px 0 rgba($deep-teal, 0.4);
    text-decoration: underline;
  }
}

/** For catching autofill event */
@keyframes onAutoFillStart {  from {}  to {}}
@keyframes onAutoFillCancel {  from {}  to {}}

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
/** End autofill */

.overlayed {
  overflow: hidden;

  &::after {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.App-Main-Container, #preloader {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

#preloader {
  display: none;
  z-index: 1000;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.w-button {
  align-items: center;
  background-color: $flat-white;
  border: 0;
  border-radius: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-height: 22px;
  min-width: 60px;
  padding: 5px 10px;

  &:disabled {
    opacity: 0.6;
  }

  &:active, &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    opacity: 0.6;
  }

  &:hover.filled {
    opacity: 0.8;
  }

  &:active, &:focus {
    outline: none;
  }

  &:hover:not(.disabled) {
    opacity: 0.6;
  }

  &.primary {
    background-color: $deep-teal;
    color: $flat-white;
    font-weight: bold;
  }

  &.primary.darken-on-hover:hover {
    background-color: darken($deep-teal, 5);
    opacity: 1;
  }

  &.primary:focus:not(.disabled) {
    background-color: darken($deep-teal, 10);
    opacity: 1;
  }

  &.secondary {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0 0 3px $deep-teal;
    color: $deep-teal;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $deep-teal;
    box-shadow: none;
    color: $deep-teal;
    padding: 3px 10px;
  }

  &.outline:focus:not(.disabled) {
    background-color: darken($flat-white, 10);
    opacity: 1;
  }

  &.outline.filled {
    background-color: $flat-white;
  }

  &.outline.filled.darken-on-hover:hover {
    background-color: darken($flat-white, 5);
    opacity: 1;
  }

  &.link {
    background-color: transparent;
    box-shadow: none;
    color: $deep-teal;
    font-weight: 600;
  }

  &.link.filled {
    background-color: $flat-white;
  }

  &.link.filled.darken-on-hover:hover {
    background-color: darken($flat-white, 5);
    opacity: 1;
  }

  &.light {
    background-color: transparent;
    box-shadow: 0 0 3px rgba($deep-teal, 0.5);
    color: $very-light-pink;
  }
}

@include ma-init(100);
