@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.wrapper {
  @include font-style($greyish-teal);

  font-family: "Questrial", sans-serif;
  font-size: 35px;
  font-weight: 300;

  &:focus,
  &:active {
    outline: none;
  }

  w-avatar {
    display: none;
  }

  @media #{$mobile} {
    align-items: center;
    display: flex;
    font-size: 25px;

    w-avatar {
      display: block;
      margin-right: 10px;
    }
  }
}
