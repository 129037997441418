@import "styles/colors";

.wrapper {
  color: $dark-grey;
  padding: 50px 40px 15px;

  h2 {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  w-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
