@import "../../colors";
@import "../../media";

.wrapper {
  align-items: center;
  background-color: rgba($flat-white, 0.55);
  bottom: 0;

  display: flex;
  justify-content: center;
  left: 0;

  overflow-y: auto;

  padding: 50px 0;
  position: fixed;
  right: 0;
  top: 0;

  z-index: 5;

  &.goals {
    padding: 0;
  }
}

.container {
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  height: fit-content;
  margin: auto;
  max-width: 600px;
  padding: 30px 15px;
  position: relative;
  z-index: 6;

  .close {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.share_goals {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    "title close"
    "text text"
    "buttons buttons";
  grid-template-columns: auto 100px;

  padding: 20px;
  position: relative;
  width: 600px;
  z-index: 6;

  @media #{$mobile} {
    align-self: flex-start;
    grid-template-areas:
      "close close"
      "title title"
      "text text"
      "buttons buttons"
      "links links";
    grid-template-columns: auto 60px;
    grid-template-rows: 30px 30px auto auto auto;
    min-height: 100%;
    width: 100%;
  }

  .links {
    align-items: center;
    display: none;
    font-size: 12px;
    grid-area: links;
    justify-content: space-around;

    a {
      color: $deep-teal;
      font-family: "OpenSans", sans-serif;
    }

    .links {
      align-items: flex-end;
      bottom: 15px;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10px;
    }
    @media #{$mobile} {
      display: flex;
    }
  }

  .title {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    grid-area: title;

    @media #{$mobile} {
      display: none;
    }
  }

  .close_btn {
    cursor: pointer;
    grid-area: close;
    justify-self: end;

    svg {
      height: 22px;
      width: 22px;
    }
    @media #{$mobile} {
      display: none;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    grid-area: text;
    justify-content: space-between;

    span {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 21px;

      @media #{$mobile} {
        font-size: 15px;
      }
    }
  }

  .buttons_wrapper {
    align-self: end;
    grid-area: buttons;
    justify-self: end;

    .button {
      border-radius: 20px;
      height: 27px;

      margin-left: 10px;

      padding: 3px 0;
      width: 83px;
    }
  }

  .preloader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .mobile_header {
    color: #4cb49c;
    display: none;
    font-family: "Questrial", sans-serif;
    font-size: 25px;
    font-weight: 400;
    grid-area: title;
    @media #{$mobile} {
      display: flex;
    }
  }

  .close_header {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $very-light-pink;
    color: $deep-teal;
    display: none;
    grid-area: close;
    height: 50px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    div {
      align-items: center;
    }

    span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      margin: 0 15px;
      width: 30px;
    }
    @media #{$mobile} {
      display: flex;
    }
  }
}

.logout, .delete_message {
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);

  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    "title close"
    "text text"
    "buttons buttons";
  grid-template-columns: auto 100px;

  padding: 20px;
  position: relative;
  width: 416px;
  z-index: 6;

  @media #{$mobile} {
    grid-template-columns: auto 60px;
    width: 96%;
  }

  .title {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    grid-area: title;

    @media #{$mobile} {
      font-size: 17px;
    }
  }

  .close_btn {

    cursor: pointer;
    grid-area: close;
    justify-self: end;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    grid-area: text;
    justify-content: space-between;

    span {
      color: #4d4d4d;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 21px;

      @media #{$mobile} {
        font-size: 15px;
      }
    }
  }

  .buttons_wrapper {
    align-self: end;
    grid-area: buttons;
    justify-self: end;

    .button {
      border-radius: 20px;
      height: 27px;

      margin-left: 10px;

      padding: 3px 0;
      width: 83px;
    }
  }

  .preloader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
}
