@import "styles/colors";
@import "styles/media";

.context_menu {
  $radius: 10px;

  background-color: $flat-white;
  border-radius: $radius;
  position: absolute;
  width: 250px;
  z-index: 2;

  @media #{$mobile} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: -100%;
    width: 100%;
  }

  section {
    background-color: $flat-white;
    border-radius: $radius;
  }

  button, button:active, button:focus {
    background: transparent;
    border: 0;
    color: $deep-teal;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    outline: none;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
  }

  button:not(:last-of-type), button:active:not(:last-of-type), button:focus:not(:last-of-type) {
    border: 0 solid rgba($very-light-grey, 0.1);
    border-bottom-width: 1px;
  }

  button:hover:first-of-type:not(:disabled) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  button:hover:last-of-type:not(:disabled) {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }


  @media #{$mobile} {
    button:hover:first-of-type:not(:disabled), button:hover:last-of-type:not(:disabled) {
      border-radius: 0;
    }
  }

  button:hover:not(:disabled) {
    background-color: darken($flat-white, 5);
  }

  button:disabled {
    color: $warm-grey;
    opacity: 0.7;
  }
}

.backdrop {
  background-color: rgba($black, 0.4);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.backdrop_in {
  animation: showBackdrop 400ms forwards;
  opacity: 0;
}

.backdrop_out {
  animation: hideBackdrop 400ms forwards;
  opacity: 1;
}

.context_in {
  animation: showMenu 400ms forwards;
}

.context_out {
  animation: hideMenu 400ms forwards;
  bottom: 0;
}

@keyframes showMenu {
  from {bottom: -100%;}
  to {bottom: 0;}
}

@keyframes showBackdrop {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes hideMenu {
  from {bottom: 0;}
  to {bottom: -100%;}
}

@keyframes hideBackdrop {
  from {opacity: 1;}
  to {opacity: 0;}
}
