@import "styles/mixins/main";
@import "../../styles/mixins/credits";

.title {}

.categories {
  padding: 0 12px 12px;
}

.question-content {
  padding: 0 12px 12px;
}

.credits-faq {
  h1 {
    color: $warm-grey;
    font-size: 22px;
    margin: 30px 14px 14px;
  }

  [class*=collapsable-wrapper] {
    border-top: 1px solid $dark-grey;

    [class*=header-collapsable]>[class*=title] {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  [class*=collapsable-wrapper]:nth-last-child(1) {
    border-bottom: 1px solid $dark-grey;
  }

  .categories>[class*=collapsable-wrapper]>[class*=header-collapsable]>[class*=title] {
    font-size: 22px;
  }

  .categories>[class*=collapsable-wrapper] [class*=collapsable-wrapper]:last-child {
    margin-bottom: 20px;
  }

  .questions>[class*=collapsable-wrapper] {
    background: rgba(#ddd, 0.2);
    border: 0;
    margin-bottom: 10px;
    padding: 4px 20px;

    &:nth-last-child(1) {
      margin: 0;
    }
  }
}

.question-answer {
  color: $dark-grey;
  padding: 12px 0 20px;
}

.empty-node {
  @extend [class*=collapsable-wrapper];

  align-items: center;
  color: $warm-grey;
  display: flex;
  font-size: 22px;
  height: 60px;
}

.questions>.empty-node {
  font-size: 16px;
}
