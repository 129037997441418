@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.wrapper {
  background-color: $white;
  border-right: 1px solid $simple-grey;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin: 30px 20px 7px;
  }

  button {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 10px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 15px;
    padding: 0;
    width: 30px;
  }

  button:hover,
  button:focus {
    background-color: darken($simple-grey, 10);
  }

  button>svg {

    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
  }

  @media #{$desktop} {
    & {
      display: none;
    }
  }
}

.collective {
  cursor: pointer;
  display: flex;
  margin: 20px auto 0;
  width: fit-content;

  img {
    height: 65px;
    width: 65px;
  }

  @media #{$mobile} {
    align-items: center;
    border-radius: 20px;
    margin: 5px 10px;
    padding: 10px;

    &:hover,
    &:focus {
      background-color: darken($simple-grey, 10);
    }
  }
}

.collective_name {
  display: none;

  @media #{$mobile} {
    display: block;
    font-family: "Questrial", sans-serif;
    font-size: 18px;
    margin-left: 20px;
  }

  &.active_name {
    color: $greyish-teal;
  }

  &.inactive_name {
    color: $deep-teal;
  }
}

.container {
  height: 100%;
  position: relative;
  width: 100%;
}

.sidebar {
  background-color: $simple-grey;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 80%;
  will-change: left;

  &.opened {
    animation: open 0.6s;
  }

  &.closed {
    animation: close 0.6s;
  }

  >h3 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    padding: 10px;
  }

  >div {
    align-items: center;
    border-radius: 40px;
    color: $deep-teal;
    cursor: pointer;
    display: flex;
    margin: 10px 0;
    padding: 10px;

    &.active {
      background-color: $greyish-teal;
      color: $flat-white;
    }

    img {
      max-width: 40px;
    }

    >span {
      margin-left: 10px;
    }
  }
}

@keyframes open {
  from {
    left: -80%;
  }

  to {
    left: 0%;
  }
}

@keyframes close {
  from {
    left: 0%;
  }

  to {
    left: -80%;
  }
}
