@import "styles/mixins/main";
@import "styles/media";

.wrapper {
  form {
    display: flex;
    flex-direction: column;
  }

  p {
    @include font-style($dark-grey, 14);

    background-color: rgba($warm-grey, 0.2);
    padding: 20px;
  }

  a {
    text-decoration: underline;
  }

  a:active,
  a:focus {
    outline: none;
  }
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
