.wrapper {
  height: 600px;
  left: 0;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 100%;
  background: white;
  z-index: 999;

  .img-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .image {
      cursor: pointer;
      flex: 1;
    }
  }

  .input {
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    margin-bottom: 20px;
    padding: 10px 15px;
    width: 100%;
    background: rgb(242, 242, 242);
  }
}
