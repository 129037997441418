@import "styles/mixins/main";

.stats-list {
  display: flex;
  flex-flow: column;

  li {
    @include body-style($dark-grey, 15);
    background: $flat-white;
    border: 1px solid $simple-grey;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0.5em;
    padding: 0.5em;
    user-select: none;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.header {
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 1em;

  w-avatar {
    margin: 0 1.5em;
  }

  .highlight {
    p {
      margin: 0;
      text-align: center;

      &:last-of-type {
        font-size: 19px;
        margin-top: 0.3em;
      }
    }
  }
}

.empty {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  padding: 1em;
  text-align: center;
}
