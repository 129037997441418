$deep-teal: #014660;
$greyish-teal: #5bdbbd;

$very-light-grey: #b9b9b9;
$warm-grey: #55556f;
$simple-grey: #d4d4d8;
$dark-grey: #222026;

$white: #f9f9f9;
$flat-white: #fff;

$black: #000;

$pastel-red: #e55e50;
$pastel-pink: #ffe6d8;
$pastel-orange: #dda700;
$pastel-green: #007516;
$pastel-grey: #f2f2f2;
$very-light-pink: #b9b9b9;

$pastel-yellow: #f8de88;

.white {
  color: $white;
}

.black {
  color: $black;
}

.deep-teal {
  color: $deep-teal;
}

.greyish-teal {
  color: $greyish-teal;
}

.very-light-grey {
  color: $very-light-grey;
}

.warm-grey {
  color: $warm-grey;
}

.simple-grey {
  color: $simple-grey;
}

.dark-grey {
  color: $dark-grey;
}

.flat-white {
  color: $flat-white;
}

.pastel-red {
  color: $pastel-red;
}

//Background-styles
.white-bg {
  background-color: $white;
}

.deep-teal-bg {
  background-color: $deep-teal;
  color: $flat-white;
}

.greyish-teal-bg {
  background-color: $greyish-teal;
  color: $white;
}

.very-light-grey-bg {
  background-color: $very-light-grey;
  color: $dark-grey;
}

.warm-grey-bg {
  background-color: $warm-grey;
  color: $white;
}

.simple-grey-bg {
  background-color: $simple-grey;
  color: $dark-grey;
}

.dark-grey-bg {
  background-color: $dark-grey;
  color: $flat-white;
}

.flat-white-bg {
  background-color: $flat-white;
  color: $deep-teal;
}

.pastel-red-bg {
  background-color: $pastel-red;
  color: $white;
}
