@import "styles/mixins/main";

.header {
  align-items: center;
  border-bottom: 1px solid $very-light-pink;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px;

  button {
    @include icon-button();
    @include font-style($deep-teal, 14);

    align-items: center;
    display: flex;
  }

  svg {
    height: 15px;
  }

  svg:last-child {
    margin-left: 5px;
  }

  svg:first-child {
    margin-right: 5px;
  }

  button:disabled {
    opacity: 0.5;
  }
}

.options {
  h1 {
    @include font-style($warm-grey, 18);

    font-family: "Questrial", sans-serif;
    padding: 20px 10px 10px;
  }

  .option {
    cursor: pointer;
  }

  .option,
  .flow_selector {
    align-items: center;
    background-color: $flat-white;
    display: flex;
    margin: 0 10px 10px;
    padding: 20px;

    span {
      @include font-style($deep-teal);
    }
  }

  .option>span {
    margin-left: 10px;
  }

  .flow_selector {
    justify-content: space-between;

    span {
      font-family: "Questrial", sans-serif;
    }
  }

  span {
    font-family: "Questrial", sans-serif;
  }

  .checkbox {
    height: 25px;
    width: 25px;
  }

  .selected {
    background-color: rgba($greyish-teal, 0.2);
  }
}

.meet-time {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  h1 {
    @include font-style($warm-grey, 18);

    font-family: "Questrial", sans-serif;
    padding: 20px 10px 10px;
  }

  h4 {
    @include font-style($dark-grey, 12);

    margin: 10px 15px;
  }
}

.choose-timezone {
  align-items: center;

  color: $dark-grey;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.9em;
  padding: 10px;

  button {
    font-size: 13px;
  }
}

.like-input,
.like-disabled-input {
  @include font-style($deep-teal, 13);
  border: 1px solid $simple-grey;
  border-radius: 18.5px;

  flex: 1;
  margin: 0 10px 10px;
  padding: 5px 10px;
}

.like-input {
  background-color: $flat-white;
}

.like-disabled-input {
  color: $dark-grey;
}

.show-hide {
  @include button();

  font-weight: lighter;
  padding: 10px 20px 20px;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.switcher {
  @include font-style($dark-grey, 14);
  background-color: $simple-grey;
  border-radius: 18.5px;

  display: flex;
  flex: 1;
  height: 34px;
  margin: 0 10px 10px;
  position: relative;

  .option {
    align-items: center;
    border-radius: 18.5px;
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    user-select: none;
  }

  .date {
    transform: translateX(0);
    transition: transform 400ms;
  }

  .time {
    transform: translateX(100%);
    transition: transform 400ms;
  }

  .holder {
    background-color: $flat-white;
    bottom: 0;
    color: $deep-teal;
    font-weight: bold;
    position: absolute;
    top: 0;
    width: 50%;
  }
}

.timezones {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    @include font-style($warm-grey, 18);

    font-family: "Questrial", sans-serif;
    padding: 10px;
  }
}

.search-timezone {
  @include v-input();

  input {
    margin: 0;
  }

  label {
    @include font-style($dark-grey, 14);
  }

  input::placeholder {
    color: $very-light-grey;
    margin-left: 20px;
  }
}

.timezone-card {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding: 16px 12px;

  div:first-child {
    color: $deep-teal;
    display: flex;
    flex-direction: column;

    line-height: 1.5;
  }

  div:first-child>div:first-child {
    @include font-style($deep-teal, 14);
    font-weight: bold;
  }
}

.tz-list {
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;

  &>div {
    margin-bottom: 10px;
  }
}

.datetime {
  background-color: rgba($simple-grey, 0.2);
  padding: 15px 0;
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  input,
  textarea {
    color: $deep-teal;
  }

  label {
    font-family: "Questrial", sans-serif;
    font-size: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;

    w-validation-box:last-of-type {
      display: flex;
      flex: 1;
    }
  }
}

.calendar-access {
  @include font-style($dark-grey, 14);
  background: rgba(221, 221, 221, 0.2);
  font-family: "Questrial", sans-serif;
  line-height: 1.3em;
  margin-bottom: 10px;
  padding: 0 10px 10px;

  button {
    @include button();
    font-size: 14px;
    padding: 0;
  }
}

.loader {
  @include loader();
}
