@import "styles/colors";

@keyframes open {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }

}

@keyframes close {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }

}


.notification {
  align-items: center;
  color: #fff;
  display: flex;
  line-height: 1.5;
  min-height: 70px;
  padding: 5px 20px;
}

.wrapper {
  animation: open 0.3s ease-out;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
}

.icon_wrapper {
  svg {
    height: 30px;
    width: 30px;
  }
}

.content_wrapper {
  flex: 1;
  padding: 5px 10px;

  .title {
    color: $warm-grey;
    margin-bottom: 3px;
  }

  .text {
    color: $dark-grey;
  }
}

.close_btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  text-decoration: underline;

  svg {
    fill: $white;
    height: 15px;
    margin-right: 5px;
    width: 15px;
  }
}

.closed {
  animation: close 0.3s ease-in;
}

.warning {
  background: linear-gradient(to right, #fcc766, #fddf8b);
  color: $black;

  .close_btn svg {
    fill: $black;
  }
}

.success {
  background: linear-gradient(to right, #56bba1, #54d4b2);
}

.error {
  background: linear-gradient(to right, #f98377, #e65e4f);
}

.alert {
  background-color: $flat-white;
  cursor: pointer;
}

.alert_icon_wrapper {
  margin: 10px 10px 15px 0;
}
