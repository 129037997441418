@import "styles/mixins/main";

.wrapper {
  @media #{$desktop} {
    width: 320px;
  }
}

.back {
  @include icon-button();

  align-items: center;
  color: $deep-teal;
  cursor: pointer;
  display: flex;

  svg {
    height: 22px;
    margin-right: 10px;
    width: 30px;
  }

  @media #{$mobile} {
    left: 15px;
    position: absolute;
    top: 10px;
  }
}

.top {
  background-color: $white;
  display: flex;
  padding: 30px 15px 25px;
  position: relative;

  @media #{$mobile} {
    justify-content: center;
    min-height: 40px;
    padding: 14px 15px;
  }
}

.nav-items {
  @include font-style($deep-teal);
  background-color: $white;
  cursor: pointer;
  display: flex;
  flex: 1;
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: thin;
  white-space: nowrap;

  .current {
    font-weight: bold;
  }

  div:hover {
    color: darken($deep-teal, 12);
  }

  &::-webkit-scrollbar {
    background: transparent;
    height: 0;
    min-height: 0;
  }

  @media #{$mobile} {
    $border: 1px solid $very-light-pink;

    align-items: center;
    height: 45px;

    border: {
      bottom: $border;
      top: $border;
    }

    div {
      padding: 15px;
    }
  }

  @media #{$desktop} {
    flex-direction: column;

    div {
      padding: 7px 15px;
    }
  }
}

.spacer {
  height: 100%;
}
