@import "styles/colors";
@import "styles/media";

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.nav {
  background-color: $white;

  display: grid;
  grid-row-gap: 25px;
  grid-template-rows: min-content;

  padding: 30px 0 15px 15px;
  width: 320px;

  .text {
    color: $deep-teal;
    cursor: pointer;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
  }
}

.back {
  align-items: center;
  display: flex;
  height: fit-content;
  min-height: 22px;

  svg {
    height: 22px;
    width: 30px;
  }

  .text {
    margin-left: 10px;
  }
}

.container {
  background-color: $white;
  border-left: solid 1px $very-light-pink;
  border-right: solid 1px $very-light-pink;

  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;

  overflow: auto;

  @media #{$mobile} {
    padding-bottom: 50px;
  }
}

%oval {
  background-color: #f8db90;
  border-radius: 100%;

  min-height: 500px;
  min-width: 500px;
  position: absolute;
  z-index: 1;
}

.header {
  align-items: center;
  background-color: rgba($pastel-orange, 0.6);
  display: flex;
  justify-content: center;
  min-height: 120px;
  overflow: hidden;
  position: relative;

  *:not(.oval_1, .oval_2) {
    z-index: 2;
  }

  .oval_1 {
    @extend %oval;
    height: 50vw;
    right: 25%;
    top: -25vw;
    width: 50vw;

    @media #{$mobile} {
      right: 20%;
      top: -250px;
    }
  }

  .oval_2 {
    @extend %oval;
    height: 50vw;
    left: 55%;
    top: 20px;
    width: 50vw;
  }
}

.block {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 10px 20px 15px;
  position: relative;

  @media #{$desktop} {
    min-height: 100%;
    width: 320px;
  }

  .card {
    background-color: $flat-white;
    cursor: pointer;

    display: flex;

    height: fit-content;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 60px;
    padding: 20px 10px;

    svg {
      height: 20px;
      width: 30px;
    }
  }

  a {
    color: $deep-teal;
    font-family: "OpenSans", sans-serif;
  }

  .links {
    align-items: flex-end;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
  }
}
