@import "styles/mixins/main";

.button {
  @include body-style($flat-white, 15);
  align-items: center;
  background: $deep-teal;
  border: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  line-height: 1.35em;
  padding: 0.6em 0.8em;
  position: relative;
  transition: all 300ms ease-in-out 0s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background: $deep-teal;
      color: $flat-white;
    }
  }

  &:hover {
    background: $greyish-teal;
    color: $deep-teal;
  }

  svg {
    flex: 0 0 22px;
    margin-right: 0.5em;
  }
}

.small {
  @extend .button;
  font-size: 0.85em;
  padding: 0.5em 0.7em;
}

.outline {
  @extend .button;
  background: $flat-white;
  border: 1px solid $simple-grey;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  color: $deep-teal;
}

.badge {
  background: #dda700;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.3em;
  padding: 0.15em 0.3em;
  position: absolute;
  right: -10px;
  top: -15px;
}
