@import "styles/colors";

.workbookContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
  justify-content: center;

  .button.button {
    flex: 1;

    & > a {
      color: #fff;

      &:hover {
        color: $deep-teal;
      }
    }
  }
}

.header.header {
  color: $deep-teal;
  font-weight: 700;
  margin: 0 0.5em;
  padding: 0.5em;
}
