@import "styles/mixins/main";

.timer-wrapper {
  display: flex;
}

.timer-block {
  padding: 10px;
}

.value-area {
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 42px;
  height: 19vw;
  justify-content: center;
  max-height: 113px;
  max-width: 113px;
  width: 19vw;

  @media #{$mobile} {
    font-size: calc(2vw + 2vh);
  }
}

.unit-area {
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  margin-top: 14px;
  text-align: center;
  text-transform: capitalize;

  @media #{$mobile} {
    font-size: calc(1vw + 1vh);
  }
}
