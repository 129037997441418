@import "styles/mixins/main";

.rating {
  @include body-style($dark-grey, 14);
  align-items: center;
  background: $white;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  padding: 0.5em 0.5em 0.5em 1em;

  span {
    flex: 1;
  }

  .options {
    background: $flat-white;
    border: 1px solid $simple-grey;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;

    button {
      background: none;
      border: 0;
      padding: 0.25em 1em;
      transition: all 300ms ease-in-out 0s;

      &:last-of-type {
        border-left: 1px solid $simple-grey;
      }

      &:hover {
        background: $greyish-teal;
      }

      svg {
        width: 22px;
      }
    }
  }
}
