@import "styles/colors";
@import "styles/media";

.wrapper {
  padding: 20px 10px;
}

.field {
  background-color: rgba($simple-grey, 0.2);
  margin: 20px 0 15px;
  padding: 25px 10px 40px;

  @media #{$desktop} {
    margin: 20px -25px 15px;
    padding: 25px 24px 40px;
  }
}

.button {
  float: right;
  padding: 3px 15px;
}
