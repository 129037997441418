@import "styles/mixins/main";

w-action-module {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  @media #{$desktop} {
    width: 320px;
  }
}

.header {
  align-items: center;
  background-color: rgba($very-light-grey, 0.1);
  border-bottom: 1px solid $very-light-pink;
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  position: relative;

  button {
    @include icon-button();

    align-items: center;
    background-color: rgba($simple-grey, 0.3);
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 10px;
    position: absolute;
    width: 40px;
  }

  button:hover {
    background-color: rgba($simple-grey, 0.6);
  }

  span {
    @include font-style();
  }

  svg {
    height: 15px;
    width: 20px;
  }
}


.info {
  background-color: rgba($very-light-grey, 0.1);
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  padding-bottom: 65px;

  @media #{$desktop} {
    padding-bottom: 15px;
  }

  h1 {
    color: $deep-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 10px 0 15px;
  }
}

.details {
  @include font-style($dark-grey, 14);
  line-height: 1.5;
  margin: 10px 0;
}

.info_block {
  @include font-style($dark-grey, 14);

  display: flex;
  line-height: 1.5;

  .details-info {
    flex: 1;
    padding: 0 10px 0 0;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    max-width: 90px;
    width: 100%;
  }

  .primary,
  .outline {
    padding: 3px 40px;
    width: 75px;
  }

  button:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .primary {
    @include button($deep-teal, $flat-white);
  }

  .outline {
    @include outlined-button($deep-teal, $deep-teal);
  }
}

.who {
  margin-bottom: 20px;
}

.remainingToggle {
  cursor: pointer;
  display: block;
  margin: 5px 0 0 26px;
  text-decoration: underline;
}

.member {
  align-items: flex-start;
  display: flex;
  line-height: 1.75;
  width: 100%;

  svg {
    flex: 0 0 16px;
    margin: 0 10px 0 0;
    min-height: 16px;
    min-width: 16px;
    position: relative;
    top: 2px;
  }
}

.cancelled {
  @include font-style($pastel-red, 13);
}

.notes {
  @include font-style($dark-grey, 14);

  line-height: 1.5;

  h3 {
    color: $warm-grey;
    margin: 0 0 10px;
  }
}
