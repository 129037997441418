@import "styles/mixins/main";

.calendar_header {
  @include font-style($warm-grey, 14);

  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  width: 100%;


  button {
    @include icon-button();

    padding: 0 20px;
  }

  button:disabled {
    opacity: 0.3;
  }

  span {
    min-width: 150px;
    text-align: center;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.days {
  display: flex;
  flex-wrap: wrap;
}

.day,
.weekday {
  display: flex;
  justify-content: center;
  min-width: 30px;
  width: 14.28%;

  button {
    @include icon-button();
    @include font-style($deep-teal, 12);
    border-radius: 50%;
    margin-bottom: 5px;
    min-height: 30px;
    min-width: 30px;
  }

  button:disabled:not(.current) {
    color: $dark-grey;
    cursor: not-allowed;
    opacity: 0.2;
  }

  .not_current_month:not(.current) {
    visibility: hidden;
  }

  .current {
    border: 2px solid $greyish-teal;
  }

  .current_selected {
    background-color: $greyish-teal;
    color: $flat-white;
  }

  .selected {
    background-color: $dark-grey;
    color: $flat-white;
  }

  .highlighted {
    font-weight: bold;
  }

  .weekend:not(.selected) {
    color: $warm-grey;
  }
}

.weekday {
  @include font-style($warm-grey, 20px);
  font-family: "Questrial", sans-serif;
  font-weight: 600;
  margin-bottom: 1.2vw;
}
