@import "styles/colors";

.underline {
  text-decoration: underline;
}

.linksWrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.tiny {
  font-size: 9px;
}

.cardContent {
  justify-content: space-between;
}

.overflow {
  overflow-y: auto;
}

//remove when the type error issue is fixed
.header.header {
  color: $deep-teal;
  font-weight: 700;
  margin: 0 0.5em;
  padding: 0.5em;
}
