@import "styles/mixins/main";

.wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.section {
  @include font-style($dark-grey, 14);

  display: flex;
  flex-direction: column;
  width: min-content;
}

.switchers {
  display: flex;
  justify-content: space-between;
  width: max-content;
}

.picker {
  @include font-style($warm-grey, 28);

  align-items: center;
  background-color: $flat-white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px 0;
  width: 60px;

  .content {
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  button {
    @include icon-button();
    //margin: 20px;
  }

  span:last-of-type {
    font-size: 12px;
  }
}

.semicolon {
  align-items: center;
  display: flex;
  margin-right: 10px;
}
