@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.wrapper {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: max-content;
  margin: auto;
  max-height: 100%;
  position: relative;
}

.layout {
  @media #{$mobile} {
    .cards_wrapper {
      padding-bottom: 60px;

      @supports (-webkit-overflow-scrolling: touch) {
        margin-bottom: 60px;
        padding-bottom: 80px;
      }
    }
  }
}

.break_lines {
  white-space: pre-wrap;
}

.modal {
  max-width: 650px;

  .header {
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  @media #{$mobile} {
    .header {
      border-radius: 0;
      box-shadow: none;
    }

    .cards_wrapper {
      @supports (-webkit-overflow-scrolling: touch) {
        padding-bottom: 120px;
      }
    }
  }
}

.hasError {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 90vh;
}

.errorWrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  margin: 1em;
  padding: 1em;

  .title {
    display: block;
  }
}

.title {
  color: $deep-teal;
  font-size: 24px;
  padding: 16px;
}

.header {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#0399be), to(#014660));
  background-image: linear-gradient(0deg, #0399be, #014660);
  display: flex;
  padding: 2em 1em;

  @media #{$mobile} {
    align-items: center;
    flex-flow: column wrap;
    padding: 1em 1em 2em;
    text-align: center;
  }
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;

  @media #{$mobile} {
    margin-left: 0;
  }

  .buttons {
    display: flex;
    flex-flow: row wrap;

    @media #{$mobile} {
      justify-content: center;
    }

    button {
      margin: 0.5em;
      position: relative;

      @media #{$mobile} {
        margin: 0.3em;
      }
    }
  }
}

.username {
  color: $flat-white;
  font-family: "Questrial", sans-serif;
  font-size: 26px;
  margin: 10px 0;

  @media #{$mobile} {
    font-size: 18px;
  }
}

.pts {
  align-items: center;
  color: $greyish-teal;
  display: flex;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;

  span {
    margin-left: 6px;
  }

  svg {
    height: 22px;
    width: 22px;
  }
}

.button {
  @include outlined-button($flat-white);

  border-radius: 20px;
  color: $flat-white;
  font-size: 14px;
  font-weight: normal;
  height: 28px;
  padding: 3px 25px;
  width: max-content;

  &:hover:not(:disabled) {
    background-color: lighten($deep-teal, 10%);
  }

  &:focus:not(:disabled) {
    background-color: lighten($deep-teal, 10%);
  }
}

.cards_wrapper {
  overflow: auto;
  padding: 16px 16px 20px;
}

.card {
  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  color: #4d4d4d;
  display: grid;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr;
  margin-bottom: 15px;
  padding: 20px 10px;

  h4 {
    color: $greyish-teal;
    font-size: 22px;
  }

  .card_content {
    display: flex;
    flex-direction: column;
    line-height: 20px;
  }
}

.social_link_wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 5px;

  svg {
    height: 22px;
    margin-right: 10px;
    width: 22px;
  }
}

a {
  color: $deep-teal;
  text-decoration: none;
}

.capitalized {
  text-transform: capitalize;
}

.avatar_block {
  position: relative;

  [class*=status] {
    bottom: 0;
    height: 20px;
    position: absolute;
    right: 0;
    width: 20px;

    &[class*=do_not_disturb][class*=offline] {
      background-color: $white;
    }
  }
}

@media #{$mobile} {
  .wrapper {
    max-width: 100vw;
    min-height: 95vh;
    padding: 0;
  }

  .cards_wrapper {
    padding-top: 0;
    position: relative;
    top: -20px;
    z-index: 2;
  }

  .title {
    display: none;
  }
}

[class*=full-screen-modal_wrapper]>[class*=full-screen-modal_body] {
  max-width: 100vw;
  padding: 0;
}

::-webkit-scrollbar {
  width: 3px;
}
