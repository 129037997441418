@import "styles/mixins/main";

$menu-hober-color: #e6f1fd;

.menu-block {
  position: relative;
}

.menu {
  background-color: transparent;
  border: 0;

  svg {
    height: 20px;
    width: 4px;
  }
}

.menu-header {
  align-items: center;
  color: $greyish-teal;
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 20px;
  justify-content: space-between;
  padding: 10px 20px;

  button,
  svg {
    height: 20px;
    width: 20px;
  }

  button {
    background-color: transparent;
    border: 0;
    border-radius: 100%;
    padding: 0;
  }

  svg {
    transform: rotate(45deg);
  }

  button:hover {
    background-color: darken($flat-white, 10);
  }
}

.link {
  cursor: pointer;
}

.menu-container {
  background-color: $flat-white;
  border-radius: 10px;
  box-shadow: 0 0 2px rgba($dark-grey, 0.6);
  min-width: 285px;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: start;
}

.rule {
  border: 0;
  border-top: 1px solid $simple-grey;
}

.open {
  animation: show 0.1s ease-in-out;
  animation-fill-mode: forwards;
}

.close {
  animation: hide 0.1s ease-in-out;
  animation-fill-mode: forwards;
}

.closed {
  visibility: hidden;
}

.item {
  padding-left: 0;
}

.notification-status {
  padding: 10px 20px;

  .status {
    color: $deep-teal;
    font-size: 18px;
    margin-bottom: 6px;
  }

  .unmute-date {
    color: $dark-grey;
  }
}

.do-not-disturb {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
}

.switch {
  @include switch-input("slider");
}

.menu-item {
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 8px 20px;
}

.menu-item:hover {
  background: $menu-hober-color;
}

.menu-item:active {
  background: darken($menu-hober-color, 2);
}

.link {
  color: $deep-teal;
}

@keyframes show {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
