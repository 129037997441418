@import "styles/mixins/main";

.card {
  background: $flat-white;
  border: 1px solid $simple-grey;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  /* FOR EDGE */
}


.header {
  border-bottom: 1px solid $simple-grey;
  display: flex;
  justify-content: space-between;

  .label {
    @include body-style($deep-teal, 15);
    display: block;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding: 0.7em 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.header-select {
  @include body-style($deep-teal, 15);
  -webkit-appearance: menulist;
  background: none;
  border: 0;
  border-left: 1px solid $simple-grey;
  cursor: pointer;
  margin: 0 0.5em;
  padding: 0.5em;
  text-overflow: ellipsis;
}

.header-select-full {
  @extend .header-select;
  border-left: 0;
  font-weight: bold;
  width: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 1.2em;

  strong {
    font-weight: bold;
  }
}

.list {
  flex: 1;

  .item {
    align-items: center;
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 0.6em 0;

    &:first-of-type {
      border-top: 0;
    }

    p,
    a {
      flex: 1;
      margin: 0;
    }

    .actions {
      margin-left: 0.5em;
    }
  }
}
