@import "styles/mixins/main";

.wrapper {
  align-items: center;
  border-bottom: 1px solid $simple-grey;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px;
}

.back {
  display: none;

  &:hover {
    opacity: 0.6;
  }

  @media #{$mobile} {
    @include icon-button();

    margin-right: 10px;
    width: 30px;
  }
}

.header_data {
  @include body-style();
  align-items: center;
  display: flex;
  font-weight: 600;
  height: 60px;
}

.status {
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0 10px;

  svg {
    height: 10px;
    width: 10px;
  }
}

.direct_status {
  margin-left: 0;
}

.menu {
  @include icon-button();

  svg {
    height: 20px;
    width: 15px;
  }
}

.opened_menu {
  transform: rotate(90deg);
}

.popup {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1000;
}


@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.toggle {
  cursor: pointer;
}

.popup_wrapper {
  animation: show 0.3s;
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
  cursor: auto;
  padding: 20px 15px;
  position: relative;
  width: 340px;

  &::before {
    background-color: $flat-white;
    height: 15px;
    position: absolute;
    right: 5px;
    top: -5px;

    transform: rotate(45deg);

    width: 15px;
    z-index: 3;
  }

  svg {
    height: 22px;
    width: 22px;
  }

  h4 {
    color: $greyish-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    margin-bottom: 15px;
  }

  span {
    color: $deep-teal;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
  }

  @media #{$mobile} {
    width: 250px;
  }
}

.option {
  align-items: center;
  cursor: pointer;
  display: flex;
  text-transform: capitalize;

  &.disabled {
    color: $simple-grey;
    pointer-events: none;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px;
}

.settings_wrapper {
  display: grid;
  grid-row-gap: 15px;
  max-height: 300px;
  overflow-y: auto;
}
