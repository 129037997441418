@import "styles/colors";

.wrapper {
  display: flex;
  flex-direction: column;
}

.dot {
  border: 2px solid var(--color-base, $warm-grey);
  border-radius: 100%;
  cursor: pointer;
  height: 10px;
  margin-top: 15px;
  transition: border 50ms linear;
  width: 10px;

  &.active {
    border: 2px solid var(--color-active, $greyish-teal);
  }
}
