@import "styles/colors";
@import "styles/media";

.logout, .delete_message {
  background-color: $flat-white;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  max-width: 600px;

  padding: 20px;
  z-index: 6;
}
