@import "../../../styles/colors";

// List Group

%sublist {
  padding-left: 10px;
}

.bullet-0 {
  list-style: disc inside;
  margin: 10px 0;
}

.bullet-1 {
  @extend %sublist;
  list-style: circle inside;
}

.bullet-2 {
  @extend %sublist;
  list-style: circle inside;
}

.ordered-0 {
  list-style: decimal inside;
  margin: 10px 0;
}

.ordered-1 {
  @extend %sublist;
  list-style: lower-latin inside;
}

.ordered-2 {
  @extend %sublist;
  list-style: lower-roman inside;
}

// Inline group
.mention {
  background-color: rgba($deep-teal, 0.5);
  border-radius: 10px;
  color: $flat-white;
  cursor: pointer;
  display: inline-block;
  margin: 5px 0;
  padding: 5px 7px;
  white-space: pre;
}

.emoji {
  margin: 0 5px;

  img {
    max-height: 40px;
    max-width: 40px;
  }
}

.alone-emoji {
  img {
    max-height: 20px;
    max-width: 20px;
  }
}

.wrapper {
  position: relative;

  .anchor {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    position: absolute;
    right: 8px;
    top: 8px;

    svg {
      width: 20px;
    }
  }
}
