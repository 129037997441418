.loader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 28px;

  > div {
    animation: loading 1s ease-in-out infinite;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    width: 4px;

    &:nth-child(1) {
      animation-delay: 0;
      background-color: #014660;
    }

    &:nth-child(2) {
      animation-delay: 0.09s;
      background-color: #4cb49c;
    }

    &:nth-child(3) {
      animation-delay: 0.18s;
      background-color: #e55e50;
    }

    &:nth-child(4) {
      animation-delay: 0.27s;
      background-color: #dda700;
    }
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.2);
  }

  40% {
    transform: scale(1);
  }
}
