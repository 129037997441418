@import "styles/colors";
@import "styles/mixins/main";

.base {
  display: block;
  font-family: "OpenSans", sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Questrial", sans-serif;
}

.h1 {
  // @include fluid-size-property("font-size", 36, 10);
  font-size: 36px;
}

.h2 {
  // @include fluid-size-property("font-size", 26, 5);;
  font-size: 36px;
}

.h3 {
  // @include fluid-size-property("font-size", 22, 4);
  font-size: 26px;
}

.h4 {
  // @include fluid-size-property("font-size", 20, 3);
  font-size: 22px;
}

.h5 {
  // @include fluid-size-property("font-size", 16, 2);
  font-size: 20px;
}

.h6 {
  // @include fluid-size-property("font-size", 16, 1);
  font-size: 16px;
}

.p {
  // @include fluid-size-property("font-size", 16, 2);
  font-size: 16px;
}

.span {
  display: inline-block;
}

.lead {
  display: block;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.invert {
  color: $white;
}

.disabled {
  color: #aaa;
}

.margin-top {
  margin-top: 0.5em;
}

.margin-bottom {
  margin-bottom: 0.5em;
}

.margin-both {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
