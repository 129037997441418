@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.opened_wrapper,
.closed_wrapper {
  display: flex;

  button {
    @include font-style($deep-teal);

    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 22px;
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0 10px;
    padding: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  button.active {
    background-color: $simple-grey;
    color: $dark-grey;
  }

  button.chat {
    display: none;

    @media #{$mobile} {
      display: flex;
    }
  }
}

.opened_wrapper {
  align-items: center;
  border-bottom: 1px solid $very-light-pink;
  height: 60px;

  justify-content: space-around;
  min-height: 60px;
  width: 320px;

  svg {
    margin-right: 10px;
  }

  button {
    font-size: 14px;
  }

  button>svg {
    height: 20px;
    width: 20px;
  }

  button.back {
    background-color: rgba($simple-grey, 0.3);
    height: 40px;
    margin-left: 10px;
    max-width: 40px;
    padding: 0;
  }

  button.back:hover {
    background-color: rgba($simple-grey, 0.5);
  }

  button.back>svg {
    margin-right: 0;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.closed_wrapper {
  flex-direction: column;
  height: 100%;

  button {
    border-radius: 50%;
    margin-top: 20px;
    max-height: 50px;
    width: 50px;
  }
}

.activity_module {
  overflow: scroll;
  width: 320px;

  @media #{$mobile} {
    width: 100%;
  }
}
