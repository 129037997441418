@import "styles/mixins/main";

.field {
  @include v-input();

  input:focus {
    box-shadow: 0 0 4px $greyish-teal;
  }

  label {
    color: $dark-grey;
    font-size: 14px;
  }

  input {
    font-size: 16px;
    height: 28px;
    line-height: 16px;
  }

  svg {
    height: 18px;
  }

  .hidden_icon>svg {
    visibility: hidden;
  }

  .box {
    position: relative;
  }

  [class*="clear"]+input {
    padding-right: 40px;
  }

  [class*="show"]+input {
    padding-right: 40px;
  }
}

.fill_to_end {
  height: 100%;
}
