@import "styles/colors";
@import "styles/media";

.launchModal {
  background-color: #f6f6f6;
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 6;
}

.launch {
  scroll-behavior: smooth;

}

.header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 7;

  .options {
    align-items: center;
    display: flex;
    margin-left: auto;

    svg {
      cursor: pointer;
      height: 25px;
      width: 25px;
    }
  }

  .wrench {
    display: none;
  }

  .menu {
    display: inline-block;
  }

  @media #{$desktop} {
    justify-content: initial;

    .wrench {
      display: inline-block;
    }

    .menu {
      display: none;
    }
  }
}

.timer {
  align-items: center;
  background: var(--timer-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
}

.sponsor {
  align-items: flex-end;
  display: flex;
  min-height: 280px;
  padding: 0 10%;
  position: relative;

  .mask {
    background-color: rgba(1, 70, 96, 0.78);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  img {
    max-width: 400px;
    min-width: 300px;
    width: 30%;
  }


  .text {
    align-self: center;
    margin-left: 80px;
    padding: 20px 0;
    z-index: 2;

    h2 {
      color: $greyish-teal;
      font-family: "Questrial", sans-serif;
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 20px;
      max-width: 470px;
    }

    span {
      color: $white;
      display: block;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 28px;
      max-width: 590px;
    }
  }

  @media #{$mobile} {
    padding: 0 20px 0 0;

    .text {
      margin-left: 0;
    }
  }


  @media all and(max-width: 650px) {
    img {
      margin-left: -70px;
    }

    .text {
      h2 {
        font-size: 5vw;
      }

      span {
        font-size: 4vw;
        line-height: 4.5vw;
      }
    }
  }

}

.nav {
  align-items: center;
  display: flex;
  padding: 0 40px;

  span {
    color: var(--nav-color);
    cursor: pointer;
    font-family: "Questrial", sans-serif;
    font-size: 18px;
    margin-right: 32px;

    &[active=""] {
      color: var(--nav-active-color);
    }
  }

  @media #{$mobile} {
    display: none;
  }
}

.title {
  color: var(--timer-title-color);
  font-family: "Questrial", sans-serif;
  font-size: 26px;
  margin-bottom: 14px;
  text-align: center;
  @media #{$mobile} {
    display: flex;
    flex-direction: column;
    font-size: 21px;

    span {
      margin-top: 5px;
    }
  }

  span {
    color: #4cb49c;
  }
}

.keyDatesContainer {
  background: var(--dates-bg);
  padding: 36px 60px;

  h2 {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  @media #{$mobile} {
    padding: 40px 15px 30px;

    h2 {
      font-size: 28px;
    }
  }
}

.keyDate {
  display: flex;
  margin-bottom: 20px;
}

.keyDate:not(:last-child) .date hr {
  background-color: #014660;
  border: 0;
  flex: 1 1;
  margin: 0;
  width: 1px;
}



.date {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  svg {
    height: 47px;
    width: 42px;
  }

  .day {
    color: $deep-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    left: calc(50% - 16px);
    position: absolute;
    text-align: center;
    top: 20px;
    width: 30px;
  }

  .month {
    color: $deep-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 4px 0;
  }

  @media #{$mobile} {
    svg {
      height: 34px;
      width: 34px;
    }

    .day {
      font-size: 17px;
      top: 14px;
    }

    .month {
      font-size: 16px;
    }
  }
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  margin-left: 20px;
  overflow: hidden;
  width: 560px;

  .top {
    padding: 20px 16px 14px;

    .cardTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      h3 {
        color: $warm-grey;
        font-family: "Questrial", sans-serif;
        font-size: 26px;
        font-weight: 400;
      }

      span {
        color: $very-light-grey;
        font-size: 22px;
        font-weight: 400;
      }
    }

    .cardText {
      color: #4d4d4d;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .bottom {
    background-color: var(--card-bg, $pastel-orange);
    color: var(--card-color, $dark-grey);
    padding: 10px 16px;
  }
}

.keyDate:nth-child(2) .card .bottom {
  background-color: var(--card-first-bg, $greyish-teal);
  color: var(--card-first-color, $flat-white);
}

.tips {
  align-items: center;
  background-color: var(--tips-bg, $deep-teal);
  display: flex;
  min-height: 680px;
  padding: 80px;

  h2 {
    color: $greyish-teal;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 470px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    .tipsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

    }
  }
  @media #{$mobile} {
    padding: 80px 10px;

    .inner {
      .tipsWrapper {
        flex-direction: column;
      }

      h2 {
        margin-bottom: 50px;
      }
    }
  }
}

.tip {
  display: flex;
  flex: 1 1 315px;
  max-width: 315px;

  img {
    height: 55px;
    min-width: 55px;
    width: 55px;
  }

  .info {
    margin-left: 20px;

    h3 {
      color: $greyish-teal;
      font-family: "Questrial", sans-serif;
      font-size: 26px;
      font-weight: 400;
    }

    div {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0;
    }

    a {
      align-items: center;
      background-color: transparent;
      border: 1px solid $flat-white;
      border-radius: 42px;
      color: $flat-white;
      cursor: pointer;
      display: flex;
      font-family: "Open Sans", sans-serif;
      font-size: 17px;
      grid-area: button;
      height: 27px;
      justify-content: center;
      margin-top: 10px;
      width: 84px;

      &:hover {
        background-color: $flat-white;
        color: $deep-teal;
      }
    }
  }
  @media #{$mobile} {
    flex: initial;
    margin-bottom: 40px;
    max-width: initial;
  }
}

.navigation {
  position: fixed;
  right: 10px;
  top: 30%;
  z-index: 4;
  @media #{$mobile} {
    display: none;
  }
}

.footer {
  display: flex;
  height: 760px;

  .left {
    background: var(--footer-bg);
    flex: 1;
  }

  .right {
    background-color: #d8d8d8;
    flex: 1;
    position: relative;
  }

  .footerCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 230px;
    overflow: hidden;
    position: absolute;

    .footerCardTop {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding: 30px;

      h3 {
        color: #014660;
        font-family: "Questrial", sans-serif;
        font-size: 36px;
        font-weight: 400;
        text-transform: uppercase;
      }

      h4 {
        color: #014660;
        font-family: "Open Sans", sans-serif;
        font-size: 24px;
        margin-top: 5px;
      }
    }

    .footerCardBottom {
      align-items: center;
      background-color: #f6f6f6;
      display: flex;
      height: 90px;
      padding: 20px 30px;

      img {
        border-radius: 100%;
        height: 48px;
        width: 48px;
      }

      div {
        color: #014660;
        font-family: "Questrial", sans-serif;
        font-size: 22px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }
  @media #{$mobile} {
    flex-direction: column;

    .left {
      flex: initial;
      height: 80%;
    }

    .right {
      flex: initial;
      height: 20%;
    }

    .footerCard .footerCardTop {
      padding: 25px 15px;

      h3 {
        font-size: 21px;
      }

      h4 {
        font-size: 18px;
      }
    }

    .footerCard .footerCardBottom {
      font-size: 18px;
      height: 70px;
      padding: 15px;
    }

    .footerCard {
      border-radius: 4px;
      bottom: 30px;
      height: min-content;
      left: 15px;
      right: 15px;
      top: -50px;
    }
  }

  @media #{$desktop} {
    .footerCard {
      left: -60px;
      top: calc(50% - 115px);
      width: 610px;
    }
  }
}

.mobileMenu {
  align-items: center;
  background-color: #014660;
  bottom: 0;
  left: 0;
  padding: 10px 15px 10px 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  .mobileHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 25px 0;

    svg {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }

  .mobileNav {
    display: flex;
    flex-direction: column;

    svg {
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }

    &:last-child {
      margin-top: 25px;
    }

    span {
      align-items: center;
      color: var(--nav-color);
      cursor: pointer;
      display: flex;
      font-family: "Questrial", sans-serif;
      font-size: 20px;
      margin-bottom: 25px;

      &[active=""] {
        color: var(--nav-active-color);
      }
    }
  }
}
