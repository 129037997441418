@import "styles/colors";

.receivers_list {
  max-height: 400px;
  overflow-y: auto;
}

.receiver {
  align-items: center;
  background-color: $pastel-grey;
  color: $deep-teal;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  &:hover {
    background-color: $white;
  }

  svg {
    width: 20px;
  }

  [class^="avatar"] {
    margin-right: 10px;
  }

  .info {
    align-items: center;
    display: flex;

    w-avatar {
      margin-right: 10px;
    }
  }
}
