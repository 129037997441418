@import "styles/mixins/main";

.wrapper {
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @media #{$mobile} {
    justify-content: center;
    margin-top: 15px;
  }

  h1 {
    @include font-style($greyish-teal, 26);

    font-family: "Questrial", sans-serif;
    margin-bottom: 40px;

    @media #{$mobile} {
      align-self: center;
      margin-bottom: 20px;
    }
  }
}

.info-wrapper {
  display: flex;

  @media #{$mobile} {
    align-items: center;
    flex-direction: column;

    text-align: center;
  }
}

.info {
  h2 {
    @include font-style($greyish-teal, 22);

    font-family: "Questrial", sans-serif;
    margin-bottom: 10px;

    @media #{$mobile} {
      margin-top: 20px;
    }
  }

  h3 {
    @include font-style($flat-white, 16);

    line-height: 1.5;
  }

  @media #{$desktop} {
    margin-left: 20px;
  }

  @media #{$mobile} {
    max-width: 380px;
    padding: 10px;
  }
}
