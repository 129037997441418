@import "../../styles/colors";
@import "../../styles/media";

.wrapper {
  background-color: #f6f6f6;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;

  padding: 80px 0 60px;
  width: 100%;

  @media #{$mobile} {
    padding: 80px 10px 60px;
  }
}

.lastBlock {
  margin-bottom: 60px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 550px;

  @media #{$mobile} {
    width: 100%;
  }

  h2 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-top: 40px;

    @media #{$mobile} {
      font-size: 19px;
    }
  }

  h3 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    font-weight: 400;

    margin-top: 25px;

    @media #{$mobile} {
      font-size: 17px;
    }
  }

  h4 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;

    @media #{$mobile} {
      font-size: 15px;
    }
  }

  h5 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    font-weight: 400;

    @media #{$mobile} {
      font-size: 12px;
    }
  }

  span {
    color: #4d4d4d;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;

    margin-top: 15px;

    @media #{$mobile} {
      font-size: 13px;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    margin-top: 20px;

    padding: 3px 15px;

    width: fit-content;
  }
}

.upgrade {
  span {
    margin-top: 15px !important;
  }

  a {
    color: $deep-teal;
  }
}

.card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.19);
  margin-top: 50px;
  padding: 25px 20px 35px;
  width: 522px;
  @media #{$mobile} {
    height: auto;
    margin-top: 30px;
    padding: 25px 15px;
    width: 100%;
  }

  h3 {
    margin: 0;
  }

  h4 {
    margin-top: 25px;
  }

  h5 {
    margin-top: 15px;
  }

  span {
    display: inline-block;

    margin-top: 8px;
  }
}
