@import "styles/mixins/main";

.focus {
  border-top: 1px solid #e8e8e8;
  padding: 0.4em 0;

  h3 {
    @include body-style($warm-grey, 15);
    font-weight: bold;
    margin-bottom: 0.2em;
  }

  .goal {
    font-size: 14px;
    margin: 0;
  }
}

.change-button {
  background: none;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;

  button {
    flex: 0 0 48.5%;
  }
}

.empty {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1em;
  text-align: center;
}
