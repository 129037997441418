@import "styles/mixins/main";

.wrapper {
  background: $white;
  flex: 1;
  padding: 2em 1em 3em;

  h1 {
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin-bottom: 1.5em;
  }

  p {
    @include body-style($dark-grey, 15);
    line-height: 1.35em;
    margin-bottom: 1em;
  }

  @media #{$desktop} {
    margin: 0 auto;
    max-width: 1300px;
    padding: 2em;
  }
}

.grid {
  display: inline-grid;
  grid-row-gap: 1em;
  margin-bottom: 2em;

  @media #{$desktop} {
    display: grid;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  >* {
    display: flex;
  }
}

w-dash-tracker-module {
  min-width: 0; // Needed for EDGE layout issue

  @media #{$desktop} {
    grid-column: span 2;
  }

  &:nth-child(3n+3) {
    grid-column: span 1;
  }
}

.avatars {
  position: absolute;
  right: 10px;
  top: -30px;
  transform: scale(0.7);

  >div:last-of-type {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    left: 40px;
    position: absolute;
    top: 0;
  }
}

.actionWithButton {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    @include body-style($dark-grey, 15);
    flex: 1;
    margin-bottom: 0;
  }

  button {
    margin-left: 10px;
    width: 100px;
  }
}

.done {
  background: $greyish-teal;
  border-radius: 4px;
  color: $deep-teal;
  margin-left: 10px;
  margin-top: 10px;
  max-width: 100px;
  padding: 0.4em 0.6em;
  text-align: center;

  @media #{$desktop} {
    margin-top: 0;
  }
}
