@import "styles/colors";
@import "styles/media";

.wrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  display: grid;
  grid-area: search;
  grid-column-gap: 10px;
  grid-template-columns: min-content 1fr min-content;
  height: 40px;
  padding: 0 10px;
  position: relative;
  transition: all 300ms ease-in-out 0s;
  width: 100%;

  &:focus-within {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  button {
    position: absolute;
    right: 7px;
    top: 9px;

    &:hover {
      background-color: transparent !important;
    }
    @media #{$mobile} {
      top: 5px;
    }
  }

  input {
    background-color: transparent;
    border: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      outline: none;
    }

    &::placeholder {
      color: #a0a0a0;
    }
  }

  svg {
    height: 16px;
    width: 20px;
  }

  @media #{$desktop} {
    height: 41px;

    svg {
      height: 22px;
      width: 22px;
    }
  }
}
