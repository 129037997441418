@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
}

.mobile-title {
  @include subtitle();
}

.subtitle {
  @include subtitle();
}

.description {
  @include description();
}

@media #{$mobile} {
  .subtitle {
    font-size: 18px;
  }

  .cards-wrapper {
    .header {
      background: #fff;
      padding: 20px 15px;

      .title {
        display: block;
        font-size: 20px;
      }
    }

    .select-skills-wrapper {
      padding: 0;
    }

    .skill_name {
      font-size: 15px;
      padding-right: 6px;
    }
  }
}

.select-skills-wrapper {
  margin: 20px 0;
  padding: 20px 15px;
}

.select-skills-header {
  display: inline-flex;
  margin-bottom: 10px;
  width: 100%;

  .control {
    display: block;
    text-align: right;
  }
}

@media #{$mobile} {
  .select-skills-wrapper {
    margin: 0;
    padding: 20px 0;
  }

  .select-skills-header {
    background: $white;
    margin: 0;
    padding: 10px;
  }
}

.col-desc {
  color: $dark-grey;
  font-size: 12px;
  margin-top: 10px;
}

.button {
  @include button($deep-teal, $white);
}

.outlined-button {
  @include outlined-button($deep-teal);
}

.button,
.outlined-button {
  font-size: 14px;
  padding: 2px 12px;
}

.skill {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 15px;

  svg {
    height: 28px;
    width: 28px;
  }

  &_remove {
    cursor: pointer;
    margin-right: 15px;
  }

  &_name {
    color: $deep-teal;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    width: 100%;
  }

  &_experience {
    color: $greyish-teal;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
  }

  &_controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 120px;

    span {
      text-align: center;
      width: 100%;
    }

    >div {
      cursor: pointer;
    }
  }
}

.input {
  @include input();
  margin: 40px 0;

  .label {
    @include font-style($dark-grey);
    padding: 10px;
  }

  input {
    font-size: 16px;
    width: 100%;
  }

  @media #{$mobile} {
    background: $white;
    margin: 0;
    padding: 40px 10px;
  }
}

.suggested {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .text {
    margin-bottom: 10px;
  }

  .skills_wrapper {
    max-height: 45vh;
    overflow: auto;

    @media #{$mobile} {
      max-height: 32vh;
    }
  }

  .skill {
    &_name {
      color: $dark-grey;
    }
  }
}
