@import "styles/mixins/main";

.share-goal-modal {
  @include font-style($dark-grey);
  background: $white;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  max-height: 95%;
  width: 550px;

  w-search {
    margin-bottom: 10px;
  }

  footer {
    display: flex;
    font-size: 16px;
    justify-content: flex-end;
    padding: 20px;
    width: 100%;

    button:last-of-type {
      margin-left: 10px;
    }
  }
}

.title {
  color: $greyish-teal;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 10px 16px;
}

.card {
  background-color: #fff;
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;
  padding: 14px 10px;

  .user-info {
    color: $warm-grey;
    flex: 1;
    line-height: 21px;
    padding-right: 10px;
  }

  .avatar-block {
    display: flex;
    height: 100%;
    margin-right: 15px;
  }
}

.link {
  color: $deep-teal;
  cursor: pointer;
  display: inline-block;
}

.description {
  padding: 8px 0;
}


.body {
  display: contents;
}

.user-list {
  flex: 1;
  margin-right: 5px;
  overflow: auto;
  -ms-overflow-style: none;
  padding: 2px 10px 15px 15px;
  scrollbar-width: thin;
}

.shared_users_list {
  @include body-style($dark-grey, 14);
  padding: 10px;
}

.shared-users {
  height: fit-content;
  max-height: 130px;
  -ms-overflow-style: none;
  overflow-y: auto;
  scrollbar-width: thin;

  .shared-item {
    margin-bottom: 10px;
  }
}

w-checkbox {
  margin: auto 10px;

  svg {
    height: 22px;
    width: 22px;
  }
}

@media #{$mobile} {
  .share-goal-modal {
    max-height: 100%;
    width: 100%;

    w-search {
      margin-bottom: 5px;
    }

    footer {
      padding: 10px 20px;
    }
  }

  .user-list {
    padding: 6px 0 0;
  }

  .search {
    padding: 0;
  }

  .shared-users {
    max-height: 80px;
  }
}
