@import "styles/mixins/main";

.wrapper {
  background: $greyish-teal;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  margin: -1px;
}

.switcher {
  align-self: flex-end;
  display: flex;
  margin: 1.2em 1.2em 0 0;

  button {
    @include body-style($deep-teal, 14);
    background: transparent;
    border: 1px solid $deep-teal;
    border-radius: 0;
    padding: 0.3em 0.5em;
    user-select: none;

    &:first-of-type {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .active {
    background: $deep-teal;
    border-color: transparent;
    color: $flat-white;
    font-weight: bold;
  }
}

.tracker {
  @include body-style($dark-grey, 15);
  cursor: grab;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  overflow-x: hidden;
  padding: 1em;
  width: 100%;
  /* FOR EDGE */

  &::-webkit-scrollbar {
    max-height: 6px;
    min-height: 6px;
    width: 1px;
  }

  .period {
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    min-height: 150px;
  }

  .actions {
    align-items: center;
    display: flex;
    flex: 1;
    flex-flow: row wrap;
    justify-content: space-evenly;
    position: relative;

    &::after {
      border-right: 1px dashed rgba(255, 255, 255, 0.6);
      bottom: 0;
      content: "";
      position: absolute;
      right: -10px;
      top: 0;
    }
  }

  .promise {
    border-bottom: 2px solid $warm-grey;
    margin-bottom: 0.5em;
    padding: 2em 0.5em 0.5em;
    position: relative;

    &::after {
      background: $warm-grey;
      border: 5px solid $greyish-teal;
      border-radius: 50%;
      bottom: -10px;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      width: 10px;
    }

    p {
      color: $warm-grey;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
      position: relative;
    }
  }

  h4 {
    padding: 0 0.5em;
  }
}

.bubble {
  background: $flat-white;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 0 0 42%;
  font-size: 13px;
  margin: 1em 0 0.5em;
  padding: 0.3em 0.5em;
  position: relative;
  transform: rotate(-3deg);

  &:nth-of-type(even) {
    margin-top: 1.5em;
    transform: rotate(3deg);
  }

  .icon {
    align-items: center;
    display: flex;
    flex: 1 0 25px;
    justify-content: flex-end;

    svg {
      width: 20px;
    }
  }

  .points {
    background: $pastel-orange;
    border: 2px solid $flat-white;
    border-radius: 30px;
    font-size: 11px;
    font-weight: bold;
    padding: 0.15em 0.3em;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

.empty {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1em;
}
