@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";
@import "styles/transitions";

w-alerts-module {
  flex: 1;
}

.placeholder {
  @include font-style();
  align-items: center;
  display: flex;
  flex-direction: column;

  font-size: 18px;
  justify-content: center;
  margin: auto;
  text-align: center;

  svg {
    height: 80px;
    margin-bottom: 30px;
    width: 80px;
  }

  @media #{$desktop} {
    min-height: 300px;
  }

  @media #{$mobile} {
    height: 100%;
  }
}

.container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: relative;

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 60px;
  }

  @media #{$desktop} {
    animation: show 0.5s;
    background-color: $flat-white;
    border-radius: 15px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
    max-height: 80vh;
    padding-bottom: 5px;
    width: 100%;
  }

  .read_all {
    align-items: baseline;
    color: $deep-teal;
    cursor: pointer;
    display: flex;
    font-family: "OpenSans", sans-serif;
    font-size: 14px;
    justify-content: flex-end;
    padding: 15px 15px 0;
    position: sticky;

    svg {
      height: 17px;
      margin-right: 5px;
      width: 17px;
    }

    &.disabled {
      filter: grayscale(50%);
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.header {
  color: $warm-grey;
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 15px;
}

.alert {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "OpenSans", sans-serif;
  margin-bottom: 10px;
  padding: 10px 20px;

  &:hover {
    background-color: $white;
  }

  >span {
    color: $warm-grey;
    font-size: 12px;
    margin-bottom: 10px;
  }

  >div {
    display: flex;

    h3 {
      color: $deep-teal;
      font-size: 14px;
      margin-bottom: 10px;

      &.unread {
        font-weight: 600;
      }
    }

    p {
      color: $warm-grey;
      font-size: 12px;
      line-height: 1.2em;
      margin-bottom: 5px;
    }

    strong {
      font-weight: bold;
    }
  }
}

.alert_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.alerts_list {
  max-height: 100%;
  overflow-y: auto;

  @media #{$mobile} {
    margin-bottom: 60px;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 60px;
  }
}
