@import "styles/mixins/main";

.scrollingContainer {
  position: relative;
}

.container {
  background-color: $flat-white;
  bottom: 45px;
  height: 0;
  left: -1px;
  margin-bottom: 1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  right: -1px;

  @media #{$mobile} {
    left: 0;
    max-height: 150px;
    right: 0;
  }
}

.show {
  border: 1px solid $simple-grey;
  border-radius: 20px 20px 0 0;
  height: auto;
}

.hide {
  height: 0;
}

.list {
  width: 100%;
}

.member {
  @include body-style($dark-grey, 15);
  align-items: center;
  display: flex;
  padding: 5px 40% 5px 20px;

  @media #{$mobile} {
    padding-right: 20px;
  }

  &:hover,
  &.selected {
    background-color: darken($flat-white, 5);
  }

  div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media #{$mobile} {
      left: 0;
      right: 0;
    }
  }

  .alias {
    margin: 0 15px;
  }
}


.mention {
  color: $deep-teal;
}
