#auth-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .auth-main-header {
    height: 220px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    @media #{$mobile} {
      display: none;
    }
  }

  .main-wrapper {
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: relative;
    right: 0;
    top: 0;


    @media #{$desktop} {
      top: 100px;

      &::-webkit-scrollbar {
        max-height: 150px;
        min-height: 6px;
        width: 0;
      }

    }
  }

  .auth-workspace-wrapper {
    background-color: $white;
    min-height: 300px;
    width: 100%;
    
    @media #{$mobile} {
      padding-bottom: 80px;
    }

    @media #{$desktop} {
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
      height: fit-content;
      margin-bottom: auto;
      width: 45%;
    }
  }

  .auth-workspace-header {
    @media #{$desktop} {
      height: 120px;
    }
  }

  #auth-workspace {
  }

}
