@import "styles/mixins/main";

.wrapper {
  background-color: rgba($white, 0.4);
  padding-bottom: 100px;

  p {
    @include font-style($dark-grey);

    padding: 20px;
  }
}

.description {
  @include font-style($dark-grey, 18);

  align-items: center;
  border-bottom: 2px solid $very-light-pink;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
}

.card {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px;

  button {
    padding: 5px 20px;
  }
}

.primary {
  @include button($deep-teal, $flat-white);
}

.outline {
  @include outlined-button($deep-teal, $deep-teal);
}
