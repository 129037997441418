@import "styles/mixins/main";

.show,
.clear {
  @include icon-button();
  align-items: center;

  display: flex;
  margin-left: -45px;
  margin-right: 25px;
  position: relative;
}

.input {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
