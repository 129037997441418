@import "styles/mixins/main";
@import "../../styles/mixins/profile";

.cards-wrapper {
  @include cards-wrapper();
}

.title {
  @include title();
  font-family: Questrial, sans-serif;
}

.subtitle {
  @include subtitle();
  font-family: Questrial, sans-serif;
}

.content {
  @include description();
}

.card {
  @include card();

  .header {
    display: flex;
  }
}

.break_lines {
  white-space: pre-wrap;
}

.link {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 5px;

  @media #{$mobile} {
    font-size: 14px;
  }
}

.soc-net-icon {
  display: flex;
  margin-right: 10px;

  svg {
    height: 22px;
    width: 22px;
  }
}
