@import "styles/mixins/main";

.wrapper {
  @include font-style($dark-grey, 14);
  line-height: 1.5;

  padding: 20px;

  a:active,
  a:focus {
    outline: none;
  }
}
