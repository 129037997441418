@import "styles/mixins/main";

.actions {
  flex: 1;
}

.item {
  align-items: center;
  border-top: 1px solid #e8e8e8;
  display: flex;
  padding: 0.4em 0;

  p {
    flex: 1;
    margin: 0;
  }

  button {
    margin-left: 0.5em;
  }
}
