@import "styles/colors";

.wrapper {
  align-self: center;
  background-color: #f6f6f6;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  height: max-content;
  max-width: 100%;
  padding: 10px;
  position: relative;
  width: 550px;
}

.header {
  color: #4cb49c;
  font-family: "Questrial", sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 18px;
}
