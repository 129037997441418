@import "styles/mixins/main";

.events {
  flex: 1;
}

.event-list {
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.event {
  display: flex;
  flex-flow: column;
  padding: 0.2em 0;

  p {
    margin-bottom: 0;
  }

  .date {
    @include body-style($warm-grey, 15);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .now {
    span:first-child {
      color: $pastel-red;
      font-weight: 600;

      &::before {
        background: $pastel-red;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 11px;
        margin-right: 5px;
        width: 11px;
      }
    }
  }

  .detail {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: 600;
      margin-right: 10px;
    }
  }
}


.no-events {
  @include body-style($warm-grey, 15);
  align-items: center;
  display: flex;
  flex: 1;
  padding: 1em;
  text-align: center;
}
