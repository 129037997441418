@import "styles/colors";
@import "styles/media";
@import "styles/mixins/main";

.avatar {
  @include icon-button();

  border-radius: 100%;
  position: relative;

  [class*=status] {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  img {
    height: 50px;
    width: 50px;
  }

  @media #{$mobile} {
    justify-self: end;

    img {
      height: 40px;
      width: 40px;
    }
  }
}
