@import "styles/components/header";

.wrapper {
  max-height: 80vh;
  position: absolute;
  right: 20px;
  top: 115px;
  width: 400px;
  z-index: 3;

  &::before {
    border-bottom: 10px solid $flat-white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 75px;
    top: -10px;
    width: 0;
    z-index: 1000;
  }
}

.alerts_spring {
  height: 80vh;
  position: absolute;
  right: 20px;
  top: 115px;
  width: 400px;
  z-index: 1000;
}

.arrow_up {
  display: none;

  @media #{$desktop} {
    border-bottom: 10px solid $flat-white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: block;
    height: 0;
    position: absolute;
    right: 75px;
    top: -10px;
    width: 0;
    z-index: 1001;
  }
}


.badge {
  align-items: center;

  background: linear-gradient(#5fe8c3, #43b395);
  border-radius: 18.5px;

  color: $flat-white;
  display: flex;
  font-size: 12px;

  height: 18px;
  justify-content: center;
  left: 25px;

  position: absolute;
  top: 7px;
  width: 18px;
}
