
.radio-button {
  .radio-icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  input {
    display: none;
  }
}
