@mixin switch-input($class) {
  display: inline-block;
  height: 23px;
  position: relative;
  width: 40px;

  input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  .#{$class} {
    background-color: #ccc;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .#{$class}::before {
    background-color: $flat-white;
    border-radius: 50%;
    bottom: 2px;
    content: "";
    height: 19px;
    left: 2px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 19px;
  }

  input:checked + .#{$class} {
    background-color: $greyish-teal;
  }

  input:checked + .#{$class}::before {
    transform: translateX(16px);
  }
}

@mixin input() {
  label {
    margin-bottom: 10px;
    margin-left: 5px;
  }

  input, textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px rgb(77, 77, 77);
    padding: 5px 13px;
  }

  input {
    height: 35px;
  }

  textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px;
  }

  &.valid {
    input, textarea {
      box-shadow: 0 0 2px rgba($greyish-teal, 0.6);
    }
  }

  &.invalid {
    input, textarea {
      box-shadow: 0 0 2px rgba($pastel-red, 0.6);
    }
  }
}

@mixin v-input () {
  @include font-style();
  @include input();

  background-color: rgba($simple-grey, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 20px;

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: 300px;
    min-height: 100px;
  }

  input, textarea {
    display: flex;
    flex: 1;
    margin-right: 10px;
  }

  .box {
    align-items: center;
    display: flex;
  }

  span {
    color: $dark-grey;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &.valid {
    background-color: rgba($greyish-teal, 0.2);

    input:focus {
      box-shadow: 0 0 4px $greyish-teal;
    }
  }

  &.invalid {
    background-color: rgba($pastel-red, 0.2);

    input:focus {
      box-shadow: 0 0 4px $pastel-red;
    }
  }

  &.disabled {
    background-color: rgba($warm-grey, 0.3);
    opacity: 0.7;

    svg {
      visibility: hidden;
    }
  }

  .error {
    color: $pastel-red;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px;
  }
}

@mixin checkbox() {

}

@mixin validation-block() {
  .validation {
    display: flex;
    padding: 20px;

    .section {
      flex: 1;
    }

    .val_field {
      align-items: center;
      display: flex;
      line-height: 2;

      svg {
        height: 15px;
        margin-right: 5px;
        width: 15px;
      }
    }
  }

}
