@import "styles/mixins/main";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media #{$desktop} {
    border-right: 1px solid $very-light-pink;
  }

  @media #{$mobile} {
    @supports (-webkit-overflow-scrolling: touch) {
      height: calc(100% - 120px);
    }
  }

  h1,
  h2,
  p {
    @include font-style($warm-grey, 20);

    padding: 20px 20px 0;
  }

  p {
    font-size: 16px;
  }
}

.loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.header {
  align-items: center;
  border-bottom: 1px solid $very-light-pink;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px;

  button {
    @include icon-button();
    @include font-style($deep-teal, 15);

    align-items: center;
    display: flex;
  }

  svg {
    height: 15px;
  }

  svg:last-child {
    margin-left: 5px;
  }

  svg:first-child {
    margin-right: 5px;
  }

  button:disabled {
    opacity: 0.5;
  }
}

.status {
  display: flex;

  span {
    background-color: $simple-grey;
    flex: 1;
    height: 3px;
  }

  span {
    content: none;
  }

  span:not(:last-of-type) {
    margin-right: 2px;
  }
}

.details_bar>span:nth-of-type(1),
.tags_bar>span:nth-of-type(2),
.members_bar>span:nth-of-type(3) {
  background-color: rgba($deep-teal, 0.7);
}

.tags_bar>span:nth-of-type(1),
.members_bar>span:nth-of-type(1),
.members_bar>span:nth-of-type(2) {
  background-color: $deep-teal;
}

.screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

/** Details screen additional styles */
.switch {
  @include switch-input("slider");
}

.access {
  @include font-style();

  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 20px;

  h3 {
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  p {
    color: $dark-grey;
    padding: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
  }
}

/** Details screen additional styles end*/

/** Search */
.search {
  @include input();
  background-color: rgba($simple-grey, 0.4);

  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  padding: 30px 20px;
}

.box {
  position: relative;
  width: 100%;

  input {
    padding: 0 35px 0 10px;
    width: 100%;
  }

  input:focus {
    box-shadow: 0 0 5px rgba($greyish-teal, 0.7);
  }
}

.clear {
  @include icon-button();

  position: absolute;
  right: 10px;
  top: 8px;

  svg {
    height: 20px;
    width: 20px;
  }
}

/** Search end */

/** Tags results */

.results {
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow-y: auto;

  padding: 20px 14px 20px 20px;
}

.tag {
  align-items: center;
  background-color: $flat-white;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px;

  button {
    @include button($deep-teal, $flat-white);

    margin-right: 10px;
    padding: 3px 15px;
  }
}

.no_results {
  @include font-style($dark-grey);

  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  justify-content: center;
  padding: 20px;
  text-align: center;

  svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px;
  }
}

/** Tags results end */

.members {
  display: flex;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
}

.member {
  @include member-card();
}

.participants-wrapper {
  background-color: rgba($greyish-teal, 0.2);
  position: relative;
}

.participants-list-wrapper {
  overflow-x: hidden;
  padding: 5px 20px;

  @media #{$mobile} {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
      min-height: 0;
    }
  }
}

.participant {
  color: $warm-grey;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 15px;
  margin-top: 5px;
  position: relative;
  text-align: center;
  width: min-content;

  @media #{$desktop} {
    margin-right: 16px;
  }

  span {
    font-family: "OpenSans", sans-serif;
    font-size: 12px;
    margin-top: 5px;
  }

  svg {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 17px;
  }
}

.participants {
  display: flex;
  height: min-content;
  transform: translate(var(--offset), 0);
  transition: transform 1s;
  width: min-content;
  will-change: transform;
}

%chevron {
  align-items: center;
  background-color: $pastel-grey;
  bottom: 0;
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 20px;
  z-index: 1;

  @media #{$mobile} {
    display: none;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.chevron_left {
  @extend %chevron;

  left: 0;

  svg {
    transform: rotate(90deg);
  }
}

.chevron_right {
  @extend %chevron;

  right: 0;

  svg {
    transform: rotate(-90deg);
  }
}
