@import "styles/mixins/main";

.cardContent {
  justify-content: space-between;
  text-align: center;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 1em;

    .dateWrapper {
      border: 1px solid $greyish-teal;
      border-radius: 5px;
      padding: 5px;
    }

    p {
      @include body-style($dark-grey, 15);
      flex: 1;
      margin-bottom: 0;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;

    .button {
      flex: 1;
    }
  }

  .span {
    cursor: pointer;
  }

  .border {
    border-top: 1px solid #e8e8e8;
    padding: 0.4em 0;
  }

  .flex {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .done {
    background: $greyish-teal;
    border-radius: 4px;
    color: $deep-teal;
    margin-left: 10px;
    padding: 0.4em 0.6em;
    text-align: center;
    width: 100px;
  }

  .colored {
    color: $deep-teal;
  }
}
