@import "styles/mixins/main";

.wrapper {
  background-color: rgba($white, 0.4);
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  width: 100%;

  svg {
    align-self: center;
    height: 150px;
    margin: 50px auto;
    width: 150px;
  }

  p {
    @include font-style($dark-grey);

    padding: 20px;
  }

  button {
    @include button($deep-teal, $flat-white);

    align-self: flex-end;
    margin-right: 20px;
    margin-top: 20px;
    padding: 5px 20px;
  }
}

.description {
  @include font-style($dark-grey, 18);

  align-items: center;
  border-bottom: 2px solid $very-light-pink;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px;
}
