@import "styles/mixins/main";

.dialog-wrapper {
  min-width: 550px;

  @media #{$mobile} {
    min-width: 60vw;
  }

  .contract-line {
    background-color: rgba(76, 180, 156, 0.1);
    margin-bottom: 10px;
    padding: 15px;
  }

  .description {
    margin-bottom: 12px;
  }
}
