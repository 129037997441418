@import "styles/media";
@import "styles/mixins/main";

.wrapper {
  background-color: #ffe6d8;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto;

  img {
    align-self: flex-end;
    bottom: 0;
    margin-top: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    width: 500px;

    @media #{$desktop} {
      width: 600px;
    }

    @media #{$mobile} {
      margin-top: 20px;
      padding: 0 12px;
      position: relative;
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100%;
  padding: 20px;
  width: 565px;

  h1 {
    @include font-style($greyish-teal, 36);

    font-family: "Questrial", sans-serif;
    margin: 54px 0;
  }

  h2 {
    @include font-style($warm-grey, 22);

    font-family: "Questrial", sans-serif;
    line-height: 1.27;
  }

  button {
    @include button($deep-teal, $flat-white);

    align-self: flex-end;
    margin-top: 40px;
    padding: 5px 20px;
    z-index: 100;
  }

  button:hover {
    background-color: darken($deep-teal, 10);
  }
}

.item {
  @include font-style($dark-grey);

  display: flex;
  margin-top: 20px;

  svg {
    align-self: flex-start;
    margin-right: 20px;
    min-width: 15px;
    width: 15px;
  }
}
