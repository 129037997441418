@mixin cards-wrapper() {
  padding: 15px 20px;

  @media #{$mobile} {
    padding-bottom: 50px;

    &::-webkit-scrollbar-thumb {
      background-color: $white;
    }

    .title {
      display: none;
    }

    .inputs {
      padding: 0;
    }
  }
}

@mixin title() {
  color: $warm-grey;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
}

@mixin subtitle() {
  color: $warm-grey;
  display: flex;
  flex: 2;
  font-size: 22px;
  margin-bottom: 12px;

  @media #{$mobile} {
    font-size: 20px;
  }
}

@mixin description() {
  @include font-style($dark-grey);
  line-height: 22px;

  @media #{$mobile} {
    font-size: 14px;
  }
}

@mixin card() {
  @include font-style($font-size: 15, $height: 20px);
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px;

  @media #{$mobile} {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .button {
    @include button($deep-teal, $white);
    padding: 3px 25px;
  }

  .button-outlined {
    @include outlined-button($deep-teal);
    padding: 2px 14px;
  }

  .button, .button-outlined {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: fit-content;
    margin-left: 10px;
  }
}
