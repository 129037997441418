@import url(https://fonts.googleapis.com/css?family=Questrial);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap);
.notifications_white__3KLXD {
  color: #f9f9f9; }

.notifications_black__2Oaw0 {
  color: #000; }

.notifications_deep-teal__1IVA- {
  color: #014660; }

.notifications_greyish-teal__2IeAd {
  color: #5bdbbd; }

.notifications_very-light-grey__37B4R {
  color: #b9b9b9; }

.notifications_warm-grey__9shTS {
  color: #55556f; }

.notifications_simple-grey__1FeAg {
  color: #d4d4d8; }

.notifications_dark-grey__2wq4z {
  color: #222026; }

.notifications_flat-white__1u67V {
  color: #fff; }

.notifications_pastel-red__1UiOT {
  color: #e55e50; }

.notifications_white-bg__3AOox {
  background-color: #f9f9f9; }

.notifications_deep-teal-bg__1mEXl {
  background-color: #014660;
  color: #fff; }

.notifications_greyish-teal-bg__11CLj {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.notifications_very-light-grey-bg__38fW5 {
  background-color: #b9b9b9;
  color: #222026; }

.notifications_warm-grey-bg__26Iib {
  background-color: #55556f;
  color: #f9f9f9; }

.notifications_simple-grey-bg__3nqdX {
  background-color: #d4d4d8;
  color: #222026; }

.notifications_dark-grey-bg__3Mx7V {
  background-color: #222026;
  color: #fff; }

.notifications_flat-white-bg__1oLK- {
  background-color: #fff;
  color: #014660; }

.notifications_pastel-red-bg__2mjzU {
  background-color: #e55e50;
  color: #f9f9f9; }

@-webkit-keyframes notifications_open__yGTu2 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes notifications_open__yGTu2 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes notifications_close__2V7Sd {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes notifications_close__2V7Sd {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

.notifications_notification__2vAzq {
  align-items: center;
  color: #fff;
  display: flex;
  line-height: 1.5;
  min-height: 70px;
  padding: 5px 20px; }

.notifications_wrapper__26IpG {
  -webkit-animation: notifications_open__yGTu2 0.3s ease-out;
          animation: notifications_open__yGTu2 0.3s ease-out;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6; }

.notifications_icon_wrapper__2HKDy svg {
  height: 30px;
  width: 30px; }

.notifications_content_wrapper__3TsLk {
  flex: 1 1;
  padding: 5px 10px; }
  .notifications_content_wrapper__3TsLk .notifications_title__2Z193 {
    color: #55556f;
    margin-bottom: 3px; }
  .notifications_content_wrapper__3TsLk .notifications_text__NKNuE {
    color: #222026; }

.notifications_close_btn__3m4eV {
  align-items: center;
  cursor: pointer;
  display: flex;
  text-decoration: underline; }
  .notifications_close_btn__3m4eV svg {
    fill: #f9f9f9;
    height: 15px;
    margin-right: 5px;
    width: 15px; }

.notifications_closed__2qMGJ {
  -webkit-animation: notifications_close__2V7Sd 0.3s ease-in;
          animation: notifications_close__2V7Sd 0.3s ease-in; }

.notifications_warning__wT6eN {
  background: -webkit-gradient(linear, left top, right top, from(#fcc766), to(#fddf8b));
  background: linear-gradient(to right, #fcc766, #fddf8b);
  color: #000; }
  .notifications_warning__wT6eN .notifications_close_btn__3m4eV svg {
    fill: #000; }

.notifications_success__2N1rF {
  background: -webkit-gradient(linear, left top, right top, from(#56bba1), to(#54d4b2));
  background: linear-gradient(to right, #56bba1, #54d4b2); }

.notifications_error__2rfml {
  background: -webkit-gradient(linear, left top, right top, from(#f98377), to(#e65e4f));
  background: linear-gradient(to right, #f98377, #e65e4f); }

.notifications_alert__l2T9w {
  background-color: #fff;
  cursor: pointer; }

.notifications_alert_icon_wrapper__1SVvW {
  margin: 10px 10px 15px 0; }

.icons_white__2lRju {
  color: #f9f9f9; }

.icons_black__13dcO {
  color: #000; }

.icons_deep-teal__2Iw_8 {
  color: #014660; }

.icons_greyish-teal__XUI48 {
  color: #5bdbbd; }

.icons_very-light-grey__3PQKn {
  color: #b9b9b9; }

.icons_warm-grey__3sC_R {
  color: #55556f; }

.icons_simple-grey__20K3n {
  color: #d4d4d8; }

.icons_dark-grey__2yjMz {
  color: #222026; }

.icons_flat-white__1MaCw {
  color: #fff; }

.icons_pastel-red__2Vu7h {
  color: #e55e50; }

.icons_white-bg__1LS_N {
  background-color: #f9f9f9; }

.icons_deep-teal-bg__1QuW_ {
  background-color: #014660;
  color: #fff; }

.icons_greyish-teal-bg__ClTLf {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.icons_very-light-grey-bg__18rEc {
  background-color: #b9b9b9;
  color: #222026; }

.icons_warm-grey-bg__KshWz {
  background-color: #55556f;
  color: #f9f9f9; }

.icons_simple-grey-bg__1rI5J {
  background-color: #d4d4d8;
  color: #222026; }

.icons_dark-grey-bg__2AXfi {
  background-color: #222026;
  color: #fff; }

.icons_flat-white-bg__3ppcQ {
  background-color: #fff;
  color: #014660; }

.icons_pastel-red-bg__2OVhd {
  background-color: #e55e50;
  color: #f9f9f9; }

.icons_icon__23no5 {
  height: 60px;
  margin-right: 10px;
  min-width: 60px;
  position: relative;
  width: 60px; }
  @media (max-width: 1024px) {
    .icons_icon__23no5 {
      height: 45px;
      min-width: 45px;
      width: 45px; } }
  .icons_icon__23no5 .icons_primary__1kcnr, .icons_icon__23no5 .icons_secondary__1JQ0y {
    border-radius: 50%;
    object-fit: contain;
    object-position: bottom; }
  .icons_icon__23no5 div.icons_primary__1kcnr, .icons_icon__23no5 div.icons_secondary__1JQ0y {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(1, 70, 96, 0.4);
    color: #5bdbbd;
    display: flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    text-transform: uppercase; }
    .icons_icon__23no5 div.icons_primary__1kcnr:not(.icons_primary__1kcnr), .icons_icon__23no5 div.icons_secondary__1JQ0y:not(.icons_primary__1kcnr) {
      font-size: 12px; }
  .icons_icon__23no5 .icons_primary__1kcnr {
    height: 100%;
    width: 100%; }
  .icons_icon__23no5 .icons_secondary__1JQ0y {
    bottom: -5px;
    height: 55%;
    position: absolute;
    right: -5px;
    width: 55%; }

.preloader_white__3EdbA {
  color: #f9f9f9; }

.preloader_black__2lz6Z {
  color: #000; }

.preloader_deep-teal__1oWFR {
  color: #014660; }

.preloader_greyish-teal__3RCBD {
  color: #5bdbbd; }

.preloader_very-light-grey__1zUyO {
  color: #b9b9b9; }

.preloader_warm-grey__1tURe {
  color: #55556f; }

.preloader_simple-grey__1dPoz {
  color: #d4d4d8; }

.preloader_dark-grey__bBmGG {
  color: #222026; }

.preloader_flat-white__18X8D {
  color: #fff; }

.preloader_pastel-red__3CjdW {
  color: #e55e50; }

.preloader_white-bg__3Vhc5 {
  background-color: #f9f9f9; }

.preloader_deep-teal-bg__2klJE {
  background-color: #014660;
  color: #fff; }

.preloader_greyish-teal-bg__12UC- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.preloader_very-light-grey-bg__HybDS {
  background-color: #b9b9b9;
  color: #222026; }

.preloader_warm-grey-bg__32D3x {
  background-color: #55556f;
  color: #f9f9f9; }

.preloader_simple-grey-bg__eeCDR {
  background-color: #d4d4d8;
  color: #222026; }

.preloader_dark-grey-bg__2YKtW {
  background-color: #222026;
  color: #fff; }

.preloader_flat-white-bg__2uoJV {
  background-color: #fff;
  color: #014660; }

.preloader_pastel-red-bg__2tAQu {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.preloader_preloader__2pRvm {
  align-items: center;
  background-color: #014660;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  z-index: 4000000000; }
  .preloader_preloader__2pRvm img {
    width: 150px; }

.preloader_progress_bar__10ZwY {
  background-color: #5bdbbd;
  display: flex;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 100ms;
  transition: width 100ms;
  width: var(--progressBarWidth);
  z-index: 11; }

.preloader_preload_animation__1nb-8 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 56px; }

.preloader_loading_bar__1KU2B {
  -webkit-animation: preloader_loading__2OPfd 1s ease-in-out infinite;
          animation: preloader_loading__2OPfd 1s ease-in-out infinite;
  border-radius: 8px;
  display: inline-block;
  height: 16px;
  width: 8px; }
  .preloader_loading_bar__1KU2B:nth-child(1) {
    -webkit-animation-delay: 0;
            animation-delay: 0;
    background-color: #fff; }
  .preloader_loading_bar__1KU2B:nth-child(2) {
    -webkit-animation-delay: 0.09s;
            animation-delay: 0.09s;
    background-color: #4cb49c; }
  .preloader_loading_bar__1KU2B:nth-child(3) {
    -webkit-animation-delay: 0.18s;
            animation-delay: 0.18s;
    background-color: #e55e50; }
  .preloader_loading_bar__1KU2B:nth-child(4) {
    -webkit-animation-delay: 0.27s;
            animation-delay: 0.27s;
    background-color: #dda700; }

@-webkit-keyframes preloader_loading__2OPfd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes preloader_loading__2OPfd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.preloader_status__3RVdP {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  bottom: 30px;
  color: #fff;
  position: absolute;
  text-align: center;
  width: 100%; }

.collective_white__1CALt {
  color: #f9f9f9; }

.collective_black__15Rau {
  color: #000; }

.collective_deep-teal__2h98B {
  color: #014660; }

.collective_greyish-teal__2YiIf {
  color: #5bdbbd; }

.collective_very-light-grey__WUrQR {
  color: #b9b9b9; }

.collective_warm-grey__32YDh {
  color: #55556f; }

.collective_simple-grey__3OUrB {
  color: #d4d4d8; }

.collective_dark-grey__12wiS {
  color: #222026; }

.collective_flat-white__3DKy4 {
  color: #fff; }

.collective_pastel-red__3ypEE {
  color: #e55e50; }

.collective_white-bg__3Dank {
  background-color: #f9f9f9; }

.collective_deep-teal-bg__2ttaW {
  background-color: #014660;
  color: #fff; }

.collective_greyish-teal-bg__7w6ZD {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.collective_very-light-grey-bg__3UPi1 {
  background-color: #b9b9b9;
  color: #222026; }

.collective_warm-grey-bg__36Kj1 {
  background-color: #55556f;
  color: #f9f9f9; }

.collective_simple-grey-bg__2q0lN {
  background-color: #d4d4d8;
  color: #222026; }

.collective_dark-grey-bg__3suF1 {
  background-color: #222026;
  color: #fff; }

.collective_flat-white-bg__3DuG6 {
  background-color: #fff;
  color: #014660; }

.collective_pastel-red-bg__cDpJ7 {
  background-color: #e55e50;
  color: #f9f9f9; }

.collective_white__1CALt {
  color: #f9f9f9; }

.collective_black__15Rau {
  color: #000; }

.collective_deep-teal__2h98B {
  color: #014660; }

.collective_greyish-teal__2YiIf {
  color: #5bdbbd; }

.collective_very-light-grey__WUrQR {
  color: #b9b9b9; }

.collective_warm-grey__32YDh {
  color: #55556f; }

.collective_simple-grey__3OUrB {
  color: #d4d4d8; }

.collective_dark-grey__12wiS {
  color: #222026; }

.collective_flat-white__3DKy4 {
  color: #fff; }

.collective_pastel-red__3ypEE {
  color: #e55e50; }

.collective_white-bg__3Dank {
  background-color: #f9f9f9; }

.collective_deep-teal-bg__2ttaW {
  background-color: #014660;
  color: #fff; }

.collective_greyish-teal-bg__7w6ZD {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.collective_very-light-grey-bg__3UPi1 {
  background-color: #b9b9b9;
  color: #222026; }

.collective_warm-grey-bg__36Kj1 {
  background-color: #55556f;
  color: #f9f9f9; }

.collective_simple-grey-bg__2q0lN {
  background-color: #d4d4d8;
  color: #222026; }

.collective_dark-grey-bg__3suF1 {
  background-color: #222026;
  color: #fff; }

.collective_flat-white-bg__3DuG6 {
  background-color: #fff;
  color: #014660; }

.collective_pastel-red-bg__cDpJ7 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.collective_wrapper__3-ZFC {
  color: #5bdbbd;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "Questrial", sans-serif;
  font-size: 35px;
  font-weight: 300; }
  .collective_wrapper__3-ZFC:focus, .collective_wrapper__3-ZFC:active {
    outline: none; }
  .collective_wrapper__3-ZFC w-avatar {
    display: none; }
  @media (max-width: 1024px) {
    .collective_wrapper__3-ZFC {
      align-items: center;
      display: flex;
      font-size: 25px; }
      .collective_wrapper__3-ZFC w-avatar {
        display: block;
        margin-right: 10px; } }

.base_white__2X7KI {
  color: #f9f9f9; }

.base_black__2Yu5V {
  color: #000; }

.base_deep-teal__2FjPf {
  color: #014660; }

.base_greyish-teal__2HfVZ {
  color: #5bdbbd; }

.base_very-light-grey__2nE8u {
  color: #b9b9b9; }

.base_warm-grey__3nSOO {
  color: #55556f; }

.base_simple-grey__39uMH {
  color: #d4d4d8; }

.base_dark-grey__2Dihs {
  color: #222026; }

.base_flat-white__2xHF0 {
  color: #fff; }

.base_pastel-red__FdXVN {
  color: #e55e50; }

.base_white-bg__3_rPC {
  background-color: #f9f9f9; }

.base_deep-teal-bg__Dvzs9 {
  background-color: #014660;
  color: #fff; }

.base_greyish-teal-bg__3J-Fd {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.base_very-light-grey-bg__VvQCe {
  background-color: #b9b9b9;
  color: #222026; }

.base_warm-grey-bg__3xh6I {
  background-color: #55556f;
  color: #f9f9f9; }

.base_simple-grey-bg__2eoRz {
  background-color: #d4d4d8;
  color: #222026; }

.base_dark-grey-bg__sZbJM {
  background-color: #222026;
  color: #fff; }

.base_flat-white-bg__vCEWA {
  background-color: #fff;
  color: #014660; }

.base_pastel-red-bg__1KVtS {
  background-color: #e55e50;
  color: #f9f9f9; }

.base_wrapper__20OFW {
  background-color: rgba(249, 249, 249, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100vh;
  max-width: 100%;
  overflow-y: auto;
  padding: 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5; }

.base_container__240ZW {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  margin: auto;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  max-width: 96%;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
  position: relative;
  z-index: 401; }

.base_close_btn__2CgPP {
  border-radius: 100%;
  cursor: pointer;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px; }

.base_back_panel__3mvB6 {
  align-items: center;
  color: #014660;
  display: none;
  height: 5vh;
  padding-left: 10px; }
  .base_back_panel__3mvB6 svg {
    height: 20px;
    margin-right: 10px;
    width: 30px; }

.base_back_btn__3AfMy {
  align-items: center;
  cursor: pointer;
  display: flex; }

@media (max-width: 1024px) {
  .base_wrapper__20OFW {
    border-radius: 0;
    padding: 0; }
  .base_close_btn__2CgPP {
    display: none; }
  .base_back_panel__3mvB6 {
    display: flex; } }

.skeleton_skeleton__3GYgd {
  background-repeat: no-repeat;
  background-size: 200px 100%;
  border-radius: 4px;
  height: 15px;
  width: 100%; }
  .skeleton_skeleton__3GYgd {
    -webkit-animation: 1.2s ease-in-out 0s infinite normal none running skeleton;
            animation: 1.2s ease-in-out 0s infinite normal none running skeleton; }

@-webkit-keyframes skeleton {
  0% {
    background-position: -200px 0; }
  100% {
    background-position: calc(200px + 100%) 0; } }

@keyframes skeleton {
  0% {
    background-position: -200px 0; }
  100% {
    background-position: calc(200px + 100%) 0; } }

.status_white__3oWhV {
  color: #f9f9f9; }

.status_black__1jL3l {
  color: #000; }

.status_deep-teal__2eAh2 {
  color: #014660; }

.status_greyish-teal__3s_xk {
  color: #5bdbbd; }

.status_very-light-grey__VZopJ {
  color: #b9b9b9; }

.status_warm-grey__-4_RL {
  color: #55556f; }

.status_simple-grey__otK0d {
  color: #d4d4d8; }

.status_dark-grey__3oGUJ {
  color: #222026; }

.status_flat-white__1O2UB {
  color: #fff; }

.status_pastel-red__3-xsG {
  color: #e55e50; }

.status_white-bg__1cKtB {
  background-color: #f9f9f9; }

.status_deep-teal-bg__sBlGe {
  background-color: #014660;
  color: #fff; }

.status_greyish-teal-bg__2un8d {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.status_very-light-grey-bg__1YbRy {
  background-color: #b9b9b9;
  color: #222026; }

.status_warm-grey-bg__2f2u5 {
  background-color: #55556f;
  color: #f9f9f9; }

.status_simple-grey-bg__1dPru {
  background-color: #d4d4d8;
  color: #222026; }

.status_dark-grey-bg__2Ex-t {
  background-color: #222026;
  color: #fff; }

.status_flat-white-bg__3_hK9 {
  background-color: #fff;
  color: #014660; }

.status_pastel-red-bg__1s8kG {
  background-color: #e55e50;
  color: #f9f9f9; }

.status_multi_direct__3LLaI {
  align-items: center;
  background-color: #55556f;
  border-radius: 50%;
  color: #d4d4d8;
  display: flex;
  font-size: 13px;
  height: 15px;
  justify-content: center;
  padding-top: 2px;
  width: 15px; }

.status_user_status__1nJLk {
  border-radius: 50%;
  height: 14px;
  position: relative;
  width: 15px; }
  .status_user_status__1nJLk.status_online__2Fstg {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(4%, #5fe8c3), color-stop(99%, #5bdbbd));
    background-image: linear-gradient(to bottom, #5fe8c3 4%, #5bdbbd 99%); }
    .status_user_status__1nJLk.status_online__2Fstg.status_do_not_disturb__3wrM7::after, .status_user_status__1nJLk.status_online__2Fstg.status_muted__3ZhX5::after {
      align-items: center;
      color: #f9f9f9;
      content: "z";
      display: flex;
      font-size: 10px;
      font-weight: bold;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%; }
  .status_user_status__1nJLk.status_offline__3eFJj {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(4%, #d4d4d8), color-stop(99%, #b9b9b9));
    background-image: linear-gradient(to bottom, #d4d4d8 4%, #b9b9b9 99%); }
    .status_user_status__1nJLk.status_offline__3eFJj.status_do_not_disturb__3wrM7, .status_user_status__1nJLk.status_offline__3eFJj.status_muted__3ZhX5 {
      background-image: none;
      border: 2px solid #b9b9b9; }
      .status_user_status__1nJLk.status_offline__3eFJj.status_do_not_disturb__3wrM7::after, .status_user_status__1nJLk.status_offline__3eFJj.status_muted__3ZhX5::after {
        align-items: center;
        color: #b9b9b9;
        content: "z";
        display: flex;
        font-size: 10px;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%; }

.alphabet-scroll_white__3_G4h {
  color: #f9f9f9; }

.alphabet-scroll_black__Vh-6V {
  color: #000; }

.alphabet-scroll_deep-teal__uI77f {
  color: #014660; }

.alphabet-scroll_greyish-teal__2mSpj {
  color: #5bdbbd; }

.alphabet-scroll_very-light-grey__10CEb {
  color: #b9b9b9; }

.alphabet-scroll_warm-grey__rT3EP {
  color: #55556f; }

.alphabet-scroll_simple-grey__1gd4Z {
  color: #d4d4d8; }

.alphabet-scroll_dark-grey__317Dj {
  color: #222026; }

.alphabet-scroll_flat-white__1wPAh {
  color: #fff; }

.alphabet-scroll_pastel-red__1Q2p9 {
  color: #e55e50; }

.alphabet-scroll_white-bg__3fSJz {
  background-color: #f9f9f9; }

.alphabet-scroll_deep-teal-bg__1rEqV {
  background-color: #014660;
  color: #fff; }

.alphabet-scroll_greyish-teal-bg__55os- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.alphabet-scroll_very-light-grey-bg__23eY3 {
  background-color: #b9b9b9;
  color: #222026; }

.alphabet-scroll_warm-grey-bg__3oPMD {
  background-color: #55556f;
  color: #f9f9f9; }

.alphabet-scroll_simple-grey-bg__1yTXi {
  background-color: #d4d4d8;
  color: #222026; }

.alphabet-scroll_dark-grey-bg__2AWqC {
  background-color: #222026;
  color: #fff; }

.alphabet-scroll_flat-white-bg__1L717 {
  background-color: #fff;
  color: #014660; }

.alphabet-scroll_pastel-red-bg__1eS6F {
  background-color: #e55e50;
  color: #f9f9f9; }

.alphabet-scroll_white__3_G4h {
  color: #f9f9f9; }

.alphabet-scroll_black__Vh-6V {
  color: #000; }

.alphabet-scroll_deep-teal__uI77f {
  color: #014660; }

.alphabet-scroll_greyish-teal__2mSpj {
  color: #5bdbbd; }

.alphabet-scroll_very-light-grey__10CEb {
  color: #b9b9b9; }

.alphabet-scroll_warm-grey__rT3EP {
  color: #55556f; }

.alphabet-scroll_simple-grey__1gd4Z {
  color: #d4d4d8; }

.alphabet-scroll_dark-grey__317Dj {
  color: #222026; }

.alphabet-scroll_flat-white__1wPAh {
  color: #fff; }

.alphabet-scroll_pastel-red__1Q2p9 {
  color: #e55e50; }

.alphabet-scroll_white-bg__3fSJz {
  background-color: #f9f9f9; }

.alphabet-scroll_deep-teal-bg__1rEqV {
  background-color: #014660;
  color: #fff; }

.alphabet-scroll_greyish-teal-bg__55os- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.alphabet-scroll_very-light-grey-bg__23eY3 {
  background-color: #b9b9b9;
  color: #222026; }

.alphabet-scroll_warm-grey-bg__3oPMD {
  background-color: #55556f;
  color: #f9f9f9; }

.alphabet-scroll_simple-grey-bg__1yTXi {
  background-color: #d4d4d8;
  color: #222026; }

.alphabet-scroll_dark-grey-bg__2AWqC {
  background-color: #222026;
  color: #fff; }

.alphabet-scroll_flat-white-bg__1L717 {
  background-color: #fff;
  color: #014660; }

.alphabet-scroll_pastel-red-bg__1eS6F {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.alphabet-scroll_wrapper__6aNle {
  display: flex;
  max-height: 100%;
  width: 100%; }

.alphabet-scroll_current__1l6Nr {
  color: #5bdbbd;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  height: auto;
  margin: 10px 0 0;
  max-height: 120px;
  min-height: 80px;
  padding: 15px;
  width: 100%; }
  .alphabet-scroll_current__1l6Nr .alphabet-scroll_details__3nUSj {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%; }
  .alphabet-scroll_current__1l6Nr .alphabet-scroll_name__26Ozm {
    color: #014660; }
  .alphabet-scroll_current__1l6Nr .alphabet-scroll_sub_info__bMoEV {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 5px; }

.alphabet-scroll_items_wrapper__24G1S {
  display: flex;
  flex: 1 1;
  height: auto;
  margin-right: 10px;
  overflow-y: hidden;
  position: relative;
  width: 100%; }

.alphabet-scroll_items__3IqVP {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 15px 10px;
  position: relative;
  scrollbar-width: thin;
  width: 100%; }
  .alphabet-scroll_items__3IqVP {
    overflow: -moz-scrollbars-none; }
  .alphabet-scroll_items__3IqVP {
    -ms-overflow-style: none; }
  .alphabet-scroll_items__3IqVP::-webkit-scrollbar {
    max-height: 150px;
    min-height: 6px;
    width: 1px; }

.alphabet-scroll_avatar__3Hz8G {
  margin-right: 10px; }

.alphabet-scroll_letters__1QA6A {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin; }
  .alphabet-scroll_letters__1QA6A {
    overflow: -moz-scrollbars-none; }
  .alphabet-scroll_letters__1QA6A {
    -ms-overflow-style: none; }
  .alphabet-scroll_letters__1QA6A::-webkit-scrollbar {
    max-height: 150px;
    min-height: 6px;
    width: 2px; }

.alphabet-scroll_letter__O5p3h {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 5px;
  min-height: 20px;
  width: 20px; }
  .alphabet-scroll_letter__O5p3h:hover:not(.alphabet-scroll_active__27z7z):not(:disabled) {
    background-color: #fff;
    box-shadow: 0 0 1px #55556f;
    color: #000; }
  .alphabet-scroll_letter__O5p3h:disabled {
    color: #55556f; }

.alphabet-scroll_active__27z7z {
  background-color: #5bdbbd;
  color: #fff; }

.not_joined_white__31FHA {
  color: #f9f9f9; }

.not_joined_black__2tZia {
  color: #000; }

.not_joined_deep-teal__1DRwz {
  color: #014660; }

.not_joined_greyish-teal__2FPFM {
  color: #5bdbbd; }

.not_joined_very-light-grey__271yF {
  color: #b9b9b9; }

.not_joined_warm-grey__1Bho- {
  color: #55556f; }

.not_joined_simple-grey__3Yawm {
  color: #d4d4d8; }

.not_joined_dark-grey__1copq {
  color: #222026; }

.not_joined_flat-white__FXDzZ {
  color: #fff; }

.not_joined_pastel-red__1tU2G {
  color: #e55e50; }

.not_joined_white-bg__1NJvv {
  background-color: #f9f9f9; }

.not_joined_deep-teal-bg__3s2_C {
  background-color: #014660;
  color: #fff; }

.not_joined_greyish-teal-bg__3eMT6 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.not_joined_very-light-grey-bg__1gfD_ {
  background-color: #b9b9b9;
  color: #222026; }

.not_joined_warm-grey-bg__T6M7s {
  background-color: #55556f;
  color: #f9f9f9; }

.not_joined_simple-grey-bg__1QV-9 {
  background-color: #d4d4d8;
  color: #222026; }

.not_joined_dark-grey-bg__23R8R {
  background-color: #222026;
  color: #fff; }

.not_joined_flat-white-bg__1ZYk- {
  background-color: #fff;
  color: #014660; }

.not_joined_pastel-red-bg__P-uJz {
  background-color: #e55e50;
  color: #f9f9f9; }

.not_joined_wrapper__1EjHg {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  text-align: center; }
  .not_joined_wrapper__1EjHg > *:not(:last-child) {
    margin-bottom: 20px; }
  .not_joined_wrapper__1EjHg h1 {
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 20px; }
  .not_joined_wrapper__1EjHg span {
    color: #222026;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    max-width: 300px; }
  .not_joined_wrapper__1EjHg svg {
    height: 90px;
    width: 90px; }

.styles_white__3KTca {
  color: #f9f9f9; }

.styles_black__cHjsG {
  color: #000; }

.styles_deep-teal__9gDgT {
  color: #014660; }

.styles_greyish-teal__3jx-6 {
  color: #5bdbbd; }

.styles_very-light-grey__2IMfq {
  color: #b9b9b9; }

.styles_warm-grey__3fUk- {
  color: #55556f; }

.styles_simple-grey__2TxKc {
  color: #d4d4d8; }

.styles_dark-grey__K9rZ_ {
  color: #222026; }

.styles_flat-white__2T1tK {
  color: #fff; }

.styles_pastel-red__3pieK {
  color: #e55e50; }

.styles_white-bg__18BeJ {
  background-color: #f9f9f9; }

.styles_deep-teal-bg__1SbgV {
  background-color: #014660;
  color: #fff; }

.styles_greyish-teal-bg__FM4i_ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.styles_very-light-grey-bg__2waru {
  background-color: #b9b9b9;
  color: #222026; }

.styles_warm-grey-bg__3yoFZ {
  background-color: #55556f;
  color: #f9f9f9; }

.styles_simple-grey-bg__3kr6m {
  background-color: #d4d4d8;
  color: #222026; }

.styles_dark-grey-bg__1D7hz {
  background-color: #222026;
  color: #fff; }

.styles_flat-white-bg__2Bn8L {
  background-color: #fff;
  color: #014660; }

.styles_pastel-red-bg__1f0bl {
  background-color: #e55e50;
  color: #f9f9f9; }

.styles_white__3KTca {
  color: #f9f9f9; }

.styles_black__cHjsG {
  color: #000; }

.styles_deep-teal__9gDgT {
  color: #014660; }

.styles_greyish-teal__3jx-6 {
  color: #5bdbbd; }

.styles_very-light-grey__2IMfq {
  color: #b9b9b9; }

.styles_warm-grey__3fUk- {
  color: #55556f; }

.styles_simple-grey__2TxKc {
  color: #d4d4d8; }

.styles_dark-grey__K9rZ_ {
  color: #222026; }

.styles_flat-white__2T1tK {
  color: #fff; }

.styles_pastel-red__3pieK {
  color: #e55e50; }

.styles_white-bg__18BeJ {
  background-color: #f9f9f9; }

.styles_deep-teal-bg__1SbgV {
  background-color: #014660;
  color: #fff; }

.styles_greyish-teal-bg__FM4i_ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.styles_very-light-grey-bg__2waru {
  background-color: #b9b9b9;
  color: #222026; }

.styles_warm-grey-bg__3yoFZ {
  background-color: #55556f;
  color: #f9f9f9; }

.styles_simple-grey-bg__3kr6m {
  background-color: #d4d4d8;
  color: #222026; }

.styles_dark-grey-bg__1D7hz {
  background-color: #222026;
  color: #fff; }

.styles_flat-white-bg__2Bn8L {
  background-color: #fff;
  color: #014660; }

.styles_pastel-red-bg__1f0bl {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.styles_outline__pt5wu {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #014660;
  box-shadow: 0 0 3px rgba(1, 70, 96, 0.5);
  font-weight: 600; }
  .styles_outline__pt5wu:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .styles_outline__pt5wu:disabled {
    opacity: 0.5; }
  .styles_outline__pt5wu:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .styles_outline__pt5wu:disabled {
    opacity: 0.5; }

.styles_primary__1S6_q {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center; }
  .styles_primary__1S6_q:hover:not(:disabled) {
    background-color: #00212e; }
  .styles_primary__1S6_q:disabled {
    opacity: 0.5; }

.styles_base__Rrg9_ {
  font-size: 14px;
  padding: 3px 15px; }

.tag-input_white__139xp {
  color: #f9f9f9; }

.tag-input_black__3gk94 {
  color: #000; }

.tag-input_deep-teal__1Ds2J {
  color: #014660; }

.tag-input_greyish-teal__g5QF3 {
  color: #5bdbbd; }

.tag-input_very-light-grey__Yz350 {
  color: #b9b9b9; }

.tag-input_warm-grey__32fiK {
  color: #55556f; }

.tag-input_simple-grey__3NUcJ {
  color: #d4d4d8; }

.tag-input_dark-grey__1Qzkt {
  color: #222026; }

.tag-input_flat-white__6teQS {
  color: #fff; }

.tag-input_pastel-red__10TUT {
  color: #e55e50; }

.tag-input_white-bg__2JF2e {
  background-color: #f9f9f9; }

.tag-input_deep-teal-bg__2AKTU {
  background-color: #014660;
  color: #fff; }

.tag-input_greyish-teal-bg__sEqZN {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.tag-input_very-light-grey-bg__1DvVA {
  background-color: #b9b9b9;
  color: #222026; }

.tag-input_warm-grey-bg__3CQse {
  background-color: #55556f;
  color: #f9f9f9; }

.tag-input_simple-grey-bg__3joUa {
  background-color: #d4d4d8;
  color: #222026; }

.tag-input_dark-grey-bg__16A3L {
  background-color: #222026;
  color: #fff; }

.tag-input_flat-white-bg__1-9_l {
  background-color: #fff;
  color: #014660; }

.tag-input_pastel-red-bg__3iwSb {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.tag-input_wrapper__CCcl0 {
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 0 1px #014660;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  height: 100%;
  padding: 10px 20px 0;
  width: 100%; }
  @media (max-width: 1024px) {
    .tag-input_wrapper__CCcl0 {
      border-radius: 20px;
      padding: 10px 10px 0; } }
  .tag-input_wrapper__CCcl0 input {
    border: 0;
    flex: 1 1;
    margin-bottom: 10px; }
  .tag-input_wrapper__CCcl0 input:disabled {
    display: none; }
  .tag-input_wrapper__CCcl0:focus-within {
    box-shadow: 0 0 0 2px #5bdbbd; }

.tag-input_tag__2WnaT {
  align-items: center;
  background-color: rgba(212, 212, 216, 0.2);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 5px; }
  .tag-input_tag__2WnaT span {
    margin: 0 10px; }
  .tag-input_tag__2WnaT button {
    background-color: transparent;
    border: 0; }
  .tag-input_tag__2WnaT svg {
    height: 10px;
    width: 10px; }
  @media (max-width: 1024px) {
    .tag-input_tag__2WnaT {
      font-size: 12px; } }

.styles_base__qe5w6 {
  cursor: pointer;
  height: 16px;
  width: 16px; }
  .styles_base__qe5w6 svg {
    height: 100%;
    width: 100%; }

.styles_disabled__2NGg5 {
  cursor: none;
  opacity: 0.7;
  pointer-events: none; }

.loader_loader__XWDf7 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 28px; }
  .loader_loader__XWDf7 > div {
    -webkit-animation: loader_loading__1yh1K 1s ease-in-out infinite;
            animation: loader_loading__1yh1K 1s ease-in-out infinite;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    width: 4px; }
    .loader_loader__XWDf7 > div:nth-child(1) {
      -webkit-animation-delay: 0;
              animation-delay: 0;
      background-color: #014660; }
    .loader_loader__XWDf7 > div:nth-child(2) {
      -webkit-animation-delay: 0.09s;
              animation-delay: 0.09s;
      background-color: #4cb49c; }
    .loader_loader__XWDf7 > div:nth-child(3) {
      -webkit-animation-delay: 0.18s;
              animation-delay: 0.18s;
      background-color: #e55e50; }
    .loader_loader__XWDf7 > div:nth-child(4) {
      -webkit-animation-delay: 0.27s;
              animation-delay: 0.27s;
      background-color: #dda700; }

@-webkit-keyframes loader_loading__1yh1K {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes loader_loading__1yh1K {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.terms-footer_white__2PRTK {
  color: #f9f9f9; }

.terms-footer_black__xszcS {
  color: #000; }

.terms-footer_deep-teal__3H2Uw {
  color: #014660; }

.terms-footer_greyish-teal__kF2m_ {
  color: #5bdbbd; }

.terms-footer_very-light-grey__1Q50M {
  color: #b9b9b9; }

.terms-footer_warm-grey__IzBhT {
  color: #55556f; }

.terms-footer_simple-grey__rsucZ {
  color: #d4d4d8; }

.terms-footer_dark-grey__2lWe5 {
  color: #222026; }

.terms-footer_flat-white__yLVoU {
  color: #fff; }

.terms-footer_pastel-red__3O4Nd {
  color: #e55e50; }

.terms-footer_white-bg__1ND0k {
  background-color: #f9f9f9; }

.terms-footer_deep-teal-bg__Y8FPt {
  background-color: #014660;
  color: #fff; }

.terms-footer_greyish-teal-bg__3tVa_ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.terms-footer_very-light-grey-bg__1TRcz {
  background-color: #b9b9b9;
  color: #222026; }

.terms-footer_warm-grey-bg__1sR9y {
  background-color: #55556f;
  color: #f9f9f9; }

.terms-footer_simple-grey-bg__6I1Qd {
  background-color: #d4d4d8;
  color: #222026; }

.terms-footer_dark-grey-bg__3U5y8 {
  background-color: #222026;
  color: #fff; }

.terms-footer_flat-white-bg__3othy {
  background-color: #fff;
  color: #014660; }

.terms-footer_pastel-red-bg__3F6ZG {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.terms-footer_wrapper__1Lej3 {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 1.5;
  padding: 20px; }
  .terms-footer_wrapper__1Lej3 a:active,
  .terms-footer_wrapper__1Lej3 a:focus {
    outline: none; }

.password-validation_white__agIlD {
  color: #f9f9f9; }

.password-validation_black__1T7d7 {
  color: #000; }

.password-validation_deep-teal__2hEAg {
  color: #014660; }

.password-validation_greyish-teal__1M2UZ {
  color: #5bdbbd; }

.password-validation_very-light-grey__wUyYL {
  color: #b9b9b9; }

.password-validation_warm-grey__DZnrk {
  color: #55556f; }

.password-validation_simple-grey__3m9Bs {
  color: #d4d4d8; }

.password-validation_dark-grey__3qH5P {
  color: #222026; }

.password-validation_flat-white__3f3HU {
  color: #fff; }

.password-validation_pastel-red__2jmKI {
  color: #e55e50; }

.password-validation_white-bg__1si5k {
  background-color: #f9f9f9; }

.password-validation_deep-teal-bg__1e4zh {
  background-color: #014660;
  color: #fff; }

.password-validation_greyish-teal-bg__3cHoI {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.password-validation_very-light-grey-bg__3movH {
  background-color: #b9b9b9;
  color: #222026; }

.password-validation_warm-grey-bg__-5-e3 {
  background-color: #55556f;
  color: #f9f9f9; }

.password-validation_simple-grey-bg__3Ve3T {
  background-color: #d4d4d8;
  color: #222026; }

.password-validation_dark-grey-bg__1hRd7 {
  background-color: #222026;
  color: #fff; }

.password-validation_flat-white-bg__2ZkLY {
  background-color: #fff;
  color: #014660; }

.password-validation_pastel-red-bg__q_-ms {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.password-validation_validation__1ro7P {
  display: flex;
  padding: 20px; }
  .password-validation_validation__1ro7P .password-validation_section__3dbbp {
    flex: 1 1; }
  .password-validation_validation__1ro7P .password-validation_val_field__2rKr_ {
    align-items: center;
    display: flex;
    line-height: 2; }
    .password-validation_validation__1ro7P .password-validation_val_field__2rKr_ svg {
      height: 15px;
      margin-right: 5px;
      min-width: 15px; }
  @media (max-width: 450px) {
    .password-validation_validation__1ro7P {
      display: block; } }

.validated-input_white__3RtL1 {
  color: #f9f9f9; }

.validated-input_black__1fXqW {
  color: #000; }

.validated-input_deep-teal__29Vj0 {
  color: #014660; }

.validated-input_greyish-teal__1blS9 {
  color: #5bdbbd; }

.validated-input_very-light-grey__MXpV1 {
  color: #b9b9b9; }

.validated-input_warm-grey__25gdZ {
  color: #55556f; }

.validated-input_simple-grey__-H07J {
  color: #d4d4d8; }

.validated-input_dark-grey__2sbTw {
  color: #222026; }

.validated-input_flat-white__yFDWY {
  color: #fff; }

.validated-input_pastel-red__2ygo3 {
  color: #e55e50; }

.validated-input_white-bg__1GxxH {
  background-color: #f9f9f9; }

.validated-input_deep-teal-bg__3NSoj {
  background-color: #014660;
  color: #fff; }

.validated-input_greyish-teal-bg__16k9k {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.validated-input_very-light-grey-bg__3zevE {
  background-color: #b9b9b9;
  color: #222026; }

.validated-input_warm-grey-bg__t_hPA {
  background-color: #55556f;
  color: #f9f9f9; }

.validated-input_simple-grey-bg__3XHZL {
  background-color: #d4d4d8;
  color: #222026; }

.validated-input_dark-grey-bg__3ehSI {
  background-color: #222026;
  color: #fff; }

.validated-input_flat-white-bg__1WDnB {
  background-color: #fff;
  color: #014660; }

.validated-input_pastel-red-bg__3Q_vS {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.validated-input_show__jb3oj,
.validated-input_clear__35Xlo {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  align-items: center;
  display: flex;
  margin-left: -45px;
  margin-right: 25px;
  position: relative; }

.validated-input_input__2EhIC {
  display: flex;
  flex-direction: row-reverse;
  width: 100%; }

.validation-wrapper_white__csIVX {
  color: #f9f9f9; }

.validation-wrapper_black__3GPPL {
  color: #000; }

.validation-wrapper_deep-teal__3E1OM {
  color: #014660; }

.validation-wrapper_greyish-teal__2B8DT {
  color: #5bdbbd; }

.validation-wrapper_very-light-grey__13JwL {
  color: #b9b9b9; }

.validation-wrapper_warm-grey__FOGgJ {
  color: #55556f; }

.validation-wrapper_simple-grey__Mhxvl {
  color: #d4d4d8; }

.validation-wrapper_dark-grey__Ll3po {
  color: #222026; }

.validation-wrapper_flat-white__HceTR {
  color: #fff; }

.validation-wrapper_pastel-red__h32R8 {
  color: #e55e50; }

.validation-wrapper_white-bg__2bf9K {
  background-color: #f9f9f9; }

.validation-wrapper_deep-teal-bg__2j4pm {
  background-color: #014660;
  color: #fff; }

.validation-wrapper_greyish-teal-bg__2uGmD {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.validation-wrapper_very-light-grey-bg__19w7U {
  background-color: #b9b9b9;
  color: #222026; }

.validation-wrapper_warm-grey-bg__2C6EM {
  background-color: #55556f;
  color: #f9f9f9; }

.validation-wrapper_simple-grey-bg__KAFZZ {
  background-color: #d4d4d8;
  color: #222026; }

.validation-wrapper_dark-grey-bg__3mGRx {
  background-color: #222026;
  color: #fff; }

.validation-wrapper_flat-white-bg__2OuMG {
  background-color: #fff;
  color: #014660; }

.validation-wrapper_pastel-red-bg__386li {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.validation-wrapper_field__1mjyV {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 20px; }
  .validation-wrapper_field__1mjyV label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .validation-wrapper_field__1mjyV input, .validation-wrapper_field__1mjyV textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .validation-wrapper_field__1mjyV input {
    height: 35px; }
  .validation-wrapper_field__1mjyV textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .validation-wrapper_field__1mjyV.validation-wrapper_valid__cpZNV input, .validation-wrapper_field__1mjyV.validation-wrapper_valid__cpZNV textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .validation-wrapper_field__1mjyV.validation-wrapper_invalid__1pQaE input, .validation-wrapper_field__1mjyV.validation-wrapper_invalid__1pQaE textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  @supports (-webkit-overflow-scrolling: touch) {
    .validation-wrapper_field__1mjyV {
      max-height: 300px;
      min-height: 100px; } }
  .validation-wrapper_field__1mjyV input, .validation-wrapper_field__1mjyV textarea {
    display: flex;
    flex: 1 1;
    margin-right: 10px; }
  .validation-wrapper_field__1mjyV .validation-wrapper_box__1tIfG {
    align-items: center;
    display: flex; }
  .validation-wrapper_field__1mjyV span {
    color: #222026;
    margin-bottom: 10px; }
  .validation-wrapper_field__1mjyV h3 {
    font-size: 18px; }
  .validation-wrapper_field__1mjyV svg {
    height: 20px;
    width: 20px; }
  .validation-wrapper_field__1mjyV.validation-wrapper_valid__cpZNV {
    background-color: rgba(91, 219, 189, 0.2); }
    .validation-wrapper_field__1mjyV.validation-wrapper_valid__cpZNV input:focus {
      box-shadow: 0 0 4px #5bdbbd; }
  .validation-wrapper_field__1mjyV.validation-wrapper_invalid__1pQaE {
    background-color: rgba(229, 94, 80, 0.2); }
    .validation-wrapper_field__1mjyV.validation-wrapper_invalid__1pQaE input:focus {
      box-shadow: 0 0 4px #e55e50; }
  .validation-wrapper_field__1mjyV.validation-wrapper_disabled__1Jp_i {
    background-color: rgba(85, 85, 111, 0.3);
    opacity: 0.7; }
    .validation-wrapper_field__1mjyV.validation-wrapper_disabled__1Jp_i svg {
      visibility: hidden; }
  .validation-wrapper_field__1mjyV .validation-wrapper_error__18zn- {
    color: #e55e50;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px; }
  .validation-wrapper_field__1mjyV input:focus {
    box-shadow: 0 0 4px #5bdbbd; }
  .validation-wrapper_field__1mjyV label {
    color: #222026;
    font-size: 14px; }
  .validation-wrapper_field__1mjyV input {
    font-size: 16px;
    height: 28px;
    line-height: 16px; }
  .validation-wrapper_field__1mjyV svg {
    height: 18px; }
  .validation-wrapper_field__1mjyV .validation-wrapper_hidden_icon__3RfPM > svg {
    visibility: hidden; }
  .validation-wrapper_field__1mjyV .validation-wrapper_box__1tIfG {
    position: relative; }
  .validation-wrapper_field__1mjyV [class*="clear"] + input {
    padding-right: 40px; }
  .validation-wrapper_field__1mjyV [class*="show"] + input {
    padding-right: 40px; }

.validation-wrapper_fill_to_end__3gL8U {
  height: 100%; }

.validated-textarea_textarea__YP966 {
  font-size: 16px;
  resize: none; }

.validated-textarea_fill_to_end__1Sbsu {
  height: 100%; }

.validated-select_white__9OqSL {
  color: #f9f9f9; }

.validated-select_black__o6X_r {
  color: #000; }

.validated-select_deep-teal__3Ogl3 {
  color: #014660; }

.validated-select_greyish-teal__1BHrD {
  color: #5bdbbd; }

.validated-select_very-light-grey__2okLh {
  color: #b9b9b9; }

.validated-select_warm-grey__1i9ZR {
  color: #55556f; }

.validated-select_simple-grey__RplEX {
  color: #d4d4d8; }

.validated-select_dark-grey__24R3h {
  color: #222026; }

.validated-select_flat-white__S-EXG {
  color: #fff; }

.validated-select_pastel-red__3_nHr {
  color: #e55e50; }

.validated-select_white-bg__ePdxs {
  background-color: #f9f9f9; }

.validated-select_deep-teal-bg__1aJsa {
  background-color: #014660;
  color: #fff; }

.validated-select_greyish-teal-bg__2TSWK {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.validated-select_very-light-grey-bg__S7XTN {
  background-color: #b9b9b9;
  color: #222026; }

.validated-select_warm-grey-bg__b7QeH {
  background-color: #55556f;
  color: #f9f9f9; }

.validated-select_simple-grey-bg__2wkwd {
  background-color: #d4d4d8;
  color: #222026; }

.validated-select_dark-grey-bg__3lw0U {
  background-color: #222026;
  color: #fff; }

.validated-select_flat-white-bg__E6vwl {
  background-color: #fff;
  color: #014660; }

.validated-select_pastel-red-bg__3sUVi {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.validated-select_select-wrapper___mpA- {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 2px #4d4d4d;
  margin-right: 10px;
  position: relative;
  width: 100%; }
  .validated-select_select-wrapper___mpA-:focus-within {
    box-shadow: 0 0 4px #5bdbbd; }

.validated-select_select__afRxq {
  display: flex;
  padding: 6px 8px 6px 13px;
  width: 100%; }
  .validated-select_select__afRxq button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    text-align: left;
    width: 100%; }
  .validated-select_select__afRxq button > svg {
    fill: #000;
    height: 12px;
    margin-left: auto; }

.validated-select_opened-select-wrapper__2cXoR {
  box-shadow: none !important;
  border-bottom: 0;
  border-left: 1px solid #5bdbbd;
  border-right: 1px solid #5bdbbd;
  border-top: 1px solid #5bdbbd;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .validated-select_opened-select-wrapper__2cXoR button > svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.validated-select_placeholder__33QoA {
  color: rgba(85, 85, 111, 0.99); }

.validated-select_list__3vhAK {
  background-color: #fff;
  box-sizing: content-box;
  color: #222026;
  display: none;
  left: -1px;
  margin-bottom: 20px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #5bdbbd;
  border-left: 1px solid #5bdbbd;
  border-right: 1px solid #5bdbbd;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; }

.validated-select_opened__3h7vh {
  display: block; }

.validated-select_list-content__l9yFh {
  font-size: 14px; }
  .validated-select_list-content__l9yFh > div {
    display: flex; }
  .validated-select_list-content__l9yFh input {
    display: flex;
    padding-left: 34px;
    width: 100%; }
  .validated-select_list-content__l9yFh svg {
    display: flex;
    height: 20px;
    margin: auto -30px auto 20px;
    z-index: 1; }

.validated-select_options__3DkQI {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0;
  max-height: 205px;
  overflow-y: auto; }
  .validated-select_options__3DkQI button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    color: #014660;
    padding: 10px;
    text-align: left; }
  .validated-select_options__3DkQI .validated-select_focused__MGTJc {
    background-color: #f2f2f2; }
  .validated-select_options__3DkQI button:hover {
    background-color: #f2f2f2;
    font-weight: bold; }
  .validated-select_options__3DkQI .validated-select_selected__1ZEvz,
  .validated-select_options__3DkQI .validated-select_selected__1ZEvz:hover {
    background-color: rgba(91, 219, 189, 0.5);
    font-weight: bold; }

.validated-select_options-placeholder__2ryoi {
  color: #222026;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 20px 10px; }

.validated-select_search-label__2yF4f {
  display: flex;
  margin: 0 !important; }

.validated-select_hidden__3RAxF {
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1; }

.form-submit-button_white__3yD8X {
  color: #f9f9f9; }

.form-submit-button_black__2IIHZ {
  color: #000; }

.form-submit-button_deep-teal__3MLOX {
  color: #014660; }

.form-submit-button_greyish-teal__hZEwk {
  color: #5bdbbd; }

.form-submit-button_very-light-grey__7_GSI {
  color: #b9b9b9; }

.form-submit-button_warm-grey__2Iee8 {
  color: #55556f; }

.form-submit-button_simple-grey__2vI_r {
  color: #d4d4d8; }

.form-submit-button_dark-grey__3nFsA {
  color: #222026; }

.form-submit-button_flat-white__1_2PD {
  color: #fff; }

.form-submit-button_pastel-red__3ULtZ {
  color: #e55e50; }

.form-submit-button_white-bg__qcBw1 {
  background-color: #f9f9f9; }

.form-submit-button_deep-teal-bg__3aNSu {
  background-color: #014660;
  color: #fff; }

.form-submit-button_greyish-teal-bg__3wIwE {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.form-submit-button_very-light-grey-bg__2PsmV {
  background-color: #b9b9b9;
  color: #222026; }

.form-submit-button_warm-grey-bg__2ikj4 {
  background-color: #55556f;
  color: #f9f9f9; }

.form-submit-button_simple-grey-bg__1fv-W {
  background-color: #d4d4d8;
  color: #222026; }

.form-submit-button_dark-grey-bg__3NCjw {
  background-color: #222026;
  color: #fff; }

.form-submit-button_flat-white-bg__MPjTc {
  background-color: #fff;
  color: #014660; }

.form-submit-button_pastel-red-bg__3LmOG {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.form-submit-button_submit__-vON4 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  align-self: flex-end;
  padding: 5px 30px; }
  .form-submit-button_submit__-vON4:hover:not(:disabled) {
    background-color: #00212e; }
  .form-submit-button_submit__-vON4:disabled {
    opacity: 0.5; }

.validated-uploader_white__Z2I3c {
  color: #f9f9f9; }

.validated-uploader_black__1nqTL {
  color: #000; }

.validated-uploader_deep-teal__ba7gq {
  color: #014660; }

.validated-uploader_greyish-teal__F-JiA {
  color: #5bdbbd; }

.validated-uploader_very-light-grey__1khcT {
  color: #b9b9b9; }

.validated-uploader_warm-grey__2RKoo {
  color: #55556f; }

.validated-uploader_simple-grey__1jIX1 {
  color: #d4d4d8; }

.validated-uploader_dark-grey__3DNxz {
  color: #222026; }

.validated-uploader_flat-white__HmlIo {
  color: #fff; }

.validated-uploader_pastel-red__3Jm2P {
  color: #e55e50; }

.validated-uploader_white-bg__1LnVv {
  background-color: #f9f9f9; }

.validated-uploader_deep-teal-bg__1ZbD- {
  background-color: #014660;
  color: #fff; }

.validated-uploader_greyish-teal-bg__2f1qo {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.validated-uploader_very-light-grey-bg__badIR {
  background-color: #b9b9b9;
  color: #222026; }

.validated-uploader_warm-grey-bg__3MVvH {
  background-color: #55556f;
  color: #f9f9f9; }

.validated-uploader_simple-grey-bg__2R9IQ {
  background-color: #d4d4d8;
  color: #222026; }

.validated-uploader_dark-grey-bg__wkYvO {
  background-color: #222026;
  color: #fff; }

.validated-uploader_flat-white-bg__3VPJU {
  background-color: #fff;
  color: #014660; }

.validated-uploader_pastel-red-bg__2k-wg {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.validated-uploader_upload__2Tg0K {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 20px;
  flex-direction: row; }
  .validated-uploader_upload__2Tg0K label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .validated-uploader_upload__2Tg0K input, .validated-uploader_upload__2Tg0K textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .validated-uploader_upload__2Tg0K input {
    height: 35px; }
  .validated-uploader_upload__2Tg0K textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .validated-uploader_upload__2Tg0K.validated-uploader_valid__2B5_A input, .validated-uploader_upload__2Tg0K.validated-uploader_valid__2B5_A textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .validated-uploader_upload__2Tg0K.validated-uploader_invalid__OvZAG input, .validated-uploader_upload__2Tg0K.validated-uploader_invalid__OvZAG textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  @supports (-webkit-overflow-scrolling: touch) {
    .validated-uploader_upload__2Tg0K {
      max-height: 300px;
      min-height: 100px; } }
  .validated-uploader_upload__2Tg0K input, .validated-uploader_upload__2Tg0K textarea {
    display: flex;
    flex: 1 1;
    margin-right: 10px; }
  .validated-uploader_upload__2Tg0K .validated-uploader_box__39rnh {
    align-items: center;
    display: flex; }
  .validated-uploader_upload__2Tg0K span {
    color: #222026;
    margin-bottom: 10px; }
  .validated-uploader_upload__2Tg0K h3 {
    font-size: 18px; }
  .validated-uploader_upload__2Tg0K svg {
    height: 20px;
    width: 20px; }
  .validated-uploader_upload__2Tg0K.validated-uploader_valid__2B5_A {
    background-color: rgba(91, 219, 189, 0.2); }
    .validated-uploader_upload__2Tg0K.validated-uploader_valid__2B5_A input:focus {
      box-shadow: 0 0 4px #5bdbbd; }
  .validated-uploader_upload__2Tg0K.validated-uploader_invalid__OvZAG {
    background-color: rgba(229, 94, 80, 0.2); }
    .validated-uploader_upload__2Tg0K.validated-uploader_invalid__OvZAG input:focus {
      box-shadow: 0 0 4px #e55e50; }
  .validated-uploader_upload__2Tg0K.validated-uploader_disabled__3WV81 {
    background-color: rgba(85, 85, 111, 0.3);
    opacity: 0.7; }
    .validated-uploader_upload__2Tg0K.validated-uploader_disabled__3WV81 svg {
      visibility: hidden; }
  .validated-uploader_upload__2Tg0K .validated-uploader_error__17fZQ {
    color: #e55e50;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px; }
  .validated-uploader_upload__2Tg0K svg {
    align-self: center;
    margin-top: 0; }

.validated-uploader_icon-info__3UFSy {
  align-self: center;
  flex: 1 1;
  justify-content: flex-start;
  margin: 0 10px; }
  .validated-uploader_icon-info__3UFSy span {
    font-size: 14px; }
  .validated-uploader_icon-info__3UFSy h3 {
    font-size: 16px; }

.validated-uploader_input__3n5HH {
  align-items: center;
  background-color: #fff;
  border: 2px solid #014660;
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
  width: 60px; }
  .validated-uploader_input__3n5HH img {
    border-radius: 50%;
    height: 54px;
    width: auto; }
  .validated-uploader_input__3n5HH svg {
    height: 40px;
    margin: 0;
    position: absolute;
    width: 40px;
    z-index: 1; }
  .validated-uploader_input__3n5HH input {
    height: 50px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 50px;
    z-index: 5; }

.load_load_btn__1Oxzc {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 10px;
  display: flex;
  height: 35px;
  justify-content: center;
  outline: none;
  padding: 5px;
  width: 35px; }
  .load_load_btn__1Oxzc:hover {
    background-color: rgba(246, 246, 246, 0.1);
    cursor: pointer; }
  .load_load_btn__1Oxzc svg {
    height: 100%;
    width: 100%; }

.profile_white__XlSIW {
  color: #f9f9f9; }

.profile_black__2wCOG {
  color: #000; }

.profile_deep-teal__2AU7O {
  color: #014660; }

.profile_greyish-teal__46XZI {
  color: #5bdbbd; }

.profile_very-light-grey__2EXS4 {
  color: #b9b9b9; }

.profile_warm-grey__3uqwO {
  color: #55556f; }

.profile_simple-grey__OMuBu {
  color: #d4d4d8; }

.profile_dark-grey__12k3o {
  color: #222026; }

.profile_flat-white__1aqsl {
  color: #fff; }

.profile_pastel-red__2Yn0O {
  color: #e55e50; }

.profile_white-bg__15yeZ {
  background-color: #f9f9f9; }

.profile_deep-teal-bg__S8zhA {
  background-color: #014660;
  color: #fff; }

.profile_greyish-teal-bg__3zUwZ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile_very-light-grey-bg__3rWlv {
  background-color: #b9b9b9;
  color: #222026; }

.profile_warm-grey-bg__1jemH {
  background-color: #55556f;
  color: #f9f9f9; }

.profile_simple-grey-bg__2F2vU {
  background-color: #d4d4d8;
  color: #222026; }

.profile_dark-grey-bg__3LSC3 {
  background-color: #222026;
  color: #fff; }

.profile_flat-white-bg__3rMPe {
  background-color: #fff;
  color: #014660; }

.profile_pastel-red-bg__2SAgY {
  background-color: #e55e50;
  color: #f9f9f9; }

.profile_white__XlSIW {
  color: #f9f9f9; }

.profile_black__2wCOG {
  color: #000; }

.profile_deep-teal__2AU7O {
  color: #014660; }

.profile_greyish-teal__46XZI {
  color: #5bdbbd; }

.profile_very-light-grey__2EXS4 {
  color: #b9b9b9; }

.profile_warm-grey__3uqwO {
  color: #55556f; }

.profile_simple-grey__OMuBu {
  color: #d4d4d8; }

.profile_dark-grey__12k3o {
  color: #222026; }

.profile_flat-white__1aqsl {
  color: #fff; }

.profile_pastel-red__2Yn0O {
  color: #e55e50; }

.profile_white-bg__15yeZ {
  background-color: #f9f9f9; }

.profile_deep-teal-bg__S8zhA {
  background-color: #014660;
  color: #fff; }

.profile_greyish-teal-bg__3zUwZ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile_very-light-grey-bg__3rWlv {
  background-color: #b9b9b9;
  color: #222026; }

.profile_warm-grey-bg__1jemH {
  background-color: #55556f;
  color: #f9f9f9; }

.profile_simple-grey-bg__2F2vU {
  background-color: #d4d4d8;
  color: #222026; }

.profile_dark-grey-bg__3LSC3 {
  background-color: #222026;
  color: #fff; }

.profile_flat-white-bg__3rMPe {
  background-color: #fff;
  color: #014660; }

.profile_pastel-red-bg__2SAgY {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile_avatar__37ili {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  border-radius: 100%;
  position: relative; }
  .profile_avatar__37ili [class*=status] {
    bottom: 0;
    position: absolute;
    right: 0; }
  .profile_avatar__37ili img {
    height: 50px;
    width: 50px; }
  @media (max-width: 1024px) {
    .profile_avatar__37ili {
      justify-self: end; }
      .profile_avatar__37ili img {
        height: 40px;
        width: 40px; } }

.header_white__163La {
  color: #f9f9f9; }

.header_black__A3rPu {
  color: #000; }

.header_deep-teal__1pYR5 {
  color: #014660; }

.header_greyish-teal__K_n7o {
  color: #5bdbbd; }

.header_very-light-grey__3o__u {
  color: #b9b9b9; }

.header_warm-grey__zvF4l {
  color: #55556f; }

.header_simple-grey__2iEJz {
  color: #d4d4d8; }

.header_dark-grey__1NjJA {
  color: #222026; }

.header_flat-white__2WMTz {
  color: #fff; }

.header_pastel-red__20yJA {
  color: #e55e50; }

.header_white-bg__2KoHr {
  background-color: #f9f9f9; }

.header_deep-teal-bg__197VQ {
  background-color: #014660;
  color: #fff; }

.header_greyish-teal-bg__j0vbK {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header_very-light-grey-bg__2JIXb {
  background-color: #b9b9b9;
  color: #222026; }

.header_warm-grey-bg__65fbL {
  background-color: #55556f;
  color: #f9f9f9; }

.header_simple-grey-bg__2QBnP {
  background-color: #d4d4d8;
  color: #222026; }

.header_dark-grey-bg__3lPKI {
  background-color: #222026;
  color: #fff; }

.header_flat-white-bg__3yalI {
  background-color: #fff;
  color: #014660; }

.header_pastel-red-bg__25xr2 {
  background-color: #e55e50;
  color: #f9f9f9; }

.header_white__163La {
  color: #f9f9f9; }

.header_black__A3rPu {
  color: #000; }

.header_deep-teal__1pYR5 {
  color: #014660; }

.header_greyish-teal__K_n7o {
  color: #5bdbbd; }

.header_very-light-grey__3o__u {
  color: #b9b9b9; }

.header_warm-grey__zvF4l {
  color: #55556f; }

.header_simple-grey__2iEJz {
  color: #d4d4d8; }

.header_dark-grey__1NjJA {
  color: #222026; }

.header_flat-white__2WMTz {
  color: #fff; }

.header_pastel-red__20yJA {
  color: #e55e50; }

.header_white-bg__2KoHr {
  background-color: #f9f9f9; }

.header_deep-teal-bg__197VQ {
  background-color: #014660;
  color: #fff; }

.header_greyish-teal-bg__j0vbK {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header_very-light-grey-bg__2JIXb {
  background-color: #b9b9b9;
  color: #222026; }

.header_warm-grey-bg__65fbL {
  background-color: #55556f;
  color: #f9f9f9; }

.header_simple-grey-bg__2QBnP {
  background-color: #d4d4d8;
  color: #222026; }

.header_dark-grey-bg__3lPKI {
  background-color: #222026;
  color: #fff; }

.header_flat-white-bg__3yalI {
  background-color: #fff;
  color: #014660; }

.header_pastel-red-bg__25xr2 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
w-header-community-module {
  margin-right: 10px; }

.header_nav__3b6kC {
  align-items: center;
  border-radius: 30px;
  display: flex;
  padding: 8px 15px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  @media (max-width: 1024px) {
    .header_nav__3b6kC {
      padding: 8px 20px; } }
  .header_nav__3b6kC:hover {
    background-color: rgba(246, 246, 246, 0.1);
    cursor: pointer;
    text-decoration: none; }
  .header_nav__3b6kC > svg {
    height: 25px;
    margin-right: 10px;
    width: 25px; }
    @media (max-width: 1024px) {
      .header_nav__3b6kC > svg {
        margin-right: 0; } }
  .header_nav__3b6kC > span {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
    @media (max-width: 1024px) {
      .header_nav__3b6kC > span {
        display: none; } }
  .header_nav__3b6kC .header_badge__36Uf9 {
    align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#5fe8c3), to(#43b395));
    background: linear-gradient(#5fe8c3, #43b395);
    border-radius: 18.5px;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 18px;
    justify-content: center;
    left: 28px;
    position: absolute;
    top: 6px;
    width: 18px; }

.header_active__2repZ {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .header_active__2repZ:hover {
    background-color: #fff;
    text-decoration: none; }
  .header_active__2repZ > span {
    color: #014660;
    font-weight: 600; }

.Search_white__3H3b5 {
  color: #f9f9f9; }

.Search_black__1Cae9 {
  color: #000; }

.Search_deep-teal__u4icv {
  color: #014660; }

.Search_greyish-teal__Myzu_ {
  color: #5bdbbd; }

.Search_very-light-grey__2sGM8 {
  color: #b9b9b9; }

.Search_warm-grey__3YZFV {
  color: #55556f; }

.Search_simple-grey__1TxMI {
  color: #d4d4d8; }

.Search_dark-grey__1oAsm {
  color: #222026; }

.Search_flat-white__2v6Ya {
  color: #fff; }

.Search_pastel-red__2udbG {
  color: #e55e50; }

.Search_white-bg__170Lf {
  background-color: #f9f9f9; }

.Search_deep-teal-bg__P469U {
  background-color: #014660;
  color: #fff; }

.Search_greyish-teal-bg__epx02 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Search_very-light-grey-bg__1kur6 {
  background-color: #b9b9b9;
  color: #222026; }

.Search_warm-grey-bg__DZLbT {
  background-color: #55556f;
  color: #f9f9f9; }

.Search_simple-grey-bg__2P-so {
  background-color: #d4d4d8;
  color: #222026; }

.Search_dark-grey-bg__37Jd5 {
  background-color: #222026;
  color: #fff; }

.Search_flat-white-bg__2DylY {
  background-color: #fff;
  color: #014660; }

.Search_pastel-red-bg__2CmFY {
  background-color: #e55e50;
  color: #f9f9f9; }

.Search_wrapper__2r5J5 {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  display: grid;
  grid-area: search;
  grid-column-gap: 10px;
  grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
  grid-template-columns: min-content 1fr min-content;
  height: 40px;
  padding: 0 10px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  width: 100%; }
  .Search_wrapper__2r5J5:focus-within {
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .Search_wrapper__2r5J5 button {
    position: absolute;
    right: 7px;
    top: 9px; }
    .Search_wrapper__2r5J5 button:hover {
      background-color: transparent !important; }
    @media (max-width: 1024px) {
      .Search_wrapper__2r5J5 button {
        top: 5px; } }
  .Search_wrapper__2r5J5 input {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .Search_wrapper__2r5J5 input:hover {
      outline: none; }
    .Search_wrapper__2r5J5 input::-webkit-input-placeholder {
      color: #a0a0a0; }
    .Search_wrapper__2r5J5 input::-moz-placeholder {
      color: #a0a0a0; }
    .Search_wrapper__2r5J5 input::-ms-input-placeholder {
      color: #a0a0a0; }
    .Search_wrapper__2r5J5 input::placeholder {
      color: #a0a0a0; }
  .Search_wrapper__2r5J5 svg {
    height: 16px;
    width: 20px; }
  @media (min-width: 1025px) {
    .Search_wrapper__2r5J5 {
      height: 41px; }
      .Search_wrapper__2r5J5 svg {
        height: 22px;
        width: 22px; } }

.Button_white__1Sf7T {
  color: #f9f9f9; }

.Button_black__ilpo4 {
  color: #000; }

.Button_deep-teal__2DN6W {
  color: #014660; }

.Button_greyish-teal__2eG-4 {
  color: #5bdbbd; }

.Button_very-light-grey__3Wd_W {
  color: #b9b9b9; }

.Button_warm-grey__32jaS {
  color: #55556f; }

.Button_simple-grey__3aXFV {
  color: #d4d4d8; }

.Button_dark-grey__3YMxk {
  color: #222026; }

.Button_flat-white__Fx7u0 {
  color: #fff; }

.Button_pastel-red__2gKUp {
  color: #e55e50; }

.Button_white-bg__3h56G {
  background-color: #f9f9f9; }

.Button_deep-teal-bg__49-W- {
  background-color: #014660;
  color: #fff; }

.Button_greyish-teal-bg__35q5h {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Button_very-light-grey-bg__24Y3M {
  background-color: #b9b9b9;
  color: #222026; }

.Button_warm-grey-bg__3EyzF {
  background-color: #55556f;
  color: #f9f9f9; }

.Button_simple-grey-bg__3v3-9 {
  background-color: #d4d4d8;
  color: #222026; }

.Button_dark-grey-bg__3uT_V {
  background-color: #222026;
  color: #fff; }

.Button_flat-white-bg__2wiU_ {
  background-color: #fff;
  color: #014660; }

.Button_pastel-red-bg__2eNNc {
  background-color: #e55e50;
  color: #f9f9f9; }

.Button_base__upIL_ {
  background: none;
  border: 0;
  padding: 0;
  position: relative; }
  .Button_base__upIL_:hover:not(:disabled) {
    background-color: #f9f9f9;
    cursor: pointer; }
  .Button_base__upIL_:focus:not(:disabled) {
    outline: none; }

.Button_primary__1M-ye {
  background-color: #014660;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 25px; }
  .Button_primary__1M-ye:hover:not(:disabled) {
    background-color: #026b92; }
  .Button_primary__1M-ye:focus:not(:disabled) {
    background-color: #026b92; }

.Button_link__1xHax {
  background-color: transparent;
  color: #014660;
  font-size: 14px;
  font-weight: 600;
  padding: 0 5px; }
  .Button_link__1xHax:hover:not(:disabled) {
    color: rgba(1, 70, 96, 0.7); }
  .Button_link__1xHax:focus:not(:disabled) {
    color: rgba(1, 70, 96, 0.7); }

.Button_primary-outline__3nyoA {
  background-color: transparent;
  border: 1px solid #014660;
  border-radius: 10px;
  color: #014660;
  font-size: 14px;
  padding: 3px 25px; }

.Button_secondary__fGJ70 {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 3px #014660;
  color: #014660;
  font-size: 14px;
  padding: 2px 25px; }
  .Button_secondary__fGJ70:hover:not(:disabled) {
    background-color: rgba(249, 249, 249, 0.3);
    box-shadow: 0 0 3px rgba(1, 70, 96, 0.5); }
  .Button_secondary__fGJ70:focus:not(:disabled) {
    background-color: rgba(249, 249, 249, 0.3);
    box-shadow: 0 0 3px rgba(1, 70, 96, 0.5); }

.Button_light__1F4Xr {
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(185, 185, 185, 0.5);
  color: #b9b9b9;
  font-size: 14px;
  padding: 2px 25px; }
  .Button_light__1F4Xr:hover:not(:disabled) {
    background-color: rgba(1, 70, 96, 0.2); }
  .Button_light__1F4Xr:focus:not(:disabled) {
    background-color: rgba(1, 70, 96, 0.2); }

.Button_disabled__1bVFI {
  opacity: 0.2;
  pointer-events: none; }

.search_white__38yAp {
  color: #f9f9f9; }

.search_black__1aSAk {
  color: #000; }

.search_deep-teal__qIQ27 {
  color: #014660; }

.search_greyish-teal__1ywL1 {
  color: #5bdbbd; }

.search_very-light-grey__2bEfT {
  color: #b9b9b9; }

.search_warm-grey__1jFeg {
  color: #55556f; }

.search_simple-grey__1TiQD {
  color: #d4d4d8; }

.search_dark-grey__3zFe9 {
  color: #222026; }

.search_flat-white__1O11O {
  color: #fff; }

.search_pastel-red__1UIJt {
  color: #e55e50; }

.search_white-bg__2QPWq {
  background-color: #f9f9f9; }

.search_deep-teal-bg__PgE6q {
  background-color: #014660;
  color: #fff; }

.search_greyish-teal-bg__1ghtt {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.search_very-light-grey-bg__7DNhL {
  background-color: #b9b9b9;
  color: #222026; }

.search_warm-grey-bg__2RLSM {
  background-color: #55556f;
  color: #f9f9f9; }

.search_simple-grey-bg__1qPeo {
  background-color: #d4d4d8;
  color: #222026; }

.search_dark-grey-bg__2uexj {
  background-color: #222026;
  color: #fff; }

.search_flat-white-bg__3pnuD {
  background-color: #fff;
  color: #014660; }

.search_pastel-red-bg__3si7k {
  background-color: #e55e50;
  color: #f9f9f9; }

.search_wrapper__bC5t3 {
  background: rgba(249, 249, 249, 0.95);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding: 20px 25% 0; }
  @media (max-width: 1024px) {
    .search_wrapper__bC5t3 {
      padding: 10px 0 0; } }

.search_tabs__dNk7m {
  display: flex;
  margin-bottom: 20px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content; }
  .search_tabs__dNk7m > div {
    align-items: center;
    background-color: #b9b9b9;
    border-radius: 20px;
    color: #f9f9f9;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 10px;
    padding: 0 10px; }
    .search_tabs__dNk7m > div:hover {
      background-color: #9b9b9b;
      cursor: pointer; }
    .search_tabs__dNk7m > div.search_selected__1YNvX {
      background-color: #5bdbbd; }
  @media (max-width: 1024px) {
    .search_tabs__dNk7m {
      margin-bottom: 10px;
      padding: 0 5px; }
      .search_tabs__dNk7m > div {
        font-size: 12px;
        height: 24px;
        margin-right: 5px;
        padding: 2px 5px;
        text-align: center; } }

.search_title__1rX7E {
  color: #55556f;
  font-family: "Questrial",  sans-serif;
  font-size: 26px;
  margin: 0 0 15px 15px; }

.search_list__1Ya52 {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 5px; }
  .search_list__1Ya52 > div {
    background-color: #fff;
    box-shadow: 0 0 2px rgba(34, 32, 38, 0.1);
    display: flex;
    line-height: 18px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding: 20px; }
    .search_list__1Ya52 > div:hover {
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer; }
    .search_list__1Ya52 > div > div:first-child {
      margin-right: 15px; }
    .search_list__1Ya52 > div > div:last-child {
      display: flex;
      flex-direction: column; }
    .search_list__1Ya52 > div h1 {
      color: #014660;
      font-family: "Questrial", sans-serif;
      font-size: 22px; }
    .search_list__1Ya52 > div span {
      color: #55556f;
      font-family: "OpenSans", sans-serif;
      font-size: 14px;
      margin-top: 5px; }

.search_loader_wrapper__YpZPl {
  align-items: center;
  background: rgba(249, 249, 249, 0.95);
  display: flex;
  height: 100%;
  justify-content: center; }

.search_search__1d9BD {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20%; }
  .search_search__1d9BD > svg {
    height: 78px;
    margin-bottom: 15px;
    width: 78px; }
  .search_search__1d9BD > h4 {
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    line-height: 26px; }
  @media (max-width: 1024px) {
    .search_search__1d9BD {
      padding-top: 40%; }
      .search_search__1d9BD > svg {
        height: 60px;
        width: 60px; }
      .search_search__1d9BD > h4 {
        font-size: 20px; } }

.Poll_white__3D3gs {
  color: #f9f9f9; }

.Poll_black__8hwR7 {
  color: #000; }

.Poll_deep-teal__11EuT {
  color: #014660; }

.Poll_greyish-teal__3sdVo {
  color: #5bdbbd; }

.Poll_very-light-grey__1mzfd {
  color: #b9b9b9; }

.Poll_warm-grey__2qKX- {
  color: #55556f; }

.Poll_simple-grey__3Gyk2 {
  color: #d4d4d8; }

.Poll_dark-grey__26GOh {
  color: #222026; }

.Poll_flat-white__zbXao {
  color: #fff; }

.Poll_pastel-red__3yl6I {
  color: #e55e50; }

.Poll_white-bg__cBAeT {
  background-color: #f9f9f9; }

.Poll_deep-teal-bg__1JtFo {
  background-color: #014660;
  color: #fff; }

.Poll_greyish-teal-bg__eEIwz {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Poll_very-light-grey-bg__neSfu {
  background-color: #b9b9b9;
  color: #222026; }

.Poll_warm-grey-bg__1Px9L {
  background-color: #55556f;
  color: #f9f9f9; }

.Poll_simple-grey-bg__2vkQ7 {
  background-color: #d4d4d8;
  color: #222026; }

.Poll_dark-grey-bg__2_LCp {
  background-color: #222026;
  color: #fff; }

.Poll_flat-white-bg___vz1c {
  background-color: #fff;
  color: #014660; }

.Poll_pastel-red-bg__2zQQn {
  background-color: #e55e50;
  color: #f9f9f9; }

.Poll_wrapper__2TskR {
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  max-height: 60px;
  overflow: hidden;
  padding: 15px 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition-property: max-height, height;
  transition-property: max-height, height;
  width: 100%; }
  .Poll_wrapper__2TskR.Poll_opened__1LPDq {
    bottom: 0;
    max-height: 50vh; }

.Poll_header__1Ns3s {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-between; }

.Poll_controls__1nbfn {
  display: flex;
  justify-content: space-between;
  width: 60px; }
  .Poll_controls__1nbfn > div {
    cursor: pointer; }
  .Poll_controls__1nbfn svg {
    height: 30px;
    width: 30px; }

.Poll_content__3QNaQ {
  line-height: 20px;
  overflow: hidden;
  position: relative; }
  .Poll_content__3QNaQ > div {
    height: 100%;
    top: 0;
    width: 100%;
    will-change: transform; }
    .Poll_content__3QNaQ > div.Poll_grid_template__1WUky {
      display: grid;
      grid-template-rows: auto -webkit-min-content;
      grid-template-rows: auto min-content; }
    @media (min-width: 1025px) {
      .Poll_content__3QNaQ > div > span {
        width: 80%; } }

.Poll_buttons__1QaT- {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 25px 0 0; }
  .Poll_buttons__1QaT- > div {
    align-items: center;
    border: 1px solid #014660;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex: 0 0 49%;
    justify-content: center;
    margin-bottom: 10px;
    padding: 8px 10px; }
    .Poll_buttons__1QaT- > div svg {
      height: 30px;
      padding-right: 8px;
      width: 30px; }
    .Poll_buttons__1QaT- > div:hover {
      background-color: #f9f9f9; }
  @media (min-width: 1025px) {
    .Poll_buttons__1QaT- {
      flex-flow: row;
      justify-content: start;
      margin-top: 20px; }
      .Poll_buttons__1QaT- > div {
        flex: 1 1 80px;
        margin: 5px; } }

.Poll_list__t1S9r {
  margin-top: 25px; }
  .Poll_list__t1S9r > div {
    margin-top: 5px; }
    .Poll_list__t1S9r > div :first-child {
      margin-right: 10px; }

.Poll_field__3Apvq {
  background-color: #f2f2f2;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 15px;
  padding: 25px 20px 35px; }
  .Poll_field__3Apvq .Poll_input__kE4Xr {
    height: 30px;
    margin-top: 5px;
    padding: 4px 10px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .Poll_field__3Apvq .Poll_input__kE4Xr.Poll_active__135Bf {
      height: 20vh; }
  @media (min-width: 1025px) {
    .Poll_field__3Apvq {
      padding-bottom: 20px; } }

.Poll_report_buttons__jTeqF {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 10px; }
  .Poll_report_buttons__jTeqF button {
    margin-left: 10px; }

.Poll_text__3bRFZ {
  margin-top: 15px; }

.Poll_choose_wrapper__3SNhD {
  overflow: hidden; }

.Poll_choose_content_wrapper__2lcMs {
  display: flex;
  min-height: auto;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear; }
  .Poll_choose_content_wrapper__2lcMs.Poll_input__kE4Xr {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }

.Poll_options_wrapper__WNKx0 {
  margin-top: 15px; }

.Poll_choose_content__1kjnt {
  min-width: 100%; }

.Text_white__21B5l {
  color: #f9f9f9; }

.Text_black__2fIVm {
  color: #000; }

.Text_deep-teal__3fZTn {
  color: #014660; }

.Text_greyish-teal__2OQkd {
  color: #5bdbbd; }

.Text_very-light-grey__2yuRu {
  color: #b9b9b9; }

.Text_warm-grey__hEqXt {
  color: #55556f; }

.Text_simple-grey__GC-d1 {
  color: #d4d4d8; }

.Text_dark-grey__1CySm {
  color: #222026; }

.Text_flat-white__3Mcp3 {
  color: #fff; }

.Text_pastel-red__2JBBe {
  color: #e55e50; }

.Text_white-bg__1T6oi {
  background-color: #f9f9f9; }

.Text_deep-teal-bg__3_2MX {
  background-color: #014660;
  color: #fff; }

.Text_greyish-teal-bg__cdJd5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Text_very-light-grey-bg__1r8ga {
  background-color: #b9b9b9;
  color: #222026; }

.Text_warm-grey-bg__5UsEO {
  background-color: #55556f;
  color: #f9f9f9; }

.Text_simple-grey-bg__bz4hO {
  background-color: #d4d4d8;
  color: #222026; }

.Text_dark-grey-bg__3Bjul {
  background-color: #222026;
  color: #fff; }

.Text_flat-white-bg__3VJ-P {
  background-color: #fff;
  color: #014660; }

.Text_pastel-red-bg__1BS_D {
  background-color: #e55e50;
  color: #f9f9f9; }

.Text_white__21B5l {
  color: #f9f9f9; }

.Text_black__2fIVm {
  color: #000; }

.Text_deep-teal__3fZTn {
  color: #014660; }

.Text_greyish-teal__2OQkd {
  color: #5bdbbd; }

.Text_very-light-grey__2yuRu {
  color: #b9b9b9; }

.Text_warm-grey__hEqXt {
  color: #55556f; }

.Text_simple-grey__GC-d1 {
  color: #d4d4d8; }

.Text_dark-grey__1CySm {
  color: #222026; }

.Text_flat-white__3Mcp3 {
  color: #fff; }

.Text_pastel-red__2JBBe {
  color: #e55e50; }

.Text_white-bg__1T6oi {
  background-color: #f9f9f9; }

.Text_deep-teal-bg__3_2MX {
  background-color: #014660;
  color: #fff; }

.Text_greyish-teal-bg__cdJd5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Text_very-light-grey-bg__1r8ga {
  background-color: #b9b9b9;
  color: #222026; }

.Text_warm-grey-bg__5UsEO {
  background-color: #55556f;
  color: #f9f9f9; }

.Text_simple-grey-bg__bz4hO {
  background-color: #d4d4d8;
  color: #222026; }

.Text_dark-grey-bg__3Bjul {
  background-color: #222026;
  color: #fff; }

.Text_flat-white-bg__3VJ-P {
  background-color: #fff;
  color: #014660; }

.Text_pastel-red-bg__1BS_D {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.Text_base__2vY4s {
  display: block;
  font-family: "OpenSans", sans-serif; }

.Text_h1__2GWJw,
.Text_h2__13uay,
.Text_h3__2zFMT,
.Text_h4__kTZL1,
.Text_h5__3gSRY,
.Text_h6__2Rqpn {
  font-family: "Questrial", sans-serif; }

.Text_h1__2GWJw {
  font-size: 36px; }

.Text_h2__13uay {
  font-size: 36px; }

.Text_h3__2zFMT {
  font-size: 26px; }

.Text_h4__kTZL1 {
  font-size: 22px; }

.Text_h5__3gSRY {
  font-size: 20px; }

.Text_h6__2Rqpn {
  font-size: 16px; }

.Text_p__ZOVN8 {
  font-size: 16px; }

.Text_span___2YMj {
  display: inline-block; }

.Text_lead__3neqX {
  display: block;
  font-weight: bold; }

.Text_bold__1PTIX {
  font-weight: bold; }

.Text_italic__1Avlw {
  font-style: italic; }

.Text_invert__2NgLt {
  color: #f9f9f9; }

.Text_disabled__3pfwa {
  color: #aaa; }

.Text_margin-top__2M7e9 {
  margin-top: 0.5em; }

.Text_margin-bottom__1A0f5 {
  margin-bottom: 0.5em; }

.Text_margin-both__gwPSh {
  margin-bottom: 0.5em;
  margin-top: 0.5em; }

.Textarea_white__2B41U {
  color: #f9f9f9; }

.Textarea_black__2NP4D {
  color: #000; }

.Textarea_deep-teal__2ynAX {
  color: #014660; }

.Textarea_greyish-teal__3Zpk3 {
  color: #5bdbbd; }

.Textarea_very-light-grey__pGUs6 {
  color: #b9b9b9; }

.Textarea_warm-grey__2YLK3 {
  color: #55556f; }

.Textarea_simple-grey__3pGHM {
  color: #d4d4d8; }

.Textarea_dark-grey__1f-LO {
  color: #222026; }

.Textarea_flat-white__spyRc {
  color: #fff; }

.Textarea_pastel-red__2sw2E {
  color: #e55e50; }

.Textarea_white-bg__3eqpG {
  background-color: #f9f9f9; }

.Textarea_deep-teal-bg__2EgLa {
  background-color: #014660;
  color: #fff; }

.Textarea_greyish-teal-bg__r_vk- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Textarea_very-light-grey-bg__2Wan3 {
  background-color: #b9b9b9;
  color: #222026; }

.Textarea_warm-grey-bg__3K4Nw {
  background-color: #55556f;
  color: #f9f9f9; }

.Textarea_simple-grey-bg__1NT50 {
  background-color: #d4d4d8;
  color: #222026; }

.Textarea_dark-grey-bg__3jG_- {
  background-color: #222026;
  color: #fff; }

.Textarea_flat-white-bg__3iOrT {
  background-color: #fff;
  color: #014660; }

.Textarea_pastel-red-bg__2aL2V {
  background-color: #e55e50;
  color: #f9f9f9; }

.Textarea_white__2B41U {
  color: #f9f9f9; }

.Textarea_black__2NP4D {
  color: #000; }

.Textarea_deep-teal__2ynAX {
  color: #014660; }

.Textarea_greyish-teal__3Zpk3 {
  color: #5bdbbd; }

.Textarea_very-light-grey__pGUs6 {
  color: #b9b9b9; }

.Textarea_warm-grey__2YLK3 {
  color: #55556f; }

.Textarea_simple-grey__3pGHM {
  color: #d4d4d8; }

.Textarea_dark-grey__1f-LO {
  color: #222026; }

.Textarea_flat-white__spyRc {
  color: #fff; }

.Textarea_pastel-red__2sw2E {
  color: #e55e50; }

.Textarea_white-bg__3eqpG {
  background-color: #f9f9f9; }

.Textarea_deep-teal-bg__2EgLa {
  background-color: #014660;
  color: #fff; }

.Textarea_greyish-teal-bg__r_vk- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Textarea_very-light-grey-bg__2Wan3 {
  background-color: #b9b9b9;
  color: #222026; }

.Textarea_warm-grey-bg__3K4Nw {
  background-color: #55556f;
  color: #f9f9f9; }

.Textarea_simple-grey-bg__1NT50 {
  background-color: #d4d4d8;
  color: #222026; }

.Textarea_dark-grey-bg__3jG_- {
  background-color: #222026;
  color: #fff; }

.Textarea_flat-white-bg__3iOrT {
  background-color: #fff;
  color: #014660; }

.Textarea_pastel-red-bg__2aL2V {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.Textarea_base__bE1nP {
  background-color: #fff;
  border: 1px solid #b9b9b9;
  border-radius: 18px;
  color: #014660;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  resize: none;
  width: 100%; }
  .Textarea_base__bE1nP:focus {
    border-color: #5bdbbd; }
  .Textarea_base__bE1nP::-webkit-input-placeholder {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .Textarea_base__bE1nP::-moz-placeholder {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .Textarea_base__bE1nP::-ms-input-placeholder {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .Textarea_base__bE1nP::placeholder {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }

.center-modal_white__2muUj {
  color: #f9f9f9; }

.center-modal_black__3i-wx {
  color: #000; }

.center-modal_deep-teal__1aUxe {
  color: #014660; }

.center-modal_greyish-teal__V7j_L {
  color: #5bdbbd; }

.center-modal_very-light-grey__3hQfJ {
  color: #b9b9b9; }

.center-modal_warm-grey__2HGNN {
  color: #55556f; }

.center-modal_simple-grey__2feuW {
  color: #d4d4d8; }

.center-modal_dark-grey__1oX5U {
  color: #222026; }

.center-modal_flat-white__Xeoli {
  color: #fff; }

.center-modal_pastel-red__YYlon {
  color: #e55e50; }

.center-modal_white-bg__hzVio {
  background-color: #f9f9f9; }

.center-modal_deep-teal-bg__2m4A_ {
  background-color: #014660;
  color: #fff; }

.center-modal_greyish-teal-bg__1NI4y {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.center-modal_very-light-grey-bg__3FMAm {
  background-color: #b9b9b9;
  color: #222026; }

.center-modal_warm-grey-bg__2Zq8A {
  background-color: #55556f;
  color: #f9f9f9; }

.center-modal_simple-grey-bg__2D4vB {
  background-color: #d4d4d8;
  color: #222026; }

.center-modal_dark-grey-bg__1df6U {
  background-color: #222026;
  color: #fff; }

.center-modal_flat-white-bg__3b3o9 {
  background-color: #fff;
  color: #014660; }

.center-modal_pastel-red-bg__2_jeL {
  background-color: #e55e50;
  color: #f9f9f9; }

.center-modal_logout__1IaLg, .center-modal_delete_message__39sr4, .center-modal_outer__12vw6, .center-modal_wide__13ce1 {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-width: 40vw;
  padding: 20px;
  z-index: 6; }

@media (min-width: 1025px) {
  .center-modal_wide__13ce1 {
    max-width: 700px;
    width: 55vw; } }

.center-modal_modal_header__1r1H9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.center-modal_form__MTZ2W {
  margin: 1em 0; }

.center-modal_title__iVWfc {
  color: #4cb49c;
  font-family: "Questrial", sans-serif;
  font-size: 22px;
  font-weight: 400;
  grid-area: title; }
  @media (max-width: 1024px) {
    .center-modal_title__iVWfc {
      font-size: 17px; } }

.center-modal_close_btn__3oBR6 {
  cursor: pointer;
  grid-area: close;
  justify-self: end;
  margin-left: 6px; }
  .center-modal_close_btn__3oBR6 svg {
    height: 22px;
    width: 22px; }

.center-modal_text__aV7fz {
  display: flex;
  flex-direction: column;
  grid-area: text;
  justify-content: space-between;
  line-height: 1.4em;
  margin: 0.8em 0; }
  .center-modal_text__aV7fz span {
    color: #4d4d4d;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 21px; }
    @media (max-width: 1024px) {
      .center-modal_text__aV7fz span {
        font-size: 15px; } }

.center-modal_buttons_wrapper__oYeji {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%; }
  .center-modal_buttons_wrapper__oYeji button {
    background-color: #fff;
    border: #014660 1px solid;
    border-radius: 20px;
    color: #014660;
    height: 27px;
    margin-left: 10px;
    padding: 3px 0;
    width: 83px; }
  .center-modal_buttons_wrapper__oYeji .center-modal_button__k3RuL {
    background-color: #014660;
    color: #fff; }

.center-info-modal_white__2X_QJ {
  color: #f9f9f9; }

.center-info-modal_black__3g-8T {
  color: #000; }

.center-info-modal_deep-teal__3Vt6f {
  color: #014660; }

.center-info-modal_greyish-teal__1A8UO {
  color: #5bdbbd; }

.center-info-modal_very-light-grey__3i-Ey {
  color: #b9b9b9; }

.center-info-modal_warm-grey__2ZvGX {
  color: #55556f; }

.center-info-modal_simple-grey__3NRPa {
  color: #d4d4d8; }

.center-info-modal_dark-grey__3hrsH {
  color: #222026; }

.center-info-modal_flat-white__3bwLy {
  color: #fff; }

.center-info-modal_pastel-red__MjtXT {
  color: #e55e50; }

.center-info-modal_white-bg__Qr1py {
  background-color: #f9f9f9; }

.center-info-modal_deep-teal-bg__T2jJI {
  background-color: #014660;
  color: #fff; }

.center-info-modal_greyish-teal-bg__3Goik {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.center-info-modal_very-light-grey-bg__1OY4A {
  background-color: #b9b9b9;
  color: #222026; }

.center-info-modal_warm-grey-bg__3JfyW {
  background-color: #55556f;
  color: #f9f9f9; }

.center-info-modal_simple-grey-bg__NHzM2 {
  background-color: #d4d4d8;
  color: #222026; }

.center-info-modal_dark-grey-bg__8jcr7 {
  background-color: #222026;
  color: #fff; }

.center-info-modal_flat-white-bg__i0sCI {
  background-color: #fff;
  color: #014660; }

.center-info-modal_pastel-red-bg__2U1bZ {
  background-color: #e55e50;
  color: #f9f9f9; }

.center-info-modal_wrapper__mxrxD {
  align-self: center;
  background-color: #f6f6f6;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-width: 100%;
  padding: 10px;
  position: relative;
  width: 550px; }

.center-info-modal_header__34Ob3 {
  color: #4cb49c;
  font-family: "Questrial", sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 18px; }

.message-statuses-modal_white__8K7pE {
  color: #f9f9f9; }

.message-statuses-modal_black__hWqfE {
  color: #000; }

.message-statuses-modal_deep-teal__14e2J {
  color: #014660; }

.message-statuses-modal_greyish-teal__2khzB {
  color: #5bdbbd; }

.message-statuses-modal_very-light-grey__3HcSO {
  color: #b9b9b9; }

.message-statuses-modal_warm-grey__22uDl {
  color: #55556f; }

.message-statuses-modal_simple-grey__3wESM {
  color: #d4d4d8; }

.message-statuses-modal_dark-grey__2xYBL {
  color: #222026; }

.message-statuses-modal_flat-white__3nwUQ {
  color: #fff; }

.message-statuses-modal_pastel-red__1fcr7 {
  color: #e55e50; }

.message-statuses-modal_white-bg__1z4Bp {
  background-color: #f9f9f9; }

.message-statuses-modal_deep-teal-bg__1-xKP {
  background-color: #014660;
  color: #fff; }

.message-statuses-modal_greyish-teal-bg__2Uj5x {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.message-statuses-modal_very-light-grey-bg__3Jlif {
  background-color: #b9b9b9;
  color: #222026; }

.message-statuses-modal_warm-grey-bg__2ipb4 {
  background-color: #55556f;
  color: #f9f9f9; }

.message-statuses-modal_simple-grey-bg__2rgfl {
  background-color: #d4d4d8;
  color: #222026; }

.message-statuses-modal_dark-grey-bg__1Zv_f {
  background-color: #222026;
  color: #fff; }

.message-statuses-modal_flat-white-bg__33aoI {
  background-color: #fff;
  color: #014660; }

.message-statuses-modal_pastel-red-bg__1EuCQ {
  background-color: #e55e50;
  color: #f9f9f9; }

.message-statuses-modal_receivers_list__3AL2n {
  max-height: 400px;
  overflow-y: auto; }

.message-statuses-modal_receiver__7p_Gl {
  align-items: center;
  background-color: #f2f2f2;
  color: #014660;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px; }
  .message-statuses-modal_receiver__7p_Gl:hover {
    background-color: #f9f9f9; }
  .message-statuses-modal_receiver__7p_Gl svg {
    width: 20px; }
  .message-statuses-modal_receiver__7p_Gl [class^="avatar"] {
    margin-right: 10px; }
  .message-statuses-modal_receiver__7p_Gl .message-statuses-modal_info__nONiA {
    align-items: center;
    display: flex; }
    .message-statuses-modal_receiver__7p_Gl .message-statuses-modal_info__nONiA w-avatar {
      margin-right: 10px; }

.full-screen-modal_white__1AeWW {
  color: #f9f9f9; }

.full-screen-modal_black__3QGM0 {
  color: #000; }

.full-screen-modal_deep-teal__32SNT {
  color: #014660; }

.full-screen-modal_greyish-teal__gdzb9 {
  color: #5bdbbd; }

.full-screen-modal_very-light-grey__1Bcol {
  color: #b9b9b9; }

.full-screen-modal_warm-grey__sA9AD {
  color: #55556f; }

.full-screen-modal_simple-grey__1lOxR {
  color: #d4d4d8; }

.full-screen-modal_dark-grey__2uzVf {
  color: #222026; }

.full-screen-modal_flat-white__2l0ef {
  color: #fff; }

.full-screen-modal_pastel-red__1xwnI {
  color: #e55e50; }

.full-screen-modal_white-bg__2PboW {
  background-color: #f9f9f9; }

.full-screen-modal_deep-teal-bg__2GrB- {
  background-color: #014660;
  color: #fff; }

.full-screen-modal_greyish-teal-bg__1PhOs {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.full-screen-modal_very-light-grey-bg__165cr {
  background-color: #b9b9b9;
  color: #222026; }

.full-screen-modal_warm-grey-bg__1NLeu {
  background-color: #55556f;
  color: #f9f9f9; }

.full-screen-modal_simple-grey-bg__y40Vj {
  background-color: #d4d4d8;
  color: #222026; }

.full-screen-modal_dark-grey-bg__YAEn_ {
  background-color: #222026;
  color: #fff; }

.full-screen-modal_flat-white-bg__1Pbu9 {
  background-color: #fff;
  color: #014660; }

.full-screen-modal_pastel-red-bg__2JOHA {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.full-screen-modal_top__2uYJj, .full-screen-modal_header__32Nxn, .full-screen-modal_header-mobile__1qWBK {
  display: block;
  justify-content: center; }
  .full-screen-modal_top__2uYJj .full-screen-modal_close__VhVVP, .full-screen-modal_header__32Nxn .full-screen-modal_close__VhVVP, .full-screen-modal_header-mobile__1qWBK .full-screen-modal_close__VhVVP {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 20px; }
  .full-screen-modal_top__2uYJj .full-screen-modal_close__VhVVP > svg, .full-screen-modal_header__32Nxn .full-screen-modal_close__VhVVP > svg, .full-screen-modal_header-mobile__1qWBK .full-screen-modal_close__VhVVP > svg {
    height: 20px;
    width: 30px; }

.full-screen-modal_title__arlja {
  align-self: center; }

.full-screen-modal_wrapper__2em6U {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5; }

.full-screen-modal_header__32Nxn {
  height: 60px; }
  .full-screen-modal_header__32Nxn .full-screen-modal_close__VhVVP {
    right: 20px; }
  .full-screen-modal_header__32Nxn .full-screen-modal_close__VhVVP > svg {
    height: 20px;
    width: 20px; }

.full-screen-modal_header-mobile__1qWBK {
  align-items: center;
  background-color: #014660;
  color: #fff;
  display: block;
  height: 60px;
  padding: 20px;
  position: relative;
  text-align: center; }
  @media (max-width: 350px) {
    .full-screen-modal_header-mobile__1qWBK {
      padding-left: 50px; } }
  .full-screen-modal_header-mobile__1qWBK span {
    align-self: center;
    text-transform: uppercase; }
  .full-screen-modal_header-mobile__1qWBK .full-screen-modal_close__VhVVP {
    left: 10px; }
    @supports (-webkit-overflow-scrolling: touch) {
      .full-screen-modal_header-mobile__1qWBK .full-screen-modal_close__VhVVP {
        left: 0; } }
  .full-screen-modal_header-mobile__1qWBK .full-screen-modal_close__VhVVP > svg {
    height: 20px;
    width: 30px; }

.full-screen-modal_body__2H0GS {
  align-self: center;
  height: 90%;
  /* fallback if needed */
  height: calc(100% - 60px);
  max-width: 800px;
  padding: 20px;
  width: 100%; }
  .full-screen-modal_body__2H0GS .full-screen-modal_title__arlja {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-self: flex-start;
    display: block;
    font-family: "Questrial", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 20px; }

.create-direct-modal_white__3F3uy {
  color: #f9f9f9; }

.create-direct-modal_black__NT4Zf {
  color: #000; }

.create-direct-modal_deep-teal__2oEFz {
  color: #014660; }

.create-direct-modal_greyish-teal__3OCCG {
  color: #5bdbbd; }

.create-direct-modal_very-light-grey__1AjVl {
  color: #b9b9b9; }

.create-direct-modal_warm-grey__LeyoC {
  color: #55556f; }

.create-direct-modal_simple-grey__1Ap45 {
  color: #d4d4d8; }

.create-direct-modal_dark-grey__1o7or {
  color: #222026; }

.create-direct-modal_flat-white__A8dKC {
  color: #fff; }

.create-direct-modal_pastel-red__dglWm {
  color: #e55e50; }

.create-direct-modal_white-bg__1BQ7v {
  background-color: #f9f9f9; }

.create-direct-modal_deep-teal-bg__31xot {
  background-color: #014660;
  color: #fff; }

.create-direct-modal_greyish-teal-bg__24tW2 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.create-direct-modal_very-light-grey-bg__1pnQY {
  background-color: #b9b9b9;
  color: #222026; }

.create-direct-modal_warm-grey-bg__-wTT6 {
  background-color: #55556f;
  color: #f9f9f9; }

.create-direct-modal_simple-grey-bg__3V7oa {
  background-color: #d4d4d8;
  color: #222026; }

.create-direct-modal_dark-grey-bg__31r1h {
  background-color: #222026;
  color: #fff; }

.create-direct-modal_flat-white-bg__kOz8Y {
  background-color: #fff;
  color: #014660; }

.create-direct-modal_pastel-red-bg__35QOu {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.create-direct-modal_wrapper__3AAS_ {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
  width: 100%; }

.create-direct-modal_left__3jJkK {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  padding: 10px 100px 0; }
  @media (max-width: 1024px) {
    .create-direct-modal_left__3jJkK {
      padding-left: 20px; } }

.create-direct-modal_top-block__3rKSZ {
  border-bottom: 1px solid rgba(85, 85, 111, 0.2);
  display: block;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky; }
  .create-direct-modal_top-block__3rKSZ .create-direct-modal_create__2avs0 {
    border-radius: 20px; }
  @media (max-width: 1024px) {
    .create-direct-modal_top-block__3rKSZ {
      padding: 10px; }
      .create-direct-modal_top-block__3rKSZ .create-direct-modal_create__2avs0 {
        display: none; } }

.create-direct-modal_search-wrapper__Hng0- {
  display: flex; }
  @media (max-width: 1024px) {
    .create-direct-modal_search-wrapper__Hng0- {
      margin: 0; } }

@media (min-width: 1025px) {
  .create-direct-modal_create__2avs0 {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 16px;
    height: 40px;
    margin-left: 10px;
    padding: 0 10px; }
    .create-direct-modal_create__2avs0:hover:not(:disabled) {
      background-color: #00212e; }
    .create-direct-modal_create__2avs0:disabled {
      opacity: 0.5; } }

@media (max-width: 1024px) {
  .create-direct-modal_create__2avs0 {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #fff;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 14px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 50px; }
    .create-direct-modal_create__2avs0:hover:not(:disabled) {
      background-color: #e6e6e6; }
    .create-direct-modal_create__2avs0:disabled {
      opacity: 0.5; } }

.create-direct-modal_list-wrapper__1OWMQ {
  display: block;
  margin: 0 80px;
  overflow-y: auto;
  padding-right: 10px; }
  @media (max-width: 1024px) {
    .create-direct-modal_list-wrapper__1OWMQ {
      margin: 0; } }

.create-direct-modal_avatars__2TBsx {
  display: flex;
  margin-right: 20px;
  max-width: 75px;
  position: relative; }
  .create-direct-modal_avatars__2TBsx .create-direct-modal_avatar__11SV_:nth-child(2) {
    margin-left: -20px;
    margin-top: 9px; }
  .create-direct-modal_avatars__2TBsx .create-direct-modal_status__HUJAm {
    background-color: #fff;
    border-radius: 100%;
    bottom: 0;
    left: 30px;
    position: absolute; }

.create-direct-modal_add-symbol__MQBHB {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding-left: 10px; }
  .create-direct-modal_add-symbol__MQBHB svg {
    height: 25px;
    opacity: 0.3;
    width: 25px; }

.create-direct-modal_user-card__juzzh {
  color: #014660;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  border-bottom: 1px solid rgba(85, 85, 111, 0.2);
  cursor: pointer;
  display: flex;
  padding: 10px; }
  .create-direct-modal_user-card__juzzh .create-direct-modal_info__3cmZT {
    display: flex;
    flex-direction: column; }
  .create-direct-modal_user-card__juzzh .create-direct-modal_position__3xDz- {
    color: #55556f;
    font-size: 11px;
    margin-top: 5px; }

.create-direct-modal_user-card__juzzh:hover {
  background-color: #f7f7f7; }
  .create-direct-modal_user-card__juzzh:hover .create-direct-modal_add-symbol__MQBHB svg {
    opacity: 1; }

.chats-list-modal_modal__3q8Dg {
  padding: 10px 15px; }
  @media (min-width: 1025px) {
    .chats-list-modal_modal__3q8Dg {
      margin: 0 auto;
      max-width: 800px;
      padding: 20px; } }

.profile-modal_white__ELTkK {
  color: #f9f9f9; }

.profile-modal_black__3YILY {
  color: #000; }

.profile-modal_deep-teal__3swUm {
  color: #014660; }

.profile-modal_greyish-teal__zgU6n {
  color: #5bdbbd; }

.profile-modal_very-light-grey__1E0e8 {
  color: #b9b9b9; }

.profile-modal_warm-grey__2_pOY {
  color: #55556f; }

.profile-modal_simple-grey__2gefZ {
  color: #d4d4d8; }

.profile-modal_dark-grey__3HooK {
  color: #222026; }

.profile-modal_flat-white__3sNU1 {
  color: #fff; }

.profile-modal_pastel-red__1Fwi2 {
  color: #e55e50; }

.profile-modal_white-bg__1D9GF {
  background-color: #f9f9f9; }

.profile-modal_deep-teal-bg__3qJWM {
  background-color: #014660;
  color: #fff; }

.profile-modal_greyish-teal-bg__aASPc {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-modal_very-light-grey-bg__2UDfs {
  background-color: #b9b9b9;
  color: #222026; }

.profile-modal_warm-grey-bg__3gH5c {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-modal_simple-grey-bg__37jlN {
  background-color: #d4d4d8;
  color: #222026; }

.profile-modal_dark-grey-bg__3VGRp {
  background-color: #222026;
  color: #fff; }

.profile-modal_flat-white-bg__1yHel {
  background-color: #fff;
  color: #014660; }

.profile-modal_pastel-red-bg__3HCGu {
  background-color: #e55e50;
  color: #f9f9f9; }

.profile-modal_logout__1eQ_E, .profile-modal_delete_message__23tcT {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 20px;
  z-index: 6; }

.profile-editing-modal_white__3H5CF {
  color: #f9f9f9; }

.profile-editing-modal_black__3lTzh {
  color: #000; }

.profile-editing-modal_deep-teal__33_fo {
  color: #014660; }

.profile-editing-modal_greyish-teal__1fy3W {
  color: #5bdbbd; }

.profile-editing-modal_very-light-grey__ld_Bb {
  color: #b9b9b9; }

.profile-editing-modal_warm-grey__3SIZE {
  color: #55556f; }

.profile-editing-modal_simple-grey__1EPzZ {
  color: #d4d4d8; }

.profile-editing-modal_dark-grey__4l6Aa {
  color: #222026; }

.profile-editing-modal_flat-white__26KC6 {
  color: #fff; }

.profile-editing-modal_pastel-red__1oh90 {
  color: #e55e50; }

.profile-editing-modal_white-bg__1VG_5 {
  background-color: #f9f9f9; }

.profile-editing-modal_deep-teal-bg__2RnLP {
  background-color: #014660;
  color: #fff; }

.profile-editing-modal_greyish-teal-bg__Ir726 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-editing-modal_very-light-grey-bg__tiNfz {
  background-color: #b9b9b9;
  color: #222026; }

.profile-editing-modal_warm-grey-bg__1mIe- {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-editing-modal_simple-grey-bg__2DOyj {
  background-color: #d4d4d8;
  color: #222026; }

.profile-editing-modal_dark-grey-bg__1f7Hg {
  background-color: #222026;
  color: #fff; }

.profile-editing-modal_flat-white-bg__2aBEz {
  background-color: #fff;
  color: #014660; }

.profile-editing-modal_pastel-red-bg__1KYkc {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-editing-modal_dialog-wrapper__Kx43b {
  min-width: 550px; }
  @media (max-width: 1024px) {
    .profile-editing-modal_dialog-wrapper__Kx43b {
      min-width: 60vw; } }

.profile-editing-modal_inputs__1XsUG {
  padding: 10px 0; }
  .profile-editing-modal_inputs__1XsUG [class*="field"] {
    padding-bottom: 30px; }
    .profile-editing-modal_inputs__1XsUG [class*="field"] input {
      height: 40px;
      width: 100%; }
  .profile-editing-modal_inputs__1XsUG [class*="validated-select_select"] > button {
    min-height: 28px; }
  .profile-editing-modal_inputs__1XsUG [class*="box"] > svg {
    height: 28px;
    width: 28px; }
  .profile-editing-modal_inputs__1XsUG [class*="clear"] svg {
    height: 28px;
    width: 28px; }
  .profile-editing-modal_inputs__1XsUG label {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .profile-editing-modal_inputs__1XsUG .profile-editing-modal_checkbox__lcgc- {
    align-items: center;
    display: flex; }
    .profile-editing-modal_inputs__1XsUG .profile-editing-modal_checkbox__lcgc- span {
      font-size: 12px; }
    .profile-editing-modal_inputs__1XsUG .profile-editing-modal_checkbox__lcgc- input {
      display: none; }
    .profile-editing-modal_inputs__1XsUG .profile-editing-modal_checkbox__lcgc- svg {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 24px;
      margin-right: 12px;
      width: 24px; }

.datepicker_white__EYepm {
  color: #f9f9f9; }

.datepicker_black__2cu9f {
  color: #000; }

.datepicker_deep-teal__2jf7i {
  color: #014660; }

.datepicker_greyish-teal__KclDT {
  color: #5bdbbd; }

.datepicker_very-light-grey__3WB_S {
  color: #b9b9b9; }

.datepicker_warm-grey__tyF-k {
  color: #55556f; }

.datepicker_simple-grey__15Llj {
  color: #d4d4d8; }

.datepicker_dark-grey__1rv35 {
  color: #222026; }

.datepicker_flat-white__bycdK {
  color: #fff; }

.datepicker_pastel-red__3F5wT {
  color: #e55e50; }

.datepicker_white-bg__w51G6 {
  background-color: #f9f9f9; }

.datepicker_deep-teal-bg__qW5Tn {
  background-color: #014660;
  color: #fff; }

.datepicker_greyish-teal-bg__20jq5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.datepicker_very-light-grey-bg__EEdSv {
  background-color: #b9b9b9;
  color: #222026; }

.datepicker_warm-grey-bg__1AVVN {
  background-color: #55556f;
  color: #f9f9f9; }

.datepicker_simple-grey-bg__1eSY_ {
  background-color: #d4d4d8;
  color: #222026; }

.datepicker_dark-grey-bg__l47j5 {
  background-color: #222026;
  color: #fff; }

.datepicker_flat-white-bg__1StX7 {
  background-color: #fff;
  color: #014660; }

.datepicker_pastel-red-bg__1HTBy {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.datepicker_calendar_header__33Gs3 {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  width: 100%; }
  .datepicker_calendar_header__33Gs3 button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    padding: 0 20px; }
  .datepicker_calendar_header__33Gs3 button:disabled {
    opacity: 0.3; }
  .datepicker_calendar_header__33Gs3 span {
    min-width: 150px;
    text-align: center; }
  .datepicker_calendar_header__33Gs3 svg {
    height: 20px;
    width: 20px; }

.datepicker_days__1pH4S {
  display: flex;
  flex-wrap: wrap; }

.datepicker_day__12vf_,
.datepicker_weekday__2gJTM {
  display: flex;
  justify-content: center;
  min-width: 30px;
  width: 14.28%; }
  .datepicker_day__12vf_ button,
  .datepicker_weekday__2gJTM button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    border-radius: 50%;
    margin-bottom: 5px;
    min-height: 30px;
    min-width: 30px; }
  .datepicker_day__12vf_ button:disabled:not(.datepicker_current__mK15s),
  .datepicker_weekday__2gJTM button:disabled:not(.datepicker_current__mK15s) {
    color: #222026;
    cursor: not-allowed;
    opacity: 0.2; }
  .datepicker_day__12vf_ .datepicker_not_current_month__3lj6g:not(.datepicker_current__mK15s),
  .datepicker_weekday__2gJTM .datepicker_not_current_month__3lj6g:not(.datepicker_current__mK15s) {
    visibility: hidden; }
  .datepicker_day__12vf_ .datepicker_current__mK15s,
  .datepicker_weekday__2gJTM .datepicker_current__mK15s {
    border: 2px solid #5bdbbd; }
  .datepicker_day__12vf_ .datepicker_current_selected__3ejwR,
  .datepicker_weekday__2gJTM .datepicker_current_selected__3ejwR {
    background-color: #5bdbbd;
    color: #fff; }
  .datepicker_day__12vf_ .datepicker_selected__2mWyL,
  .datepicker_weekday__2gJTM .datepicker_selected__2mWyL {
    background-color: #222026;
    color: #fff; }
  .datepicker_day__12vf_ .datepicker_highlighted__2yNDD,
  .datepicker_weekday__2gJTM .datepicker_highlighted__2yNDD {
    font-weight: bold; }
  .datepicker_day__12vf_ .datepicker_weekend__2i3wa:not(.datepicker_selected__2mWyL),
  .datepicker_weekday__2gJTM .datepicker_weekend__2i3wa:not(.datepicker_selected__2mWyL) {
    color: #55556f; }

.datepicker_weekday__2gJTM {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 20pxpx;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "Questrial", sans-serif;
  font-weight: 600;
  margin-bottom: 1.2vw; }

.calendar_white__1qHNU {
  color: #f9f9f9; }

.calendar_black__1AusB {
  color: #000; }

.calendar_deep-teal__1b714 {
  color: #014660; }

.calendar_greyish-teal__13zq7 {
  color: #5bdbbd; }

.calendar_very-light-grey__1z_lz {
  color: #b9b9b9; }

.calendar_warm-grey__17M1C {
  color: #55556f; }

.calendar_simple-grey__i525Q {
  color: #d4d4d8; }

.calendar_dark-grey__2nRcJ {
  color: #222026; }

.calendar_flat-white__Lycw- {
  color: #fff; }

.calendar_pastel-red__2h1v1 {
  color: #e55e50; }

.calendar_white-bg__3cGwC {
  background-color: #f9f9f9; }

.calendar_deep-teal-bg__1xNVa {
  background-color: #014660;
  color: #fff; }

.calendar_greyish-teal-bg__2sRTj {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.calendar_very-light-grey-bg__3i2SP {
  background-color: #b9b9b9;
  color: #222026; }

.calendar_warm-grey-bg__ce_dc {
  background-color: #55556f;
  color: #f9f9f9; }

.calendar_simple-grey-bg__IMzT9 {
  background-color: #d4d4d8;
  color: #222026; }

.calendar_dark-grey-bg__2Y_uu {
  background-color: #222026;
  color: #fff; }

.calendar_flat-white-bg__209PU {
  background-color: #fff;
  color: #014660; }

.calendar_pastel-red-bg__19Fzo {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.calendar_wrapper__1az7X {
  background-color: rgba(249, 249, 249, 0.5);
  border-right: 1px solid #b9b9b9;
  height: 100%; }
  .calendar_wrapper__1az7X h1 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 20px; }

.chat-flow_white__I5CWf {
  color: #f9f9f9; }

.chat-flow_black__QP_2I {
  color: #000; }

.chat-flow_deep-teal__sf7CV {
  color: #014660; }

.chat-flow_greyish-teal__2IKt2 {
  color: #5bdbbd; }

.chat-flow_very-light-grey__3r2iM {
  color: #b9b9b9; }

.chat-flow_warm-grey__2sRSA {
  color: #55556f; }

.chat-flow_simple-grey__31wu3 {
  color: #d4d4d8; }

.chat-flow_dark-grey__1jBm5 {
  color: #222026; }

.chat-flow_flat-white__wBtbd {
  color: #fff; }

.chat-flow_pastel-red__3FPo1 {
  color: #e55e50; }

.chat-flow_white-bg__2Pc32 {
  background-color: #f9f9f9; }

.chat-flow_deep-teal-bg__3JpVb {
  background-color: #014660;
  color: #fff; }

.chat-flow_greyish-teal-bg__3MWvF {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.chat-flow_very-light-grey-bg__3NODK {
  background-color: #b9b9b9;
  color: #222026; }

.chat-flow_warm-grey-bg__TXP4n {
  background-color: #55556f;
  color: #f9f9f9; }

.chat-flow_simple-grey-bg__2auY7 {
  background-color: #d4d4d8;
  color: #222026; }

.chat-flow_dark-grey-bg__1mJ0o {
  background-color: #222026;
  color: #fff; }

.chat-flow_flat-white-bg__1rF8G {
  background-color: #fff;
  color: #014660; }

.chat-flow_pastel-red-bg__330su {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.chat-flow_wrapper__lKM7V {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }
  @media (min-width: 1025px) {
    .chat-flow_wrapper__lKM7V {
      border-right: 1px solid #b9b9b9; } }
  @media (max-width: 1024px) {
    @supports (-webkit-overflow-scrolling: touch) {
      .chat-flow_wrapper__lKM7V {
        height: calc(100% - 120px); } } }
  .chat-flow_wrapper__lKM7V h1,
  .chat-flow_wrapper__lKM7V h2,
  .chat-flow_wrapper__lKM7V p {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 20px 20px 0; }
  .chat-flow_wrapper__lKM7V p {
    font-size: 16px; }

.chat-flow_loading__1cTp3 {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }

.chat-flow_header__3cL0t {
  align-items: center;
  border-bottom: 1px solid #b9b9b9;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px; }
  .chat-flow_header__3cL0t button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    display: flex; }
  .chat-flow_header__3cL0t svg {
    height: 15px; }
  .chat-flow_header__3cL0t svg:last-child {
    margin-left: 5px; }
  .chat-flow_header__3cL0t svg:first-child {
    margin-right: 5px; }
  .chat-flow_header__3cL0t button:disabled {
    opacity: 0.5; }

.chat-flow_status__1Q_En {
  display: flex; }
  .chat-flow_status__1Q_En span {
    background-color: #d4d4d8;
    flex: 1 1;
    height: 3px; }
  .chat-flow_status__1Q_En span {
    content: none; }
  .chat-flow_status__1Q_En span:not(:last-of-type) {
    margin-right: 2px; }

.chat-flow_details_bar__ayGeJ > span:nth-of-type(1),
.chat-flow_tags_bar__1yZSn > span:nth-of-type(2),
.chat-flow_members_bar__23AnN > span:nth-of-type(3) {
  background-color: rgba(1, 70, 96, 0.7); }

.chat-flow_tags_bar__1yZSn > span:nth-of-type(1),
.chat-flow_members_bar__23AnN > span:nth-of-type(1),
.chat-flow_members_bar__23AnN > span:nth-of-type(2) {
  background-color: #014660; }

.chat-flow_screen__24KIz {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%; }

/** Details screen additional styles */
.chat-flow_switch__3kcJW {
  display: inline-block;
  height: 23px;
  position: relative;
  width: 40px; }
  .chat-flow_switch__3kcJW input {
    height: 0;
    opacity: 0;
    width: 0; }
  .chat-flow_switch__3kcJW .chat-flow_slider__22Ax0 {
    background-color: #ccc;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .chat-flow_switch__3kcJW .chat-flow_slider__22Ax0::before {
    background-color: #fff;
    border-radius: 50%;
    bottom: 2px;
    content: "";
    height: 19px;
    left: 2px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 19px; }
  .chat-flow_switch__3kcJW input:checked + .chat-flow_slider__22Ax0 {
    background-color: #5bdbbd; }
  .chat-flow_switch__3kcJW input:checked + .chat-flow_slider__22Ax0::before {
    -webkit-transform: translateX(16px);
            transform: translateX(16px); }

.chat-flow_access__2XzLn {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 20px; }
  .chat-flow_access__2XzLn h3 {
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px; }
  .chat-flow_access__2XzLn p {
    color: #222026;
    padding: 0; }
  .chat-flow_access__2XzLn div {
    display: flex;
    justify-content: space-between; }

/** Details screen additional styles end*/
/** Search */
.chat-flow_search__2FbVk {
  background-color: rgba(212, 212, 216, 0.4);
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  padding: 30px 20px; }
  .chat-flow_search__2FbVk label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .chat-flow_search__2FbVk input, .chat-flow_search__2FbVk textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .chat-flow_search__2FbVk input {
    height: 35px; }
  .chat-flow_search__2FbVk textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .chat-flow_search__2FbVk.chat-flow_valid__3BaZB input, .chat-flow_search__2FbVk.chat-flow_valid__3BaZB textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .chat-flow_search__2FbVk.chat-flow_invalid__2AAzq input, .chat-flow_search__2FbVk.chat-flow_invalid__2AAzq textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }

.chat-flow_box__2YuXm {
  position: relative;
  width: 100%; }
  .chat-flow_box__2YuXm input {
    padding: 0 35px 0 10px;
    width: 100%; }
  .chat-flow_box__2YuXm input:focus {
    box-shadow: 0 0 5px rgba(91, 219, 189, 0.7); }

.chat-flow_clear__2hMq6 {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  position: absolute;
  right: 10px;
  top: 8px; }
  .chat-flow_clear__2hMq6 svg {
    height: 20px;
    width: 20px; }

/** Search end */
/** Tags results */
.chat-flow_results__DeUVA {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: auto;
  padding: 20px 14px 20px 20px; }

.chat-flow_tag__2PZc1 {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px; }
  .chat-flow_tag__2PZc1 button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    margin-right: 10px;
    padding: 3px 15px; }
    .chat-flow_tag__2PZc1 button:hover:not(:disabled) {
      background-color: #00212e; }
    .chat-flow_tag__2PZc1 button:disabled {
      opacity: 0.5; }

.chat-flow_no_results__2ZhtN {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
  text-align: center; }
  .chat-flow_no_results__2ZhtN svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px; }

/** Tags results end */
.chat-flow_members__1o8gR {
  display: flex;
  flex: 1 1;
  height: 100%;
  overflow-y: auto;
  padding: 5px; }

.chat-flow_member__14HjH {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 15px 20px; }
  .chat-flow_member__14HjH [class*="avatar"] {
    margin-top: 10px; }
  .chat-flow_member__14HjH [class*="checkbox"] {
    align-self: center; }
  .chat-flow_member__14HjH .chat-flow_details__lWl-x {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 10px; }
    .chat-flow_member__14HjH .chat-flow_details__lWl-x .chat-flow_name__1nirK {
      color: #014660;
      font-size: 18px;
      margin-bottom: 10px; }
    .chat-flow_member__14HjH .chat-flow_details__lWl-x .chat-flow_sub_info__MgfbS p {
      color: #55556f;
      font-family: OpenSans, sans-serif;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 0 0 5px; }

.chat-flow_participants-wrapper__2KZRZ {
  background-color: rgba(91, 219, 189, 0.2);
  position: relative; }

.chat-flow_participants-list-wrapper__1iiC6 {
  overflow-x: hidden;
  padding: 5px 20px; }
  @media (max-width: 1024px) {
    .chat-flow_participants-list-wrapper__1iiC6 {
      overflow-x: auto; }
      .chat-flow_participants-list-wrapper__1iiC6::-webkit-scrollbar {
        height: 0;
        min-height: 0; } }

.chat-flow_participant__d2AeD {
  color: #55556f;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 15px;
  margin-top: 5px;
  position: relative;
  text-align: center;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content; }
  @media (min-width: 1025px) {
    .chat-flow_participant__d2AeD {
      margin-right: 16px; } }
  .chat-flow_participant__d2AeD span {
    font-family: "OpenSans", sans-serif;
    font-size: 12px;
    margin-top: 5px; }
  .chat-flow_participant__d2AeD svg {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 17px; }

.chat-flow_participants__2SbIc {
  display: flex;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  -webkit-transform: translate(var(--offset), 0);
          transform: translate(var(--offset), 0);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  will-change: transform; }

.chat-flow_chevron_left__2ly97, .chat-flow_chevron_right__r9yGC {
  align-items: center;
  background-color: #f2f2f2;
  bottom: 0;
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 20px;
  z-index: 1; }
  @media (max-width: 1024px) {
    .chat-flow_chevron_left__2ly97, .chat-flow_chevron_right__r9yGC {
      display: none; } }
  .chat-flow_chevron_left__2ly97 svg, .chat-flow_chevron_right__r9yGC svg {
    height: 20px;
    width: 20px; }

.chat-flow_chevron_left__2ly97 {
  left: 0; }
  .chat-flow_chevron_left__2ly97 svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.chat-flow_chevron_right__r9yGC {
  right: 0; }
  .chat-flow_chevron_right__r9yGC svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.error_error__3rGfH {
  align-items: center;
  background-image: url(/601d36c719a7e711c4f3ed3a8ff116dd.svg);
  background-position: center;
  background-size: cover;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10; }
  .error_error__3rGfH .error_title__3BGwL {
    font-size: 40px;
    margin-top: 13vh;
    max-width: 90%; }
  .error_error__3rGfH .error_suggestion__hxJKj {
    font-size: 18px;
    margin-top: 7vh;
    max-width: 90%;
    text-align: center; }
    .error_error__3rGfH .error_suggestion__hxJKj a {
      color: #fff;
      text-decoration: underline; }
  @media (max-width: 600px) {
    .error_error__3rGfH {
      background-image: url(/e9dca6a8d12e874750b2de2a4f711c9c.svg); }
      .error_error__3rGfH .error_title__3BGwL {
        margin-top: 5vh; }
      .error_error__3rGfH .error_suggestion__hxJKj {
        margin-top: 3vh; } }

.chats_white__2-Kyn {
  color: #f9f9f9; }

.chats_black__105ek {
  color: #000; }

.chats_deep-teal__2pFXf {
  color: #014660; }

.chats_greyish-teal__1gSC0 {
  color: #5bdbbd; }

.chats_very-light-grey__34-HF {
  color: #b9b9b9; }

.chats_warm-grey__2BXzl {
  color: #55556f; }

.chats_simple-grey__3IMHR {
  color: #d4d4d8; }

.chats_dark-grey__14PTm {
  color: #222026; }

.chats_flat-white__gJS7v {
  color: #fff; }

.chats_pastel-red__2cRKJ {
  color: #e55e50; }

.chats_white-bg__1qLog {
  background-color: #f9f9f9; }

.chats_deep-teal-bg__3Uxgf {
  background-color: #014660;
  color: #fff; }

.chats_greyish-teal-bg__2yrUP {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.chats_very-light-grey-bg__7kuIU {
  background-color: #b9b9b9;
  color: #222026; }

.chats_warm-grey-bg__35pd_ {
  background-color: #55556f;
  color: #f9f9f9; }

.chats_simple-grey-bg__1b9Kl {
  background-color: #d4d4d8;
  color: #222026; }

.chats_dark-grey-bg__u4aPD {
  background-color: #222026;
  color: #fff; }

.chats_flat-white-bg__rDhAA {
  background-color: #fff;
  color: #014660; }

.chats_pastel-red-bg__3eLiH {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.chats_wrapper__3cgOX {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px 20px 110px;
  position: relative;
  width: 100%; }
  @media (max-width: 1024px) {
    .chats_wrapper__3cgOX {
      margin-bottom: 60px; } }
  @media (min-width: 1025px) {
    .chats_wrapper__3cgOX {
      border-right: 1px solid #d4d4d8;
      height: 100%;
      padding-bottom: 20px;
      width: 320px; } }
  @supports (-webkit-overflow-scrolling: touch) {
    .chats_wrapper__3cgOX {
      padding-bottom: 130px; } }

.chats_direct_block__2oegN {
  margin-bottom: 20px;
  margin-top: 20px; }

.chats_title_wrapper__3wLHm {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; }

.chats_title__3XA_G {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  color: #014660;
  font-family: "Questrial", sans-serif;
  font-size: 20px;
  font-weight: normal; }
  .chats_title__3XA_G:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .chats_title__3XA_G:disabled {
    opacity: 0.5; }
  .chats_title__3XA_G:hover {
    color: #3f3f52; }

.chats_list__3LGto {
  display: flex;
  flex: 1 1;
  flex-direction: column; }
  .chats_list__3LGto a {
    align-items: center;
    border-radius: 18.5px;
    color: #014660;
    display: block;
    font-size: 14px;
    max-width: 320px;
    padding: 6px;
    position: relative; }
    .chats_list__3LGto a .chats_item-inner__1eafi {
      display: flex;
      flex-flow: row nowrap; }
    .chats_list__3LGto a:focus, .chats_list__3LGto a:active {
      box-shadow: none;
      outline: none;
      text-decoration: none; }
    .chats_list__3LGto a:hover {
      background-color: #eceaea; }
      .chats_list__3LGto a:hover.chats_active__Pm9qk {
        background-color: #5bdbbd; }
    @media (max-width: 1024px) {
      .chats_list__3LGto a {
        max-width: 100%; } }
  .chats_list__3LGto svg {
    height: 12px;
    position: relative;
    top: 1px;
    width: 12px; }

.chats_status__2r0ge {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  width: 15px; }

.chats_chat_name__2rS-J {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  flex: 1 1;
  overflow: hidden; }
  .chats_chat_name__2rS-J span {
    flex: 1 1;
    line-height: 1.4;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .chats_chat_name__2rS-J.chats_unread__3rp0I .chats_unread-marker__1M4hd {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }

.chats_active__Pm9qk {
  background-color: #5bdbbd;
  color: #014660;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  .chats_active__Pm9qk .chats_chat_name__2rS-J {
    color: #014660;
    font-weight: 600; }

.chats_unread-marker__1M4hd {
  align-items: center;
  background-color: #5bdbbd;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  font-size: 11px;
  height: 20px;
  justify-content: center;
  opacity: 0;
  padding: 0 7px;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s;
  transition: -webkit-transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s;
  transition: transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s;
  transition: transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s, -webkit-transform 500ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s; }

.chats_create_btn__23909 {
  background-color: transparent;
  border: 0;
  display: flex; }
  .chats_create_btn__23909 svg {
    height: 22px;
    width: 22px; }

.chats_unread__3rp0I {
  font-weight: bold; }

.subnav_white__1bQQ- {
  color: #f9f9f9; }

.subnav_black__2KUsz {
  color: #000; }

.subnav_deep-teal__1OlTS {
  color: #014660; }

.subnav_greyish-teal__3GH7B {
  color: #5bdbbd; }

.subnav_very-light-grey__1ZMVC {
  color: #b9b9b9; }

.subnav_warm-grey__192NN {
  color: #55556f; }

.subnav_simple-grey__3MinK {
  color: #d4d4d8; }

.subnav_dark-grey__3_BCv {
  color: #222026; }

.subnav_flat-white__xIxyP {
  color: #fff; }

.subnav_pastel-red__5fDn8 {
  color: #e55e50; }

.subnav_white-bg__3f-Nk {
  background-color: #f9f9f9; }

.subnav_deep-teal-bg__1hMTt {
  background-color: #014660;
  color: #fff; }

.subnav_greyish-teal-bg__ngSPX {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.subnav_very-light-grey-bg__2-IuO {
  background-color: #b9b9b9;
  color: #222026; }

.subnav_warm-grey-bg__VXvbm {
  background-color: #55556f;
  color: #f9f9f9; }

.subnav_simple-grey-bg__3Im-5 {
  background-color: #d4d4d8;
  color: #222026; }

.subnav_dark-grey-bg__2G1At {
  background-color: #222026;
  color: #fff; }

.subnav_flat-white-bg__2V52s {
  background-color: #fff;
  color: #014660; }

.subnav_pastel-red-bg__c6cDc {
  background-color: #e55e50;
  color: #f9f9f9; }

.subnav_white__1bQQ- {
  color: #f9f9f9; }

.subnav_black__2KUsz {
  color: #000; }

.subnav_deep-teal__1OlTS {
  color: #014660; }

.subnav_greyish-teal__3GH7B {
  color: #5bdbbd; }

.subnav_very-light-grey__1ZMVC {
  color: #b9b9b9; }

.subnav_warm-grey__192NN {
  color: #55556f; }

.subnav_simple-grey__3MinK {
  color: #d4d4d8; }

.subnav_dark-grey__3_BCv {
  color: #222026; }

.subnav_flat-white__xIxyP {
  color: #fff; }

.subnav_pastel-red__5fDn8 {
  color: #e55e50; }

.subnav_white-bg__3f-Nk {
  background-color: #f9f9f9; }

.subnav_deep-teal-bg__1hMTt {
  background-color: #014660;
  color: #fff; }

.subnav_greyish-teal-bg__ngSPX {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.subnav_very-light-grey-bg__2-IuO {
  background-color: #b9b9b9;
  color: #222026; }

.subnav_warm-grey-bg__VXvbm {
  background-color: #55556f;
  color: #f9f9f9; }

.subnav_simple-grey-bg__3Im-5 {
  background-color: #d4d4d8;
  color: #222026; }

.subnav_dark-grey-bg__2G1At {
  background-color: #222026;
  color: #fff; }

.subnav_flat-white-bg__2V52s {
  background-color: #fff;
  color: #014660; }

.subnav_pastel-red-bg__c6cDc {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.subnav_opened_wrapper__1v9j-,
.subnav_closed_wrapper__yxKE3 {
  display: flex; }
  .subnav_opened_wrapper__1v9j- button,
  .subnav_closed_wrapper__yxKE3 button {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 22px;
    display: flex;
    flex: 1 1;
    justify-content: center;
    margin: 0 10px;
    padding: 10px; }
  .subnav_opened_wrapper__1v9j- svg,
  .subnav_closed_wrapper__yxKE3 svg {
    height: 20px;
    width: 20px; }
  .subnav_opened_wrapper__1v9j- button.subnav_active__LGGav,
  .subnav_closed_wrapper__yxKE3 button.subnav_active__LGGav {
    background-color: #d4d4d8;
    color: #222026; }
  .subnav_opened_wrapper__1v9j- button.subnav_chat__2so2u,
  .subnav_closed_wrapper__yxKE3 button.subnav_chat__2so2u {
    display: none; }
    @media (max-width: 1024px) {
      .subnav_opened_wrapper__1v9j- button.subnav_chat__2so2u,
      .subnav_closed_wrapper__yxKE3 button.subnav_chat__2so2u {
        display: flex; } }

.subnav_opened_wrapper__1v9j- {
  align-items: center;
  border-bottom: 1px solid #b9b9b9;
  height: 60px;
  justify-content: space-around;
  min-height: 60px;
  width: 320px; }
  .subnav_opened_wrapper__1v9j- svg {
    margin-right: 10px; }
  .subnav_opened_wrapper__1v9j- button {
    font-size: 14px; }
  .subnav_opened_wrapper__1v9j- button > svg {
    height: 20px;
    width: 20px; }
  .subnav_opened_wrapper__1v9j- button.subnav_back__3fhNY {
    background-color: rgba(212, 212, 216, 0.3);
    height: 40px;
    margin-left: 10px;
    max-width: 40px;
    padding: 0; }
  .subnav_opened_wrapper__1v9j- button.subnav_back__3fhNY:hover {
    background-color: rgba(212, 212, 216, 0.5); }
  .subnav_opened_wrapper__1v9j- button.subnav_back__3fhNY > svg {
    margin-right: 0; }
  @media (max-width: 1024px) {
    .subnav_opened_wrapper__1v9j- {
      width: 100%; } }

.subnav_closed_wrapper__yxKE3 {
  flex-direction: column;
  height: 100%; }
  .subnav_closed_wrapper__yxKE3 button {
    border-radius: 50%;
    margin-top: 20px;
    max-height: 50px;
    width: 50px; }

.subnav_activity_module__2Agc2 {
  overflow: scroll;
  width: 320px; }
  @media (max-width: 1024px) {
    .subnav_activity_module__2Agc2 {
      width: 100%; } }

.GiphyModal_wrapper__OdH9Z {
  height: 600px;
  left: 0;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 100%;
  background: white;
  z-index: 999; }
  .GiphyModal_wrapper__OdH9Z .GiphyModal_img-wrapper__Ht81Q {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px; }
    .GiphyModal_wrapper__OdH9Z .GiphyModal_img-wrapper__Ht81Q .GiphyModal_image__1rXsE {
      cursor: pointer;
      flex: 1 1; }
  .GiphyModal_wrapper__OdH9Z .GiphyModal_input__26Dgk {
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    margin-bottom: 20px;
    padding: 10px 15px;
    width: 100%;
    background: #f2f2f2; }

.editor_white__3cZEM {
  color: #f9f9f9; }

.editor_black__2zCjn {
  color: #000; }

.editor_deep-teal__3EwW7 {
  color: #014660; }

.editor_greyish-teal__Pekx0 {
  color: #5bdbbd; }

.editor_very-light-grey__3VqpS {
  color: #b9b9b9; }

.editor_warm-grey__t4Am_ {
  color: #55556f; }

.editor_simple-grey__13ZuW {
  color: #d4d4d8; }

.editor_dark-grey__1tnSn {
  color: #222026; }

.editor_flat-white__gi4y4 {
  color: #fff; }

.editor_pastel-red__3eoEY {
  color: #e55e50; }

.editor_white-bg__1EBYb {
  background-color: #f9f9f9; }

.editor_deep-teal-bg__2qetU {
  background-color: #014660;
  color: #fff; }

.editor_greyish-teal-bg__1majY {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.editor_very-light-grey-bg__1OepR {
  background-color: #b9b9b9;
  color: #222026; }

.editor_warm-grey-bg__1dvkx {
  background-color: #55556f;
  color: #f9f9f9; }

.editor_simple-grey-bg__1deJQ {
  background-color: #d4d4d8;
  color: #222026; }

.editor_dark-grey-bg__8ef3V {
  background-color: #222026;
  color: #fff; }

.editor_flat-white-bg__3rvso {
  background-color: #fff;
  color: #014660; }

.editor_pastel-red-bg__2Pa7H {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
w-chat-input-module {
  background: #fff;
  padding: 0 1em 1em; }

.editor_editor-wrapper__cv9pZ {
  border: 1px solid #d4d4d8;
  border-radius: 20px;
  box-shadow: 0 10px 20px #f9f9f9;
  position: relative;
  width: 100%; }
  .editor_editor-wrapper__cv9pZ strong {
    font-weight: bold; }
  .editor_editor-wrapper__cv9pZ em {
    font-style: italic; }
  .editor_editor-wrapper__cv9pZ [class*="ql-editor"] {
    padding: 12px 30px 12px 15px; }
  .editor_editor-wrapper__cv9pZ [class*="ql-mention-list-container"] {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-height: 240px;
    overflow-y: auto;
    position: absolute;
    width: 100%; }
  .editor_editor-wrapper__cv9pZ [class*="ql-toolbar"] {
    z-index: 1;
    border-bottom: 1px solid #d4d4d8 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important; }
  @media (max-width: 1024px) {
    .editor_editor-wrapper__cv9pZ [class*="ql-toolbar"] {
      display: none; } }
  .editor_editor-wrapper__cv9pZ [id*="editor-container"] {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    border: 0 !important;
    flex: 1 1;
    width: 100%; }
    @media (max-width: 1024px) {
      .editor_editor-wrapper__cv9pZ [id*="editor-container"] {
        border-radius: 20px;
        min-height: 40px; } }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] {
    border-radius: 20px !important;
    min-width: 300px !important;
    top: -260px !important; }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] > [id*="tab-toolbar"] {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] > [id*="tab-toolbar"] > ul {
    display: flex;
    justify-content: space-between; }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] > [id*="tab-panel"] {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] > [id*="tab-panel"]::-webkit-scrollbar {
    max-width: 2px;
    width: 2px; }
  .editor_editor-wrapper__cv9pZ [id*="textarea-emoji"] > [id*="tab-panel"] > [class*="bem"] {
    margin: 10px; }

[class*="mention"] {
  color: #014660;
  cursor: pointer; }

.editor_mentions-menu__3OxTa {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%; }
  @media (max-width: 1024px) {
    .editor_mentions-menu__3OxTa {
      max-height: 120px; } }

.editor_mention-item__2WgET {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  height: 40px; }

.editor_highlight__2bsVj {
  background-color: #f2f2f2;
  color: #014660; }

.editor_avatar__lqRqz,
.editor_avatar__lqRqz > img {
  align-items: center;
  background-color: rgba(221, 167, 0, 0.3);
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0 10px;
  min-height: 30px;
  min-width: 30px;
  width: 30px; }

.editor_alias__1odHJ {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  width: 200px; }

.editor_mention_user__1kWZa {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #014660;
  flex: 1 1;
  font-weight: bold; }

.emoji_white__17uRB {
  color: #f9f9f9; }

.emoji_black__1esAu {
  color: #000; }

.emoji_deep-teal__3vFzk {
  color: #014660; }

.emoji_greyish-teal__35BXG {
  color: #5bdbbd; }

.emoji_very-light-grey__11V5O {
  color: #b9b9b9; }

.emoji_warm-grey__1WlU4 {
  color: #55556f; }

.emoji_simple-grey__hjHOo {
  color: #d4d4d8; }

.emoji_dark-grey__15m7c {
  color: #222026; }

.emoji_flat-white__2K_qa {
  color: #fff; }

.emoji_pastel-red__15GkC {
  color: #e55e50; }

.emoji_white-bg__HZ5eq {
  background-color: #f9f9f9; }

.emoji_deep-teal-bg__1jJZ0 {
  background-color: #014660;
  color: #fff; }

.emoji_greyish-teal-bg__2ya6T {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.emoji_very-light-grey-bg__1YWX9 {
  background-color: #b9b9b9;
  color: #222026; }

.emoji_warm-grey-bg__sWttr {
  background-color: #55556f;
  color: #f9f9f9; }

.emoji_simple-grey-bg__3r3bi {
  background-color: #d4d4d8;
  color: #222026; }

.emoji_dark-grey-bg__cm0zx {
  background-color: #222026;
  color: #fff; }

.emoji_flat-white-bg__3nn2p {
  background-color: #fff;
  color: #014660; }

.emoji_pastel-red-bg__mIP2E {
  background-color: #e55e50;
  color: #f9f9f9; }

.emoji_wrapper__efkmH {
  background-color: #fff;
  border: 1px solid rgba(85, 85, 111, 0.3);
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(34, 32, 38, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  width: 100%; }

.emoji_emoji-header__20AEc {
  border-bottom: 1px solid rgba(85, 85, 111, 0.3);
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px; }
  .emoji_emoji-header__20AEc button {
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    display: flex;
    flex: 1 1;
    height: 30px;
    padding: 0; }
  .emoji_emoji-header__20AEc button:hover {
    background-color: #f2f2f2; }
  .emoji_emoji-header__20AEc button.emoji_active-category__vuKRR {
    background-color: #e6e6e6; }
  .emoji_emoji-header__20AEc svg {
    fill: #014660;
    margin: auto;
    max-height: 30px;
    max-width: 30px; }

.emoji_emoji-body__2XfK4 {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  overflow-y: auto; }
  .emoji_emoji-body__2XfK4 span {
    cursor: pointer;
    margin: 5px; }
  .emoji_emoji-body__2XfK4 img {
    max-height: 28px;
    max-width: 28px; }
  .emoji_emoji-body__2XfK4 span:hover {
    background-color: #e6e6e6; }

.emoji_list__oKKxw {
  background-color: #fff;
  height: 300px;
  position: absolute;
  right: 30px;
  top: -300px;
  width: 300px; }

.emoji_emoji__1LwTY img {
  max-height: 20px;
  max-width: 20px; }

.emoji_textarea-emoji-control__1fzXr {
  height: 25px;
  position: absolute;
  right: 4px;
  top: 10px;
  width: 25px; }

.mentions_white__2oy-U {
  color: #f9f9f9; }

.mentions_black__252Ae {
  color: #000; }

.mentions_deep-teal__1Jsip {
  color: #014660; }

.mentions_greyish-teal__1anFR {
  color: #5bdbbd; }

.mentions_very-light-grey__2LEce {
  color: #b9b9b9; }

.mentions_warm-grey__50fej {
  color: #55556f; }

.mentions_simple-grey__2Tieg {
  color: #d4d4d8; }

.mentions_dark-grey__AWBfy {
  color: #222026; }

.mentions_flat-white__2bTSn {
  color: #fff; }

.mentions_pastel-red__30pTl {
  color: #e55e50; }

.mentions_white-bg__qrL-r {
  background-color: #f9f9f9; }

.mentions_deep-teal-bg__BoE9K {
  background-color: #014660;
  color: #fff; }

.mentions_greyish-teal-bg__1kHX4 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.mentions_very-light-grey-bg__24MCX {
  background-color: #b9b9b9;
  color: #222026; }

.mentions_warm-grey-bg__wFvti {
  background-color: #55556f;
  color: #f9f9f9; }

.mentions_simple-grey-bg__2_lGM {
  background-color: #d4d4d8;
  color: #222026; }

.mentions_dark-grey-bg__O2P2R {
  background-color: #222026;
  color: #fff; }

.mentions_flat-white-bg__13ZKh {
  background-color: #fff;
  color: #014660; }

.mentions_pastel-red-bg__Eiwut {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.mentions_scrollingContainer__33FqR {
  position: relative; }

.mentions_container__38MZo {
  background-color: #fff;
  bottom: 45px;
  height: 0;
  left: -1px;
  margin-bottom: 1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  right: -1px; }
  @media (max-width: 1024px) {
    .mentions_container__38MZo {
      left: 0;
      max-height: 150px;
      right: 0; } }

.mentions_show__2Jg5k {
  border: 1px solid #d4d4d8;
  border-radius: 20px 20px 0 0;
  height: auto; }

.mentions_hide__t6GbJ {
  height: 0; }

.mentions_list__1vl0s {
  width: 100%; }

.mentions_member__zFhwJ {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  padding: 5px 40% 5px 20px; }
  @media (max-width: 1024px) {
    .mentions_member__zFhwJ {
      padding-right: 20px; } }
  .mentions_member__zFhwJ:hover, .mentions_member__zFhwJ.mentions_selected__3rVx- {
    background-color: #f2f2f2; }
  .mentions_member__zFhwJ div {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (max-width: 1024px) {
      .mentions_member__zFhwJ div {
        left: 0;
        right: 0; } }
  .mentions_member__zFhwJ .mentions_alias__3t_6t {
    margin: 0 15px; }

.mentions_mention__3AzTc {
  color: #014660; }

.messages_white__MZKQM {
  color: #f9f9f9; }

.messages_black__3PduR {
  color: #000; }

.messages_deep-teal__3WIGI {
  color: #014660; }

.messages_greyish-teal__1tAb6 {
  color: #5bdbbd; }

.messages_very-light-grey__pa12h {
  color: #b9b9b9; }

.messages_warm-grey__1gqda {
  color: #55556f; }

.messages_simple-grey__3tR93 {
  color: #d4d4d8; }

.messages_dark-grey__fM0gE {
  color: #222026; }

.messages_flat-white__ZQIxc {
  color: #fff; }

.messages_pastel-red__3Q6ag {
  color: #e55e50; }

.messages_white-bg__2zCdQ {
  background-color: #f9f9f9; }

.messages_deep-teal-bg__MIgUQ {
  background-color: #014660;
  color: #fff; }

.messages_greyish-teal-bg__m_-5v {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.messages_very-light-grey-bg__1AjbA {
  background-color: #b9b9b9;
  color: #222026; }

.messages_warm-grey-bg__3zPXb {
  background-color: #55556f;
  color: #f9f9f9; }

.messages_simple-grey-bg__NXUAO {
  background-color: #d4d4d8;
  color: #222026; }

.messages_dark-grey-bg__2Xyts {
  background-color: #222026;
  color: #fff; }

.messages_flat-white-bg__1uBMp {
  background-color: #fff;
  color: #014660; }

.messages_pastel-red-bg__3VKpR {
  background-color: #e55e50;
  color: #f9f9f9; }

.messages_white__MZKQM {
  color: #f9f9f9; }

.messages_black__3PduR {
  color: #000; }

.messages_deep-teal__3WIGI {
  color: #014660; }

.messages_greyish-teal__1tAb6 {
  color: #5bdbbd; }

.messages_very-light-grey__pa12h {
  color: #b9b9b9; }

.messages_warm-grey__1gqda {
  color: #55556f; }

.messages_simple-grey__3tR93 {
  color: #d4d4d8; }

.messages_dark-grey__fM0gE {
  color: #222026; }

.messages_flat-white__ZQIxc {
  color: #fff; }

.messages_pastel-red__3Q6ag {
  color: #e55e50; }

.messages_white-bg__2zCdQ {
  background-color: #f9f9f9; }

.messages_deep-teal-bg__MIgUQ {
  background-color: #014660;
  color: #fff; }

.messages_greyish-teal-bg__m_-5v {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.messages_very-light-grey-bg__1AjbA {
  background-color: #b9b9b9;
  color: #222026; }

.messages_warm-grey-bg__3zPXb {
  background-color: #55556f;
  color: #f9f9f9; }

.messages_simple-grey-bg__NXUAO {
  background-color: #d4d4d8;
  color: #222026; }

.messages_dark-grey-bg__2Xyts {
  background-color: #222026;
  color: #fff; }

.messages_flat-white-bg__1uBMp {
  background-color: #fff;
  color: #014660; }

.messages_pastel-red-bg__3VKpR {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.messages_alerts-enter__pYHbG {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.messages_alerts-enter-active__1Hsfi {
  opacity: 1;
  -webkit-transform: scale(1) translate3d(0, 0, 0);
          transform: scale(1) translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.messages_alerts-exit__1ZrLl {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.messages_alerts-exit-active__Qw7St {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
.messages_slide-out-left__3kcSX {
  -webkit-animation: messages_slide-out-left__3kcSX 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: messages_slide-out-left__3kcSX 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes messages_slide-out-left__3kcSX {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes messages_slide-out-left__3kcSX {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.messages_slide-in-left__1Jskw {
  -webkit-animation: messages_slide-in-left__1Jskw 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: messages_slide-in-left__1Jskw 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes messages_slide-in-left__1Jskw {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

@keyframes messages_slide-in-left__1Jskw {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
.messages_wobble-hor-bottom__V0y3P {
  -webkit-animation: messages_wobble-hor-bottom__V0y3P 0.8s both;
          animation: messages_wobble-hor-bottom__V0y3P 0.8s both; }

@-webkit-keyframes messages_wobble-hor-bottom__V0y3P {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

@keyframes messages_wobble-hor-bottom__V0y3P {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

/**
 * ----------------------------------------
 * animation Show-Hide by changing opacity
 * ----------------------------------------
 */
@-webkit-keyframes messages_show__2-zfL {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes messages_show__2-zfL {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes messages_hide__3TCAD {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes messages_hide__3TCAD {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.messages_wrapper__14F4m {
  background: #fff;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .messages_wrapper__14F4m strong {
    font-weight: bold; }
  .messages_wrapper__14F4m em {
    font-style: italic; }
  .messages_wrapper__14F4m [class*="loader"] {
    left: 50%;
    position: absolute;
    top: 50%; }

.messages_group_wrapper__1wSe0 {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  width: 100%; }

.messages_day__1Xdoi {
  color: #55556f;
  font-size: 22px;
  margin: 10px 0 30px;
  text-align: center;
  width: 100%; }

.messages_group_item__1O4Vr {
  margin-bottom: 20px; }
  .messages_group_item__1O4Vr:last-of-type {
    margin-bottom: 0; }
  .messages_group_item__1O4Vr.messages_own_group_item__3W2n5 {
    display: flex;
    flex-direction: column;
    margin-left: auto; }

.messages_date_wrapper__3RgaQ {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1; }
  .messages_date_wrapper__3RgaQ .messages_date__25U1d {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background: #fff;
    border: 1px solid #e0dada;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
    padding: 6px 12px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.messages_fetch_wrapper__1fWMe {
  background: #eceaea;
  border-bottom: 1px solid #e0dada;
  display: flex; }
  .messages_fetch_wrapper__1fWMe .messages_fetch_button__1tFMF {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    background: none;
    border: 0;
    box-shadow: none;
    display: flex;
    flex: 1 1;
    font-weight: bold;
    justify-content: center;
    padding: 0.8em; }
    .messages_fetch_wrapper__1fWMe .messages_fetch_button__1tFMF svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .messages_fetch_wrapper__1fWMe .messages_fetching_message__x5x-U {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    display: flex;
    flex: 1 1;
    font-weight: bold;
    justify-content: center;
    margin: 0;
    padding: 0.8em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .messages_fetch_wrapper__1fWMe svg {
    height: 14px;
    margin-left: 8px;
    width: 14px; }

.messages_loader_wrapper__21sld {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  left: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .messages_loader_wrapper__21sld svg {
    align-self: flex-start;
    flex: 0 1 300px; }
    .messages_loader_wrapper__21sld svg:last-of-type {
      align-self: flex-end; }

.messages_wave__2rqW_ {
  align-items: center;
  background: #fff;
  display: flex;
  flex: 1 1;
  height: 100%;
  justify-content: center; }
  .messages_wave__2rqW_ div {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center; }
  .messages_wave__2rqW_ div > *:not(:last-child) {
    margin-bottom: 30px; }
    @media (max-width: 1024px) {
      .messages_wave__2rqW_ div > *:not(:last-child) {
        margin-bottom: 20px; } }
  .messages_wave__2rqW_ svg {
    height: 150px;
    width: 150px; }
    @media (max-width: 1024px) {
      .messages_wave__2rqW_ svg {
        height: 100px;
        width: 100px; } }
  .messages_wave__2rqW_ span {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    max-width: 300px; }

.messages_wave_button__1wen3 {
  color: #f9f9f9;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  padding: 8px 15px; }
  .messages_wave_button__1wen3:hover:not(:disabled) {
    background-color: #00212e; }
  .messages_wave_button__1wen3:disabled {
    opacity: 0.5; }

.messages_context-item__27fob:not(:disabled) {
  color: #014660; }

.messages_status__10VRx,
.messages_status_btn__2ESCS {
  align-items: center;
  color: #55556f;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  margin-right: 5px;
  margin-top: 7px; }

.messages_status_btn__2ESCS {
  background-color: transparent;
  border: 0;
  padding: 0; }
  .messages_status_btn__2ESCS span {
    align-self: flex-end; }
  .messages_status_btn__2ESCS:hover:not(:disabled) {
    color: #014660; }

.messages_multiply_status__1mIG6 {
  font-size: 10px; }

.messages_status_images_wrapper__34V60 {
  display: flex;
  margin-left: 5px; }
  .messages_status_images_wrapper__34V60 [class^="avatar"]:not(:first-child) {
    font-size: 100%;
    margin-left: -8px; }

.suggestions_white__2Zaq1 {
  color: #f9f9f9; }

.suggestions_black__3HeRN {
  color: #000; }

.suggestions_deep-teal__2JKxi {
  color: #014660; }

.suggestions_greyish-teal__2mSNC {
  color: #5bdbbd; }

.suggestions_very-light-grey__3AqNI {
  color: #b9b9b9; }

.suggestions_warm-grey__3a8ma {
  color: #55556f; }

.suggestions_simple-grey__2Pb8D {
  color: #d4d4d8; }

.suggestions_dark-grey__3p1sN {
  color: #222026; }

.suggestions_flat-white__1IXmg {
  color: #fff; }

.suggestions_pastel-red__j8JQb {
  color: #e55e50; }

.suggestions_white-bg__3szsg {
  background-color: #f9f9f9; }

.suggestions_deep-teal-bg__1bWK1 {
  background-color: #014660;
  color: #fff; }

.suggestions_greyish-teal-bg__2a1jl {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.suggestions_very-light-grey-bg__1MglW {
  background-color: #b9b9b9;
  color: #222026; }

.suggestions_warm-grey-bg__30Inx {
  background-color: #55556f;
  color: #f9f9f9; }

.suggestions_simple-grey-bg__2anGE {
  background-color: #d4d4d8;
  color: #222026; }

.suggestions_dark-grey-bg__awevw {
  background-color: #222026;
  color: #fff; }

.suggestions_flat-white-bg__3rT8F {
  background-color: #fff;
  color: #014660; }

.suggestions_pastel-red-bg__jCcOd {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.suggestions_wrapper__KjDSs {
  align-items: center;
  background: #fff;
  border-top: 1px solid #d4d4d8;
  display: flex;
  padding: 0.5em 1em; }
  .suggestions_wrapper__KjDSs a {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    margin-right: 5px;
    padding: 8px 15px; }
    .suggestions_wrapper__KjDSs a:hover:not(:disabled) {
      background-color: #00212e; }
    .suggestions_wrapper__KjDSs a:disabled {
      opacity: 0.5; }
  .suggestions_wrapper__KjDSs .suggestions_test_button__3Rd5T {
    background-color: #f8de88;
    border: #222026 1px solid;
    color: #222026; }

.Wrapper_white__3ce0l {
  color: #f9f9f9; }

.Wrapper_black__pVMGe {
  color: #000; }

.Wrapper_deep-teal__1eo6P {
  color: #014660; }

.Wrapper_greyish-teal__1Wolm {
  color: #5bdbbd; }

.Wrapper_very-light-grey__yUEGS {
  color: #b9b9b9; }

.Wrapper_warm-grey__1UPcQ {
  color: #55556f; }

.Wrapper_simple-grey__KNGY_ {
  color: #d4d4d8; }

.Wrapper_dark-grey__SPEc7 {
  color: #222026; }

.Wrapper_flat-white__2SnOk {
  color: #fff; }

.Wrapper_pastel-red__RVz-m {
  color: #e55e50; }

.Wrapper_white-bg__1xghL {
  background-color: #f9f9f9; }

.Wrapper_deep-teal-bg__lVf-d {
  background-color: #014660;
  color: #fff; }

.Wrapper_greyish-teal-bg__3UmId {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Wrapper_very-light-grey-bg__vgbQ3 {
  background-color: #b9b9b9;
  color: #222026; }

.Wrapper_warm-grey-bg__kZLDJ {
  background-color: #55556f;
  color: #f9f9f9; }

.Wrapper_simple-grey-bg__3Df_b {
  background-color: #d4d4d8;
  color: #222026; }

.Wrapper_dark-grey-bg__87l5X {
  background-color: #222026;
  color: #fff; }

.Wrapper_flat-white-bg__3WdkA {
  background-color: #fff;
  color: #014660; }

.Wrapper_pastel-red-bg__D3G8A {
  background-color: #e55e50;
  color: #f9f9f9; }

.Wrapper_wrapper__1akVE {
  display: flex;
  height: 100%;
  width: 100%; }

.Wrapper_nav__3UGtH {
  background-color: #f9f9f9;
  display: grid;
  grid-row-gap: 25px;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  padding: 30px 0 15px 15px;
  width: 320px; }
  .Wrapper_nav__3UGtH .Wrapper_text__HYDeu {
    color: #014660;
    cursor: pointer;
    font-family: "OpenSans", sans-serif;
    font-size: 16px; }

.Wrapper_back__1Nwzo {
  align-items: center;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 22px; }
  .Wrapper_back__1Nwzo svg {
    height: 22px;
    width: 30px; }
  .Wrapper_back__1Nwzo .Wrapper_text__HYDeu {
    margin-left: 10px; }

.Wrapper_container__1hS_7 {
  background-color: #f9f9f9;
  border-left: solid 1px #b9b9b9;
  border-right: solid 1px #b9b9b9;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  overflow: auto; }
  @media (max-width: 1024px) {
    .Wrapper_container__1hS_7 {
      padding-bottom: 50px; } }

.Wrapper_header__1GsuJ .Wrapper_oval_1__3WeaG, .Wrapper_header__1GsuJ .Wrapper_oval_2__1DZMK {
  background-color: #f8db90;
  border-radius: 100%;
  min-height: 500px;
  min-width: 500px;
  position: absolute;
  z-index: 1; }

.Wrapper_header__1GsuJ {
  align-items: center;
  background-color: rgba(221, 167, 0, 0.6);
  display: flex;
  justify-content: center;
  min-height: 120px;
  overflow: hidden;
  position: relative; }
  .Wrapper_header__1GsuJ * {
    z-index: 2; }
  .Wrapper_header__1GsuJ .Wrapper_oval_1__3WeaG {
    height: 50vw;
    right: 25%;
    top: -25vw;
    width: 50vw; }
    @media (max-width: 1024px) {
      .Wrapper_header__1GsuJ .Wrapper_oval_1__3WeaG {
        right: 20%;
        top: -250px; } }
  .Wrapper_header__1GsuJ .Wrapper_oval_2__1DZMK {
    height: 50vw;
    left: 55%;
    top: 20px;
    width: 50vw; }

.Wrapper_block__111Vj {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 10px 20px 15px;
  position: relative; }
  @media (min-width: 1025px) {
    .Wrapper_block__111Vj {
      min-height: 100%;
      width: 320px; } }
  .Wrapper_block__111Vj .Wrapper_card__3akYu {
    background-color: #fff;
    cursor: pointer;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 60px;
    padding: 20px 10px; }
    .Wrapper_block__111Vj .Wrapper_card__3akYu svg {
      height: 20px;
      width: 30px; }
  .Wrapper_block__111Vj a {
    color: #014660;
    font-family: "OpenSans", sans-serif; }
  .Wrapper_block__111Vj .Wrapper_links__3XMK8 {
    align-items: flex-end;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px; }

.FAQ_white__2HeQm {
  color: #f9f9f9; }

.FAQ_black__wmxs4 {
  color: #000; }

.FAQ_deep-teal__3kcQJ {
  color: #014660; }

.FAQ_greyish-teal__2Z0Ae {
  color: #5bdbbd; }

.FAQ_very-light-grey__wgYuH {
  color: #b9b9b9; }

.FAQ_warm-grey__KFac_ {
  color: #55556f; }

.FAQ_simple-grey__200IX {
  color: #d4d4d8; }

.FAQ_dark-grey__2Pyw1 {
  color: #222026; }

.FAQ_flat-white__2tiHV {
  color: #fff; }

.FAQ_pastel-red__1r3lJ {
  color: #e55e50; }

.FAQ_white-bg__3rd8p {
  background-color: #f9f9f9; }

.FAQ_deep-teal-bg__VawMd {
  background-color: #014660;
  color: #fff; }

.FAQ_greyish-teal-bg__2-f72 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.FAQ_very-light-grey-bg__2Aypj {
  background-color: #b9b9b9;
  color: #222026; }

.FAQ_warm-grey-bg__3LLGG {
  background-color: #55556f;
  color: #f9f9f9; }

.FAQ_simple-grey-bg__2D-BV {
  background-color: #d4d4d8;
  color: #222026; }

.FAQ_dark-grey-bg__1f_hv {
  background-color: #222026;
  color: #fff; }

.FAQ_flat-white-bg__kQyc2 {
  background-color: #fff;
  color: #014660; }

.FAQ_pastel-red-bg__31Ban {
  background-color: #e55e50;
  color: #f9f9f9; }

.FAQ_faq__6aOqL {
  padding: 25px 15px; }

.FAQ_questions_wrapper__bK87O {
  display: grid;
  grid-row-gap: 5px;
  grid-template-columns: 1fr; }

.FAQ_question__1XvsY {
  background-color: rgba(212, 212, 216, 0.2); }
  .FAQ_question_header__3y9cU {
    border: 0 !important;
    padding: 10px 15px; }
  .FAQ_question_title__3X-0_ {
    font-family: "OpenSans", sans-serif;
    font-size: 16px; }

.FAQ_text_container__1xAcc {
  color: #222026;
  font-family: "OpenSans", sans-serif;
  line-height: 20px;
  padding: 10px 30px 30px 15px; }

.Accordion_white__3pdV1 {
  color: #f9f9f9; }

.Accordion_black__3S60S {
  color: #000; }

.Accordion_deep-teal__PqYmi {
  color: #014660; }

.Accordion_greyish-teal__3mK_J {
  color: #5bdbbd; }

.Accordion_very-light-grey__1iYhO {
  color: #b9b9b9; }

.Accordion_warm-grey__3ZS5q {
  color: #55556f; }

.Accordion_simple-grey__1u4yz {
  color: #d4d4d8; }

.Accordion_dark-grey__NEUpJ {
  color: #222026; }

.Accordion_flat-white__wRfF_ {
  color: #fff; }

.Accordion_pastel-red__2LrEd {
  color: #e55e50; }

.Accordion_white-bg__2WT2k {
  background-color: #f9f9f9; }

.Accordion_deep-teal-bg__3NfjI {
  background-color: #014660;
  color: #fff; }

.Accordion_greyish-teal-bg__1nR0G {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Accordion_very-light-grey-bg__1mR8C {
  background-color: #b9b9b9;
  color: #222026; }

.Accordion_warm-grey-bg__2lexL {
  background-color: #55556f;
  color: #f9f9f9; }

.Accordion_simple-grey-bg__2JaEV {
  background-color: #d4d4d8;
  color: #222026; }

.Accordion_dark-grey-bg__3R7Ue {
  background-color: #222026;
  color: #fff; }

.Accordion_flat-white-bg__v3d09 {
  background-color: #fff;
  color: #014660; }

.Accordion_pastel-red-bg__1O8hz {
  background-color: #e55e50;
  color: #f9f9f9; }

.Accordion_underline__feKhQ {
  text-decoration: underline; }

.Accordion_header__1lR7v {
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 50px; }
  .Accordion_header__1lR7v .Accordion_icon__aa2Hw {
    height: 15px;
    width: 15px; }

.Accordion_title__3xIBG {
  line-height: 20px;
  max-width: 80%; }

.Accordion_collapsible__15P3l {
  overflow: hidden;
  position: relative; }
  .Accordion_collapsible__15P3l.Accordion_toggled__1_O33 {
    display: block; }

.TechnicalRequirements_white__186G6 {
  color: #f9f9f9; }

.TechnicalRequirements_black__1YTG9 {
  color: #000; }

.TechnicalRequirements_deep-teal__12zUS {
  color: #014660; }

.TechnicalRequirements_greyish-teal__bXzgP {
  color: #5bdbbd; }

.TechnicalRequirements_very-light-grey__3JmYd {
  color: #b9b9b9; }

.TechnicalRequirements_warm-grey__3i0ux {
  color: #55556f; }

.TechnicalRequirements_simple-grey__2o9aI {
  color: #d4d4d8; }

.TechnicalRequirements_dark-grey__2x2s1 {
  color: #222026; }

.TechnicalRequirements_flat-white__1j0a5 {
  color: #fff; }

.TechnicalRequirements_pastel-red__2NjAi {
  color: #e55e50; }

.TechnicalRequirements_white-bg__1j0FI {
  background-color: #f9f9f9; }

.TechnicalRequirements_deep-teal-bg__3yXPS {
  background-color: #014660;
  color: #fff; }

.TechnicalRequirements_greyish-teal-bg__1_epT {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.TechnicalRequirements_very-light-grey-bg__2lGTO {
  background-color: #b9b9b9;
  color: #222026; }

.TechnicalRequirements_warm-grey-bg__3uw9V {
  background-color: #55556f;
  color: #f9f9f9; }

.TechnicalRequirements_simple-grey-bg__2JOSz {
  background-color: #d4d4d8;
  color: #222026; }

.TechnicalRequirements_dark-grey-bg__T2DRm {
  background-color: #222026;
  color: #fff; }

.TechnicalRequirements_flat-white-bg__3ruL4 {
  background-color: #fff;
  color: #014660; }

.TechnicalRequirements_pastel-red-bg__6GTRw {
  background-color: #e55e50;
  color: #f9f9f9; }

.TechnicalRequirements_wrapper__rJHez {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  @media (max-width: 1024px) {
    .TechnicalRequirements_wrapper__rJHez {
      background-color: #f9f9f9;
      padding: 20px 15px; } }

.TechnicalRequirements_container__2A2wC {
  display: flex;
  flex-direction: column; }
  .TechnicalRequirements_container__2A2wC h2 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 19px;
    font-weight: 400;
    margin-top: 30px; }
  .TechnicalRequirements_container__2A2wC h3 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 17px;
    font-weight: 400;
    margin-top: 25px; }
  .TechnicalRequirements_container__2A2wC h4 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 15px;
    font-weight: 400; }
  .TechnicalRequirements_container__2A2wC h5 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 12px;
    font-weight: 400; }
  .TechnicalRequirements_container__2A2wC span {
    color: #222026;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 22px;
    margin-top: 15px; }

.TechnicalRequirements_block__QvQnD {
  display: grid;
  grid-template-columns: auto; }
  .TechnicalRequirements_block__QvQnD button {
    justify-self: end;
    margin-top: 20px;
    padding: 3px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }

.TechnicalRequirements_upgrade__3M6VF span {
  margin-top: 15px !important; }

.TechnicalRequirements_upgrade__3M6VF a {
  color: #014660; }

.TechnicalRequirements_card__1co8h {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  padding: 25px 15px;
  width: 100%; }
  .TechnicalRequirements_card__1co8h h3 {
    margin: 0; }
  .TechnicalRequirements_card__1co8h h4 {
    margin-top: 25px; }
  .TechnicalRequirements_card__1co8h h5 {
    margin-top: 15px; }
  .TechnicalRequirements_card__1co8h span {
    display: inline-block;
    margin-top: 8px; }

.Support_white__3RG4F {
  color: #f9f9f9; }

.Support_black__2WGFN {
  color: #000; }

.Support_deep-teal__me-xL {
  color: #014660; }

.Support_greyish-teal__1LeWW {
  color: #5bdbbd; }

.Support_very-light-grey__34YxW {
  color: #b9b9b9; }

.Support_warm-grey__3zjyG {
  color: #55556f; }

.Support_simple-grey__2lMSi {
  color: #d4d4d8; }

.Support_dark-grey__2tcb6 {
  color: #222026; }

.Support_flat-white__8iq2j {
  color: #fff; }

.Support_pastel-red__2N8H5 {
  color: #e55e50; }

.Support_white-bg__3Ru7J {
  background-color: #f9f9f9; }

.Support_deep-teal-bg__1up5y {
  background-color: #014660;
  color: #fff; }

.Support_greyish-teal-bg__3tAQC {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.Support_very-light-grey-bg__1e6KN {
  background-color: #b9b9b9;
  color: #222026; }

.Support_warm-grey-bg__3INAU {
  background-color: #55556f;
  color: #f9f9f9; }

.Support_simple-grey-bg__26GGA {
  background-color: #d4d4d8;
  color: #222026; }

.Support_dark-grey-bg__1BBXc {
  background-color: #222026;
  color: #fff; }

.Support_flat-white-bg__1gSgm {
  background-color: #fff;
  color: #014660; }

.Support_pastel-red-bg__aRz9J {
  background-color: #e55e50;
  color: #f9f9f9; }

.Support_wrapper__3nnFF {
  padding: 20px 10px; }

.Support_field__7lvws {
  background-color: rgba(212, 212, 216, 0.2);
  margin: 20px 0 15px;
  padding: 25px 10px 40px; }
  @media (min-width: 1025px) {
    .Support_field__7lvws {
      margin: 20px -25px 15px;
      padding: 25px 24px 40px; } }

.Support_button__3Fnbk {
  float: right;
  padding: 3px 15px; }

.modal_white__LB5DN {
  color: #f9f9f9; }

.modal_black__E5SCY {
  color: #000; }

.modal_deep-teal__3QCk2 {
  color: #014660; }

.modal_greyish-teal__3y5yC {
  color: #5bdbbd; }

.modal_very-light-grey__3iyQu {
  color: #b9b9b9; }

.modal_warm-grey__3VPLX {
  color: #55556f; }

.modal_simple-grey__28aGt {
  color: #d4d4d8; }

.modal_dark-grey__2v35i {
  color: #222026; }

.modal_flat-white__2vb2U {
  color: #fff; }

.modal_pastel-red__3AcBz {
  color: #e55e50; }

.modal_white-bg__fCuTB {
  background-color: #f9f9f9; }

.modal_deep-teal-bg__1Ps7l {
  background-color: #014660;
  color: #fff; }

.modal_greyish-teal-bg__3YcP7 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.modal_very-light-grey-bg__1Pyvp {
  background-color: #b9b9b9;
  color: #222026; }

.modal_warm-grey-bg__3Zg54 {
  background-color: #55556f;
  color: #f9f9f9; }

.modal_simple-grey-bg__2-Mwr {
  background-color: #d4d4d8;
  color: #222026; }

.modal_dark-grey-bg__3vF4b {
  background-color: #222026;
  color: #fff; }

.modal_flat-white-bg__1iyIf {
  background-color: #fff;
  color: #014660; }

.modal_pastel-red-bg__1gvX6 {
  background-color: #e55e50;
  color: #f9f9f9; }

.modal_wrapper__FfMhS {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.55);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  padding: 50px 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5; }
  .modal_wrapper__FfMhS.modal_goals__1UrlE {
    padding: 0; }

.modal_container__1dQwV {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  max-width: 600px;
  padding: 30px 15px;
  position: relative;
  z-index: 6; }
  .modal_container__1dQwV .modal_close__1G0Yu {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px; }
    .modal_container__1dQwV .modal_close__1G0Yu svg {
      height: 100%;
      width: 100%; }

.modal_share_goals__AEJFL {
  background-color: #f9f9f9;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas: "title close" "text text" "buttons buttons";
  grid-template-columns: auto 100px;
  padding: 20px;
  position: relative;
  width: 600px;
  z-index: 6; }
  @media (max-width: 1024px) {
    .modal_share_goals__AEJFL {
      align-self: flex-start;
      grid-template-areas: "close close" "title title" "text text" "buttons buttons" "links links";
      grid-template-columns: auto 60px;
      grid-template-rows: 30px 30px auto auto auto;
      min-height: 100%;
      width: 100%; } }
  .modal_share_goals__AEJFL .modal_links__4t_Z1 {
    align-items: center;
    display: none;
    font-size: 12px;
    grid-area: links;
    justify-content: space-around; }
    .modal_share_goals__AEJFL .modal_links__4t_Z1 a {
      color: #014660;
      font-family: "OpenSans", sans-serif; }
    .modal_share_goals__AEJFL .modal_links__4t_Z1 .modal_links__4t_Z1 {
      align-items: flex-end;
      bottom: 15px;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10px; }
    @media (max-width: 1024px) {
      .modal_share_goals__AEJFL .modal_links__4t_Z1 {
        display: flex; } }
  .modal_share_goals__AEJFL .modal_title__jBM-R {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    grid-area: title; }
    @media (max-width: 1024px) {
      .modal_share_goals__AEJFL .modal_title__jBM-R {
        display: none; } }
  .modal_share_goals__AEJFL .modal_close_btn__QX6o8 {
    cursor: pointer;
    grid-area: close;
    justify-self: end; }
    .modal_share_goals__AEJFL .modal_close_btn__QX6o8 svg {
      height: 22px;
      width: 22px; }
    @media (max-width: 1024px) {
      .modal_share_goals__AEJFL .modal_close_btn__QX6o8 {
        display: none; } }
  .modal_share_goals__AEJFL .modal_text__3V579 {
    display: flex;
    flex-direction: column;
    grid-area: text;
    justify-content: space-between; }
    .modal_share_goals__AEJFL .modal_text__3V579 span {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 21px; }
      @media (max-width: 1024px) {
        .modal_share_goals__AEJFL .modal_text__3V579 span {
          font-size: 15px; } }
  .modal_share_goals__AEJFL .modal_buttons_wrapper__39Esc {
    align-self: end;
    grid-area: buttons;
    justify-self: end; }
    .modal_share_goals__AEJFL .modal_buttons_wrapper__39Esc .modal_button__25Gbd {
      border-radius: 20px;
      height: 27px;
      margin-left: 10px;
      padding: 3px 0;
      width: 83px; }
  .modal_share_goals__AEJFL .modal_preloader__1cSGj {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .modal_share_goals__AEJFL .modal_mobile_header__2LYSI {
    color: #4cb49c;
    display: none;
    font-family: "Questrial", sans-serif;
    font-size: 25px;
    font-weight: 400;
    grid-area: title; }
    @media (max-width: 1024px) {
      .modal_share_goals__AEJFL .modal_mobile_header__2LYSI {
        display: flex; } }
  .modal_share_goals__AEJFL .modal_close_header__24IMM {
    align-items: center;
    background-color: #f9f9f9;
    border-bottom: 1px solid #b9b9b9;
    color: #014660;
    display: none;
    grid-area: close;
    height: 50px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1; }
    .modal_share_goals__AEJFL .modal_close_header__24IMM div {
      align-items: center; }
    .modal_share_goals__AEJFL .modal_close_header__24IMM span {
      margin-left: 10px; }
    .modal_share_goals__AEJFL .modal_close_header__24IMM svg {
      height: 20px;
      margin: 0 15px;
      width: 30px; }
    @media (max-width: 1024px) {
      .modal_share_goals__AEJFL .modal_close_header__24IMM {
        display: flex; } }

.modal_logout__3LzV8, .modal_delete_message__2lF2Y {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas: "title close" "text text" "buttons buttons";
  grid-template-columns: auto 100px;
  padding: 20px;
  position: relative;
  width: 416px;
  z-index: 6; }
  @media (max-width: 1024px) {
    .modal_logout__3LzV8, .modal_delete_message__2lF2Y {
      grid-template-columns: auto 60px;
      width: 96%; } }
  .modal_logout__3LzV8 .modal_title__jBM-R, .modal_delete_message__2lF2Y .modal_title__jBM-R {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    grid-area: title; }
    @media (max-width: 1024px) {
      .modal_logout__3LzV8 .modal_title__jBM-R, .modal_delete_message__2lF2Y .modal_title__jBM-R {
        font-size: 17px; } }
  .modal_logout__3LzV8 .modal_close_btn__QX6o8, .modal_delete_message__2lF2Y .modal_close_btn__QX6o8 {
    cursor: pointer;
    grid-area: close;
    justify-self: end; }
    .modal_logout__3LzV8 .modal_close_btn__QX6o8 svg, .modal_delete_message__2lF2Y .modal_close_btn__QX6o8 svg {
      height: 22px;
      width: 22px; }
  .modal_logout__3LzV8 .modal_text__3V579, .modal_delete_message__2lF2Y .modal_text__3V579 {
    display: flex;
    flex-direction: column;
    grid-area: text;
    justify-content: space-between; }
    .modal_logout__3LzV8 .modal_text__3V579 span, .modal_delete_message__2lF2Y .modal_text__3V579 span {
      color: #4d4d4d;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 21px; }
      @media (max-width: 1024px) {
        .modal_logout__3LzV8 .modal_text__3V579 span, .modal_delete_message__2lF2Y .modal_text__3V579 span {
          font-size: 15px; } }
  .modal_logout__3LzV8 .modal_buttons_wrapper__39Esc, .modal_delete_message__2lF2Y .modal_buttons_wrapper__39Esc {
    align-self: end;
    grid-area: buttons;
    justify-self: end; }
    .modal_logout__3LzV8 .modal_buttons_wrapper__39Esc .modal_button__25Gbd, .modal_delete_message__2lF2Y .modal_buttons_wrapper__39Esc .modal_button__25Gbd {
      border-radius: 20px;
      height: 27px;
      margin-left: 10px;
      padding: 3px 0;
      width: 83px; }
  .modal_logout__3LzV8 .modal_preloader__1cSGj, .modal_delete_message__2lF2Y .modal_preloader__1cSGj {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 2; }

.info-tab_white__2n4lb {
  color: #f9f9f9; }

.info-tab_black__W3yhX {
  color: #000; }

.info-tab_deep-teal__RezRr {
  color: #014660; }

.info-tab_greyish-teal__3cF7m {
  color: #5bdbbd; }

.info-tab_very-light-grey__3eVqz {
  color: #b9b9b9; }

.info-tab_warm-grey__38WNL {
  color: #55556f; }

.info-tab_simple-grey__3yPpz {
  color: #d4d4d8; }

.info-tab_dark-grey__1C7xP {
  color: #222026; }

.info-tab_flat-white__3nPte {
  color: #fff; }

.info-tab_pastel-red__3Jm7k {
  color: #e55e50; }

.info-tab_white-bg__2jFEV {
  background-color: #f9f9f9; }

.info-tab_deep-teal-bg__2qlFp {
  background-color: #014660;
  color: #fff; }

.info-tab_greyish-teal-bg__1NtTC {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.info-tab_very-light-grey-bg__35QzG {
  background-color: #b9b9b9;
  color: #222026; }

.info-tab_warm-grey-bg__1VH4- {
  background-color: #55556f;
  color: #f9f9f9; }

.info-tab_simple-grey-bg__3Xq0P {
  background-color: #d4d4d8;
  color: #222026; }

.info-tab_dark-grey-bg__cEsFl {
  background-color: #222026;
  color: #fff; }

.info-tab_flat-white-bg__1YiOw {
  background-color: #fff;
  color: #014660; }

.info-tab_pastel-red-bg__2BjiT {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.info-tab_user-info__3_s7U .info-tab_share-card__1pxVL, .info-tab_user-info__3_s7U .info-tab_collectives-card__p022r, .info-tab_user-info__3_s7U .info-tab_groups-card__1w84f {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px; }
  .info-tab_user-info__3_s7U .info-tab_share-card__1pxVL a, .info-tab_user-info__3_s7U .info-tab_collectives-card__p022r a, .info-tab_user-info__3_s7U .info-tab_groups-card__1w84f a {
    display: inline-block;
    text-decoration: underline; }
  .info-tab_user-info__3_s7U .info-tab_share-card__1pxVL w-button, .info-tab_user-info__3_s7U .info-tab_collectives-card__p022r w-button, .info-tab_user-info__3_s7U .info-tab_groups-card__1w84f w-button {
    margin-left: 10px; }

.info-tab_wrapper__IVmuM {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto; }
  .info-tab_wrapper__IVmuM h2 {
    color: #55556f;
    font-family: "Questrial", sans-serif; }
  .info-tab_wrapper__IVmuM h3 {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    margin-bottom: 10px; }
  .info-tab_wrapper__IVmuM h5 {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif; }
  @media (min-width: 1025px) {
    .info-tab_wrapper__IVmuM {
      max-width: 320px; } }

.info-tab_chat-info-header__2w2Me {
  display: flex;
  margin-bottom: 20px;
  min-height: 24px; }
  .info-tab_chat-info-header__2w2Me h2 {
    flex: 1 1;
    font-size: 20px; }

.info-tab_access__3o0ia {
  border: #5bdbbd 1px solid;
  border-radius: 5px;
  color: #5bdbbd;
  padding: 3px 15px;
  text-transform: capitalize; }

.info-tab_chat-info-body__3C8nj,
.info-tab_chat-info-body-center__1BdLY,
.info-tab_body-center__UVPHL {
  display: flex; }

.info-tab_chat-info-body-center__1BdLY {
  flex-direction: column;
  width: 100%; }

.info-tab_chat-info-details__1u_-e {
  flex: 1 1;
  padding: 0 10px; }
  .info-tab_chat-info-details__1u_-e h2 {
    font-size: 16px;
    margin: 10px 0; }
  .info-tab_chat-info-details__1u_-e p {
    font-size: 14px; }

.info-tab_chat-info__1VeKv {
  display: block;
  padding: 20px; }

.info-tab_tags-list__rBhTS {
  color: #014660;
  display: flex;
  font-size: 14px;
  margin-top: 10px; }

.info-tab_members-wrapper__15N4E {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-height: 100%;
  min-height: 400px; }

.info-tab_members__CZvcm {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  padding: 5px 5px 20px; }

.info-tab_members-header__YABE6 {
  align-items: center;
  display: flex;
  padding: 0 20px 10px; }
  .info-tab_members-header__YABE6 h2 {
    flex: 1 1;
    font-size: 16px;
    padding-right: 10px; }

.info-tab_member__2m4eG {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 15px 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  margin: 10px 0 0; }
  .info-tab_member__2m4eG [class*="avatar"] {
    margin-top: 10px; }
  .info-tab_member__2m4eG [class*="checkbox"] {
    align-self: center; }
  .info-tab_member__2m4eG .info-tab_details__3Egp9 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0 10px; }
    .info-tab_member__2m4eG .info-tab_details__3Egp9 .info-tab_name__1nzMT {
      color: #014660;
      font-size: 18px;
      margin-bottom: 10px; }
    .info-tab_member__2m4eG .info-tab_details__3Egp9 .info-tab_sub_info__1z_MI p {
      color: #55556f;
      font-family: OpenSans, sans-serif;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      padding: 0 0 5px; }
  .info-tab_member__2m4eG .info-tab_avatar_wrapper__15oep {
    position: relative; }
  .info-tab_member__2m4eG [class*="user_status"] {
    position: absolute;
    right: -1px;
    top: 30px; }

.info-tab_close__34Lsa button {
  background-color: transparent;
  border: 1px solid #014660;
  border-radius: 100%;
  display: flex;
  max-height: 27px;
  max-width: 27px;
  padding: 5px;
  position: absolute;
  right: 20px; }

.info-tab_close__34Lsa button:hover {
  background-color: #e6e6e6; }

.info-tab_close__34Lsa svg {
  min-height: 10px;
  min-width: 10px; }

.info-tab_user-info__3_s7U {
  display: block;
  padding: 20px; }
  .info-tab_user-info__3_s7U h1 {
    margin: 0 0 20px; }
  .info-tab_user-info__3_s7U h2 {
    margin-right: 5px; }
  .info-tab_user-info__3_s7U h1,
  .info-tab_user-info__3_s7U h2 {
    font-size: 16px; }
  .info-tab_user-info__3_s7U .info-tab_name__1nzMT {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px; }
  .info-tab_user-info__3_s7U span {
    color: #55556f; }
  .info-tab_user-info__3_s7U p {
    font-size: 14px;
    margin-bottom: 10px; }
  .info-tab_user-info__3_s7U .info-tab_profile__2nbUQ {
    padding: 5px 20px; }
  .info-tab_user-info__3_s7U .info-tab_details__3Egp9 {
    display: flex;
    flex-direction: row;
    padding: 0; }
  .info-tab_user-info__3_s7U .info-tab_info__3bafA {
    display: flex;
    flex-direction: column; }
  .info-tab_user-info__3_s7U .info-tab_ops__10xYt {
    margin-top: 20px;
    max-height: 100%;
    overflow-y: auto; }
  .info-tab_user-info__3_s7U .info-tab_share-card__1pxVL {
    align-items: center;
    display: flex; }
  .info-tab_user-info__3_s7U w-avatar {
    margin-right: 10px; }

.info-tab_close_goals__1MMqk {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 20px;
  line-height: 1.1em;
  margin-bottom: 35px; }
  .info-tab_close_goals__1MMqk button {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: #fff;
    display: flex;
    flex: 1 1;
    font-size: 0.75em;
    line-height: 1.1em;
    padding: 0; }
  .info-tab_close_goals__1MMqk span {
    flex: 1 0 auto;
    text-align: center; }
  .info-tab_close_goals__1MMqk svg {
    flex: 0 0 25px;
    height: 20px;
    margin-right: 5px; }
  .info-tab_close_goals__1MMqk::after {
    content: "";
    flex: 1 1; }

.info-tab_goals-details-wrapper__4eWer {
  background-color: #014660;
  height: 100%;
  padding: 20px;
  width: 100%; }
  .info-tab_goals-details-wrapper__4eWer h2 {
    color: #fff;
    display: block;
    font-size: 18px; }
  .info-tab_goals-details-wrapper__4eWer .info-tab_not-updated__2fhgD {
    color: #f8de88;
    display: block;
    font-size: 12px;
    padding: 10px 0; }

.info-tab_goal-card__1cbaQ {
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 20px; }
  .info-tab_goal-card__1cbaQ .info-tab_goal-header__KHF8p {
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 17px;
    line-height: 1.3em;
    padding: 10px 15px; }
    @media (max-width: 1024px) {
      .info-tab_goal-card__1cbaQ .info-tab_goal-header__KHF8p {
        font-size: 16px; } }
  .info-tab_goal-card__1cbaQ .info-tab_goal-body__29wMP {
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    padding: 20px 15px; }
    .info-tab_goal-card__1cbaQ .info-tab_goal-body__29wMP .info-tab_goals_text__3jL_Q {
      font-size: 14px; }
      @media (max-width: 1024px) {
        .info-tab_goal-card__1cbaQ .info-tab_goal-body__29wMP .info-tab_goals_text__3jL_Q {
          font-size: 14px; } }
    .info-tab_goal-card__1cbaQ .info-tab_goal-body__29wMP .info-tab_goal_body_text__3Prv4 {
      color: #014660;
      margin-top: 10px; }

.info-tab_goals__10be4 {
  margin-top: 20px; }

.report_white__38WvS {
  color: #f9f9f9; }

.report_black__2G-db {
  color: #000; }

.report_deep-teal__1N7KK {
  color: #014660; }

.report_greyish-teal__3jNNo {
  color: #5bdbbd; }

.report_very-light-grey__LNvXr {
  color: #b9b9b9; }

.report_warm-grey__3KLO0 {
  color: #55556f; }

.report_simple-grey__1gAhn {
  color: #d4d4d8; }

.report_dark-grey__2x0xU {
  color: #222026; }

.report_flat-white__2l_zb {
  color: #fff; }

.report_pastel-red__KcBS9 {
  color: #e55e50; }

.report_white-bg__2MA8_ {
  background-color: #f9f9f9; }

.report_deep-teal-bg__u5OBI {
  background-color: #014660;
  color: #fff; }

.report_greyish-teal-bg__2Wz01 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.report_very-light-grey-bg__2L1Pm {
  background-color: #b9b9b9;
  color: #222026; }

.report_warm-grey-bg__3zUdn {
  background-color: #55556f;
  color: #f9f9f9; }

.report_simple-grey-bg__7bcj8 {
  background-color: #d4d4d8;
  color: #222026; }

.report_dark-grey-bg__1yz23 {
  background-color: #222026;
  color: #fff; }

.report_flat-white-bg__30pG9 {
  background-color: #fff;
  color: #014660; }

.report_pastel-red-bg__1nQ1S {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.report_white__38WvS {
  color: #f9f9f9; }

.report_black__2G-db {
  color: #000; }

.report_deep-teal__1N7KK {
  color: #014660; }

.report_greyish-teal__3jNNo {
  color: #5bdbbd; }

.report_very-light-grey__LNvXr {
  color: #b9b9b9; }

.report_warm-grey__3KLO0 {
  color: #55556f; }

.report_simple-grey__1gAhn {
  color: #d4d4d8; }

.report_dark-grey__2x0xU {
  color: #222026; }

.report_flat-white__2l_zb {
  color: #fff; }

.report_pastel-red__KcBS9 {
  color: #e55e50; }

.report_white-bg__2MA8_ {
  background-color: #f9f9f9; }

.report_deep-teal-bg__u5OBI {
  background-color: #014660;
  color: #fff; }

.report_greyish-teal-bg__2Wz01 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.report_very-light-grey-bg__2L1Pm {
  background-color: #b9b9b9;
  color: #222026; }

.report_warm-grey-bg__3zUdn {
  background-color: #55556f;
  color: #f9f9f9; }

.report_simple-grey-bg__7bcj8 {
  background-color: #d4d4d8;
  color: #222026; }

.report_dark-grey-bg__1yz23 {
  background-color: #222026;
  color: #fff; }

.report_flat-white-bg__30pG9 {
  background-color: #fff;
  color: #014660; }

.report_pastel-red-bg__1nQ1S {
  background-color: #e55e50;
  color: #f9f9f9; }

.report_wrapper__1T1Dt {
  background-color: rgba(212, 212, 216, 0.2);
  height: 100%;
  width: 100%; }

.report_body__F9Laa {
  background-color: #f9f9f9;
  height: 100%;
  max-width: 800px;
  padding: 30px; }
  .report_body__F9Laa form {
    padding-bottom: 60px; }
  @media (max-width: 1024px) {
    .report_body__F9Laa {
      max-width: 100%; } }
  .report_body__F9Laa h1 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin: 20px 0; }
  .report_body__F9Laa svg {
    align-self: center;
    height: 15px;
    margin-right: 10px;
    width: 20px; }
  .report_body__F9Laa button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    display: flex; }
    .report_body__F9Laa button:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .report_body__F9Laa button:disabled {
      opacity: 0.5; }

.report_block__2rt4M {
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 40px 20px; }
  .report_block__2rt4M label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .report_block__2rt4M input, .report_block__2rt4M textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .report_block__2rt4M input {
    height: 35px; }
  .report_block__2rt4M textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .report_block__2rt4M.report_valid__1bGJ_ input, .report_block__2rt4M.report_valid__1bGJ_ textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .report_block__2rt4M.report_invalid__3IYCW input, .report_block__2rt4M.report_invalid__3IYCW textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  .report_block__2rt4M label {
    margin-left: 15px; }
  .report_block__2rt4M textarea {
    height: 150px;
    margin: 10px 20px 0; }

.report_valid__1bGJ_ {
  background-color: rgba(91, 219, 189, 0.1);
  color: #014660; }
  .report_valid__1bGJ_ textarea {
    border: #5bdbbd; }

.report_invalid__3IYCW {
  background-color: rgba(229, 94, 80, 0.1); }
  .report_invalid__3IYCW textarea {
    border: #e55e50; }

.report_error__3-9Ws {
  color: #e55e50;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 10px; }

.report_message_wrapper__waJF- {
  display: flex; }

.report_user_avatar__41_bw {
  align-self: center;
  margin-right: 10px; }

.report_right_info__1HkSs {
  display: flex;
  flex-direction: column;
  font-size: 13px; }

.report_message__1STIz {
  border-radius: 12px 12px 0;
  line-height: 1.3;
  padding: 10px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #d4d4d8;
  border-radius: 12px 12px 12px 0;
  color: #222026;
  margin-top: 5px; }

.report_buttons_wrapper__2q4Hx {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px; }
  .report_buttons_wrapper__2q4Hx button:first-child {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    font-size: 14px;
    margin-right: 20px;
    padding: 5px 20px; }
    .report_buttons_wrapper__2q4Hx button:first-child:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .report_buttons_wrapper__2q4Hx button:first-child:disabled {
      opacity: 0.5; }
    .report_buttons_wrapper__2q4Hx button:first-child:disabled {
      background-color: #fff;
      color: #014660;
      opacity: 0.5; }
  .report_buttons_wrapper__2q4Hx button:last-child {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 14px;
    padding: 5px 20px; }
    .report_buttons_wrapper__2q4Hx button:last-child:hover:not(:disabled) {
      background-color: #00212e; }
    .report_buttons_wrapper__2q4Hx button:last-child:disabled {
      opacity: 0.5; }
    .report_buttons_wrapper__2q4Hx button:last-child:disabled {
      background-color: #014660;
      opacity: 0.5; }

.timepicker_white__3_adz {
  color: #f9f9f9; }

.timepicker_black__Wps5M {
  color: #000; }

.timepicker_deep-teal__IldWf {
  color: #014660; }

.timepicker_greyish-teal__1toIg {
  color: #5bdbbd; }

.timepicker_very-light-grey__3CIXI {
  color: #b9b9b9; }

.timepicker_warm-grey__2Fi-5 {
  color: #55556f; }

.timepicker_simple-grey__1Ktir {
  color: #d4d4d8; }

.timepicker_dark-grey__1g3Lv {
  color: #222026; }

.timepicker_flat-white__2Lfql {
  color: #fff; }

.timepicker_pastel-red__25L5j {
  color: #e55e50; }

.timepicker_white-bg__2gf25 {
  background-color: #f9f9f9; }

.timepicker_deep-teal-bg___orsi {
  background-color: #014660;
  color: #fff; }

.timepicker_greyish-teal-bg__1A6Nz {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.timepicker_very-light-grey-bg__1IJeU {
  background-color: #b9b9b9;
  color: #222026; }

.timepicker_warm-grey-bg__jFARd {
  background-color: #55556f;
  color: #f9f9f9; }

.timepicker_simple-grey-bg__1hzwk {
  background-color: #d4d4d8;
  color: #222026; }

.timepicker_dark-grey-bg__yBGNe {
  background-color: #222026;
  color: #fff; }

.timepicker_flat-white-bg__285BS {
  background-color: #fff;
  color: #014660; }

.timepicker_pastel-red-bg__2WXdh {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.timepicker_wrapper__29I9G {
  display: flex;
  justify-content: center;
  padding: 10px; }

.timepicker_section__hkDrI {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content; }

.timepicker_switchers__28-1F {
  display: flex;
  justify-content: space-between;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }

.timepicker_picker__3A53J {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px 0;
  width: 60px; }
  .timepicker_picker__3A53J .timepicker_content__3N-ri {
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: center; }
  .timepicker_picker__3A53J svg {
    height: 20px;
    width: 20px; }
  .timepicker_picker__3A53J button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center; }
  .timepicker_picker__3A53J span:last-of-type {
    font-size: 12px; }

.timepicker_semicolon__E5EbO {
  align-items: center;
  display: flex;
  margin-right: 10px; }

.action-flow_white__sKW0a {
  color: #f9f9f9; }

.action-flow_black__133Tg {
  color: #000; }

.action-flow_deep-teal__1DzJl {
  color: #014660; }

.action-flow_greyish-teal__1Ke7n {
  color: #5bdbbd; }

.action-flow_very-light-grey__1LA0P {
  color: #b9b9b9; }

.action-flow_warm-grey__2r6pp {
  color: #55556f; }

.action-flow_simple-grey__OlgFY {
  color: #d4d4d8; }

.action-flow_dark-grey__Il3KH {
  color: #222026; }

.action-flow_flat-white__1_2ZA {
  color: #fff; }

.action-flow_pastel-red__L4oV4 {
  color: #e55e50; }

.action-flow_white-bg__3m2rI {
  background-color: #f9f9f9; }

.action-flow_deep-teal-bg__1qTrs {
  background-color: #014660;
  color: #fff; }

.action-flow_greyish-teal-bg__BCEn7 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.action-flow_very-light-grey-bg__SQ2Fw {
  background-color: #b9b9b9;
  color: #222026; }

.action-flow_warm-grey-bg__yQUgo {
  background-color: #55556f;
  color: #f9f9f9; }

.action-flow_simple-grey-bg__1p0G9 {
  background-color: #d4d4d8;
  color: #222026; }

.action-flow_dark-grey-bg__1KFPJ {
  background-color: #222026;
  color: #fff; }

.action-flow_flat-white-bg__3_odm {
  background-color: #fff;
  color: #014660; }

.action-flow_pastel-red-bg__1uifa {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.action-flow_header__19Imt {
  align-items: center;
  border-bottom: 1px solid #b9b9b9;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px; }
  .action-flow_header__19Imt button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    align-items: center;
    display: flex; }
  .action-flow_header__19Imt svg {
    height: 15px; }
  .action-flow_header__19Imt svg:last-child {
    margin-left: 5px; }
  .action-flow_header__19Imt svg:first-child {
    margin-right: 5px; }
  .action-flow_header__19Imt button:disabled {
    opacity: 0.5; }

.action-flow_options__1aYv2 h1 {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "Questrial", sans-serif;
  padding: 20px 10px 10px; }

.action-flow_options__1aYv2 .action-flow_option__3Rilr {
  cursor: pointer; }

.action-flow_options__1aYv2 .action-flow_option__3Rilr,
.action-flow_options__1aYv2 .action-flow_flow_selector__MR1Dw {
  align-items: center;
  background-color: #fff;
  display: flex;
  margin: 0 10px 10px;
  padding: 20px; }
  .action-flow_options__1aYv2 .action-flow_option__3Rilr span,
  .action-flow_options__1aYv2 .action-flow_flow_selector__MR1Dw span {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }

.action-flow_options__1aYv2 .action-flow_option__3Rilr > span {
  margin-left: 10px; }

.action-flow_options__1aYv2 .action-flow_flow_selector__MR1Dw {
  justify-content: space-between; }
  .action-flow_options__1aYv2 .action-flow_flow_selector__MR1Dw span {
    font-family: "Questrial", sans-serif; }

.action-flow_options__1aYv2 span {
  font-family: "Questrial", sans-serif; }

.action-flow_options__1aYv2 .action-flow_checkbox__16BhQ {
  height: 25px;
  width: 25px; }

.action-flow_options__1aYv2 .action-flow_selected__Kbk4c {
  background-color: rgba(91, 219, 189, 0.2); }

.action-flow_meet-time__3SJU_ {
  display: flex;
  flex-direction: column;
  overflow-y: auto; }
  .action-flow_meet-time__3SJU_ h1 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    padding: 20px 10px 10px; }
  .action-flow_meet-time__3SJU_ h4 {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 10px 15px; }

.action-flow_choose-timezone__1eqRh {
  align-items: center;
  color: #222026;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.9em;
  padding: 10px; }
  .action-flow_choose-timezone__1eqRh button {
    font-size: 13px; }

.action-flow_like-input__3OkPc,
.action-flow_like-disabled-input__2uSAo {
  color: #014660;
  font-family: OpenSans, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  border: 1px solid #d4d4d8;
  border-radius: 18.5px;
  flex: 1 1;
  margin: 0 10px 10px;
  padding: 5px 10px; }

.action-flow_like-input__3OkPc {
  background-color: #fff; }

.action-flow_like-disabled-input__2uSAo {
  color: #222026; }

.action-flow_show-hide__2BYHY {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  font-weight: lighter;
  padding: 10px 20px 20px;
  width: 100%; }
  .action-flow_show-hide__2BYHY:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .action-flow_show-hide__2BYHY:disabled {
    opacity: 0.5; }
  .action-flow_show-hide__2BYHY:hover {
    text-decoration: underline; }

.action-flow_switcher__1o-ld {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #d4d4d8;
  border-radius: 18.5px;
  display: flex;
  flex: 1 1;
  height: 34px;
  margin: 0 10px 10px;
  position: relative; }
  .action-flow_switcher__1o-ld .action-flow_option__3Rilr {
    align-items: center;
    border-radius: 18.5px;
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .action-flow_switcher__1o-ld .action-flow_date__1hjAp {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms; }
  .action-flow_switcher__1o-ld .action-flow_time__1nL9- {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms; }
  .action-flow_switcher__1o-ld .action-flow_holder__1nGim {
    background-color: #fff;
    bottom: 0;
    color: #014660;
    font-weight: bold;
    position: absolute;
    top: 0;
    width: 50%; }

.action-flow_timezones__2xDWC {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .action-flow_timezones__2xDWC h1 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    padding: 10px; }

.action-flow_search-timezone__QaeGN {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 20px; }
  .action-flow_search-timezone__QaeGN label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .action-flow_search-timezone__QaeGN input, .action-flow_search-timezone__QaeGN textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .action-flow_search-timezone__QaeGN input {
    height: 35px; }
  .action-flow_search-timezone__QaeGN textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .action-flow_search-timezone__QaeGN.action-flow_valid__1M7WN input, .action-flow_search-timezone__QaeGN.action-flow_valid__1M7WN textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .action-flow_search-timezone__QaeGN.action-flow_invalid__2GpNk input, .action-flow_search-timezone__QaeGN.action-flow_invalid__2GpNk textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  @supports (-webkit-overflow-scrolling: touch) {
    .action-flow_search-timezone__QaeGN {
      max-height: 300px;
      min-height: 100px; } }
  .action-flow_search-timezone__QaeGN input, .action-flow_search-timezone__QaeGN textarea {
    display: flex;
    flex: 1 1;
    margin-right: 10px; }
  .action-flow_search-timezone__QaeGN .action-flow_box__3uTBu {
    align-items: center;
    display: flex; }
  .action-flow_search-timezone__QaeGN span {
    color: #222026;
    margin-bottom: 10px; }
  .action-flow_search-timezone__QaeGN h3 {
    font-size: 18px; }
  .action-flow_search-timezone__QaeGN svg {
    height: 20px;
    width: 20px; }
  .action-flow_search-timezone__QaeGN.action-flow_valid__1M7WN {
    background-color: rgba(91, 219, 189, 0.2); }
    .action-flow_search-timezone__QaeGN.action-flow_valid__1M7WN input:focus {
      box-shadow: 0 0 4px #5bdbbd; }
  .action-flow_search-timezone__QaeGN.action-flow_invalid__2GpNk {
    background-color: rgba(229, 94, 80, 0.2); }
    .action-flow_search-timezone__QaeGN.action-flow_invalid__2GpNk input:focus {
      box-shadow: 0 0 4px #e55e50; }
  .action-flow_search-timezone__QaeGN.action-flow_disabled__1BxXN {
    background-color: rgba(85, 85, 111, 0.3);
    opacity: 0.7; }
    .action-flow_search-timezone__QaeGN.action-flow_disabled__1BxXN svg {
      visibility: hidden; }
  .action-flow_search-timezone__QaeGN .action-flow_error__27LvZ {
    color: #e55e50;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px; }
  .action-flow_search-timezone__QaeGN input {
    margin: 0; }
  .action-flow_search-timezone__QaeGN label {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .action-flow_search-timezone__QaeGN input::-webkit-input-placeholder {
    color: #b9b9b9;
    margin-left: 20px; }
  .action-flow_search-timezone__QaeGN input::-moz-placeholder {
    color: #b9b9b9;
    margin-left: 20px; }
  .action-flow_search-timezone__QaeGN input::-ms-input-placeholder {
    color: #b9b9b9;
    margin-left: 20px; }
  .action-flow_search-timezone__QaeGN input::placeholder {
    color: #b9b9b9;
    margin-left: 20px; }

.action-flow_timezone-card__1tHcn {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding: 16px 12px; }
  .action-flow_timezone-card__1tHcn div:first-child {
    color: #014660;
    display: flex;
    flex-direction: column;
    line-height: 1.5; }
  .action-flow_timezone-card__1tHcn div:first-child > div:first-child {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-weight: bold; }

.action-flow_tz-list__1FE8k {
  flex: 1 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px; }
  .action-flow_tz-list__1FE8k > div {
    margin-bottom: 10px; }

.action-flow_datetime__Xy_lz {
  background-color: rgba(212, 212, 216, 0.2);
  padding: 15px 0; }

.action-flow_details__1qJ9v {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%; }
  .action-flow_details__1qJ9v input,
  .action-flow_details__1qJ9v textarea {
    color: #014660; }
  .action-flow_details__1qJ9v label {
    font-family: "Questrial", sans-serif;
    font-size: 16px; }
  .action-flow_details__1qJ9v form {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .action-flow_details__1qJ9v form w-validation-box:last-of-type {
      display: flex;
      flex: 1 1; }

.action-flow_calendar-access__nXnUH {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background: rgba(221, 221, 221, 0.2);
  font-family: "Questrial", sans-serif;
  line-height: 1.3em;
  margin-bottom: 10px;
  padding: 0 10px 10px; }
  .action-flow_calendar-access__nXnUH button {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 14px;
    padding: 0; }
    .action-flow_calendar-access__nXnUH button:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .action-flow_calendar-access__nXnUH button:disabled {
      opacity: 0.5; }

.action-flow_loader__2gFfP {
  align-items: center;
  background-color: rgba(1, 70, 96, 0.65);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  .action-flow_loader__2gFfP div:first-of-type {
    z-index: 5; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  *margin-left: -7px;
  /* 3 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  *height: 13px;
  /* 3 */
  padding: 0;
  /* 2 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  background: #ccc;
  color: #000;
  margin: 0.2em 0;
  padding: 0.2em 0; }

::-webkit-scrollbar-track {
  box-shadow: none; }

::-webkit-scrollbar {
  max-height: 150px;
  min-height: 6px;
  width: 6px; }

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 2px; } }

::-webkit-scrollbar-thumb {
  background: rgba(147, 146, 146, 0.5);
  border-radius: 3.5px; }

@supports (-webkit-overflow-scrolling: touch) {
  input, textarea {
    font-size: 16px !important; } }

.alerts-enter {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts-enter-active {
  opacity: 1;
  -webkit-transform: scale(1) translate3d(0, 0, 0);
          transform: scale(1) translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts-exit {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts-exit-active {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
.slide-out-left {
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: slide-out-left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: slide-in-left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes slide-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 0.8s both;
          animation: wobble-hor-bottom 0.8s both; }

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

/**
 * ----------------------------------------
 * animation Show-Hide by changing opacity
 * ----------------------------------------
 */
@-webkit-keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.d-flex {
  display: flex; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.flex-direction-row-reverse {
  flex-direction: row-reverse; }

.flex-direction-column-reverse {
  flex-direction: column-reverse; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-fill {
  flex: 1 1 auto; }

.flex-grow-0 {
  flex-grow: 0; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-shrink-0 {
  flex-shrink: 0; }

.flex-shrink-1 {
  flex-shrink: 1; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.justify-content-around {
  justify-content: space-around; }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-center {
  align-items: center; }

.align-items-baseline {
  align-items: baseline; }

.align-items-stretch {
  align-items: stretch; }

.align-content-start {
  align-content: flex-start; }

.align-content-end {
  align-content: flex-end; }

.align-content-center {
  align-content: center; }

.align-content-between {
  align-content: space-between; }

.align-content-around {
  align-content: space-around; }

.align-content-stretch {
  align-content: stretch; }

.align-self-auto {
  align-self: auto; }

.align-self-start {
  align-self: flex-start; }

.align-self-end {
  align-self: flex-end; }

.align-self-center {
  align-self: center; }

.align-self-baseline {
  align-self: baseline; }

.align-self-stretch {
  align-self: stretch; }

.white {
  color: #f9f9f9; }

.black {
  color: #000; }

.deep-teal {
  color: #014660; }

.greyish-teal {
  color: #5bdbbd; }

.very-light-grey {
  color: #b9b9b9; }

.warm-grey {
  color: #55556f; }

.simple-grey {
  color: #d4d4d8; }

.dark-grey {
  color: #222026; }

.flat-white {
  color: #fff; }

.pastel-red {
  color: #e55e50; }

.white-bg {
  background-color: #f9f9f9; }

.deep-teal-bg {
  background-color: #014660;
  color: #fff; }

.greyish-teal-bg {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.very-light-grey-bg {
  background-color: #b9b9b9;
  color: #222026; }

.warm-grey-bg {
  background-color: #55556f;
  color: #f9f9f9; }

.simple-grey-bg {
  background-color: #d4d4d8;
  color: #222026; }

.dark-grey-bg {
  background-color: #222026;
  color: #fff; }

.flat-white-bg {
  background-color: #fff;
  color: #014660; }

.pastel-red-bg {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
#layout {
  background-color: #f9f9f9;
  display: flex;
  height: 100%;
  max-width: 100%; }
  @media (max-width: 1024px) {
    #layout {
      flex-direction: column;
      overflow-y: hidden; } }

#layout-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  width: 100%; }

#upsert-layout {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  width: 100vw; }

#main {
  background-color: #f9f9f9;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  position: relative; }
  @media (max-width: 1024px) {
    #main {
      overflow-y: auto; } }

#body {
  display: flex;
  height: 100vh;
  -ms-scroll-chaining: none;
      overscroll-behavior: none; }

#search {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

#left {
  max-height: 100%;
  position: relative;
  width: 320px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    #left {
      display: none; } }
  @media (max-width: 1024px) {
    #left {
      display: none; } }

#main-center {
  flex: 1 1;
  max-width: 100%;
  -ms-scroll-chaining: none;
      overscroll-behavior: none; }
  @media (min-width: 1025px) {
    #main-center {
      display: flex; } }

#calendar-center {
  display: flex;
  flex: 1 1;
  -ms-scroll-chaining: none;
      overscroll-behavior: none; }
  @media (max-width: 1024px) {
    #calendar-center {
      flex-direction: column;
      padding-bottom: 40px;
      width: 100%; }
      #calendar-center #events, #calendar-center #calendar {
        flex: 1 1; } }

#calendar-actions {
  display: flex; }
  @media (min-width: 1025px) {
    #calendar-actions {
      flex: 0 0 320px; } }

#center {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  position: relative; }

w-sidebar-module {
  height: 100vh;
  width: 100%; }
  @media (min-width: 1025px) {
    w-sidebar-module {
      position: relative;
      width: 80px; } }
  @media (max-width: 1024px) {
    w-sidebar-module {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 403; } }

#activity_body {
  -webkit-animation: show 0.5s;
          animation: show 0.5s;
  display: flex;
  flex: 1 1;
  flex-direction: column; }

#messages_body {
  display: flex;
  flex: 1 1;
  flex-direction: column; }

#info_body {
  -webkit-animation: show 0.5s;
          animation: show 0.5s;
  display: flex;
  flex: 1 1;
  overflow-y: hidden;
  position: relative; }

#chat_messages {
  display: flex;
  flex: 1 1;
  position: relative; }

#tabbar {
  bottom: 0;
  height: 50px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2; }
  @media (max-height: 400px) {
    #tabbar {
      display: none; } }

#sub_nav {
  background-color: #f9f9f9;
  border-left: 1px solid #b9b9b9;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  min-width: 80px; }
  @media (max-width: 1024px) {
    #sub_nav {
      border-left: 0;
      height: calc(100% - 60px); } }

#schedule {
  background-color: #f9f9f9;
  border-left: 1px solid #b9b9b9;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 320px; }
  @media (max-width: 1024px) {
    #schedule {
      width: 100%; } }

#report {
  flex: 1 1; }

#events {
  height: 100%;
  min-width: 320px;
  overflow: hidden; }
  @media (min-width: 1025px) {
    #events {
      max-width: 320px;
      overflow: scroll; } }

#poll {
  position: relative;
  width: 100%; }

.with-polls-center {
  bottom: 50px;
  display: flex;
  position: absolute;
  width: 100%; }
  @media (min-width: 1025px) {
    .with-polls-center {
      padding: 0 320px; } }

.main-tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative; }

.custom-polls-center {
  display: flex;
  margin-top: -50px;
  width: 100%; }
  @media (min-width: 1025px) {
    .custom-polls-center {
      max-width: 1120px;
      padding-left: 320px; } }

.tab-wrapper {
  display: flex;
  height: 100%;
  width: 100%; }
  .tab-wrapper .container {
    min-width: 460px; }

.container {
  background-color: #f6f6f6;
  border-left: solid 1px #b9b9b9;
  border-right: solid 1px #b9b9b9;
  border-top: solid 1px #b9b9b9;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden; }
  .container .profile-content {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: thin; }
  .container .mobile-profile-content {
    background-color: transparent;
    margin-top: -40px;
    overflow: auto;
    -ms-overflow-style: none;
    position: relative;
    scrollbar-width: thin;
    z-index: 1; }
  .container .public-profile {
    max-height: 100%; }

.empty-right {
  flex: 1 1;
  height: 100%;
  max-width: 320px; }

.tab-wrapper-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .tab-wrapper-mobile .cards_wrapper {
    display: flex;
    padding: 16px; }

@media (min-width: 1025px) {
  #navigation {
    width: 320px; } }

w-public-profile-module {
  height: 100%; }

#chat {
  height: 100%;
  width: 100%; }

w-credits-achievements-module,
w-credits-leaderboards-module,
w-credits-faqs-module,
w-credits-streaks-module {
  flex: 1 1;
  height: 100%;
  width: 100%; }
  @media (max-width: 1024px) {
    w-credits-achievements-module,
    w-credits-leaderboards-module,
    w-credits-faqs-module,
    w-credits-streaks-module {
      margin-bottom: 50px; } }

.credits-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
  position: relative; }
  .credits-wrapper-background {
    background-color: #ffc866;
    background-color: linear-gradient(to bottom, #ffe08b 0%, #ffc866 100%);
    border-radius: 0 0 100% 100%;
    height: 500px;
    left: -120px;
    max-height: 500px;
    min-height: 500px;
    position: absolute;
    right: -120px;
    top: -160px; }
  .credits-wrapper .search-card {
    background: #f9f9f9;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
    margin: 10px 15px 0;
    padding: 20px;
    position: relative;
    z-index: 1; }
  @media (max-width: 1024px) {
    .credits-wrapper {
      padding-bottom: 50px; }
      .credits-wrapper-mobile {
        height: 64%; } }

#auth-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw; }
  #auth-main .auth-main-header {
    height: 220px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0; }
    @media (max-width: 1024px) {
      #auth-main .auth-main-header {
        display: none; } }
  #auth-main .main-wrapper {
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: relative;
    right: 0;
    top: 0; }
    @media (min-width: 1025px) {
      #auth-main .main-wrapper {
        top: 100px; }
        #auth-main .main-wrapper::-webkit-scrollbar {
          max-height: 150px;
          min-height: 6px;
          width: 0; } }
  #auth-main .auth-workspace-wrapper {
    background-color: #f9f9f9;
    min-height: 300px;
    width: 100%; }
    @media (max-width: 1024px) {
      #auth-main .auth-workspace-wrapper {
        padding-bottom: 80px; } }
    @media (min-width: 1025px) {
      #auth-main .auth-workspace-wrapper {
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: auto;
        width: 45%; } }
  @media (min-width: 1025px) {
    #auth-main .auth-workspace-header {
      height: 120px; } }

#match {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%; }
  #match #match-header {
    align-items: center;
    background-color: #014660;
    display: flex;
    height: 450px;
    justify-content: center; }
    @media (min-width: 1025px) {
      #match #match-header {
        height: 400px;
        min-height: 400px; } }
    @media (max-width: 1024px) {
      #match #match-header {
        display: block;
        padding-bottom: 50px; } }
  #match .circle {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.13)), to(rgba(255, 255, 255, 0.04)));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.04));
    border-radius: 100%;
    height: 400px;
    left: 50%;
    margin-left: -200px;
    opacity: 0.64;
    position: absolute;
    -webkit-transform: scale(3);
            transform: scale(3);
    width: 400px; }
    @media (max-width: 1220px) {
      #match .circle {
        -webkit-transform: scale(2);
                transform: scale(2); } }
    @media (max-width: 1024px) {
      #match .circle {
        height: 430px;
        margin-left: -215px;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        width: 430px; } }

.white {
  color: #f9f9f9; }

.black {
  color: #000; }

.deep-teal {
  color: #014660; }

.greyish-teal {
  color: #5bdbbd; }

.very-light-grey {
  color: #b9b9b9; }

.warm-grey {
  color: #55556f; }

.simple-grey {
  color: #d4d4d8; }

.dark-grey {
  color: #222026; }

.flat-white {
  color: #fff; }

.pastel-red {
  color: #e55e50; }

.white-bg {
  background-color: #f9f9f9; }

.deep-teal-bg {
  background-color: #014660;
  color: #fff; }

.greyish-teal-bg {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.very-light-grey-bg {
  background-color: #b9b9b9;
  color: #222026; }

.warm-grey-bg {
  background-color: #55556f;
  color: #f9f9f9; }

.simple-grey-bg {
  background-color: #d4d4d8;
  color: #222026; }

.dark-grey-bg {
  background-color: #222026;
  color: #fff; }

.flat-white-bg {
  background-color: #fff;
  color: #014660; }

.pastel-red-bg {
  background-color: #e55e50;
  color: #f9f9f9; }

#body {
  padding-top: 134px; }
  @media (max-width: 1024px) {
    #body {
      padding-top: 124px; } }

#search {
  top: 134px; }
  @media (max-width: 1024px) {
    #search {
      top: 124px; } }

#header {
  background-color: #014660;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: auto;
  justify-content: space-between;
  left: 0;
  padding: 15px 20px;
  position: fixed;
  right: 0;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 2; }
  @media (min-width: 1025px) {
    #header {
      left: 80px; } }
  #header.contracted {
    -webkit-transform: translate(0, -60px);
            transform: translate(0, -60px); }
    @media (max-width: 1024px) {
      #header.contracted {
        -webkit-transform: translate(0, -60px);
                transform: translate(0, -60px); } }

#header-top {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  #header-top #header-collective {
    display: flex;
    flex: 1 1; }
  #header-top #header-search {
    flex: 0 1 400px; }
  #header-top #header-profile {
    margin-left: 20px; }
  #header-top > div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }

#header-bottom {
  display: flex;
  font-size: 16px;
  width: 100%; }
  #header-bottom #header-profile {
    margin-right: 15px; }
  #header-bottom-left {
    align-items: center;
    display: flex;
    flex: 3 1; }
    @media (max-width: 1024px) {
      #header-bottom-left {
        flex: 5 1; } }
    #header-bottom-left > div {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-right: 10px; }
      @media (max-width: 1024px) {
        #header-bottom-left > div {
          margin-right: 0; } }
    #header-bottom-left #header-search {
      flex: 1 1;
      width: 100%; }
  #header-bottom-right {
    align-items: center;
    display: flex;
    flex: 1 1;
    justify-content: flex-end; }
    #header-bottom-right > div, #header-bottom-right w-header-menu-module, #header-bottom-right w-header-alerts-module {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-left: 10px; }
      @media (max-width: 1024px) {
        #header-bottom-right > div, #header-bottom-right w-header-menu-module, #header-bottom-right w-header-alerts-module {
          margin-left: 0; } }

@media (max-width: 1024px) {
  #alerts {
    width: 100%; } }

.white {
  color: #f9f9f9; }

.black {
  color: #000; }

.deep-teal {
  color: #014660; }

.greyish-teal {
  color: #5bdbbd; }

.very-light-grey {
  color: #b9b9b9; }

.warm-grey {
  color: #55556f; }

.simple-grey {
  color: #d4d4d8; }

.dark-grey {
  color: #222026; }

.flat-white {
  color: #fff; }

.pastel-red {
  color: #e55e50; }

.white-bg {
  background-color: #f9f9f9; }

.deep-teal-bg {
  background-color: #014660;
  color: #fff; }

.greyish-teal-bg {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.very-light-grey-bg {
  background-color: #b9b9b9;
  color: #222026; }

.warm-grey-bg {
  background-color: #55556f;
  color: #f9f9f9; }

.simple-grey-bg {
  background-color: #d4d4d8;
  color: #222026; }

.dark-grey-bg {
  background-color: #222026;
  color: #fff; }

.flat-white-bg {
  background-color: #fff;
  color: #014660; }

.pastel-red-bg {
  background-color: #e55e50;
  color: #f9f9f9; }

html,
body {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  width: 100%; }

body {
  font-family: "Questrial", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body::after {
    background-color: transparent;
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
    width: 100%;
    z-index: -100; }
  body * {
    box-sizing: border-box; }
  body a {
    text-decoration: none; }
  body a:focus {
    box-shadow: 0 1px 0 rgba(1, 70, 96, 0.4);
    text-decoration: underline; }

/** For catching autofill event */
@-webkit-keyframes onAutoFillStart {
  from { }
  to { } }
@keyframes onAutoFillStart {
  from { }
  to { } }

@-webkit-keyframes onAutoFillCancel {
  from { }
  to { } }

@keyframes onAutoFillCancel {
  from { }
  to { } }

input:-webkit-autofill {
  -webkit-animation-name: onAutoFillStart;
          animation-name: onAutoFillStart;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s; }

input:not(:-webkit-autofill) {
  -webkit-animation-name: onAutoFillCancel;
          animation-name: onAutoFillCancel; }

/** End autofill */
.overlayed {
  overflow: hidden; }
  .overlayed::after {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1; }

.App-Main-Container, #preloader {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%; }

#preloader {
  display: none;
  z-index: 1000; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }

.w-button {
  align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-height: 22px;
  min-width: 60px;
  padding: 5px 10px; }
  .w-button:disabled {
    opacity: 0.6; }
  .w-button:active, .w-button:focus {
    outline: none; }
  .w-button:hover:not(:disabled) {
    opacity: 0.6; }
  .w-button:hover.filled {
    opacity: 0.8; }
  .w-button:active, .w-button:focus {
    outline: none; }
  .w-button:hover:not(.disabled) {
    opacity: 0.6; }
  .w-button.primary {
    background-color: #014660;
    color: #fff;
    font-weight: bold; }
  .w-button.primary.darken-on-hover:hover {
    background-color: #013447;
    opacity: 1; }
  .w-button.primary:focus:not(.disabled) {
    background-color: #00212e;
    opacity: 1; }
  .w-button.secondary {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0 0 3px #014660;
    color: #014660; }
  .w-button.outline {
    background-color: transparent;
    border: 1px solid #014660;
    box-shadow: none;
    color: #014660;
    padding: 3px 10px; }
  .w-button.outline:focus:not(.disabled) {
    background-color: #e6e6e6;
    opacity: 1; }
  .w-button.outline.filled {
    background-color: #fff; }
  .w-button.outline.filled.darken-on-hover:hover {
    background-color: #f2f2f2;
    opacity: 1; }
  .w-button.link {
    background-color: transparent;
    box-shadow: none;
    color: #014660;
    font-weight: 600; }
  .w-button.link.filled {
    background-color: #fff; }
  .w-button.link.filled.darken-on-hover:hover {
    background-color: #f2f2f2;
    opacity: 1; }
  .w-button.light {
    background-color: transparent;
    box-shadow: 0 0 3px rgba(1, 70, 96, 0.5);
    color: #b9b9b9; }

.mt-0 {
  margin-top: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mt-1 {
  margin-top: 1px !important; }

.mr-1 {
  margin-right: 1px !important; }

.mb-1 {
  margin-bottom: 1px !important; }

.ml-1 {
  margin-left: 1px !important; }

.mt-2 {
  margin-top: 2px !important; }

.mr-2 {
  margin-right: 2px !important; }

.mb-2 {
  margin-bottom: 2px !important; }

.ml-2 {
  margin-left: 2px !important; }

.mt-3 {
  margin-top: 3px !important; }

.mr-3 {
  margin-right: 3px !important; }

.mb-3 {
  margin-bottom: 3px !important; }

.ml-3 {
  margin-left: 3px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.ml-4 {
  margin-left: 4px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.mt-6 {
  margin-top: 6px !important; }

.mr-6 {
  margin-right: 6px !important; }

.mb-6 {
  margin-bottom: 6px !important; }

.ml-6 {
  margin-left: 6px !important; }

.mt-7 {
  margin-top: 7px !important; }

.mr-7 {
  margin-right: 7px !important; }

.mb-7 {
  margin-bottom: 7px !important; }

.ml-7 {
  margin-left: 7px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.mt-9 {
  margin-top: 9px !important; }

.mr-9 {
  margin-right: 9px !important; }

.mb-9 {
  margin-bottom: 9px !important; }

.ml-9 {
  margin-left: 9px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mt-11 {
  margin-top: 11px !important; }

.mr-11 {
  margin-right: 11px !important; }

.mb-11 {
  margin-bottom: 11px !important; }

.ml-11 {
  margin-left: 11px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.mt-13 {
  margin-top: 13px !important; }

.mr-13 {
  margin-right: 13px !important; }

.mb-13 {
  margin-bottom: 13px !important; }

.ml-13 {
  margin-left: 13px !important; }

.mt-14 {
  margin-top: 14px !important; }

.mr-14 {
  margin-right: 14px !important; }

.mb-14 {
  margin-bottom: 14px !important; }

.ml-14 {
  margin-left: 14px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.ml-16 {
  margin-left: 16px !important; }

.mt-17 {
  margin-top: 17px !important; }

.mr-17 {
  margin-right: 17px !important; }

.mb-17 {
  margin-bottom: 17px !important; }

.ml-17 {
  margin-left: 17px !important; }

.mt-18 {
  margin-top: 18px !important; }

.mr-18 {
  margin-right: 18px !important; }

.mb-18 {
  margin-bottom: 18px !important; }

.ml-18 {
  margin-left: 18px !important; }

.mt-19 {
  margin-top: 19px !important; }

.mr-19 {
  margin-right: 19px !important; }

.mb-19 {
  margin-bottom: 19px !important; }

.ml-19 {
  margin-left: 19px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mt-21 {
  margin-top: 21px !important; }

.mr-21 {
  margin-right: 21px !important; }

.mb-21 {
  margin-bottom: 21px !important; }

.ml-21 {
  margin-left: 21px !important; }

.mt-22 {
  margin-top: 22px !important; }

.mr-22 {
  margin-right: 22px !important; }

.mb-22 {
  margin-bottom: 22px !important; }

.ml-22 {
  margin-left: 22px !important; }

.mt-23 {
  margin-top: 23px !important; }

.mr-23 {
  margin-right: 23px !important; }

.mb-23 {
  margin-bottom: 23px !important; }

.ml-23 {
  margin-left: 23px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-24 {
  margin-left: 24px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.mt-26 {
  margin-top: 26px !important; }

.mr-26 {
  margin-right: 26px !important; }

.mb-26 {
  margin-bottom: 26px !important; }

.ml-26 {
  margin-left: 26px !important; }

.mt-27 {
  margin-top: 27px !important; }

.mr-27 {
  margin-right: 27px !important; }

.mb-27 {
  margin-bottom: 27px !important; }

.ml-27 {
  margin-left: 27px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.ml-28 {
  margin-left: 28px !important; }

.mt-29 {
  margin-top: 29px !important; }

.mr-29 {
  margin-right: 29px !important; }

.mb-29 {
  margin-bottom: 29px !important; }

.ml-29 {
  margin-left: 29px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mt-31 {
  margin-top: 31px !important; }

.mr-31 {
  margin-right: 31px !important; }

.mb-31 {
  margin-bottom: 31px !important; }

.ml-31 {
  margin-left: 31px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.ml-32 {
  margin-left: 32px !important; }

.mt-33 {
  margin-top: 33px !important; }

.mr-33 {
  margin-right: 33px !important; }

.mb-33 {
  margin-bottom: 33px !important; }

.ml-33 {
  margin-left: 33px !important; }

.mt-34 {
  margin-top: 34px !important; }

.mr-34 {
  margin-right: 34px !important; }

.mb-34 {
  margin-bottom: 34px !important; }

.ml-34 {
  margin-left: 34px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.ml-35 {
  margin-left: 35px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mr-36 {
  margin-right: 36px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.ml-36 {
  margin-left: 36px !important; }

.mt-37 {
  margin-top: 37px !important; }

.mr-37 {
  margin-right: 37px !important; }

.mb-37 {
  margin-bottom: 37px !important; }

.ml-37 {
  margin-left: 37px !important; }

.mt-38 {
  margin-top: 38px !important; }

.mr-38 {
  margin-right: 38px !important; }

.mb-38 {
  margin-bottom: 38px !important; }

.ml-38 {
  margin-left: 38px !important; }

.mt-39 {
  margin-top: 39px !important; }

.mr-39 {
  margin-right: 39px !important; }

.mb-39 {
  margin-bottom: 39px !important; }

.ml-39 {
  margin-left: 39px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mt-41 {
  margin-top: 41px !important; }

.mr-41 {
  margin-right: 41px !important; }

.mb-41 {
  margin-bottom: 41px !important; }

.ml-41 {
  margin-left: 41px !important; }

.mt-42 {
  margin-top: 42px !important; }

.mr-42 {
  margin-right: 42px !important; }

.mb-42 {
  margin-bottom: 42px !important; }

.ml-42 {
  margin-left: 42px !important; }

.mt-43 {
  margin-top: 43px !important; }

.mr-43 {
  margin-right: 43px !important; }

.mb-43 {
  margin-bottom: 43px !important; }

.ml-43 {
  margin-left: 43px !important; }

.mt-44 {
  margin-top: 44px !important; }

.mr-44 {
  margin-right: 44px !important; }

.mb-44 {
  margin-bottom: 44px !important; }

.ml-44 {
  margin-left: 44px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.ml-45 {
  margin-left: 45px !important; }

.mt-46 {
  margin-top: 46px !important; }

.mr-46 {
  margin-right: 46px !important; }

.mb-46 {
  margin-bottom: 46px !important; }

.ml-46 {
  margin-left: 46px !important; }

.mt-47 {
  margin-top: 47px !important; }

.mr-47 {
  margin-right: 47px !important; }

.mb-47 {
  margin-bottom: 47px !important; }

.ml-47 {
  margin-left: 47px !important; }

.mt-48 {
  margin-top: 48px !important; }

.mr-48 {
  margin-right: 48px !important; }

.mb-48 {
  margin-bottom: 48px !important; }

.ml-48 {
  margin-left: 48px !important; }

.mt-49 {
  margin-top: 49px !important; }

.mr-49 {
  margin-right: 49px !important; }

.mb-49 {
  margin-bottom: 49px !important; }

.ml-49 {
  margin-left: 49px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mt-51 {
  margin-top: 51px !important; }

.mr-51 {
  margin-right: 51px !important; }

.mb-51 {
  margin-bottom: 51px !important; }

.ml-51 {
  margin-left: 51px !important; }

.mt-52 {
  margin-top: 52px !important; }

.mr-52 {
  margin-right: 52px !important; }

.mb-52 {
  margin-bottom: 52px !important; }

.ml-52 {
  margin-left: 52px !important; }

.mt-53 {
  margin-top: 53px !important; }

.mr-53 {
  margin-right: 53px !important; }

.mb-53 {
  margin-bottom: 53px !important; }

.ml-53 {
  margin-left: 53px !important; }

.mt-54 {
  margin-top: 54px !important; }

.mr-54 {
  margin-right: 54px !important; }

.mb-54 {
  margin-bottom: 54px !important; }

.ml-54 {
  margin-left: 54px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.ml-55 {
  margin-left: 55px !important; }

.mt-56 {
  margin-top: 56px !important; }

.mr-56 {
  margin-right: 56px !important; }

.mb-56 {
  margin-bottom: 56px !important; }

.ml-56 {
  margin-left: 56px !important; }

.mt-57 {
  margin-top: 57px !important; }

.mr-57 {
  margin-right: 57px !important; }

.mb-57 {
  margin-bottom: 57px !important; }

.ml-57 {
  margin-left: 57px !important; }

.mt-58 {
  margin-top: 58px !important; }

.mr-58 {
  margin-right: 58px !important; }

.mb-58 {
  margin-bottom: 58px !important; }

.ml-58 {
  margin-left: 58px !important; }

.mt-59 {
  margin-top: 59px !important; }

.mr-59 {
  margin-right: 59px !important; }

.mb-59 {
  margin-bottom: 59px !important; }

.ml-59 {
  margin-left: 59px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.ml-60 {
  margin-left: 60px !important; }

.mt-61 {
  margin-top: 61px !important; }

.mr-61 {
  margin-right: 61px !important; }

.mb-61 {
  margin-bottom: 61px !important; }

.ml-61 {
  margin-left: 61px !important; }

.mt-62 {
  margin-top: 62px !important; }

.mr-62 {
  margin-right: 62px !important; }

.mb-62 {
  margin-bottom: 62px !important; }

.ml-62 {
  margin-left: 62px !important; }

.mt-63 {
  margin-top: 63px !important; }

.mr-63 {
  margin-right: 63px !important; }

.mb-63 {
  margin-bottom: 63px !important; }

.ml-63 {
  margin-left: 63px !important; }

.mt-64 {
  margin-top: 64px !important; }

.mr-64 {
  margin-right: 64px !important; }

.mb-64 {
  margin-bottom: 64px !important; }

.ml-64 {
  margin-left: 64px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.ml-65 {
  margin-left: 65px !important; }

.mt-66 {
  margin-top: 66px !important; }

.mr-66 {
  margin-right: 66px !important; }

.mb-66 {
  margin-bottom: 66px !important; }

.ml-66 {
  margin-left: 66px !important; }

.mt-67 {
  margin-top: 67px !important; }

.mr-67 {
  margin-right: 67px !important; }

.mb-67 {
  margin-bottom: 67px !important; }

.ml-67 {
  margin-left: 67px !important; }

.mt-68 {
  margin-top: 68px !important; }

.mr-68 {
  margin-right: 68px !important; }

.mb-68 {
  margin-bottom: 68px !important; }

.ml-68 {
  margin-left: 68px !important; }

.mt-69 {
  margin-top: 69px !important; }

.mr-69 {
  margin-right: 69px !important; }

.mb-69 {
  margin-bottom: 69px !important; }

.ml-69 {
  margin-left: 69px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.ml-70 {
  margin-left: 70px !important; }

.mt-71 {
  margin-top: 71px !important; }

.mr-71 {
  margin-right: 71px !important; }

.mb-71 {
  margin-bottom: 71px !important; }

.ml-71 {
  margin-left: 71px !important; }

.mt-72 {
  margin-top: 72px !important; }

.mr-72 {
  margin-right: 72px !important; }

.mb-72 {
  margin-bottom: 72px !important; }

.ml-72 {
  margin-left: 72px !important; }

.mt-73 {
  margin-top: 73px !important; }

.mr-73 {
  margin-right: 73px !important; }

.mb-73 {
  margin-bottom: 73px !important; }

.ml-73 {
  margin-left: 73px !important; }

.mt-74 {
  margin-top: 74px !important; }

.mr-74 {
  margin-right: 74px !important; }

.mb-74 {
  margin-bottom: 74px !important; }

.ml-74 {
  margin-left: 74px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.ml-75 {
  margin-left: 75px !important; }

.mt-76 {
  margin-top: 76px !important; }

.mr-76 {
  margin-right: 76px !important; }

.mb-76 {
  margin-bottom: 76px !important; }

.ml-76 {
  margin-left: 76px !important; }

.mt-77 {
  margin-top: 77px !important; }

.mr-77 {
  margin-right: 77px !important; }

.mb-77 {
  margin-bottom: 77px !important; }

.ml-77 {
  margin-left: 77px !important; }

.mt-78 {
  margin-top: 78px !important; }

.mr-78 {
  margin-right: 78px !important; }

.mb-78 {
  margin-bottom: 78px !important; }

.ml-78 {
  margin-left: 78px !important; }

.mt-79 {
  margin-top: 79px !important; }

.mr-79 {
  margin-right: 79px !important; }

.mb-79 {
  margin-bottom: 79px !important; }

.ml-79 {
  margin-left: 79px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.ml-80 {
  margin-left: 80px !important; }

.mt-81 {
  margin-top: 81px !important; }

.mr-81 {
  margin-right: 81px !important; }

.mb-81 {
  margin-bottom: 81px !important; }

.ml-81 {
  margin-left: 81px !important; }

.mt-82 {
  margin-top: 82px !important; }

.mr-82 {
  margin-right: 82px !important; }

.mb-82 {
  margin-bottom: 82px !important; }

.ml-82 {
  margin-left: 82px !important; }

.mt-83 {
  margin-top: 83px !important; }

.mr-83 {
  margin-right: 83px !important; }

.mb-83 {
  margin-bottom: 83px !important; }

.ml-83 {
  margin-left: 83px !important; }

.mt-84 {
  margin-top: 84px !important; }

.mr-84 {
  margin-right: 84px !important; }

.mb-84 {
  margin-bottom: 84px !important; }

.ml-84 {
  margin-left: 84px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.ml-85 {
  margin-left: 85px !important; }

.mt-86 {
  margin-top: 86px !important; }

.mr-86 {
  margin-right: 86px !important; }

.mb-86 {
  margin-bottom: 86px !important; }

.ml-86 {
  margin-left: 86px !important; }

.mt-87 {
  margin-top: 87px !important; }

.mr-87 {
  margin-right: 87px !important; }

.mb-87 {
  margin-bottom: 87px !important; }

.ml-87 {
  margin-left: 87px !important; }

.mt-88 {
  margin-top: 88px !important; }

.mr-88 {
  margin-right: 88px !important; }

.mb-88 {
  margin-bottom: 88px !important; }

.ml-88 {
  margin-left: 88px !important; }

.mt-89 {
  margin-top: 89px !important; }

.mr-89 {
  margin-right: 89px !important; }

.mb-89 {
  margin-bottom: 89px !important; }

.ml-89 {
  margin-left: 89px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.ml-90 {
  margin-left: 90px !important; }

.mt-91 {
  margin-top: 91px !important; }

.mr-91 {
  margin-right: 91px !important; }

.mb-91 {
  margin-bottom: 91px !important; }

.ml-91 {
  margin-left: 91px !important; }

.mt-92 {
  margin-top: 92px !important; }

.mr-92 {
  margin-right: 92px !important; }

.mb-92 {
  margin-bottom: 92px !important; }

.ml-92 {
  margin-left: 92px !important; }

.mt-93 {
  margin-top: 93px !important; }

.mr-93 {
  margin-right: 93px !important; }

.mb-93 {
  margin-bottom: 93px !important; }

.ml-93 {
  margin-left: 93px !important; }

.mt-94 {
  margin-top: 94px !important; }

.mr-94 {
  margin-right: 94px !important; }

.mb-94 {
  margin-bottom: 94px !important; }

.ml-94 {
  margin-left: 94px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.ml-95 {
  margin-left: 95px !important; }

.mt-96 {
  margin-top: 96px !important; }

.mr-96 {
  margin-right: 96px !important; }

.mb-96 {
  margin-bottom: 96px !important; }

.ml-96 {
  margin-left: 96px !important; }

.mt-97 {
  margin-top: 97px !important; }

.mr-97 {
  margin-right: 97px !important; }

.mb-97 {
  margin-bottom: 97px !important; }

.ml-97 {
  margin-left: 97px !important; }

.mt-98 {
  margin-top: 98px !important; }

.mr-98 {
  margin-right: 98px !important; }

.mb-98 {
  margin-bottom: 98px !important; }

.ml-98 {
  margin-left: 98px !important; }

.mt-99 {
  margin-top: 99px !important; }

.mr-99 {
  margin-right: 99px !important; }

.mb-99 {
  margin-bottom: 99px !important; }

.ml-99 {
  margin-left: 99px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.ml-100 {
  margin-left: 100px !important; }

.w-button_button__3etnB {
  border: 0;
  height: 0;
  padding: 0;
  width: 0; }

.w-checkbox_base__141xQ {
  cursor: pointer;
  height: 16px;
  width: 16px; }
  .w-checkbox_base__141xQ svg {
    height: 100%;
    width: 100%; }

.w-checkbox_disabled__37cRL {
  cursor: none;
  opacity: 0.7;
  pointer-events: none; }

.w-input_white__Wy68i {
  color: #f9f9f9; }

.w-input_black__GJ99M {
  color: #000; }

.w-input_deep-teal__zgOHp {
  color: #014660; }

.w-input_greyish-teal__16jAY {
  color: #5bdbbd; }

.w-input_very-light-grey__1BHRP {
  color: #b9b9b9; }

.w-input_warm-grey__31zo8 {
  color: #55556f; }

.w-input_simple-grey__3s62C {
  color: #d4d4d8; }

.w-input_dark-grey___Xuxp {
  color: #222026; }

.w-input_flat-white__yfdBN {
  color: #fff; }

.w-input_pastel-red__2HnzJ {
  color: #e55e50; }

.w-input_white-bg__1kpLj {
  background-color: #f9f9f9; }

.w-input_deep-teal-bg__18jju {
  background-color: #014660;
  color: #fff; }

.w-input_greyish-teal-bg__VTJuw {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-input_very-light-grey-bg__3K7AT {
  background-color: #b9b9b9;
  color: #222026; }

.w-input_warm-grey-bg__2gTUi {
  background-color: #55556f;
  color: #f9f9f9; }

.w-input_simple-grey-bg__3YnRi {
  background-color: #d4d4d8;
  color: #222026; }

.w-input_dark-grey-bg__39BKI {
  background-color: #222026;
  color: #fff; }

.w-input_flat-white-bg__3uKmY {
  background-color: #fff;
  color: #014660; }

.w-input_pastel-red-bg__3CF8c {
  background-color: #e55e50;
  color: #f9f9f9; }

.w-input_w-input__2Py9s {
  border: 0;
  border-radius: 20px;
  box-shadow: 0 0 2px #4d4d4d;
  color: #222026;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  min-height: 20px;
  padding: 3px 10px;
  position: relative;
  resize: none;
  width: 100%; }
  .w-input_w-inputtextarea__1ef2H {
    padding: 15px; }
  .w-input_w-input__2Py9s:focus {
    box-shadow: 0 0 5px rgba(91, 219, 189, 0.5);
    outline: none; }
  .w-input_w-input__2Py9s:disabled {
    background-color: rgba(85, 85, 111, 0.1); }

.w-input_eye-wrapper__3HZo6 {
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  right: 10px;
  top: 0;
  width: 22px;
  z-index: 1; }
  .w-input_eye-wrapper__3HZo6 svg {
    width: 100%; }

.w-input_w-input-wrapper__1x2lI {
  margin-right: 30px;
  position: relative;
  width: 100%; }

.w-input_after__1SuVU {
  position: absolute;
  right: 0; }

.w-input_w-search-wrapper__nd_Md {
  display: block; }
  .w-input_w-search-wrapper__nd_Md.w-input_default__1aj7y {
    background-color: rgba(212, 212, 216, 0.2);
    padding: 20px 20px 40px; }
  .w-input_w-search-wrapper__nd_Md.w-input_simple__ARJD8 {
    background-color: transparent; }
  .w-input_w-search-wrapper__nd_Md label {
    display: block;
    margin-bottom: 10px; }
  .w-input_w-search-wrapper__nd_Md div {
    position: relative; }
  .w-input_w-search-wrapper__nd_Md .w-input_clear-wrapper__2Ct2i {
    align-items: center;
    bottom: 0;
    display: flex;
    position: absolute;
    right: 7px;
    top: 0;
    z-index: 1; }
  .w-input_w-search-wrapper__nd_Md svg {
    height: 21px;
    min-height: 21px;
    min-width: 21px;
    width: 21px; }
  .w-input_w-search-wrapper__nd_Md input {
    height: 35px;
    padding-right: 35px; }
  @media (max-width: 1024px) {
    .w-input_w-search-wrapper__nd_Md {
      padding: 15px 15px 25px; } }

.w-navigation_white__1gdsq {
  color: #f9f9f9; }

.w-navigation_black__2QFXH {
  color: #000; }

.w-navigation_deep-teal__3xFJV {
  color: #014660; }

.w-navigation_greyish-teal__2Ccg2 {
  color: #5bdbbd; }

.w-navigation_very-light-grey__12sQT {
  color: #b9b9b9; }

.w-navigation_warm-grey__20OXB {
  color: #55556f; }

.w-navigation_simple-grey__RLGLT {
  color: #d4d4d8; }

.w-navigation_dark-grey__3PUXD {
  color: #222026; }

.w-navigation_flat-white__2OSOG {
  color: #fff; }

.w-navigation_pastel-red__rAPnQ {
  color: #e55e50; }

.w-navigation_white-bg__1Y2DZ {
  background-color: #f9f9f9; }

.w-navigation_deep-teal-bg__27NJI {
  background-color: #014660;
  color: #fff; }

.w-navigation_greyish-teal-bg__1A3xR {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-navigation_very-light-grey-bg__1ci-Z {
  background-color: #b9b9b9;
  color: #222026; }

.w-navigation_warm-grey-bg__1u_KV {
  background-color: #55556f;
  color: #f9f9f9; }

.w-navigation_simple-grey-bg__eueEp {
  background-color: #d4d4d8;
  color: #222026; }

.w-navigation_dark-grey-bg__2Z4FP {
  background-color: #222026;
  color: #fff; }

.w-navigation_flat-white-bg__1B2gv {
  background-color: #fff;
  color: #014660; }

.w-navigation_pastel-red-bg__SUUkJ {
  background-color: #e55e50;
  color: #f9f9f9; }

.w-navigation_wrapper__DWK-M {
  display: flex;
  flex-direction: column; }

.w-navigation_dot__3LxT6 {
  border: 2px solid #55556f;
  border: 2px solid var(--color-base, #55556f);
  border-radius: 100%;
  cursor: pointer;
  height: 10px;
  margin-top: 15px;
  -webkit-transition: border 50ms linear;
  transition: border 50ms linear;
  width: 10px; }
  .w-navigation_dot__3LxT6.w-navigation_active__2VhcJ {
    border: 2px solid #5bdbbd;
    border: 2px solid var(--color-active, #5bdbbd); }

.w-select_white__bJHWd {
  color: #f9f9f9; }

.w-select_black__6tYH8 {
  color: #000; }

.w-select_deep-teal__3SfSa {
  color: #014660; }

.w-select_greyish-teal__2BhOk {
  color: #5bdbbd; }

.w-select_very-light-grey__2AqZm {
  color: #b9b9b9; }

.w-select_warm-grey__2w98_ {
  color: #55556f; }

.w-select_simple-grey__3Lef5 {
  color: #d4d4d8; }

.w-select_dark-grey__3bl1h {
  color: #222026; }

.w-select_flat-white__2M9_n {
  color: #fff; }

.w-select_pastel-red__1a_Vz {
  color: #e55e50; }

.w-select_white-bg__1xkP6 {
  background-color: #f9f9f9; }

.w-select_deep-teal-bg__2cTQq {
  background-color: #014660;
  color: #fff; }

.w-select_greyish-teal-bg__nyXV8 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-select_very-light-grey-bg__1Sf_N {
  background-color: #b9b9b9;
  color: #222026; }

.w-select_warm-grey-bg__3jTaE {
  background-color: #55556f;
  color: #f9f9f9; }

.w-select_simple-grey-bg__3290c {
  background-color: #d4d4d8;
  color: #222026; }

.w-select_dark-grey-bg__2sDj2 {
  background-color: #222026;
  color: #fff; }

.w-select_flat-white-bg__hN0dt {
  background-color: #fff;
  color: #014660; }

.w-select_pastel-red-bg__2y3Xh {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.w-select_white__bJHWd {
  color: #f9f9f9; }

.w-select_black__6tYH8 {
  color: #000; }

.w-select_deep-teal__3SfSa {
  color: #014660; }

.w-select_greyish-teal__2BhOk {
  color: #5bdbbd; }

.w-select_very-light-grey__2AqZm {
  color: #b9b9b9; }

.w-select_warm-grey__2w98_ {
  color: #55556f; }

.w-select_simple-grey__3Lef5 {
  color: #d4d4d8; }

.w-select_dark-grey__3bl1h {
  color: #222026; }

.w-select_flat-white__2M9_n {
  color: #fff; }

.w-select_pastel-red__1a_Vz {
  color: #e55e50; }

.w-select_white-bg__1xkP6 {
  background-color: #f9f9f9; }

.w-select_deep-teal-bg__2cTQq {
  background-color: #014660;
  color: #fff; }

.w-select_greyish-teal-bg__nyXV8 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-select_very-light-grey-bg__1Sf_N {
  background-color: #b9b9b9;
  color: #222026; }

.w-select_warm-grey-bg__3jTaE {
  background-color: #55556f;
  color: #f9f9f9; }

.w-select_simple-grey-bg__3290c {
  background-color: #d4d4d8;
  color: #222026; }

.w-select_dark-grey-bg__2sDj2 {
  background-color: #222026;
  color: #fff; }

.w-select_flat-white-bg__hN0dt {
  background-color: #fff;
  color: #014660; }

.w-select_pastel-red-bg__2y3Xh {
  background-color: #e55e50;
  color: #f9f9f9; }

.w-select_wrapper__gkgrt {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: 0 0 2px #4d4d4d;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; }
  .w-select_wrapper__gkgrt.w-select_opened__3NI_w, .w-select_wrapper__gkgrt:focus-within {
    border-bottom: 1px solid #4cb49c;
    border-left: 1px solid #4cb49c;
    border-right: 1px solid #4cb49c;
    border-top: 1px solid #4cb49c;
    box-shadow: none;
    outline: none; }
  .w-select_wrapper__gkgrt.w-select_opened__3NI_w .w-select_btn__3Oass svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .w-select_wrapper__gkgrt.w-select_opened__3NI_w .w-select_placeholder__tnFs9 svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .w-select_wrapper__gkgrt.w-select_top__3_mOM {
    border-radius: 0 0 13px 13px; }
  .w-select_wrapper__gkgrt.w-select_bottom__HQiJd {
    border-radius: 13px 13px 0 0; }
  .w-select_wrapper__gkgrt.w-select_disabled__3EKOC {
    background-color: rgba(147, 146, 146, 0.1);
    pointer-events: none; }
  .w-select_wrapper__gkgrt select {
    display: none; }

.w-select_selectmenu__3jpJY {
  background-color: #fff;
  border: 1px solid #5bdbbd;
  color: #4d4d4d;
  left: -1px;
  overflow: hidden;
  padding: 12px 0;
  position: absolute;
  right: 0;
  width: calc(100% + 2px);
  z-index: 100; }
  .w-select_selectmenu__3jpJY.w-select_top__3_mOM {
    border-bottom-width: 0;
    border-radius: 13px 13px 0 0;
    bottom: 100%; }
  .w-select_selectmenu__3jpJY.w-select_bottom__HQiJd {
    border-radius: 0 0 13px 13px;
    border-top-width: 0;
    margin-bottom: 20px;
    top: 100%; }

.w-select_input__3nHBF {
  background-color: transparent;
  border: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.w-select_options__3Asj9 {
  display: flex;
  flex-direction: column;
  max-height: 144px;
  overflow-y: auto; }
  .w-select_options__3Asj9 .w-select_option__28ELx {
    color: #014660;
    cursor: pointer;
    padding: 10px;
    text-align: left; }
  .w-select_options__3Asj9 .w-select_selected__l5ucd,
  .w-select_options__3Asj9 .w-select_option__28ELx:hover {
    background-color: rgba(91, 219, 189, 0.5); }
  .w-select_options__3Asj9 .w-select_selected__l5ucd {
    pointer-events: none; }

.w-select_btn__3Oass,
.w-select_placeholder__tnFs9 {
  color: #4d4d4d;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 1.3em;
  padding: 6px 8px 6px 13px; }
  .w-select_btn__3Oass svg,
  .w-select_placeholder__tnFs9 svg {
    flex: 0 0 12px;
    height: 20px;
    margin-left: 5px;
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: -webkit-transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear; }

.w-select_placeholder__tnFs9 {
  color: #797979;
  font-family: "Open Sans", sans-serif;
  padding: 6px 8px 6px 13px; }

.w-select_search__3lPZq {
  padding: 10px; }
  .w-select_search__3lPZq svg {
    margin: 5px;
    position: absolute;
    width: 20px; }
  .w-select_search__3lPZq input {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 4px #5bdbbd;
    font-size: 16px;
    height: 28px;
    line-height: 16px;
    padding-left: 32px;
    width: 100%; }

.w-select_empty__20TTo {
  color: #4d4d4d;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 20px 10px; }

.w-textarea_white__1bhay {
  color: #f9f9f9; }

.w-textarea_black__2Pkk1 {
  color: #000; }

.w-textarea_deep-teal__2jhyr {
  color: #014660; }

.w-textarea_greyish-teal__36StG {
  color: #5bdbbd; }

.w-textarea_very-light-grey__3T39T {
  color: #b9b9b9; }

.w-textarea_warm-grey__2KNe5 {
  color: #55556f; }

.w-textarea_simple-grey__1WOrW {
  color: #d4d4d8; }

.w-textarea_dark-grey__18y_d {
  color: #222026; }

.w-textarea_flat-white__2B_Qx {
  color: #fff; }

.w-textarea_pastel-red__1v8mO {
  color: #e55e50; }

.w-textarea_white-bg__23Y-L {
  background-color: #f9f9f9; }

.w-textarea_deep-teal-bg__1J2m2 {
  background-color: #014660;
  color: #fff; }

.w-textarea_greyish-teal-bg__1Jihb {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-textarea_very-light-grey-bg__3_yVn {
  background-color: #b9b9b9;
  color: #222026; }

.w-textarea_warm-grey-bg__2wML2 {
  background-color: #55556f;
  color: #f9f9f9; }

.w-textarea_simple-grey-bg__1VIth {
  background-color: #d4d4d8;
  color: #222026; }

.w-textarea_dark-grey-bg__3rxSR {
  background-color: #222026;
  color: #fff; }

.w-textarea_flat-white-bg__3JQJI {
  background-color: #fff;
  color: #014660; }

.w-textarea_pastel-red-bg__2_hiA {
  background-color: #e55e50;
  color: #f9f9f9; }

w-textarea {
  flex: 1 1; }

.w-textarea_w-textarea__3brCQ {
  border: 0;
  border-radius: 18.5px;
  box-shadow: 0 0 2px #4d4d4d;
  color: #222026;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  height: 100%;
  min-height: 20px;
  padding: 10px;
  position: relative;
  resize: none;
  width: 100%; }
  .w-textarea_w-textarea__3brCQ:focus {
    box-shadow: 0 0 5px rgba(91, 219, 189, 0.5);
    outline: none; }
  .w-textarea_w-textarea__3brCQ:disabled {
    background-color: rgba(85, 85, 111, 0.1); }

.w-timer_white__cudrQ {
  color: #f9f9f9; }

.w-timer_black__3Nj36 {
  color: #000; }

.w-timer_deep-teal__1Ck5A {
  color: #014660; }

.w-timer_greyish-teal__TLclA {
  color: #5bdbbd; }

.w-timer_very-light-grey__1dIHA {
  color: #b9b9b9; }

.w-timer_warm-grey__1NN2u {
  color: #55556f; }

.w-timer_simple-grey__10jgd {
  color: #d4d4d8; }

.w-timer_dark-grey__2viIQ {
  color: #222026; }

.w-timer_flat-white__3nR5F {
  color: #fff; }

.w-timer_pastel-red__1NXxx {
  color: #e55e50; }

.w-timer_white-bg__3z2jQ {
  background-color: #f9f9f9; }

.w-timer_deep-teal-bg__1hxpP {
  background-color: #014660;
  color: #fff; }

.w-timer_greyish-teal-bg__3-7LT {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.w-timer_very-light-grey-bg__1_o_e {
  background-color: #b9b9b9;
  color: #222026; }

.w-timer_warm-grey-bg__1kDr8 {
  background-color: #55556f;
  color: #f9f9f9; }

.w-timer_simple-grey-bg__3DscB {
  background-color: #d4d4d8;
  color: #222026; }

.w-timer_dark-grey-bg__dfYzD {
  background-color: #222026;
  color: #fff; }

.w-timer_flat-white-bg__2RuDh {
  background-color: #fff;
  color: #014660; }

.w-timer_pastel-red-bg__sshLc {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.w-timer_timer-wrapper__3WEqa {
  display: flex; }

.w-timer_timer-block__19jY1 {
  padding: 10px; }

.w-timer_value-area__myYea {
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 42px;
  height: 19vw;
  justify-content: center;
  max-height: 113px;
  max-width: 113px;
  width: 19vw; }
  @media (max-width: 1024px) {
    .w-timer_value-area__myYea {
      font-size: calc(2vw + 2vh); } }

.w-timer_unit-area__3tLOG {
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  margin-top: 14px;
  text-align: center;
  text-transform: capitalize; }
  @media (max-width: 1024px) {
    .w-timer_unit-area__3tLOG {
      font-size: calc(1vw + 1vh); } }

.wwc-message_white__32eht {
  color: #f9f9f9; }

.wwc-message_black__3URcs {
  color: #000; }

.wwc-message_deep-teal__2B5h1 {
  color: #014660; }

.wwc-message_greyish-teal__t5pVH {
  color: #5bdbbd; }

.wwc-message_very-light-grey__MS4-z {
  color: #b9b9b9; }

.wwc-message_warm-grey__ofcsY {
  color: #55556f; }

.wwc-message_simple-grey__-ikCv {
  color: #d4d4d8; }

.wwc-message_dark-grey__3epK2 {
  color: #222026; }

.wwc-message_flat-white__3huHE {
  color: #fff; }

.wwc-message_pastel-red__2ilKa {
  color: #e55e50; }

.wwc-message_white-bg__2TMC0 {
  background-color: #f9f9f9; }

.wwc-message_deep-teal-bg__2YgRZ {
  background-color: #014660;
  color: #fff; }

.wwc-message_greyish-teal-bg__2CfDa {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.wwc-message_very-light-grey-bg__r0Ysc {
  background-color: #b9b9b9;
  color: #222026; }

.wwc-message_warm-grey-bg__1XGLR {
  background-color: #55556f;
  color: #f9f9f9; }

.wwc-message_simple-grey-bg__IfGf8 {
  background-color: #d4d4d8;
  color: #222026; }

.wwc-message_dark-grey-bg__3x1uQ {
  background-color: #222026;
  color: #fff; }

.wwc-message_flat-white-bg__1cQ_a {
  background-color: #fff;
  color: #014660; }

.wwc-message_pastel-red-bg__3mHFD {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.wwc-message_outbox_message_wrapper__vaXjq .wwc-message_time__1KoD7, .wwc-message_inbox_message_wrapper__2EnZs .wwc-message_time__1KoD7 {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 5px; }

.wwc-message_alert_message__2YWB5 {
  display: flex;
  flex-direction: column; }
  .wwc-message_alert_message__2YWB5 h1 {
    font-size: 20px; }

.wwc-message_alert_message_buttons__2fycF {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 -20px;
  width: 100%; }
  .wwc-message_alert_message_buttons__2fycF button {
    font-size: 14px;
    padding: 5px 25px; }
  .wwc-message_alert_message_buttons__2fycF button:not(:last-of-type) {
    margin-right: 20px; }
  .wwc-message_alert_message_buttons__2fycF button:last-of-type {
    margin-right: 10px; }
  .wwc-message_alert_message_buttons__2fycF button:first-of-type {
    padding: 3px 25px; }

.wwc-message_outbox_message_wrapper__vaXjq {
  display: flex; }
  .wwc-message_outbox_message_wrapper__vaXjq .wwc-message_outbox_info__3dB1a {
    margin-left: 5px; }
  @media (min-width: 1025px) {
    .wwc-message_outbox_message_wrapper__vaXjq {
      max-width: 550px; } }

.wwc-message_message__30GJF {
  border-radius: 12px 12px 0;
  line-height: 1.3;
  padding: 10px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column; }
  .wwc-message_message__30GJF h1,
  .wwc-message_message__30GJF h2 {
    font-family: "Questrial", sans-serif; }
  .wwc-message_message__30GJF h1 {
    font-size: 24px; }
  .wwc-message_message__30GJF h2 {
    font-size: 20px; }
  .wwc-message_message__30GJF a {
    word-break: break-all; }

.wwc-message_outbox_message__16RAM {
  background-color: #d4d4d8;
  border-radius: 12px 12px 12px 0;
  color: #222026;
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal; }

.wwc-message_inbox_message_wrapper__2EnZs {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  min-width: 80px; }
  .wwc-message_inbox_message_wrapper__2EnZs .wwc-message_time__1KoD7 {
    margin-right: 5px;
    text-align: end; }
  .wwc-message_inbox_message_wrapper__2EnZs .wwc-message_inbox_info__sMqu2 {
    margin-right: 10px; }

.wwc-message_inbox_message__zM6xw {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #5bdbbd;
  display: flex;
  justify-content: flex-end; }
  .wwc-message_inbox_message__zM6xw::after {
    background-color: transparent;
    box-shadow: 9px 1px 0 0 #5bdbbd;
    right: -22px;
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1); }
  .wwc-message_inbox_message__zM6xw[state="pending"] {
    background: #ccc; }
  .wwc-message_inbox_message__zM6xw[state="deleted"] {
    background: #ccc;
    color: #7c7c7c; }

.wwc-message_preview_wrapper__1gvCa {
  border-radius: 10px 10px 0 0;
  margin: -10px -15px 5px;
  overflow: hidden; }

.wwc-message_spotify__3R-o6 {
  position: relative; }

.wwc-message_youtube__2oE1c {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative; }
  .wwc-message_youtube__2oE1c iframe,
  .wwc-message_youtube__2oE1c object,
  .wwc-message_youtube__2oE1c embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.templates_white__1q3TL {
  color: #f9f9f9; }

.templates_black__3k1oH {
  color: #000; }

.templates_deep-teal__14DKf {
  color: #014660; }

.templates_greyish-teal__rXUM_ {
  color: #5bdbbd; }

.templates_very-light-grey__2-0lF {
  color: #b9b9b9; }

.templates_warm-grey__3Gfg6 {
  color: #55556f; }

.templates_simple-grey__2PeKV {
  color: #d4d4d8; }

.templates_dark-grey__1g7-Q {
  color: #222026; }

.templates_flat-white__2f5ro {
  color: #fff; }

.templates_pastel-red__jgCIi {
  color: #e55e50; }

.templates_white-bg__2UsaJ {
  background-color: #f9f9f9; }

.templates_deep-teal-bg__jiymA {
  background-color: #014660;
  color: #fff; }

.templates_greyish-teal-bg__3pEB0 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.templates_very-light-grey-bg__1bjeI {
  background-color: #b9b9b9;
  color: #222026; }

.templates_warm-grey-bg__JWUCQ {
  background-color: #55556f;
  color: #f9f9f9; }

.templates_simple-grey-bg__jsWoJ {
  background-color: #d4d4d8;
  color: #222026; }

.templates_dark-grey-bg__35eig {
  background-color: #222026;
  color: #fff; }

.templates_flat-white-bg__brnbm {
  background-color: #fff;
  color: #014660; }

.templates_pastel-red-bg__5RnWh {
  background-color: #e55e50;
  color: #f9f9f9; }

.templates_bullet-1__1JJor, .templates_bullet-2__1b1XQ, .templates_ordered-1__2ARzm, .templates_ordered-2__1mMUz {
  padding-left: 10px; }

.templates_bullet-0__3HE6v {
  list-style: disc inside;
  margin: 10px 0; }

.templates_bullet-1__1JJor {
  list-style: circle inside; }

.templates_bullet-2__1b1XQ {
  list-style: circle inside; }

.templates_ordered-0__2n7bE {
  list-style: decimal inside;
  margin: 10px 0; }

.templates_ordered-1__2ARzm {
  list-style: lower-latin inside; }

.templates_ordered-2__1mMUz {
  list-style: lower-roman inside; }

.templates_mention__1vD-M {
  background-color: rgba(1, 70, 96, 0.5);
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 5px 0;
  padding: 5px 7px;
  white-space: pre; }

.templates_emoji__3M5MJ {
  margin: 0 5px; }
  .templates_emoji__3M5MJ img {
    max-height: 40px;
    max-width: 40px; }

.templates_alone-emoji__6qK-n img {
  max-height: 20px;
  max-width: 20px; }

.templates_wrapper___yadQ {
  position: relative; }
  .templates_wrapper___yadQ .templates_anchor__2vaTs {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    position: absolute;
    right: 8px;
    top: 8px; }
    .templates_wrapper___yadQ .templates_anchor__2vaTs svg {
      width: 20px; }

.wwc-message-context_white__2i97s {
  color: #f9f9f9; }

.wwc-message-context_black__2PHbB {
  color: #000; }

.wwc-message-context_deep-teal__1BZGf {
  color: #014660; }

.wwc-message-context_greyish-teal__2NkTp {
  color: #5bdbbd; }

.wwc-message-context_very-light-grey__3qBNu {
  color: #b9b9b9; }

.wwc-message-context_warm-grey__2Bwp5 {
  color: #55556f; }

.wwc-message-context_simple-grey__1dSrp {
  color: #d4d4d8; }

.wwc-message-context_dark-grey__D7q8x {
  color: #222026; }

.wwc-message-context_flat-white__3x0PS {
  color: #fff; }

.wwc-message-context_pastel-red__sZ0PY {
  color: #e55e50; }

.wwc-message-context_white-bg__2jHOy {
  background-color: #f9f9f9; }

.wwc-message-context_deep-teal-bg__KVgDz {
  background-color: #014660;
  color: #fff; }

.wwc-message-context_greyish-teal-bg__3NBoj {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.wwc-message-context_very-light-grey-bg__2b1Oi {
  background-color: #b9b9b9;
  color: #222026; }

.wwc-message-context_warm-grey-bg__1ESd0 {
  background-color: #55556f;
  color: #f9f9f9; }

.wwc-message-context_simple-grey-bg__18POU {
  background-color: #d4d4d8;
  color: #222026; }

.wwc-message-context_dark-grey-bg__wfJ0- {
  background-color: #222026;
  color: #fff; }

.wwc-message-context_flat-white-bg__1O7Q6 {
  background-color: #fff;
  color: #014660; }

.wwc-message-context_pastel-red-bg__2IllD {
  background-color: #e55e50;
  color: #f9f9f9; }

.wwc-message-context_context_menu__1Askl {
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  width: 250px;
  z-index: 2; }
  @media (max-width: 1024px) {
    .wwc-message-context_context_menu__1Askl {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: -100%;
      width: 100%; } }
  .wwc-message-context_context_menu__1Askl section {
    background-color: #fff;
    border-radius: 10px; }
  .wwc-message-context_context_menu__1Askl button, .wwc-message-context_context_menu__1Askl button:active, .wwc-message-context_context_menu__1Askl button:focus {
    background: transparent;
    border: 0;
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    outline: none;
    padding: 10px 20px;
    text-align: left;
    width: 100%; }
  .wwc-message-context_context_menu__1Askl button:not(:last-of-type), .wwc-message-context_context_menu__1Askl button:active:not(:last-of-type), .wwc-message-context_context_menu__1Askl button:focus:not(:last-of-type) {
    border: 0 solid rgba(185, 185, 185, 0.1);
    border-bottom-width: 1px; }
  .wwc-message-context_context_menu__1Askl button:hover:first-of-type:not(:disabled) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .wwc-message-context_context_menu__1Askl button:hover:last-of-type:not(:disabled) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
  @media (max-width: 1024px) {
    .wwc-message-context_context_menu__1Askl button:hover:first-of-type:not(:disabled), .wwc-message-context_context_menu__1Askl button:hover:last-of-type:not(:disabled) {
      border-radius: 0; } }
  .wwc-message-context_context_menu__1Askl button:hover:not(:disabled) {
    background-color: #f2f2f2; }
  .wwc-message-context_context_menu__1Askl button:disabled {
    color: #55556f;
    opacity: 0.7; }

.wwc-message-context_backdrop__2y0F8 {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

.wwc-message-context_backdrop_in__1UlZ7 {
  -webkit-animation: wwc-message-context_showBackdrop__1GsSA 400ms forwards;
          animation: wwc-message-context_showBackdrop__1GsSA 400ms forwards;
  opacity: 0; }

.wwc-message-context_backdrop_out__3Q8LN {
  -webkit-animation: wwc-message-context_hideBackdrop__3-uqQ 400ms forwards;
          animation: wwc-message-context_hideBackdrop__3-uqQ 400ms forwards;
  opacity: 1; }

.wwc-message-context_context_in__OZq7y {
  -webkit-animation: wwc-message-context_showMenu__v0kWJ 400ms forwards;
          animation: wwc-message-context_showMenu__v0kWJ 400ms forwards; }

.wwc-message-context_context_out__2sASD {
  -webkit-animation: wwc-message-context_hideMenu__2e35t 400ms forwards;
          animation: wwc-message-context_hideMenu__2e35t 400ms forwards;
  bottom: 0; }

@-webkit-keyframes wwc-message-context_showMenu__v0kWJ {
  from {
    bottom: -100%; }
  to {
    bottom: 0; } }

@keyframes wwc-message-context_showMenu__v0kWJ {
  from {
    bottom: -100%; }
  to {
    bottom: 0; } }

@-webkit-keyframes wwc-message-context_showBackdrop__1GsSA {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes wwc-message-context_showBackdrop__1GsSA {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes wwc-message-context_hideMenu__2e35t {
  from {
    bottom: 0; }
  to {
    bottom: -100%; } }

@keyframes wwc-message-context_hideMenu__2e35t {
  from {
    bottom: 0; }
  to {
    bottom: -100%; } }

@-webkit-keyframes wwc-message-context_hideBackdrop__3-uqQ {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes wwc-message-context_hideBackdrop__3-uqQ {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.forgot-password_white__1a1Ce {
  color: #f9f9f9; }

.forgot-password_black__2bbbz {
  color: #000; }

.forgot-password_deep-teal__1ObY6 {
  color: #014660; }

.forgot-password_greyish-teal__2sfXW {
  color: #5bdbbd; }

.forgot-password_very-light-grey__2Zoow {
  color: #b9b9b9; }

.forgot-password_warm-grey__1d3Nc {
  color: #55556f; }

.forgot-password_simple-grey__3jZHo {
  color: #d4d4d8; }

.forgot-password_dark-grey__2m2Dm {
  color: #222026; }

.forgot-password_flat-white__32iQ9 {
  color: #fff; }

.forgot-password_pastel-red__1Y2e0 {
  color: #e55e50; }

.forgot-password_white-bg__3MT9Q {
  background-color: #f9f9f9; }

.forgot-password_deep-teal-bg__1km5t {
  background-color: #014660;
  color: #fff; }

.forgot-password_greyish-teal-bg__17Ez1 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.forgot-password_very-light-grey-bg__3MSqZ {
  background-color: #b9b9b9;
  color: #222026; }

.forgot-password_warm-grey-bg__GPSv1 {
  background-color: #55556f;
  color: #f9f9f9; }

.forgot-password_simple-grey-bg__1LRp1 {
  background-color: #d4d4d8;
  color: #222026; }

.forgot-password_dark-grey-bg__j81E1 {
  background-color: #222026;
  color: #fff; }

.forgot-password_flat-white-bg__3LukB {
  background-color: #fff;
  color: #014660; }

.forgot-password_pastel-red-bg__2eIBN {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.forgot-password_wrapper__zkjxU form {
  display: flex;
  flex-direction: column; }

.forgot-password_wrapper__zkjxU p {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(85, 85, 111, 0.2);
  padding: 20px; }

.forgot-password_wrapper__zkjxU a {
  text-decoration: underline; }

.forgot-password_wrapper__zkjxU a:active,
.forgot-password_wrapper__zkjxU a:focus {
  outline: none; }

.forgot-password_buttons__-zxTp {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px; }

.get-link_white__2CGv- {
  color: #f9f9f9; }

.get-link_black__2Iejz {
  color: #000; }

.get-link_deep-teal__clRrF {
  color: #014660; }

.get-link_greyish-teal__hwQzQ {
  color: #5bdbbd; }

.get-link_very-light-grey__2y--v {
  color: #b9b9b9; }

.get-link_warm-grey__2keId {
  color: #55556f; }

.get-link_simple-grey__1Yaam {
  color: #d4d4d8; }

.get-link_dark-grey__Q2KQx {
  color: #222026; }

.get-link_flat-white__2YXdG {
  color: #fff; }

.get-link_pastel-red__3XRdz {
  color: #e55e50; }

.get-link_white-bg__3Kf-f {
  background-color: #f9f9f9; }

.get-link_deep-teal-bg__3DKa_ {
  background-color: #014660;
  color: #fff; }

.get-link_greyish-teal-bg__2Aj95 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.get-link_very-light-grey-bg__3ydXj {
  background-color: #b9b9b9;
  color: #222026; }

.get-link_warm-grey-bg__gZyK- {
  background-color: #55556f;
  color: #f9f9f9; }

.get-link_simple-grey-bg__2ylJX {
  background-color: #d4d4d8;
  color: #222026; }

.get-link_dark-grey-bg__10NnJ {
  background-color: #222026;
  color: #fff; }

.get-link_flat-white-bg__1mzVG {
  background-color: #fff;
  color: #014660; }

.get-link_pastel-red-bg__3kLg7 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.get-link_wrapper__17Z46 form {
  display: flex;
  flex-direction: column; }

.get-link_wrapper__17Z46 button {
  align-self: flex-end;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px; }

.login_white__5-0gS {
  color: #f9f9f9; }

.login_black__X6M-n {
  color: #000; }

.login_deep-teal__1Fs8o {
  color: #014660; }

.login_greyish-teal__1KkR3 {
  color: #5bdbbd; }

.login_very-light-grey__2T6Wq {
  color: #b9b9b9; }

.login_warm-grey__3OuGb {
  color: #55556f; }

.login_simple-grey__35F3w {
  color: #d4d4d8; }

.login_dark-grey__1CA4b {
  color: #222026; }

.login_flat-white__1_Zqg {
  color: #fff; }

.login_pastel-red___f9CU {
  color: #e55e50; }

.login_white-bg__2jKEA {
  background-color: #f9f9f9; }

.login_deep-teal-bg__1MCPn {
  background-color: #014660;
  color: #fff; }

.login_greyish-teal-bg__2CiEb {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.login_very-light-grey-bg__3bbe8 {
  background-color: #b9b9b9;
  color: #222026; }

.login_warm-grey-bg__3uQw3 {
  background-color: #55556f;
  color: #f9f9f9; }

.login_simple-grey-bg__3DF-a {
  background-color: #d4d4d8;
  color: #222026; }

.login_dark-grey-bg__2gKiH {
  background-color: #222026;
  color: #fff; }

.login_flat-white-bg__2zQ9Z {
  background-color: #fff;
  color: #014660; }

.login_pastel-red-bg__3wTbU {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.login_wrapper__3Z-Cz form {
  display: flex;
  flex-direction: column; }

.login_wrapper__3Z-Cz a:active,
.login_wrapper__3Z-Cz a:focus {
  outline: none; }

.login_wrapper__3Z-Cz w-input {
  margin-right: 30px;
  width: 100%; }

.login_buttons__1VKYz {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px; }

.register_white__27Sp- {
  color: #f9f9f9; }

.register_black__1t8ct {
  color: #000; }

.register_deep-teal__TxJq0 {
  color: #014660; }

.register_greyish-teal__36Wg- {
  color: #5bdbbd; }

.register_very-light-grey__22izJ {
  color: #b9b9b9; }

.register_warm-grey__15nWy {
  color: #55556f; }

.register_simple-grey__3kzBS {
  color: #d4d4d8; }

.register_dark-grey__2YvB2 {
  color: #222026; }

.register_flat-white__2V64i {
  color: #fff; }

.register_pastel-red__25e_j {
  color: #e55e50; }

.register_white-bg__3vVsy {
  background-color: #f9f9f9; }

.register_deep-teal-bg__N8JKx {
  background-color: #014660;
  color: #fff; }

.register_greyish-teal-bg__3EX5A {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.register_very-light-grey-bg__Fu5AU {
  background-color: #b9b9b9;
  color: #222026; }

.register_warm-grey-bg__1QCWc {
  background-color: #55556f;
  color: #f9f9f9; }

.register_simple-grey-bg__38ATS {
  background-color: #d4d4d8;
  color: #222026; }

.register_dark-grey-bg__30g82 {
  background-color: #222026;
  color: #fff; }

.register_flat-white-bg__9Lhaf {
  background-color: #fff;
  color: #014660; }

.register_pastel-red-bg__vjkp3 {
  background-color: #e55e50;
  color: #f9f9f9; }

.register_wrapper__37LyV form {
  display: flex;
  flex-direction: column; }

.register_wrapper__37LyV w-validation-box {
  margin-bottom: 1px; }

.register_wrapper__37LyV button {
  align-self: flex-end;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px; }

.register_wrapper__37LyV .register_error__hRmj1 {
  background-color: rgba(229, 94, 80, 0.2);
  color: #e55e50;
  font-size: 14px;
  padding: 20px; }

.register-interest_white__1D1wr {
  color: #f9f9f9; }

.register-interest_black__18t69 {
  color: #000; }

.register-interest_deep-teal__3Rha4 {
  color: #014660; }

.register-interest_greyish-teal__1eoQ7 {
  color: #5bdbbd; }

.register-interest_very-light-grey__2TLTh {
  color: #b9b9b9; }

.register-interest_warm-grey__Wy8Yx {
  color: #55556f; }

.register-interest_simple-grey__3b8tl {
  color: #d4d4d8; }

.register-interest_dark-grey__2b-Ed {
  color: #222026; }

.register-interest_flat-white__3e6jI {
  color: #fff; }

.register-interest_pastel-red__2KpU1 {
  color: #e55e50; }

.register-interest_white-bg__1r_hY {
  background-color: #f9f9f9; }

.register-interest_deep-teal-bg__2ZrLE {
  background-color: #014660;
  color: #fff; }

.register-interest_greyish-teal-bg__ZyGYn {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.register-interest_very-light-grey-bg__HvEc3 {
  background-color: #b9b9b9;
  color: #222026; }

.register-interest_warm-grey-bg__2LV1p {
  background-color: #55556f;
  color: #f9f9f9; }

.register-interest_simple-grey-bg__3-94t {
  background-color: #d4d4d8;
  color: #222026; }

.register-interest_dark-grey-bg__1i7Qd {
  background-color: #222026;
  color: #fff; }

.register-interest_flat-white-bg__30vAB {
  background-color: #fff;
  color: #014660; }

.register-interest_pastel-red-bg__2MDHS {
  background-color: #e55e50;
  color: #f9f9f9; }

.register-interest_white__1D1wr {
  color: #f9f9f9; }

.register-interest_black__18t69 {
  color: #000; }

.register-interest_deep-teal__3Rha4 {
  color: #014660; }

.register-interest_greyish-teal__1eoQ7 {
  color: #5bdbbd; }

.register-interest_very-light-grey__2TLTh {
  color: #b9b9b9; }

.register-interest_warm-grey__Wy8Yx {
  color: #55556f; }

.register-interest_simple-grey__3b8tl {
  color: #d4d4d8; }

.register-interest_dark-grey__2b-Ed {
  color: #222026; }

.register-interest_flat-white__3e6jI {
  color: #fff; }

.register-interest_pastel-red__2KpU1 {
  color: #e55e50; }

.register-interest_white-bg__1r_hY {
  background-color: #f9f9f9; }

.register-interest_deep-teal-bg__2ZrLE {
  background-color: #014660;
  color: #fff; }

.register-interest_greyish-teal-bg__ZyGYn {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.register-interest_very-light-grey-bg__HvEc3 {
  background-color: #b9b9b9;
  color: #222026; }

.register-interest_warm-grey-bg__2LV1p {
  background-color: #55556f;
  color: #f9f9f9; }

.register-interest_simple-grey-bg__3-94t {
  background-color: #d4d4d8;
  color: #222026; }

.register-interest_dark-grey-bg__1i7Qd {
  background-color: #222026;
  color: #fff; }

.register-interest_flat-white-bg__30vAB {
  background-color: #fff;
  color: #014660; }

.register-interest_pastel-red-bg__2MDHS {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.register-interest_wrapper__3_D4G {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  flex-direction: column; }

.register-interest_request__sAUOD,
.register-interest_mandatory__2OMEu {
  align-items: center;
  background-color: #f9f9f9;
  color: #222026;
  display: flex;
  padding: 20px; }

.register-interest_request__sAUOD {
  justify-content: center; }

.register-interest_desc__22EIJ {
  background-color: rgba(212, 212, 216, 0.2);
  padding: 20px; }

.register-interest_email__3prHK {
  background-color: rgba(212, 212, 216, 0.2); }

.register-interest_buttons__2PSR7 {
  display: flex;
  justify-content: space-between;
  padding: 20px; }

.register-interest_loading__2Qx5A {
  align-items: center;
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  height: 80px;
  justify-content: center; }

.registration-closed_white__1qe1Z {
  color: #f9f9f9; }

.registration-closed_black__1uDQ5 {
  color: #000; }

.registration-closed_deep-teal__IO8Yw {
  color: #014660; }

.registration-closed_greyish-teal__lW09P {
  color: #5bdbbd; }

.registration-closed_very-light-grey__1OPjU {
  color: #b9b9b9; }

.registration-closed_warm-grey__36IhM {
  color: #55556f; }

.registration-closed_simple-grey__1DQg8 {
  color: #d4d4d8; }

.registration-closed_dark-grey__HjA1I {
  color: #222026; }

.registration-closed_flat-white__1n3Td {
  color: #fff; }

.registration-closed_pastel-red__vKvEn {
  color: #e55e50; }

.registration-closed_white-bg__3VnKJ {
  background-color: #f9f9f9; }

.registration-closed_deep-teal-bg__1CaFa {
  background-color: #014660;
  color: #fff; }

.registration-closed_greyish-teal-bg__2U-Gm {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.registration-closed_very-light-grey-bg__u4DWU {
  background-color: #b9b9b9;
  color: #222026; }

.registration-closed_warm-grey-bg__2fVp5 {
  background-color: #55556f;
  color: #f9f9f9; }

.registration-closed_simple-grey-bg__X0gO7 {
  background-color: #d4d4d8;
  color: #222026; }

.registration-closed_dark-grey-bg__NVVO9 {
  background-color: #222026;
  color: #fff; }

.registration-closed_flat-white-bg__7Ubvu {
  background-color: #fff;
  color: #014660; }

.registration-closed_pastel-red-bg__1xYjS {
  background-color: #e55e50;
  color: #f9f9f9; }

.registration-closed_wrapper__3_zs4 {
  color: #222026;
  padding: 50px 40px 15px; }
  .registration-closed_wrapper__3_zs4 h2 {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px; }
  .registration-closed_wrapper__3_zs4 p {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 15px; }
  .registration-closed_wrapper__3_zs4 w-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0; }

.reset-password_white__32hBT {
  color: #f9f9f9; }

.reset-password_black__tRTV1 {
  color: #000; }

.reset-password_deep-teal__19DsU {
  color: #014660; }

.reset-password_greyish-teal__1kRjs {
  color: #5bdbbd; }

.reset-password_very-light-grey__lIZBM {
  color: #b9b9b9; }

.reset-password_warm-grey__2XDBm {
  color: #55556f; }

.reset-password_simple-grey__3clRg {
  color: #d4d4d8; }

.reset-password_dark-grey__i7_XX {
  color: #222026; }

.reset-password_flat-white__34bOC {
  color: #fff; }

.reset-password_pastel-red__13kcI {
  color: #e55e50; }

.reset-password_white-bg__3dU0V {
  background-color: #f9f9f9; }

.reset-password_deep-teal-bg__1HK0u {
  background-color: #014660;
  color: #fff; }

.reset-password_greyish-teal-bg__3GzqN {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.reset-password_very-light-grey-bg__2sqTj {
  background-color: #b9b9b9;
  color: #222026; }

.reset-password_warm-grey-bg__18Cx5 {
  background-color: #55556f;
  color: #f9f9f9; }

.reset-password_simple-grey-bg__33uh9 {
  background-color: #d4d4d8;
  color: #222026; }

.reset-password_dark-grey-bg__2TmBd {
  background-color: #222026;
  color: #fff; }

.reset-password_flat-white-bg__3JMds {
  background-color: #fff;
  color: #014660; }

.reset-password_pastel-red-bg__Bg5mX {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.reset-password_wrapper__9JswD form {
  display: flex;
  flex-direction: column; }
  .reset-password_wrapper__9JswD form .reset-password_validation__1poci {
    display: flex;
    padding: 20px; }
    .reset-password_wrapper__9JswD form .reset-password_validation__1poci .reset-password_section__3iVSE {
      flex: 1 1; }
    .reset-password_wrapper__9JswD form .reset-password_validation__1poci .reset-password_val_field__x9xF9 {
      align-items: center;
      display: flex;
      line-height: 2; }
      .reset-password_wrapper__9JswD form .reset-password_validation__1poci .reset-password_val_field__x9xF9 svg {
        height: 15px;
        margin-right: 5px;
        width: 15px; }

.reset-password_wrapper__9JswD p {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(85, 85, 111, 0.2);
  padding: 20px; }

.reset-password_wrapper__9JswD a {
  text-decoration: underline; }

.reset-password_wrapper__9JswD a:active,
.reset-password_wrapper__9JswD a:focus {
  outline: none; }

.reset-password_error__GyKCa {
  background-color: rgba(229, 94, 80, 0.2);
  color: #e55e50;
  flex: 1 1;
  padding: 20px; }

.reset-password_input__28ujF {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(212, 212, 216, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 20px; }
  .reset-password_input__28ujF label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .reset-password_input__28ujF input, .reset-password_input__28ujF textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .reset-password_input__28ujF input {
    height: 35px; }
  .reset-password_input__28ujF textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .reset-password_input__28ujF.reset-password_valid__17R3j input, .reset-password_input__28ujF.reset-password_valid__17R3j textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .reset-password_input__28ujF.reset-password_invalid__36Dkm input, .reset-password_input__28ujF.reset-password_invalid__36Dkm textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  @supports (-webkit-overflow-scrolling: touch) {
    .reset-password_input__28ujF {
      max-height: 300px;
      min-height: 100px; } }
  .reset-password_input__28ujF input, .reset-password_input__28ujF textarea {
    display: flex;
    flex: 1 1;
    margin-right: 10px; }
  .reset-password_input__28ujF .reset-password_box__ZV1M1 {
    align-items: center;
    display: flex; }
  .reset-password_input__28ujF span {
    color: #222026;
    margin-bottom: 10px; }
  .reset-password_input__28ujF h3 {
    font-size: 18px; }
  .reset-password_input__28ujF svg {
    height: 20px;
    width: 20px; }
  .reset-password_input__28ujF.reset-password_valid__17R3j {
    background-color: rgba(91, 219, 189, 0.2); }
    .reset-password_input__28ujF.reset-password_valid__17R3j input:focus {
      box-shadow: 0 0 4px #5bdbbd; }
  .reset-password_input__28ujF.reset-password_invalid__36Dkm {
    background-color: rgba(229, 94, 80, 0.2); }
    .reset-password_input__28ujF.reset-password_invalid__36Dkm input:focus {
      box-shadow: 0 0 4px #e55e50; }
  .reset-password_input__28ujF.reset-password_disabled__2cT3H {
    background-color: rgba(85, 85, 111, 0.3);
    opacity: 0.7; }
    .reset-password_input__28ujF.reset-password_disabled__2cT3H svg {
      visibility: hidden; }
  .reset-password_input__28ujF .reset-password_error__GyKCa {
    color: #e55e50;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px; }
  .reset-password_input__28ujF input:focus {
    box-shadow: 0 0 4px #5bdbbd; }
  .reset-password_input__28ujF label {
    color: #222026;
    font-size: 14px; }
  .reset-password_input__28ujF input {
    display: block;
    font-size: 14px;
    height: 28px;
    line-height: 14px; }
  .reset-password_input__28ujF svg {
    height: 18px; }
  .reset-password_input__28ujF .reset-password_box__ZV1M1 {
    position: relative; }
  .reset-password_input__28ujF .reset-password_show__XM7kP {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    bottom: 1px;
    margin: auto 0;
    position: absolute;
    right: 38px; }
    .reset-password_input__28ujF .reset-password_show__XM7kP:hover svg {
      width: 22px; }

.reset-password_buttons__124Cg {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px; }

.temporary_white__2WX3Z {
  color: #f9f9f9; }

.temporary_black__fBT76 {
  color: #000; }

.temporary_deep-teal__2jFUt {
  color: #014660; }

.temporary_greyish-teal__1deGu {
  color: #5bdbbd; }

.temporary_very-light-grey__Gr9Nx {
  color: #b9b9b9; }

.temporary_warm-grey__3b1ay {
  color: #55556f; }

.temporary_simple-grey__11Ljv {
  color: #d4d4d8; }

.temporary_dark-grey__2v1G2 {
  color: #222026; }

.temporary_flat-white__1tUt9 {
  color: #fff; }

.temporary_pastel-red__Jqeah {
  color: #e55e50; }

.temporary_white-bg__3aHMw {
  background-color: #f9f9f9; }

.temporary_deep-teal-bg__S9cYm {
  background-color: #014660;
  color: #fff; }

.temporary_greyish-teal-bg__2kpzB {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.temporary_very-light-grey-bg__SwArJ {
  background-color: #b9b9b9;
  color: #222026; }

.temporary_warm-grey-bg__2IYJI {
  background-color: #55556f;
  color: #f9f9f9; }

.temporary_simple-grey-bg__2zkdO {
  background-color: #d4d4d8;
  color: #222026; }

.temporary_dark-grey-bg__2tjKv {
  background-color: #222026;
  color: #fff; }

.temporary_flat-white-bg__331o6 {
  background-color: #fff;
  color: #014660; }

.temporary_pastel-red-bg__2ProW {
  background-color: #e55e50;
  color: #f9f9f9; }

.temporary_white__2WX3Z {
  color: #f9f9f9; }

.temporary_black__fBT76 {
  color: #000; }

.temporary_deep-teal__2jFUt {
  color: #014660; }

.temporary_greyish-teal__1deGu {
  color: #5bdbbd; }

.temporary_very-light-grey__Gr9Nx {
  color: #b9b9b9; }

.temporary_warm-grey__3b1ay {
  color: #55556f; }

.temporary_simple-grey__11Ljv {
  color: #d4d4d8; }

.temporary_dark-grey__2v1G2 {
  color: #222026; }

.temporary_flat-white__1tUt9 {
  color: #fff; }

.temporary_pastel-red__Jqeah {
  color: #e55e50; }

.temporary_white-bg__3aHMw {
  background-color: #f9f9f9; }

.temporary_deep-teal-bg__S9cYm {
  background-color: #014660;
  color: #fff; }

.temporary_greyish-teal-bg__2kpzB {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.temporary_very-light-grey-bg__SwArJ {
  background-color: #b9b9b9;
  color: #222026; }

.temporary_warm-grey-bg__2IYJI {
  background-color: #55556f;
  color: #f9f9f9; }

.temporary_simple-grey-bg__2zkdO {
  background-color: #d4d4d8;
  color: #222026; }

.temporary_dark-grey-bg__2tjKv {
  background-color: #222026;
  color: #fff; }

.temporary_flat-white-bg__331o6 {
  background-color: #fff;
  color: #014660; }

.temporary_pastel-red-bg__2ProW {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.temporary_wrapper__1bdYk form {
  display: flex;
  flex-direction: column; }
  .temporary_wrapper__1bdYk form .temporary_validation__T4z3m {
    display: flex;
    padding: 20px; }
    .temporary_wrapper__1bdYk form .temporary_validation__T4z3m .temporary_section__fyOEO {
      flex: 1 1; }
    .temporary_wrapper__1bdYk form .temporary_validation__T4z3m .temporary_val_field__3BJPs {
      align-items: center;
      display: flex;
      line-height: 2; }
      .temporary_wrapper__1bdYk form .temporary_validation__T4z3m .temporary_val_field__3BJPs svg {
        height: 15px;
        margin-right: 5px;
        width: 15px; }
  @media (max-width: 1024px) {
    .temporary_wrapper__1bdYk form {
      padding-bottom: 80px; } }

.temporary_wrapper__1bdYk p {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(85, 85, 111, 0.2);
  padding: 20px; }

.temporary_wrapper__1bdYk a {
  text-decoration: underline; }

.temporary_wrapper__1bdYk a:focus,
.temporary_wrapper__1bdYk a:visited,
.temporary_wrapper__1bdYk a:active {
  outline: none; }

.temporary_error_link__1eLzY {
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: underline; }

.temporary_error__36Znz {
  background-color: rgba(229, 94, 80, 0.2);
  color: #e55e50;
  flex: 1 1;
  padding: 20px; }

.temporary_buttons__2LS6j {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px; }

.before-continue_white__wYKZI {
  color: #f9f9f9; }

.before-continue_black__3eGD0 {
  color: #000; }

.before-continue_deep-teal__1KPJ_ {
  color: #014660; }

.before-continue_greyish-teal__J7S2R {
  color: #5bdbbd; }

.before-continue_very-light-grey__3b1Aw {
  color: #b9b9b9; }

.before-continue_warm-grey__3-AZq {
  color: #55556f; }

.before-continue_simple-grey__sI4zU {
  color: #d4d4d8; }

.before-continue_dark-grey__1m8zh {
  color: #222026; }

.before-continue_flat-white__a0byx {
  color: #fff; }

.before-continue_pastel-red__10Evb {
  color: #e55e50; }

.before-continue_white-bg__1moNJ {
  background-color: #f9f9f9; }

.before-continue_deep-teal-bg__2mPGG {
  background-color: #014660;
  color: #fff; }

.before-continue_greyish-teal-bg__1bd1K {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.before-continue_very-light-grey-bg__pzs86 {
  background-color: #b9b9b9;
  color: #222026; }

.before-continue_warm-grey-bg__2ScgP {
  background-color: #55556f;
  color: #f9f9f9; }

.before-continue_simple-grey-bg__tvXHD {
  background-color: #d4d4d8;
  color: #222026; }

.before-continue_dark-grey-bg__2cTlv {
  background-color: #222026;
  color: #fff; }

.before-continue_flat-white-bg__2Yhc3 {
  background-color: #fff;
  color: #014660; }

.before-continue_pastel-red-bg__1g0AV {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.before-continue_wrapper__1aT-a {
  background-color: rgba(249, 249, 249, 0.4);
  padding-bottom: 100px; }
  .before-continue_wrapper__1aT-a p {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 20px; }

.before-continue_description__21pTo {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  border-bottom: 2px solid #b9b9b9;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px; }

.before-continue_card__KRZLD {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px; }
  .before-continue_card__KRZLD button {
    padding: 5px 20px; }

.before-continue_primary__3Qkxd {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center; }
  .before-continue_primary__3Qkxd:hover:not(:disabled) {
    background-color: #00212e; }
  .before-continue_primary__3Qkxd:disabled {
    opacity: 0.5; }

.before-continue_outline__1W8rb {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #014660;
  box-shadow: 0 0 3px rgba(1, 70, 96, 0.5);
  font-weight: 600; }
  .before-continue_outline__1W8rb:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .before-continue_outline__1W8rb:disabled {
    opacity: 0.5; }
  .before-continue_outline__1W8rb:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .before-continue_outline__1W8rb:disabled {
    opacity: 0.5; }

.join_white__1xr6g {
  color: #f9f9f9; }

.join_black__nDl1E {
  color: #000; }

.join_deep-teal__1tZ-y {
  color: #014660; }

.join_greyish-teal__2ZEE8 {
  color: #5bdbbd; }

.join_very-light-grey__15La1 {
  color: #b9b9b9; }

.join_warm-grey__1QY3D {
  color: #55556f; }

.join_simple-grey__2eEzO {
  color: #d4d4d8; }

.join_dark-grey__2QnTD {
  color: #222026; }

.join_flat-white__2FRkm {
  color: #fff; }

.join_pastel-red__UrivO {
  color: #e55e50; }

.join_white-bg__2Zbj9 {
  background-color: #f9f9f9; }

.join_deep-teal-bg__3iIHP {
  background-color: #014660;
  color: #fff; }

.join_greyish-teal-bg__1Nmge {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.join_very-light-grey-bg__-u5kc {
  background-color: #b9b9b9;
  color: #222026; }

.join_warm-grey-bg__1ZcEQ {
  background-color: #55556f;
  color: #f9f9f9; }

.join_simple-grey-bg__27dhY {
  background-color: #d4d4d8;
  color: #222026; }

.join_dark-grey-bg__2IxbV {
  background-color: #222026;
  color: #fff; }

.join_flat-white-bg__2-wGH {
  background-color: #fff;
  color: #014660; }

.join_pastel-red-bg__2sZPN {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.join_loading__2z7xS {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%; }

.join_desc__3raS2,
.join_mandatory__3IpZ2 {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(212, 212, 216, 0.2);
  padding: 20px; }

.join_loaderWrapper__18WwJ {
  display: flex;
  justify-content: center;
  padding: 3em 0; }

.join_mandatory__3IpZ2 {
  background-color: #f9f9f9; }

.join_buttons__9rejj {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px; }
  .join_buttons__9rejj .join_next__11wvk {
    margin-left: auto;
    width: 30%; }
  .join_buttons__9rejj .join_back__j5ON5 {
    border-radius: 18.5px; }

.join_added_skill_card__EJ5Al {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px; }
  .join_added_skill_card__EJ5Al button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    margin: 0 10px; }
  .join_added_skill_card__EJ5Al svg {
    height: 20px;
    width: 20px; }
  .join_added_skill_card__EJ5Al .join_experience__2Nxf1 {
    align-items: center;
    color: #5bdbbd;
    display: flex;
    font-size: 25px; }

.join_added_skill_card_label__2QOqp {
  flex: 1 1; }
  @media (min-width: 1025px) {
    .join_added_skill_card_label__2QOqp {
      text-align: center; } }

.join_no_results__b6Yah {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px; }
  .join_no_results__b6Yah svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px; }

.join_search__1-bHU {
  background-color: rgba(212, 212, 216, 0.4);
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%; }
  .join_search__1-bHU label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .join_search__1-bHU input, .join_search__1-bHU textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .join_search__1-bHU input {
    height: 35px; }
  .join_search__1-bHU textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .join_search__1-bHU.join_valid__2wevx input, .join_search__1-bHU.join_valid__2wevx textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .join_search__1-bHU.join_invalid__D3om8 input, .join_search__1-bHU.join_invalid__D3om8 textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }

.join_box__3FIQ_ {
  position: relative;
  width: 100%; }
  .join_box__3FIQ_ input {
    padding: 0 10px;
    width: 100%; }
  .join_box__3FIQ_ input:focus {
    box-shadow: 0 0 5px rgba(91, 219, 189, 0.7); }

.join_clear__2w872 {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  position: absolute;
  right: 10px;
  top: 8px; }
  .join_clear__2w872 svg {
    height: 20px;
    width: 20px; }

.join_results__kRe7D {
  max-height: 400px;
  overflow-y: auto; }

.join_skill_card__1swZa {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  padding: 20px; }
  .join_skill_card__1swZa button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 5px 20px; }
    .join_skill_card__1swZa button:hover:not(:disabled) {
      background-color: #00212e; }
    .join_skill_card__1swZa button:disabled {
      opacity: 0.5; }

.join_kpis__2so-j {
  padding: 40px 20px; }
  @media (min-width: 1025px) {
    .join_kpis__2so-j {
      padding: 2em; } }

.join_kpi-header__HJv1R {
  margin-bottom: 1em; }
  .join_kpi-header__HJv1R h2 {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    margin-bottom: 0.5em; }
  .join_kpi-header__HJv1R p {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }

.join_questions-wrapper__1QEfd {
  position: relative; }

.join_question__3Di4D {
  background: #fff;
  border: 1px solid #ececec;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(60px);
          transform: translateX(60px);
  -webkit-transition: all 400ms ease-in 0s;
  transition: all 400ms ease-in 0s;
  width: 100%; }
  .join_question__3Di4D.join_hiddenq__vifki {
    opacity: 0; }
  .join_question__3Di4D.join_activeq__2DszH {
    display: block;
    opacity: 1;
    pointer-events: visible;
    position: relative;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    z-index: 2; }
  .join_question__3Di4D h4 {
    color: #014660;
    font-size: 18px;
    line-height: 1.4em;
    padding: 1em 1em 0; }

.join_singles__rL_4r {
  padding: 1em; }

.join_single__3-w4l {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  border: 1px solid #d4d4d8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  padding: 1em;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .join_single__3-w4l:hover {
    background: #d4d4d8; }
  .join_single__3-w4l.join_active__yUwKj {
    background: #014660;
    border-color: #014660;
    color: #fff;
    font-weight: bold; }
  .join_single__3-w4l span {
    flex: 1 1; }
  .join_single__3-w4l svg {
    height: 22px;
    width: 22px; }

.join_hint__3Zuhy {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  justify-content: center;
  padding-top: 1em; }

.TechnicalRequirements_white__24WTK {
  color: #f9f9f9; }

.TechnicalRequirements_black__1o1hn {
  color: #000; }

.TechnicalRequirements_deep-teal__2Ts3s {
  color: #014660; }

.TechnicalRequirements_greyish-teal__1JtdZ {
  color: #5bdbbd; }

.TechnicalRequirements_very-light-grey__1zBZf {
  color: #b9b9b9; }

.TechnicalRequirements_warm-grey__3hojX {
  color: #55556f; }

.TechnicalRequirements_simple-grey__ec9xy {
  color: #d4d4d8; }

.TechnicalRequirements_dark-grey__2lbga {
  color: #222026; }

.TechnicalRequirements_flat-white__1vy_m {
  color: #fff; }

.TechnicalRequirements_pastel-red__26_aZ {
  color: #e55e50; }

.TechnicalRequirements_white-bg__2YAc6 {
  background-color: #f9f9f9; }

.TechnicalRequirements_deep-teal-bg__1sIJP {
  background-color: #014660;
  color: #fff; }

.TechnicalRequirements_greyish-teal-bg__3V9Gh {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.TechnicalRequirements_very-light-grey-bg__2X3JR {
  background-color: #b9b9b9;
  color: #222026; }

.TechnicalRequirements_warm-grey-bg__3R9Zp {
  background-color: #55556f;
  color: #f9f9f9; }

.TechnicalRequirements_simple-grey-bg__1-8RI {
  background-color: #d4d4d8;
  color: #222026; }

.TechnicalRequirements_dark-grey-bg__1Papp {
  background-color: #222026;
  color: #fff; }

.TechnicalRequirements_flat-white-bg__2JmZD {
  background-color: #fff;
  color: #014660; }

.TechnicalRequirements_pastel-red-bg__2CA_I {
  background-color: #e55e50;
  color: #f9f9f9; }

.TechnicalRequirements_wrapper__2CLQa {
  background-color: #f6f6f6;
  display: flex;
  flex: 1 1;
  justify-content: center;
  min-height: 100vh;
  padding: 80px 0 60px;
  width: 100%; }
  @media (max-width: 1024px) {
    .TechnicalRequirements_wrapper__2CLQa {
      padding: 80px 10px 60px; } }

.TechnicalRequirements_lastBlock__1nRsm {
  margin-bottom: 60px; }

.TechnicalRequirements_container__2Dp7c {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 550px; }
  @media (max-width: 1024px) {
    .TechnicalRequirements_container__2Dp7c {
      width: 100%; } }
  .TechnicalRequirements_container__2Dp7c h2 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-top: 40px; }
    @media (max-width: 1024px) {
      .TechnicalRequirements_container__2Dp7c h2 {
        font-size: 19px; } }
  .TechnicalRequirements_container__2Dp7c h3 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin-top: 25px; }
    @media (max-width: 1024px) {
      .TechnicalRequirements_container__2Dp7c h3 {
        font-size: 17px; } }
  .TechnicalRequirements_container__2Dp7c h4 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400; }
    @media (max-width: 1024px) {
      .TechnicalRequirements_container__2Dp7c h4 {
        font-size: 15px; } }
  .TechnicalRequirements_container__2Dp7c h5 {
    color: #4cb49c;
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    font-weight: 400; }
    @media (max-width: 1024px) {
      .TechnicalRequirements_container__2Dp7c h5 {
        font-size: 12px; } }
  .TechnicalRequirements_container__2Dp7c span {
    color: #4d4d4d;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px; }
    @media (max-width: 1024px) {
      .TechnicalRequirements_container__2Dp7c span {
        font-size: 13px; } }

.TechnicalRequirements_block__3tT95 {
  display: flex;
  flex-direction: column; }
  .TechnicalRequirements_block__3tT95 button {
    align-self: flex-end;
    margin-top: 20px;
    padding: 3px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }

.TechnicalRequirements_upgrade__1foIO span {
  margin-top: 15px !important; }

.TechnicalRequirements_upgrade__1foIO a {
  color: #014660; }

.TechnicalRequirements_card__1btM6 {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.19);
  margin-top: 50px;
  padding: 25px 20px 35px;
  width: 522px; }
  @media (max-width: 1024px) {
    .TechnicalRequirements_card__1btM6 {
      height: auto;
      margin-top: 30px;
      padding: 25px 15px;
      width: 100%; } }
  .TechnicalRequirements_card__1btM6 h3 {
    margin: 0; }
  .TechnicalRequirements_card__1btM6 h4 {
    margin-top: 25px; }
  .TechnicalRequirements_card__1btM6 h5 {
    margin-top: 15px; }
  .TechnicalRequirements_card__1btM6 span {
    display: inline-block;
    margin-top: 8px; }

.launch_white__Bp5d1 {
  color: #f9f9f9; }

.launch_black__3h9tn {
  color: #000; }

.launch_deep-teal__NDTBb {
  color: #014660; }

.launch_greyish-teal__KkUpx {
  color: #5bdbbd; }

.launch_very-light-grey__Zpu8J {
  color: #b9b9b9; }

.launch_warm-grey__31yio {
  color: #55556f; }

.launch_simple-grey__3JNsC {
  color: #d4d4d8; }

.launch_dark-grey__j5jYt {
  color: #222026; }

.launch_flat-white__1gBMw {
  color: #fff; }

.launch_pastel-red__2KMsI {
  color: #e55e50; }

.launch_white-bg__1ClB3 {
  background-color: #f9f9f9; }

.launch_deep-teal-bg__2Z1PK {
  background-color: #014660;
  color: #fff; }

.launch_greyish-teal-bg__wrqxz {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.launch_very-light-grey-bg__3qDAe {
  background-color: #b9b9b9;
  color: #222026; }

.launch_warm-grey-bg__372c1 {
  background-color: #55556f;
  color: #f9f9f9; }

.launch_simple-grey-bg__Pj9Nz {
  background-color: #d4d4d8;
  color: #222026; }

.launch_dark-grey-bg__2k5U5 {
  background-color: #222026;
  color: #fff; }

.launch_flat-white-bg__1eN4S {
  background-color: #fff;
  color: #014660; }

.launch_pastel-red-bg__1YgGL {
  background-color: #e55e50;
  color: #f9f9f9; }

.launch_launchModal__1pjii {
  background-color: #f6f6f6;
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 6; }

.launch_launch__2jb_1 {
  scroll-behavior: smooth; }

.launch_header__5Gv1M {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 7; }
  .launch_header__5Gv1M .launch_options__pUfHV {
    align-items: center;
    display: flex;
    margin-left: auto; }
    .launch_header__5Gv1M .launch_options__pUfHV svg {
      cursor: pointer;
      height: 25px;
      width: 25px; }
  .launch_header__5Gv1M .launch_wrench__fs5Cv {
    display: none; }
  .launch_header__5Gv1M .launch_menu__3BnkV {
    display: inline-block; }
  @media (min-width: 1025px) {
    .launch_header__5Gv1M {
      justify-content: initial; }
      .launch_header__5Gv1M .launch_wrench__fs5Cv {
        display: inline-block; }
      .launch_header__5Gv1M .launch_menu__3BnkV {
        display: none; } }

.launch_timer__25GW3 {
  align-items: center;
  background: var(--timer-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px; }

.launch_sponsor__2gEZT {
  align-items: flex-end;
  display: flex;
  min-height: 280px;
  padding: 0 10%;
  position: relative; }
  .launch_sponsor__2gEZT .launch_mask__17QBx {
    background-color: rgba(1, 70, 96, 0.78);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .launch_sponsor__2gEZT img {
    max-width: 400px;
    min-width: 300px;
    width: 30%; }
  .launch_sponsor__2gEZT .launch_text__3JOdT {
    align-self: center;
    margin-left: 80px;
    padding: 20px 0;
    z-index: 2; }
    .launch_sponsor__2gEZT .launch_text__3JOdT h2 {
      color: #5bdbbd;
      font-family: "Questrial", sans-serif;
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 20px;
      max-width: 470px; }
    .launch_sponsor__2gEZT .launch_text__3JOdT span {
      color: #f9f9f9;
      display: block;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 28px;
      max-width: 590px; }
  @media (max-width: 1024px) {
    .launch_sponsor__2gEZT {
      padding: 0 20px 0 0; }
      .launch_sponsor__2gEZT .launch_text__3JOdT {
        margin-left: 0; } }
  @media all and (max-width: 650px) {
    .launch_sponsor__2gEZT img {
      margin-left: -70px; }
    .launch_sponsor__2gEZT .launch_text__3JOdT h2 {
      font-size: 5vw; }
    .launch_sponsor__2gEZT .launch_text__3JOdT span {
      font-size: 4vw;
      line-height: 4.5vw; } }

.launch_nav__enXSH {
  align-items: center;
  display: flex;
  padding: 0 40px; }
  .launch_nav__enXSH span {
    color: var(--nav-color);
    cursor: pointer;
    font-family: "Questrial", sans-serif;
    font-size: 18px;
    margin-right: 32px; }
    .launch_nav__enXSH span[active=""] {
      color: var(--nav-active-color); }
  @media (max-width: 1024px) {
    .launch_nav__enXSH {
      display: none; } }

.launch_title__2Kd04 {
  color: var(--timer-title-color);
  font-family: "Questrial", sans-serif;
  font-size: 26px;
  margin-bottom: 14px;
  text-align: center; }
  @media (max-width: 1024px) {
    .launch_title__2Kd04 {
      display: flex;
      flex-direction: column;
      font-size: 21px; }
      .launch_title__2Kd04 span {
        margin-top: 5px; } }
  .launch_title__2Kd04 span {
    color: #4cb49c; }

.launch_keyDatesContainer__bXput {
  background: var(--dates-bg);
  padding: 36px 60px; }
  .launch_keyDatesContainer__bXput h2 {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px; }
  @media (max-width: 1024px) {
    .launch_keyDatesContainer__bXput {
      padding: 40px 15px 30px; }
      .launch_keyDatesContainer__bXput h2 {
        font-size: 28px; } }

.launch_keyDate__1HSOf {
  display: flex;
  margin-bottom: 20px; }

.launch_keyDate__1HSOf:not(:last-child) .launch_date__1KkYp hr {
  background-color: #014660;
  border: 0;
  flex: 1 1;
  margin: 0;
  width: 1px; }

.launch_date__1KkYp {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative; }
  .launch_date__1KkYp svg {
    height: 47px;
    width: 42px; }
  .launch_date__1KkYp .launch_day__3ckpW {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    left: calc(50% - 16px);
    position: absolute;
    text-align: center;
    top: 20px;
    width: 30px; }
  .launch_date__1KkYp .launch_month__2RC2H {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 4px 0; }
  @media (max-width: 1024px) {
    .launch_date__1KkYp svg {
      height: 34px;
      width: 34px; }
    .launch_date__1KkYp .launch_day__3ckpW {
      font-size: 17px;
      top: 14px; }
    .launch_date__1KkYp .launch_month__2RC2H {
      font-size: 16px; } }

.launch_card__2fu4Q {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  margin-left: 20px;
  overflow: hidden;
  width: 560px; }
  .launch_card__2fu4Q .launch_top__2_aIc {
    padding: 20px 16px 14px; }
    .launch_card__2fu4Q .launch_top__2_aIc .launch_cardTitle__20VyO {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px; }
      .launch_card__2fu4Q .launch_top__2_aIc .launch_cardTitle__20VyO h3 {
        color: #55556f;
        font-family: "Questrial", sans-serif;
        font-size: 26px;
        font-weight: 400; }
      .launch_card__2fu4Q .launch_top__2_aIc .launch_cardTitle__20VyO span {
        color: #b9b9b9;
        font-size: 22px;
        font-weight: 400; }
    .launch_card__2fu4Q .launch_top__2_aIc .launch_cardText__1FG_y {
      color: #4d4d4d;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 21px; }
  .launch_card__2fu4Q .launch_bottom__3_kvM {
    background-color: #dda700;
    background-color: var(--card-bg, #dda700);
    color: #222026;
    color: var(--card-color, #222026);
    padding: 10px 16px; }

.launch_keyDate__1HSOf:nth-child(2) .launch_card__2fu4Q .launch_bottom__3_kvM {
  background-color: #5bdbbd;
  background-color: var(--card-first-bg, #5bdbbd);
  color: #fff;
  color: var(--card-first-color, #fff); }

.launch_tips__2gQUQ {
  align-items: center;
  background-color: #014660;
  background-color: var(--tips-bg, #014660);
  display: flex;
  min-height: 680px;
  padding: 80px; }
  .launch_tips__2gQUQ h2 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 20px;
    max-width: 470px; }
  .launch_tips__2gQUQ .launch_inner__EC6El {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .launch_tips__2gQUQ .launch_inner__EC6El .launch_tipsWrapper__38c53 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%; }
  @media (max-width: 1024px) {
    .launch_tips__2gQUQ {
      padding: 80px 10px; }
      .launch_tips__2gQUQ .launch_inner__EC6El .launch_tipsWrapper__38c53 {
        flex-direction: column; }
      .launch_tips__2gQUQ .launch_inner__EC6El h2 {
        margin-bottom: 50px; } }

.launch_tip__3OF0s {
  display: flex;
  flex: 1 1 315px;
  max-width: 315px; }
  .launch_tip__3OF0s img {
    height: 55px;
    min-width: 55px;
    width: 55px; }
  .launch_tip__3OF0s .launch_info__JlZoC {
    margin-left: 20px; }
    .launch_tip__3OF0s .launch_info__JlZoC h3 {
      color: #5bdbbd;
      font-family: "Questrial", sans-serif;
      font-size: 26px;
      font-weight: 400; }
    .launch_tip__3OF0s .launch_info__JlZoC div {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0; }
    .launch_tip__3OF0s .launch_info__JlZoC a {
      align-items: center;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 42px;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-family: "Open Sans", sans-serif;
      font-size: 17px;
      grid-area: button;
      height: 27px;
      justify-content: center;
      margin-top: 10px;
      width: 84px; }
      .launch_tip__3OF0s .launch_info__JlZoC a:hover {
        background-color: #fff;
        color: #014660; }
  @media (max-width: 1024px) {
    .launch_tip__3OF0s {
      flex: initial;
      margin-bottom: 40px;
      max-width: none;
      max-width: initial; } }

.launch_navigation__1uHcQ {
  position: fixed;
  right: 10px;
  top: 30%;
  z-index: 4; }
  @media (max-width: 1024px) {
    .launch_navigation__1uHcQ {
      display: none; } }

.launch_footer__2xD-m {
  display: flex;
  height: 760px; }
  .launch_footer__2xD-m .launch_left__d-zPz {
    background: var(--footer-bg);
    flex: 1 1; }
  .launch_footer__2xD-m .launch_right__1usmd {
    background-color: #d8d8d8;
    flex: 1 1;
    position: relative; }
  .launch_footer__2xD-m .launch_footerCard__1rItQ {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 230px;
    overflow: hidden;
    position: absolute; }
    .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding: 30px; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 h3 {
        color: #014660;
        font-family: "Questrial", sans-serif;
        font-size: 36px;
        font-weight: 400;
        text-transform: uppercase; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 h4 {
        color: #014660;
        font-family: "Open Sans", sans-serif;
        font-size: 24px;
        margin-top: 5px; }
    .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardBottom__1VOSt {
      align-items: center;
      background-color: #f6f6f6;
      display: flex;
      height: 90px;
      padding: 20px 30px; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardBottom__1VOSt img {
        border-radius: 100%;
        height: 48px;
        width: 48px; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardBottom__1VOSt div {
        color: #014660;
        font-family: "Questrial", sans-serif;
        font-size: 22px;
        font-weight: 400;
        margin-left: 10px; }
  @media (max-width: 1024px) {
    .launch_footer__2xD-m {
      flex-direction: column; }
      .launch_footer__2xD-m .launch_left__d-zPz {
        flex: initial;
        height: 80%; }
      .launch_footer__2xD-m .launch_right__1usmd {
        flex: initial;
        height: 20%; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 {
        padding: 25px 15px; }
        .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 h3 {
          font-size: 21px; }
        .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardTop__QBnG9 h4 {
          font-size: 18px; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ .launch_footerCardBottom__1VOSt {
        font-size: 18px;
        height: 70px;
        padding: 15px; }
      .launch_footer__2xD-m .launch_footerCard__1rItQ {
        border-radius: 4px;
        bottom: 30px;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        left: 15px;
        right: 15px;
        top: -50px; } }
  @media (min-width: 1025px) {
    .launch_footer__2xD-m .launch_footerCard__1rItQ {
      left: -60px;
      top: calc(50% - 115px);
      width: 610px; } }

.launch_mobileMenu__3BcY2 {
  align-items: center;
  background-color: #014660;
  bottom: 0;
  left: 0;
  padding: 10px 15px 10px 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10; }
  .launch_mobileMenu__3BcY2 .launch_mobileHeader__39PVb {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 25px 0; }
    .launch_mobileMenu__3BcY2 .launch_mobileHeader__39PVb svg {
      cursor: pointer;
      height: 24px;
      width: 24px; }
  .launch_mobileMenu__3BcY2 .launch_mobileNav__2Dwdg {
    display: flex;
    flex-direction: column; }
    .launch_mobileMenu__3BcY2 .launch_mobileNav__2Dwdg svg {
      height: 20px;
      margin-right: 10px;
      width: 20px; }
    .launch_mobileMenu__3BcY2 .launch_mobileNav__2Dwdg:last-child {
      margin-top: 25px; }
    .launch_mobileMenu__3BcY2 .launch_mobileNav__2Dwdg span {
      align-items: center;
      color: var(--nav-color);
      cursor: pointer;
      display: flex;
      font-family: "Questrial", sans-serif;
      font-size: 20px;
      margin-bottom: 25px; }
      .launch_mobileMenu__3BcY2 .launch_mobileNav__2Dwdg span[active=""] {
        color: var(--nav-active-color); }

.match-body_white__2eJYC {
  color: #f9f9f9; }

.match-body_black__2E2Jz {
  color: #000; }

.match-body_deep-teal__3Evj6 {
  color: #014660; }

.match-body_greyish-teal__131c3 {
  color: #5bdbbd; }

.match-body_very-light-grey__1X-3U {
  color: #b9b9b9; }

.match-body_warm-grey__11tvi {
  color: #55556f; }

.match-body_simple-grey__2oYYm {
  color: #d4d4d8; }

.match-body_dark-grey__2rtR5 {
  color: #222026; }

.match-body_flat-white__qEIcC {
  color: #fff; }

.match-body_pastel-red__2PALW {
  color: #e55e50; }

.match-body_white-bg__3ffdx {
  background-color: #f9f9f9; }

.match-body_deep-teal-bg__1KBQu {
  background-color: #014660;
  color: #fff; }

.match-body_greyish-teal-bg__Ss0nQ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.match-body_very-light-grey-bg__22pD1 {
  background-color: #b9b9b9;
  color: #222026; }

.match-body_warm-grey-bg__2w3bn {
  background-color: #55556f;
  color: #f9f9f9; }

.match-body_simple-grey-bg__1PaM1 {
  background-color: #d4d4d8;
  color: #222026; }

.match-body_dark-grey-bg__1LRuc {
  background-color: #222026;
  color: #fff; }

.match-body_flat-white-bg__1s2J6 {
  background-color: #fff;
  color: #014660; }

.match-body_pastel-red-bg__vwmkc {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.match-body_wrapper__2-uoI {
  align-items: center;
  align-self: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  margin: -50px auto 0;
  width: 90%; }
  .match-body_wrapper__2-uoI section {
    width: 100%; }
  @media (min-width: 1025px) {
    .match-body_wrapper__2-uoI {
      flex: 1 1;
      width: 40%; } }

.match-body_looking-wrapper__3QYao,
.match-body_commitment-wrapper__yUSvg {
  background-color: #fff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%; }
  .match-body_looking-wrapper__3QYao h1,
  .match-body_commitment-wrapper__yUSvg h1 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif; }

.match-body_looking-wrapper__3QYao li {
  margin-top: 20px; }

.match-body_commitment-wrapper__yUSvg {
  display: flex;
  flex-direction: column; }
  .match-body_commitment-wrapper__yUSvg .match-body_loading__8hKM5 {
    align-self: flex-end;
    margin-right: 20px;
    padding: 5px 20px; }
  .match-body_commitment-wrapper__yUSvg h2 {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 20px 0 10px; }
  .match-body_commitment-wrapper__yUSvg ul {
    margin-bottom: 10px; }
  .match-body_commitment-wrapper__yUSvg li {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: rgba(91, 219, 189, 0.2);
    margin-bottom: 10px;
    padding: 20px; }
  .match-body_commitment-wrapper__yUSvg button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    align-self: flex-end;
    padding: 5px 20px; }
    .match-body_commitment-wrapper__yUSvg button:hover:not(:disabled) {
      background-color: #00212e; }
    .match-body_commitment-wrapper__yUSvg button:disabled {
      opacity: 0.5; }

.match-header_white__11iSQ {
  color: #f9f9f9; }

.match-header_black__3Wi5B {
  color: #000; }

.match-header_deep-teal__I-Bvf {
  color: #014660; }

.match-header_greyish-teal__1B0Fu {
  color: #5bdbbd; }

.match-header_very-light-grey__uZSX2 {
  color: #b9b9b9; }

.match-header_warm-grey__2CWXK {
  color: #55556f; }

.match-header_simple-grey__2rVFM {
  color: #d4d4d8; }

.match-header_dark-grey__1wdid {
  color: #222026; }

.match-header_flat-white__2bSf0 {
  color: #fff; }

.match-header_pastel-red__2Msld {
  color: #e55e50; }

.match-header_white-bg__3ferT {
  background-color: #f9f9f9; }

.match-header_deep-teal-bg__2SGbQ {
  background-color: #014660;
  color: #fff; }

.match-header_greyish-teal-bg__1TZhB {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.match-header_very-light-grey-bg__uteP_ {
  background-color: #b9b9b9;
  color: #222026; }

.match-header_warm-grey-bg__1evjS {
  background-color: #55556f;
  color: #f9f9f9; }

.match-header_simple-grey-bg__1PSgH {
  background-color: #d4d4d8;
  color: #222026; }

.match-header_dark-grey-bg__Lrkba {
  background-color: #222026;
  color: #fff; }

.match-header_flat-white-bg__1Y-20 {
  background-color: #fff;
  color: #014660; }

.match-header_pastel-red-bg__2AKuV {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.match-header_wrapper__1OrIg {
  display: flex;
  flex-direction: column;
  margin: auto 0; }
  @media (max-width: 1024px) {
    .match-header_wrapper__1OrIg {
      justify-content: center;
      margin-top: 15px; } }
  .match-header_wrapper__1OrIg h1 {
    color: #5bdbbd;
    font-family: OpenSans, sans-serif;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    margin-bottom: 40px; }
    @media (max-width: 1024px) {
      .match-header_wrapper__1OrIg h1 {
        align-self: center;
        margin-bottom: 20px; } }

.match-header_info-wrapper__2eAfk {
  display: flex; }
  @media (max-width: 1024px) {
    .match-header_info-wrapper__2eAfk {
      align-items: center;
      flex-direction: column;
      text-align: center; } }

.match-header_info__2S-5J h2 {
  color: #5bdbbd;
  font-family: OpenSans, sans-serif;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "Questrial", sans-serif;
  margin-bottom: 10px; }
  @media (max-width: 1024px) {
    .match-header_info__2S-5J h2 {
      margin-top: 20px; } }

.match-header_info__2S-5J h3 {
  color: #fff;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 1.5; }

@media (min-width: 1025px) {
  .match-header_info__2S-5J {
    margin-left: 20px; } }

@media (max-width: 1024px) {
  .match-header_info__2S-5J {
    max-width: 380px;
    padding: 10px; } }

.no-match_white__3li8V {
  color: #f9f9f9; }

.no-match_black__3Ugod {
  color: #000; }

.no-match_deep-teal__1hfeZ {
  color: #014660; }

.no-match_greyish-teal__3Atm1 {
  color: #5bdbbd; }

.no-match_very-light-grey__2Z4UG {
  color: #b9b9b9; }

.no-match_warm-grey__2NIzU {
  color: #55556f; }

.no-match_simple-grey__1hcnI {
  color: #d4d4d8; }

.no-match_dark-grey__1_Rzi {
  color: #222026; }

.no-match_flat-white__2qbUj {
  color: #fff; }

.no-match_pastel-red__347Y2 {
  color: #e55e50; }

.no-match_white-bg__7u08_ {
  background-color: #f9f9f9; }

.no-match_deep-teal-bg__1cg8j {
  background-color: #014660;
  color: #fff; }

.no-match_greyish-teal-bg__3D3t5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.no-match_very-light-grey-bg__3R-Zh {
  background-color: #b9b9b9;
  color: #222026; }

.no-match_warm-grey-bg__1aPPl {
  background-color: #55556f;
  color: #f9f9f9; }

.no-match_simple-grey-bg__2VKyG {
  background-color: #d4d4d8;
  color: #222026; }

.no-match_dark-grey-bg__2AKfs {
  background-color: #222026;
  color: #fff; }

.no-match_flat-white-bg__1A8yM {
  background-color: #fff;
  color: #014660; }

.no-match_pastel-red-bg__IWFri {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.no-match_wrapper__iVDyO {
  background-color: #ffe6d8;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  overflow: auto; }
  .no-match_wrapper__iVDyO img {
    align-self: flex-end;
    bottom: 0;
    margin-top: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    width: 500px; }
    @media (min-width: 1025px) {
      .no-match_wrapper__iVDyO img {
        width: 600px; } }
    @media (max-width: 1024px) {
      .no-match_wrapper__iVDyO img {
        margin-top: 20px;
        padding: 0 12px;
        position: relative; } }

.no-match_items__2DssW {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100%;
  padding: 20px;
  width: 565px; }
  .no-match_items__2DssW h1 {
    color: #5bdbbd;
    font-family: OpenSans, sans-serif;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    margin: 54px 0; }
  .no-match_items__2DssW h2 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-family: "Questrial", sans-serif;
    line-height: 1.27; }
  .no-match_items__2DssW button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    align-self: flex-end;
    margin-top: 40px;
    padding: 5px 20px;
    z-index: 100; }
    .no-match_items__2DssW button:hover:not(:disabled) {
      background-color: #00212e; }
    .no-match_items__2DssW button:disabled {
      opacity: 0.5; }
  .no-match_items__2DssW button:hover {
    background-color: #00212e; }

.no-match_item__3fshd {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  margin-top: 20px; }
  .no-match_item__3fshd svg {
    align-self: flex-start;
    margin-right: 20px;
    min-width: 15px;
    width: 15px; }

.tabbar_white__1Wks6 {
  color: #f9f9f9; }

.tabbar_black__2dH_r {
  color: #000; }

.tabbar_deep-teal__2xJ1j {
  color: #014660; }

.tabbar_greyish-teal__OsZZI {
  color: #5bdbbd; }

.tabbar_very-light-grey__2De6- {
  color: #b9b9b9; }

.tabbar_warm-grey__3CJ14 {
  color: #55556f; }

.tabbar_simple-grey__3RH7n {
  color: #d4d4d8; }

.tabbar_dark-grey__3M8oM {
  color: #222026; }

.tabbar_flat-white__1zsIW {
  color: #fff; }

.tabbar_pastel-red__2vWX6 {
  color: #e55e50; }

.tabbar_white-bg__1jXlw {
  background-color: #f9f9f9; }

.tabbar_deep-teal-bg__vjoJ- {
  background-color: #014660;
  color: #fff; }

.tabbar_greyish-teal-bg__mHxQ9 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.tabbar_very-light-grey-bg__1Pc41 {
  background-color: #b9b9b9;
  color: #222026; }

.tabbar_warm-grey-bg__-dpOK {
  background-color: #55556f;
  color: #f9f9f9; }

.tabbar_simple-grey-bg__NPBkZ {
  background-color: #d4d4d8;
  color: #222026; }

.tabbar_dark-grey-bg__2JMCE {
  background-color: #222026;
  color: #fff; }

.tabbar_flat-white-bg__3cTD1 {
  background-color: #fff;
  color: #014660; }

.tabbar_pastel-red-bg__11zKN {
  background-color: #e55e50;
  color: #f9f9f9; }

.tabbar_white__1Wks6 {
  color: #f9f9f9; }

.tabbar_black__2dH_r {
  color: #000; }

.tabbar_deep-teal__2xJ1j {
  color: #014660; }

.tabbar_greyish-teal__OsZZI {
  color: #5bdbbd; }

.tabbar_very-light-grey__2De6- {
  color: #b9b9b9; }

.tabbar_warm-grey__3CJ14 {
  color: #55556f; }

.tabbar_simple-grey__3RH7n {
  color: #d4d4d8; }

.tabbar_dark-grey__3M8oM {
  color: #222026; }

.tabbar_flat-white__1zsIW {
  color: #fff; }

.tabbar_pastel-red__2vWX6 {
  color: #e55e50; }

.tabbar_white-bg__1jXlw {
  background-color: #f9f9f9; }

.tabbar_deep-teal-bg__vjoJ- {
  background-color: #014660;
  color: #fff; }

.tabbar_greyish-teal-bg__mHxQ9 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.tabbar_very-light-grey-bg__1Pc41 {
  background-color: #b9b9b9;
  color: #222026; }

.tabbar_warm-grey-bg__-dpOK {
  background-color: #55556f;
  color: #f9f9f9; }

.tabbar_simple-grey-bg__NPBkZ {
  background-color: #d4d4d8;
  color: #222026; }

.tabbar_dark-grey-bg__2JMCE {
  background-color: #222026;
  color: #fff; }

.tabbar_flat-white-bg__3cTD1 {
  background-color: #fff;
  color: #014660; }

.tabbar_pastel-red-bg__11zKN {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.tabbar_wrapper__2dJ7g {
  border-top: 1px solid #b9b9b9;
  bottom: 0;
  display: flex;
  height: 50px;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 2; }
  @media (max-height: 400px) {
    .tabbar_wrapper__2dJ7g {
      display: none; } }

.tabbar_tab__mAcJN {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  padding: 5px 0; }
  .tabbar_tab__mAcJN svg {
    height: 20px;
    margin-bottom: 5px;
    width: 20px; }
  .tabbar_tab__mAcJN.tabbar_active__VmnVn {
    background-color: #ececec;
    color: #014660; }

.tabbar_badge__3AT6Q {
  align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#5fe8c3), to(#43b395));
  background: linear-gradient(#5fe8c3, #43b395);
  border-radius: 18.5px;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 18px;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  top: 3px;
  width: 18px;
  z-index: 3; }

.will-be-in-touch_white__2GQV3 {
  color: #f9f9f9; }

.will-be-in-touch_black__3Uzdk {
  color: #000; }

.will-be-in-touch_deep-teal__WSw3n {
  color: #014660; }

.will-be-in-touch_greyish-teal__BeZ3L {
  color: #5bdbbd; }

.will-be-in-touch_very-light-grey__1s-li {
  color: #b9b9b9; }

.will-be-in-touch_warm-grey__2ySQ2 {
  color: #55556f; }

.will-be-in-touch_simple-grey__2bbS2 {
  color: #d4d4d8; }

.will-be-in-touch_dark-grey__KvO8f {
  color: #222026; }

.will-be-in-touch_flat-white__3fKZT {
  color: #fff; }

.will-be-in-touch_pastel-red__2tLBc {
  color: #e55e50; }

.will-be-in-touch_white-bg__3oZ1H {
  background-color: #f9f9f9; }

.will-be-in-touch_deep-teal-bg__BygFm {
  background-color: #014660;
  color: #fff; }

.will-be-in-touch_greyish-teal-bg__3fnR6 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.will-be-in-touch_very-light-grey-bg__18C5m {
  background-color: #b9b9b9;
  color: #222026; }

.will-be-in-touch_warm-grey-bg__2dM51 {
  background-color: #55556f;
  color: #f9f9f9; }

.will-be-in-touch_simple-grey-bg__3B5m4 {
  background-color: #d4d4d8;
  color: #222026; }

.will-be-in-touch_dark-grey-bg__2wEa6 {
  background-color: #222026;
  color: #fff; }

.will-be-in-touch_flat-white-bg__3Anl5 {
  background-color: #fff;
  color: #014660; }

.will-be-in-touch_pastel-red-bg__33ZZs {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.will-be-in-touch_wrapper__3TLd7 {
  background-color: rgba(249, 249, 249, 0.4);
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  width: 100%; }
  .will-be-in-touch_wrapper__3TLd7 svg {
    align-self: center;
    height: 150px;
    margin: 50px auto;
    width: 150px; }
  .will-be-in-touch_wrapper__3TLd7 p {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 20px; }
  .will-be-in-touch_wrapper__3TLd7 button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 20px;
    padding: 5px 20px; }
    .will-be-in-touch_wrapper__3TLd7 button:hover:not(:disabled) {
      background-color: #00212e; }
    .will-be-in-touch_wrapper__3TLd7 button:disabled {
      opacity: 0.5; }

.will-be-in-touch_description__2Hwbu {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  border-bottom: 2px solid #b9b9b9;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 0 20px; }

.action_white__2kJbp {
  color: #f9f9f9; }

.action_black__3xRan {
  color: #000; }

.action_deep-teal__3LYn8 {
  color: #014660; }

.action_greyish-teal__3CQEB {
  color: #5bdbbd; }

.action_very-light-grey__1mHit {
  color: #b9b9b9; }

.action_warm-grey__2cFxQ {
  color: #55556f; }

.action_simple-grey__3aqbb {
  color: #d4d4d8; }

.action_dark-grey__X3fiu {
  color: #222026; }

.action_flat-white__KlpU0 {
  color: #fff; }

.action_pastel-red__hppz6 {
  color: #e55e50; }

.action_white-bg__1xpa8 {
  background-color: #f9f9f9; }

.action_deep-teal-bg__2SWbM {
  background-color: #014660;
  color: #fff; }

.action_greyish-teal-bg__XwPuV {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.action_very-light-grey-bg__2mKnd {
  background-color: #b9b9b9;
  color: #222026; }

.action_warm-grey-bg__clFxE {
  background-color: #55556f;
  color: #f9f9f9; }

.action_simple-grey-bg__11M7J {
  background-color: #d4d4d8;
  color: #222026; }

.action_dark-grey-bg__1sSdO {
  background-color: #222026;
  color: #fff; }

.action_flat-white-bg___pVV2 {
  background-color: #fff;
  color: #014660; }

.action_pastel-red-bg__1f9vx {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
w-action-module {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto; }
  @media (min-width: 1025px) {
    w-action-module {
      width: 320px; } }

.action_header__3Xpoq {
  align-items: center;
  background-color: rgba(185, 185, 185, 0.1);
  border-bottom: 1px solid #b9b9b9;
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  position: relative; }
  .action_header__3Xpoq button {
    background-color: transparent;
    border: 0;
    display: block;
    padding: 0;
    text-align: -webkit-center;
    align-items: center;
    background-color: rgba(212, 212, 216, 0.3);
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 10px;
    position: absolute;
    width: 40px; }
  .action_header__3Xpoq button:hover {
    background-color: rgba(212, 212, 216, 0.6); }
  .action_header__3Xpoq span {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .action_header__3Xpoq svg {
    height: 15px;
    width: 20px; }

.action_info__3a9BP {
  background-color: rgba(185, 185, 185, 0.1);
  flex: 1 1;
  overflow-y: auto;
  padding: 15px;
  padding-bottom: 65px; }
  @media (min-width: 1025px) {
    .action_info__3a9BP {
      padding-bottom: 15px; } }
  .action_info__3a9BP h1 {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 10px 0 15px; }

.action_details__3FQWl {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 1.5;
  margin: 10px 0; }

.action_info_block__2J_Ql {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: flex;
  line-height: 1.5; }
  .action_info_block__2J_Ql .action_details-info__17d_1 {
    flex: 1 1;
    padding: 0 10px 0 0; }
  .action_info_block__2J_Ql .action_buttons__2u8eT {
    display: flex;
    flex-direction: column;
    max-width: 90px;
    width: 100%; }
  .action_info_block__2J_Ql .action_primary__1iKqh,
  .action_info_block__2J_Ql .action_outline__41ddx {
    padding: 3px 40px;
    width: 75px; }
  .action_info_block__2J_Ql button:not(:last-of-type) {
    margin-bottom: 10px; }
  .action_info_block__2J_Ql .action_primary__1iKqh {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center; }
    .action_info_block__2J_Ql .action_primary__1iKqh:hover:not(:disabled) {
      background-color: #00212e; }
    .action_info_block__2J_Ql .action_primary__1iKqh:disabled {
      opacity: 0.5; }
  .action_info_block__2J_Ql .action_outline__41ddx {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(1, 70, 96, 0.5);
    font-weight: 600; }
    .action_info_block__2J_Ql .action_outline__41ddx:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .action_info_block__2J_Ql .action_outline__41ddx:disabled {
      opacity: 0.5; }
    .action_info_block__2J_Ql .action_outline__41ddx:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .action_info_block__2J_Ql .action_outline__41ddx:disabled {
      opacity: 0.5; }

.action_who__1kzt2 {
  margin-bottom: 20px; }

.action_remainingToggle__3CwSw {
  cursor: pointer;
  display: block;
  margin: 5px 0 0 26px;
  text-decoration: underline; }

.action_member__1-8g8 {
  align-items: flex-start;
  display: flex;
  line-height: 1.75;
  width: 100%; }
  .action_member__1-8g8 svg {
    flex: 0 0 16px;
    margin: 0 10px 0 0;
    min-height: 16px;
    min-width: 16px;
    position: relative;
    top: 2px; }

.action_cancelled__jtrfk {
  color: #e55e50;
  font-family: OpenSans, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal; }

.action_notes__1Yfic {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 1.5; }
  .action_notes__1Yfic h3 {
    color: #55556f;
    margin: 0 0 10px; }

.actions-list_white__2t9QZ {
  color: #f9f9f9; }

.actions-list_black__D3PVQ {
  color: #000; }

.actions-list_deep-teal__27KhI {
  color: #014660; }

.actions-list_greyish-teal__2Ko1_ {
  color: #5bdbbd; }

.actions-list_very-light-grey__1Ysno {
  color: #b9b9b9; }

.actions-list_warm-grey__3E6by {
  color: #55556f; }

.actions-list_simple-grey__12YFQ {
  color: #d4d4d8; }

.actions-list_dark-grey__2Iv7n {
  color: #222026; }

.actions-list_flat-white__1eFB1 {
  color: #fff; }

.actions-list_pastel-red__2ImZY {
  color: #e55e50; }

.actions-list_white-bg__2NKZy {
  background-color: #f9f9f9; }

.actions-list_deep-teal-bg__r0MQ9 {
  background-color: #014660;
  color: #fff; }

.actions-list_greyish-teal-bg__ccs6e {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.actions-list_very-light-grey-bg__582JQ {
  background-color: #b9b9b9;
  color: #222026; }

.actions-list_warm-grey-bg__2E7Bg {
  background-color: #55556f;
  color: #f9f9f9; }

.actions-list_simple-grey-bg__wYwh_ {
  background-color: #d4d4d8;
  color: #222026; }

.actions-list_dark-grey-bg__3TDNk {
  background-color: #222026;
  color: #fff; }

.actions-list_flat-white-bg__2krgk {
  background-color: #fff;
  color: #014660; }

.actions-list_pastel-red-bg__1YuTK {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
w-actions-list-module {
  flex: 1 1 auto;
  overflow-y: auto; }
  @media (min-width: 1025px) {
    w-actions-list-module {
      max-width: 320px; } }

.actions-list_wrapper__2AuOX {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%; }

.actions-list_selected-date__3KOgA {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "Questrial", sans-serif;
  padding: 20px; }

.actions-list_datepicker__3rRlI {
  background-color: rgba(212, 212, 216, 0.2);
  padding: 0 0 20px; }

.actions-list_list__31azI {
  padding: 15px;
  padding-bottom: 65px; }
  @media (min-width: 1025px) {
    .actions-list_list__31azI {
      padding-bottom: 15px; } }

.actions-list_empty__2WyGt {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0; }
  .actions-list_empty__2WyGt svg {
    width: 100px; }
  .actions-list_empty__2WyGt h2 {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 20px 0;
    text-align: center; }
  .actions-list_empty__2WyGt p {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center; }

.actions-list_event__2psJr {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%; }
  .actions-list_event-header__3ohkx {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 18pxpx;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 10px; }
  .actions-list_event-body__3l9h- {
    display: flex; }
  .actions-list_event-icon__2uusp svg {
    height: 20px;
    width: 20px; }
  .actions-list_event-info__1zSzh {
    flex: 1 1;
    min-width: 0;
    padding: 0 20px; }
  .actions-list_event-info__1zSzh > span {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .actions-list_event-info__1zSzh > span:not(:last-of-type) {
    margin-bottom: 10px; }
  .actions-list_event-info__1zSzh > span:first-of-type {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .actions-list_event-info__1zSzh > span:last-of-type {
    color: #e55e50;
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .actions-list_event-info__1zSzh, .actions-list_event-buttons__yfkuD {
    display: flex;
    flex-direction: column; }
  .actions-list_event__2psJr .actions-list_primary__nrdzb,
  .actions-list_event__2psJr .actions-list_outline__1sCGM {
    padding: 3px 40px;
    width: 75px; }
  .actions-list_event__2psJr button {
    padding: 5px 20px; }
  .actions-list_event__2psJr button:not(:last-of-type) {
    margin-bottom: 10px; }
  .actions-list_event__2psJr .actions-list_primary__nrdzb {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center; }
    .actions-list_event__2psJr .actions-list_primary__nrdzb:hover:not(:disabled) {
      background-color: #00212e; }
    .actions-list_event__2psJr .actions-list_primary__nrdzb:disabled {
      opacity: 0.5; }
  .actions-list_event__2psJr .actions-list_outline__1sCGM {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(1, 70, 96, 0.5);
    font-weight: 600; }
    .actions-list_event__2psJr .actions-list_outline__1sCGM:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .actions-list_event__2psJr .actions-list_outline__1sCGM:disabled {
      opacity: 0.5; }
    .actions-list_event__2psJr .actions-list_outline__1sCGM:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .actions-list_event__2psJr .actions-list_outline__1sCGM:disabled {
      opacity: 0.5; }

.activity_white__C0D9J {
  color: #f9f9f9; }

.activity_black__3P_f8 {
  color: #000; }

.activity_deep-teal__siHWe {
  color: #014660; }

.activity_greyish-teal__3jPcK {
  color: #5bdbbd; }

.activity_very-light-grey__ZsGUm {
  color: #b9b9b9; }

.activity_warm-grey__upDJM {
  color: #55556f; }

.activity_simple-grey__2mkMv {
  color: #d4d4d8; }

.activity_dark-grey__20Y_L {
  color: #222026; }

.activity_flat-white__1Wcsh {
  color: #fff; }

.activity_pastel-red__3UOrY {
  color: #e55e50; }

.activity_white-bg__1Bjea {
  background-color: #f9f9f9; }

.activity_deep-teal-bg__34xR3 {
  background-color: #014660;
  color: #fff; }

.activity_greyish-teal-bg__1v-aS {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.activity_very-light-grey-bg__2B3a8 {
  background-color: #b9b9b9;
  color: #222026; }

.activity_warm-grey-bg__1kuTI {
  background-color: #55556f;
  color: #f9f9f9; }

.activity_simple-grey-bg__R2nGS {
  background-color: #d4d4d8;
  color: #222026; }

.activity_dark-grey-bg__2x50Y {
  background-color: #222026;
  color: #fff; }

.activity_flat-white-bg__2aQhh {
  background-color: #fff;
  color: #014660; }

.activity_pastel-red-bg__162q5 {
  background-color: #e55e50;
  color: #f9f9f9; }

.activity_white__C0D9J {
  color: #f9f9f9; }

.activity_black__3P_f8 {
  color: #000; }

.activity_deep-teal__siHWe {
  color: #014660; }

.activity_greyish-teal__3jPcK {
  color: #5bdbbd; }

.activity_very-light-grey__ZsGUm {
  color: #b9b9b9; }

.activity_warm-grey__upDJM {
  color: #55556f; }

.activity_simple-grey__2mkMv {
  color: #d4d4d8; }

.activity_dark-grey__20Y_L {
  color: #222026; }

.activity_flat-white__1Wcsh {
  color: #fff; }

.activity_pastel-red__3UOrY {
  color: #e55e50; }

.activity_white-bg__1Bjea {
  background-color: #f9f9f9; }

.activity_deep-teal-bg__34xR3 {
  background-color: #014660;
  color: #fff; }

.activity_greyish-teal-bg__1v-aS {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.activity_very-light-grey-bg__2B3a8 {
  background-color: #b9b9b9;
  color: #222026; }

.activity_warm-grey-bg__1kuTI {
  background-color: #55556f;
  color: #f9f9f9; }

.activity_simple-grey-bg__R2nGS {
  background-color: #d4d4d8;
  color: #222026; }

.activity_dark-grey-bg__2x50Y {
  background-color: #222026;
  color: #fff; }

.activity_flat-white-bg__2aQhh {
  background-color: #fff;
  color: #014660; }

.activity_pastel-red-bg__162q5 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.activity_wrapper__3roAB {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 40%;
  text-align: center; }
  .activity_wrapper__3roAB svg {
    height: 100px;
    margin-bottom: 30px;
    width: 100px; }
  .activity_wrapper__3roAB h1 {
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 18px; }

.activity_feed_list__ymEcU {
  padding: 0.8em; }

.activity_feed_item__1Cqku {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 0.8em; }

.activity_item_content__1uIkd {
  padding: 0.8em; }

.activity_item_text__1WGYJ p {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 1.2em;
  word-break: break-word; }

.activity_item_text__1WGYJ a {
  color: #014660; }

.activity_item_date__2ng99 {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  display: block;
  padding-top: 0.5em; }

.activity_item_footer__1plLW {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }

.activity_item_button_group__1YeHd {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.8em 0.8em 0; }

.activity_item_button__D6Eve {
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  color: #fff;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 5px;
  padding: 0.3em 0.8em;
  text-align: center; }

.activity_social_wrapper__3JQGF {
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 0.8em 0.8em; }

.activity_social_item___siFB {
  align-items: center;
  cursor: pointer;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .activity_social_item___siFB svg {
    margin-right: 0.3em; }

.activity_badge_wrapper__2wJV1 {
  margin-bottom: 1em;
  overflow: hidden;
  position: relative; }

.activity_badge_background__2AQfJ {
  background: #014660;
  border-radius: 50%;
  bottom: 0;
  height: 300px;
  position: absolute;
  -webkit-transform: translateX(-16%);
          transform: translateX(-16%);
  width: 140%; }

.activity_badge_content__ZJSHN {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  padding: 2em 3em;
  position: relative; }

.activity_badge_value__2iDyD {
  align-items: center;
  background-color: #4cb49c;
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
  color: #fff;
  display: flex;
  font-size: 2em;
  height: 34.64px;
  justify-content: center;
  margin: 17.32px 0;
  position: relative;
  width: 60px; }

.activity_badge_value__2iDyD::before,
.activity_badge_value__2iDyD::after {
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  content: "";
  left: 0;
  position: absolute;
  width: 0; }

.activity_badge_value__2iDyD::before {
  border-bottom: 17.32px solid #4cb49c;
  bottom: 100%; }

.activity_badge_value__2iDyD::after {
  border-top: 17.32px solid #4cb49c;
  top: 100%;
  width: 0; }

.activity_badge_caption__1f-k6 {
  color: #fff;
  line-height: 1.2em;
  padding-top: 1em;
  text-align: center; }

.header_white__1MKVt {
  color: #f9f9f9; }

.header_black__1fxys {
  color: #000; }

.header_deep-teal__1ORZq {
  color: #014660; }

.header_greyish-teal__1FJkY {
  color: #5bdbbd; }

.header_very-light-grey__TByQX {
  color: #b9b9b9; }

.header_warm-grey__mgThO {
  color: #55556f; }

.header_simple-grey__3uuh- {
  color: #d4d4d8; }

.header_dark-grey__3BC2u {
  color: #222026; }

.header_flat-white__JX3hF {
  color: #fff; }

.header_pastel-red__30s-i {
  color: #e55e50; }

.header_white-bg__37Fx7 {
  background-color: #f9f9f9; }

.header_deep-teal-bg__3OrRT {
  background-color: #014660;
  color: #fff; }

.header_greyish-teal-bg__1fHg1 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header_very-light-grey-bg__M8N66 {
  background-color: #b9b9b9;
  color: #222026; }

.header_warm-grey-bg__1iq5N {
  background-color: #55556f;
  color: #f9f9f9; }

.header_simple-grey-bg__99A0F {
  background-color: #d4d4d8;
  color: #222026; }

.header_dark-grey-bg__3lN5S {
  background-color: #222026;
  color: #fff; }

.header_flat-white-bg__gtG4M {
  background-color: #fff;
  color: #014660; }

.header_pastel-red-bg__8NhDd {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.header_wrapper__3sR9l {
  align-items: center;
  border-bottom: 1px solid #d4d4d8;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px; }

.header_back__yfr1M {
  display: none; }
  .header_back__yfr1M:hover {
    opacity: 0.6; }
  @media (max-width: 1024px) {
    .header_back__yfr1M {
      background-color: transparent;
      border: 0;
      display: block;
      padding: 0;
      text-align: -webkit-center;
      margin-right: 10px;
      width: 30px; } }

.header_header_data__BSTpf {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  font-weight: 600;
  height: 60px; }

.header_status__3Op3k {
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0 10px; }
  .header_status__3Op3k svg {
    height: 10px;
    width: 10px; }

.header_direct_status__M-xZ1 {
  margin-left: 0; }

.header_menu__1a8zm {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center; }
  .header_menu__1a8zm svg {
    height: 20px;
    width: 15px; }

.header_opened_menu__2975M {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.header_popup__2YgFS {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1000; }

@-webkit-keyframes header_show__4aTw7 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes header_show__4aTw7 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.header_toggle__2pK2l {
  cursor: pointer; }

.header_popup_wrapper__3fS6T {
  -webkit-animation: header_show__4aTw7 0.3s;
          animation: header_show__4aTw7 0.3s;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
  cursor: auto;
  padding: 20px 15px;
  position: relative;
  width: 340px; }
  .header_popup_wrapper__3fS6T::before {
    background-color: #fff;
    height: 15px;
    position: absolute;
    right: 5px;
    top: -5px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 15px;
    z-index: 3; }
  .header_popup_wrapper__3fS6T svg {
    height: 22px;
    width: 22px; }
  .header_popup_wrapper__3fS6T h4 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 22px;
    margin-bottom: 15px; }
  .header_popup_wrapper__3fS6T span {
    color: #014660;
    font-family: "OpenSans", sans-serif;
    font-size: 16px; }
  @media (max-width: 1024px) {
    .header_popup_wrapper__3fS6T {
      width: 250px; } }

.header_option__1IhVp {
  align-items: center;
  cursor: pointer;
  display: flex;
  text-transform: capitalize; }
  .header_option__1IhVp.header_disabled__cfggn {
    color: #d4d4d8;
    pointer-events: none; }

.header_close__1cS8j {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px; }

.header_settings_wrapper__1C3CH {
  display: grid;
  grid-row-gap: 15px;
  max-height: 300px;
  overflow-y: auto; }

.navigation_white__3SVFY {
  color: #f9f9f9; }

.navigation_black__3kIqQ {
  color: #000; }

.navigation_deep-teal__-0rtC {
  color: #014660; }

.navigation_greyish-teal__1r6J5 {
  color: #5bdbbd; }

.navigation_very-light-grey__3spPk {
  color: #b9b9b9; }

.navigation_warm-grey__O4_82 {
  color: #55556f; }

.navigation_simple-grey__36Rds {
  color: #d4d4d8; }

.navigation_dark-grey__-epmB {
  color: #222026; }

.navigation_flat-white__31vAR {
  color: #fff; }

.navigation_pastel-red__2B7RS {
  color: #e55e50; }

.navigation_white-bg__1Op-k {
  background-color: #f9f9f9; }

.navigation_deep-teal-bg__3cSMH {
  background-color: #014660;
  color: #fff; }

.navigation_greyish-teal-bg__3J-Qh {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.navigation_very-light-grey-bg__2rVUE {
  background-color: #b9b9b9;
  color: #222026; }

.navigation_warm-grey-bg__18lov {
  background-color: #55556f;
  color: #f9f9f9; }

.navigation_simple-grey-bg__1XB30 {
  background-color: #d4d4d8;
  color: #222026; }

.navigation_dark-grey-bg__38pZu {
  background-color: #222026;
  color: #fff; }

.navigation_flat-white-bg__29SeF {
  background-color: #fff;
  color: #014660; }

.navigation_pastel-red-bg__1gLy5 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
@media (min-width: 1025px) {
  .navigation_wrapper__gwqDV {
    width: 320px; } }

.navigation_back__2N4ke {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  align-items: center;
  color: #014660;
  cursor: pointer;
  display: flex; }
  .navigation_back__2N4ke svg {
    height: 22px;
    margin-right: 10px;
    width: 30px; }
  @media (max-width: 1024px) {
    .navigation_back__2N4ke {
      left: 15px;
      position: absolute;
      top: 10px; } }

.navigation_top__En1Mv {
  background-color: #f9f9f9;
  display: flex;
  padding: 30px 15px 25px;
  position: relative; }
  @media (max-width: 1024px) {
    .navigation_top__En1Mv {
      justify-content: center;
      min-height: 40px;
      padding: 14px 15px; } }

.navigation_nav-items__3HkZP {
  color: #014660;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  flex: 1 1;
  -ms-overflow-style: none;
  overflow-x: scroll;
  scrollbar-width: thin;
  white-space: nowrap; }
  .navigation_nav-items__3HkZP .navigation_current__279Oq {
    font-weight: bold; }
  .navigation_nav-items__3HkZP div:hover {
    color: #001a23; }
  .navigation_nav-items__3HkZP::-webkit-scrollbar {
    background: transparent;
    height: 0;
    min-height: 0; }
  @media (max-width: 1024px) {
    .navigation_nav-items__3HkZP {
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #b9b9b9;
      border-top: 1px solid #b9b9b9; }
      .navigation_nav-items__3HkZP div {
        padding: 15px; } }
  @media (min-width: 1025px) {
    .navigation_nav-items__3HkZP {
      flex-direction: column; }
      .navigation_nav-items__3HkZP div {
        padding: 7px 15px; } }

.navigation_spacer__1nFKt {
  height: 100%; }

.profile-contact-details_white__WVsZr {
  color: #f9f9f9; }

.profile-contact-details_black__3e4QN {
  color: #000; }

.profile-contact-details_deep-teal__QaBbv {
  color: #014660; }

.profile-contact-details_greyish-teal__1Udqf {
  color: #5bdbbd; }

.profile-contact-details_very-light-grey__35DiS {
  color: #b9b9b9; }

.profile-contact-details_warm-grey__3nt9p {
  color: #55556f; }

.profile-contact-details_simple-grey__sWaqG {
  color: #d4d4d8; }

.profile-contact-details_dark-grey__1sNZ- {
  color: #222026; }

.profile-contact-details_flat-white__1-h9J {
  color: #fff; }

.profile-contact-details_pastel-red__3-3In {
  color: #e55e50; }

.profile-contact-details_white-bg__2VAdR {
  background-color: #f9f9f9; }

.profile-contact-details_deep-teal-bg__3ri2k {
  background-color: #014660;
  color: #fff; }

.profile-contact-details_greyish-teal-bg__1vhay {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-contact-details_very-light-grey-bg__facfq {
  background-color: #b9b9b9;
  color: #222026; }

.profile-contact-details_warm-grey-bg__oxbep {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-contact-details_simple-grey-bg__3NooI {
  background-color: #d4d4d8;
  color: #222026; }

.profile-contact-details_dark-grey-bg__1QeNh {
  background-color: #222026;
  color: #fff; }

.profile-contact-details_flat-white-bg__3NhsB {
  background-color: #fff;
  color: #014660; }

.profile-contact-details_pastel-red-bg__2ztqq {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-contact-details_cards-wrapper__3psXn {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-contact-details_cards-wrapper__3psXn {
      padding-bottom: 50px; }
      .profile-contact-details_cards-wrapper__3psXn::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-contact-details_cards-wrapper__3psXn .profile-contact-details_title__1dke- {
        display: none; }
      .profile-contact-details_cards-wrapper__3psXn .profile-contact-details_inputs__2xwRz {
        padding: 0; } }

.profile-contact-details_title__1dke- {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: Questrial, sans-serif; }

@media (max-width: 1024px) {
  .profile-contact-details_cards-wrapper__3psXn .profile-contact-details_mobile-header__VxmkG {
    display: inline-flex; } }

.profile-contact-details_mobile-header__VxmkG {
  display: none;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%; }

.profile-contact-details_mobile-title__37lXG {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px;
  font-family: Questrial, sans-serif; }
  @media (max-width: 1024px) {
    .profile-contact-details_mobile-title__37lXG {
      font-size: 20px; } }

.profile-contact-details_card__3rcyg {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px; }
  @media (max-width: 1024px) {
    .profile-contact-details_card__3rcyg {
      margin-bottom: 20px;
      margin-top: 0; } }
  .profile-contact-details_card__3rcyg .profile-contact-details_button__1VNoX {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-contact-details_card__3rcyg .profile-contact-details_button__1VNoX:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-contact-details_card__3rcyg .profile-contact-details_button__1VNoX:disabled {
      opacity: 0.5; }
  .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 14px; }
    .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS:disabled {
      opacity: 0.5; }
    .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS:disabled {
      opacity: 0.5; }
  .profile-contact-details_card__3rcyg .profile-contact-details_button__1VNoX, .profile-contact-details_card__3rcyg .profile-contact-details_button-outlined__3D4uS {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-contact-details_card__3rcyg .profile-contact-details_card-title__K5nWt,
  .profile-contact-details_card__3rcyg .profile-contact-details_card-line__vs_F0 {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    line-height: 22px;
    margin-bottom: 10px; }
    @media (max-width: 1024px) {
      .profile-contact-details_card__3rcyg .profile-contact-details_card-title__K5nWt,
      .profile-contact-details_card__3rcyg .profile-contact-details_card-line__vs_F0 {
        font-size: 14px; } }
  .profile-contact-details_card__3rcyg .profile-contact-details_card-title__K5nWt {
    display: flex;
    height: 22px;
    justify-content: space-between; }
  .profile-contact-details_card__3rcyg .profile-contact-details_line-content__351WS {
    display: inline-block;
    max-width: calc(100% - 105px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .profile-contact-details_card__3rcyg .profile-contact-details_line-label__1GFGa {
    display: inline-block;
    min-width: 100px; }

.profile-contact-details_info__gPJbq {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: rgba(246, 246, 246, 0.5);
  font-size: 12px;
  line-height: 16px;
  margin: 15px -15px -20px;
  padding: 15px; }

.profile-contact-details_button-outlined__3D4uS {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #014660;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-weight: 600; }
  .profile-contact-details_button-outlined__3D4uS:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .profile-contact-details_button-outlined__3D4uS:disabled {
    opacity: 0.5; }
  .profile-contact-details_button-outlined__3D4uS:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .profile-contact-details_button-outlined__3D4uS:disabled {
    opacity: 0.5; }

.profile-general_white__16lX- {
  color: #f9f9f9; }

.profile-general_black__2KBZN {
  color: #000; }

.profile-general_deep-teal__2-tE1 {
  color: #014660; }

.profile-general_greyish-teal__1cPn5 {
  color: #5bdbbd; }

.profile-general_very-light-grey__1aM08 {
  color: #b9b9b9; }

.profile-general_warm-grey__2yunc {
  color: #55556f; }

.profile-general_simple-grey__DpvT7 {
  color: #d4d4d8; }

.profile-general_dark-grey__1DgVU {
  color: #222026; }

.profile-general_flat-white__2tTRE {
  color: #fff; }

.profile-general_pastel-red__2b0kH {
  color: #e55e50; }

.profile-general_white-bg__3Xc6J {
  background-color: #f9f9f9; }

.profile-general_deep-teal-bg__3b8op {
  background-color: #014660;
  color: #fff; }

.profile-general_greyish-teal-bg__1gn3Q {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-general_very-light-grey-bg__tCk5E {
  background-color: #b9b9b9;
  color: #222026; }

.profile-general_warm-grey-bg__1BJ2L {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-general_simple-grey-bg__AqYw7 {
  background-color: #d4d4d8;
  color: #222026; }

.profile-general_dark-grey-bg__3vc2I {
  background-color: #222026;
  color: #fff; }

.profile-general_flat-white-bg__N-7Jv {
  background-color: #fff;
  color: #014660; }

.profile-general_pastel-red-bg__2pewq {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-general_cards-wrapper__2wVAh {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-general_cards-wrapper__2wVAh {
      padding-bottom: 50px; }
      .profile-general_cards-wrapper__2wVAh::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-general_cards-wrapper__2wVAh .profile-general_title__3Lsqf {
        display: none; }
      .profile-general_cards-wrapper__2wVAh .profile-general_inputs__196qd {
        padding: 0; } }

.profile-general_title__3Lsqf {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: Questrial, sans-serif; }

.profile-general_subtitle__xYNIR {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px;
  font-family: Questrial, sans-serif; }
  @media (max-width: 1024px) {
    .profile-general_subtitle__xYNIR {
      font-size: 20px; } }

.profile-general_content__pJbu- {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 22px; }
  @media (max-width: 1024px) {
    .profile-general_content__pJbu- {
      font-size: 14px; } }

.profile-general_card__2_bI4 {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px; }
  @media (max-width: 1024px) {
    .profile-general_card__2_bI4 {
      margin-bottom: 20px;
      margin-top: 0; } }
  .profile-general_card__2_bI4 .profile-general_button__29zJw {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-general_card__2_bI4 .profile-general_button__29zJw:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-general_card__2_bI4 .profile-general_button__29zJw:disabled {
      opacity: 0.5; }
  .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 14px; }
    .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M:disabled {
      opacity: 0.5; }
    .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M:disabled {
      opacity: 0.5; }
  .profile-general_card__2_bI4 .profile-general_button__29zJw, .profile-general_card__2_bI4 .profile-general_button-outlined__2lB-M {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-general_card__2_bI4 .profile-general_header__135ny {
    display: flex; }

.profile-general_break_lines__UaSw2 {
  white-space: pre-wrap; }

.profile-general_link__3y2HH {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 5px; }
  @media (max-width: 1024px) {
    .profile-general_link__3y2HH {
      font-size: 14px; } }

.profile-general_soc-net-icon__4FS5M {
  display: flex;
  margin-right: 10px; }
  .profile-general_soc-net-icon__4FS5M svg {
    height: 22px;
    width: 22px; }

.profile-header_white__3l1ok {
  color: #f9f9f9; }

.profile-header_black__L8SH- {
  color: #000; }

.profile-header_deep-teal__2uR_w {
  color: #014660; }

.profile-header_greyish-teal__15A4g {
  color: #5bdbbd; }

.profile-header_very-light-grey__3YSCF {
  color: #b9b9b9; }

.profile-header_warm-grey__2CRd3 {
  color: #55556f; }

.profile-header_simple-grey__6W3-- {
  color: #d4d4d8; }

.profile-header_dark-grey__25Wq9 {
  color: #222026; }

.profile-header_flat-white__3Alf7 {
  color: #fff; }

.profile-header_pastel-red__ETAZl {
  color: #e55e50; }

.profile-header_white-bg__2WWen {
  background-color: #f9f9f9; }

.profile-header_deep-teal-bg__3QrmH {
  background-color: #014660;
  color: #fff; }

.profile-header_greyish-teal-bg__3G6gx {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-header_very-light-grey-bg__3QD48 {
  background-color: #b9b9b9;
  color: #222026; }

.profile-header_warm-grey-bg__2uLMB {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-header_simple-grey-bg__3lGkd {
  background-color: #d4d4d8;
  color: #222026; }

.profile-header_dark-grey-bg__30SFA {
  background-color: #222026;
  color: #fff; }

.profile-header_flat-white-bg__3GYFS {
  background-color: #fff;
  color: #014660; }

.profile-header_pastel-red-bg__2-pVT {
  background-color: #e55e50;
  color: #f9f9f9; }

.profile-header_white__3l1ok {
  color: #f9f9f9; }

.profile-header_black__L8SH- {
  color: #000; }

.profile-header_deep-teal__2uR_w {
  color: #014660; }

.profile-header_greyish-teal__15A4g {
  color: #5bdbbd; }

.profile-header_very-light-grey__3YSCF {
  color: #b9b9b9; }

.profile-header_warm-grey__2CRd3 {
  color: #55556f; }

.profile-header_simple-grey__6W3-- {
  color: #d4d4d8; }

.profile-header_dark-grey__25Wq9 {
  color: #222026; }

.profile-header_flat-white__3Alf7 {
  color: #fff; }

.profile-header_pastel-red__ETAZl {
  color: #e55e50; }

.profile-header_white-bg__2WWen {
  background-color: #f9f9f9; }

.profile-header_deep-teal-bg__3QrmH {
  background-color: #014660;
  color: #fff; }

.profile-header_greyish-teal-bg__3G6gx {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-header_very-light-grey-bg__3QD48 {
  background-color: #b9b9b9;
  color: #222026; }

.profile-header_warm-grey-bg__2uLMB {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-header_simple-grey-bg__3lGkd {
  background-color: #d4d4d8;
  color: #222026; }

.profile-header_dark-grey-bg__30SFA {
  background-color: #222026;
  color: #fff; }

.profile-header_flat-white-bg__3GYFS {
  background-color: #fff;
  color: #014660; }

.profile-header_pastel-red-bg__2-pVT {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-header_header-wrapper__DWKWY {
  align-items: flex-start;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#0399be), to(#014660));
  background-image: linear-gradient(to top, #0399be, #014660);
  display: flex;
  height: 170px;
  padding: 15px;
  position: relative; }
  @media (max-width: 1024px) {
    .profile-header_header-wrapper__DWKWY {
      height: auto;
      padding-bottom: 8vh;
      padding-top: 2vh; } }
  .profile-header_header-wrapper__DWKWY input[type="file"] {
    display: none; }
  .profile-header_header-wrapper__DWKWY .profile-header_avatar__wRqPH {
    position: relative; }
  .profile-header_header-wrapper__DWKWY .profile-header_pencil__14E0w {
    align-items: center;
    background-color: #fff;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 22px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 22px;
    z-index: 1; }
    .profile-header_header-wrapper__DWKWY .profile-header_pencil__14E0w svg {
      height: 12px;
      width: 12px; }
  .profile-header_header-wrapper__DWKWY .profile-header_remove__2CL8D {
    left: 0;
    position: absolute;
    top: 0; }
    .profile-header_header-wrapper__DWKWY .profile-header_remove__2CL8D svg {
      cursor: pointer;
      height: 22px;
      width: 22px; }
  .profile-header_header-wrapper__DWKWY .profile-header_details__Ki4qz {
    display: flex;
    flex-direction: column;
    margin-left: 20px; }
  .profile-header_header-wrapper__DWKWY .profile-header_username__1e5NT {
    color: #fff;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    margin: 10px 0; }
    @media (max-width: 1024px) {
      .profile-header_header-wrapper__DWKWY .profile-header_username__1e5NT {
        font-size: 18px; } }
  .profile-header_header-wrapper__DWKWY .profile-header_pts__IJrju {
    align-items: center;
    align-self: flex-start;
    background: none;
    border: 0;
    color: #5bdbbd;
    display: flex;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    margin-bottom: 10px; }
    .profile-header_header-wrapper__DWKWY .profile-header_pts__IJrju span {
      margin-left: 6px; }
    .profile-header_header-wrapper__DWKWY .profile-header_pts__IJrju svg {
      height: 22px;
      width: 22px; }
  .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    height: 28px;
    padding: 3px 25px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:disabled {
      opacity: 0.5; }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:disabled {
      opacity: 0.5; }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:hover:not(:disabled) {
      background-color: #026b92; }
    .profile-header_header-wrapper__DWKWY .profile-header_button__2N6zx:focus:not(:disabled) {
      background-color: #026b92; }

.collapsable_white__1gl7z {
  color: #f9f9f9; }

.collapsable_black__1a9z0 {
  color: #000; }

.collapsable_deep-teal__3slFq {
  color: #014660; }

.collapsable_greyish-teal__3H_l3 {
  color: #5bdbbd; }

.collapsable_very-light-grey__jfUVt {
  color: #b9b9b9; }

.collapsable_warm-grey__32OYo {
  color: #55556f; }

.collapsable_simple-grey__3ugpX {
  color: #d4d4d8; }

.collapsable_dark-grey__vLcsV {
  color: #222026; }

.collapsable_flat-white__1M9rQ {
  color: #fff; }

.collapsable_pastel-red__1BrGy {
  color: #e55e50; }

.collapsable_white-bg__2Tx15 {
  background-color: #f9f9f9; }

.collapsable_deep-teal-bg__3Qx-i {
  background-color: #014660;
  color: #fff; }

.collapsable_greyish-teal-bg__172L1 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.collapsable_very-light-grey-bg__19w9l {
  background-color: #b9b9b9;
  color: #222026; }

.collapsable_warm-grey-bg__jKKn8 {
  background-color: #55556f;
  color: #f9f9f9; }

.collapsable_simple-grey-bg__7-hR7 {
  background-color: #d4d4d8;
  color: #222026; }

.collapsable_dark-grey-bg__2PPuT {
  background-color: #222026;
  color: #fff; }

.collapsable_flat-white-bg__3YdC5 {
  background-color: #fff;
  color: #014660; }

.collapsable_pastel-red-bg__10Zth {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.collapsable_collapsable-wrapper__2WvxA {
  border-top: 1px solid #014660;
  padding: 0 10px; }

.collapsable_grouped-mobile__OVv2N {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 61px; }

.collapsable_collapsable-wrapper__2WvxA:nth-last-child(1) {
  border-bottom: 1px solid #014660; }

.collapsable_header-collapsable__10WRn {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px; }
  .collapsable_header-collapsable__10WRn .collapsable_title__3nfY_ {
    color: #014660;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .collapsable_header-collapsable__10WRn .collapsable_collapsable-button__3utfP > svg {
    height: 20px;
    width: 20px; }

.collapsable_content__c1pbM {
  display: block;
  overflow: auto; }

.radio-buttons_radio-button__1-R9S .radio-buttons_radio-icon__2Ksk4 {
  cursor: pointer;
  height: 24px;
  width: 24px; }

.radio-buttons_radio-button__1-R9S input {
  display: none; }

.profile-notifications-and-emails_white__h7L8V {
  color: #f9f9f9; }

.profile-notifications-and-emails_black__1FLI- {
  color: #000; }

.profile-notifications-and-emails_deep-teal__15t4c {
  color: #014660; }

.profile-notifications-and-emails_greyish-teal__3UMwj {
  color: #5bdbbd; }

.profile-notifications-and-emails_very-light-grey__3TLR5 {
  color: #b9b9b9; }

.profile-notifications-and-emails_warm-grey__2USn9 {
  color: #55556f; }

.profile-notifications-and-emails_simple-grey__3-kcf {
  color: #d4d4d8; }

.profile-notifications-and-emails_dark-grey__4JMIt {
  color: #222026; }

.profile-notifications-and-emails_flat-white__2RDRS {
  color: #fff; }

.profile-notifications-and-emails_pastel-red__aR18C {
  color: #e55e50; }

.profile-notifications-and-emails_white-bg__1CLx6 {
  background-color: #f9f9f9; }

.profile-notifications-and-emails_deep-teal-bg__GjX1Z {
  background-color: #014660;
  color: #fff; }

.profile-notifications-and-emails_greyish-teal-bg__3kysf {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-notifications-and-emails_very-light-grey-bg__3vCVY {
  background-color: #b9b9b9;
  color: #222026; }

.profile-notifications-and-emails_warm-grey-bg__21QSe {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-notifications-and-emails_simple-grey-bg__3XDuv {
  background-color: #d4d4d8;
  color: #222026; }

.profile-notifications-and-emails_dark-grey-bg__32gIn {
  background-color: #222026;
  color: #fff; }

.profile-notifications-and-emails_flat-white-bg__o8JYP {
  background-color: #fff;
  color: #014660; }

.profile-notifications-and-emails_pastel-red-bg__20ZLf {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-notifications-and-emails_cards-wrapper__oRndA {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-notifications-and-emails_cards-wrapper__oRndA {
      padding-bottom: 50px; }
      .profile-notifications-and-emails_cards-wrapper__oRndA::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-notifications-and-emails_cards-wrapper__oRndA .profile-notifications-and-emails_title__Rx9Qo {
        display: none; }
      .profile-notifications-and-emails_cards-wrapper__oRndA .profile-notifications-and-emails_inputs__3H12f {
        padding: 0; } }

.profile-notifications-and-emails_title__Rx9Qo {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: Questrial, sans-serif; }

.profile-notifications-and-emails_subtitle__BRDOM {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px; }
  @media (max-width: 1024px) {
    .profile-notifications-and-emails_subtitle__BRDOM {
      font-size: 20px; } }

.profile-notifications-and-emails_description__2S0Wt {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 22px; }
  @media (max-width: 1024px) {
    .profile-notifications-and-emails_description__2S0Wt {
      font-size: 14px; } }

@media (max-width: 1024px) {
  .profile-notifications-and-emails_header__23cEv {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px 15px; }
    .profile-notifications-and-emails_header__23cEv .profile-notifications-and-emails_title__Rx9Qo {
      display: block;
      font-size: 20px; }
    .profile-notifications-and-emails_header__23cEv .profile-notifications-and-emails_description__2S0Wt {
      color: #55556f; } }

.profile-notifications-and-emails_alert__3qRjH {
  color: #fff;
  font-family: OpenSans, sans-serif;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5fe8c3), to(#43b395));
  background-image: linear-gradient(to bottom, #5fe8c3, #43b395);
  border-radius: 100%;
  display: inline-flex;
  height: 15px;
  justify-content: center;
  position: relative;
  top: -2px;
  width: 15px; }

.profile-notifications-and-emails_bell__2Z_eO > svg {
  height: 15px;
  position: relative;
  top: 1px;
  width: 20px; }

.profile-notifications-and-emails_notification-title__1HU8H {
  color: #222026;
  font-size: 16px;
  margin-bottom: 8px; }

.profile-notifications-and-emails_notification-description__1WUpZ {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 22px;
  font-size: 14px;
  line-height: 1.4em; }
  @media (max-width: 1024px) {
    .profile-notifications-and-emails_notification-description__1WUpZ {
      font-size: 14px; } }

.profile-notifications-and-emails_notification-description__1WUpZ,
.profile-notifications-and-emails_row-title__3buAV,
.profile-notifications-and-emails_col-title__3PjEE,
.profile-notifications-and-emails_collapsable-description__3Abcb {
  color: #55556f; }

.profile-notifications-and-emails_col-title__3PjEE,
.profile-notifications-and-emails_collapsable-description__3Abcb,
.profile-notifications-and-emails_reset-config__3atmi {
  font-size: 12px; }

.profile-notifications-and-emails_collapsable-description__3Abcb {
  line-height: normal;
  padding-bottom: 10px; }

.profile-notifications-and-emails_card__96ICK {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px; }
  @media (max-width: 1024px) {
    .profile-notifications-and-emails_card__96ICK {
      margin-bottom: 20px;
      margin-top: 0; } }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl:disabled {
      opacity: 0.5; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 14px; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:disabled {
      opacity: 0.5; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:disabled {
      opacity: 0.5; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl, .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_notification-content__1e9BA {
    display: flex;
    justify-content: space-between; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl:disabled {
      opacity: 0.5; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 18px; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:disabled {
      opacity: 0.5; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV:disabled {
      opacity: 0.5; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button__2sSEl,
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_button-outlined__30VNV {
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-notifications-and-emails_card__96ICK .profile-notifications-and-emails_notification-button__2ZaUl {
    display: flex; }

.profile-notifications-and-emails_notification-card__3fc0s {
  display: inline-flex;
  justify-content: space-between;
  width: 100%; }

.profile-notifications-and-emails_table__1xevY {
  padding: 10px 0; }

.profile-notifications-and-emails_table-row__37Qp4 {
  display: inline-flex;
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede; }
  .profile-notifications-and-emails_table-row__37Qp4 .profile-notifications-and-emails_row-title__3buAV {
    font-size: 16px;
    justify-content: flex-start;
    text-align: left; }
  .profile-notifications-and-emails_table-row__37Qp4 > div {
    align-items: center;
    display: flex;
    flex: 1 1;
    justify-content: center;
    text-align: center; }

.profile-notifications-and-emails_reset-config__3atmi {
  color: #014660;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 34px; }

.mentoring-contract_white__YSlYr {
  color: #f9f9f9; }

.mentoring-contract_black__3mRhz {
  color: #000; }

.mentoring-contract_deep-teal__1y1li {
  color: #014660; }

.mentoring-contract_greyish-teal__3Kz4W {
  color: #5bdbbd; }

.mentoring-contract_very-light-grey__35Wcc {
  color: #b9b9b9; }

.mentoring-contract_warm-grey__1Od09 {
  color: #55556f; }

.mentoring-contract_simple-grey__33C8U {
  color: #d4d4d8; }

.mentoring-contract_dark-grey__3IeWO {
  color: #222026; }

.mentoring-contract_flat-white__3He-u {
  color: #fff; }

.mentoring-contract_pastel-red__3O00p {
  color: #e55e50; }

.mentoring-contract_white-bg__3nz51 {
  background-color: #f9f9f9; }

.mentoring-contract_deep-teal-bg__3n6_o {
  background-color: #014660;
  color: #fff; }

.mentoring-contract_greyish-teal-bg__UB47V {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.mentoring-contract_very-light-grey-bg__21YcO {
  background-color: #b9b9b9;
  color: #222026; }

.mentoring-contract_warm-grey-bg__1CfG3 {
  background-color: #55556f;
  color: #f9f9f9; }

.mentoring-contract_simple-grey-bg__1WcJA {
  background-color: #d4d4d8;
  color: #222026; }

.mentoring-contract_dark-grey-bg__2NXq8 {
  background-color: #222026;
  color: #fff; }

.mentoring-contract_flat-white-bg__3Xp84 {
  background-color: #fff;
  color: #014660; }

.mentoring-contract_pastel-red-bg__1SnuE {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.mentoring-contract_dialog-wrapper__33-F1 {
  min-width: 550px; }
  @media (max-width: 1024px) {
    .mentoring-contract_dialog-wrapper__33-F1 {
      min-width: 60vw; } }
  .mentoring-contract_dialog-wrapper__33-F1 .mentoring-contract_contract-line__2iFx2 {
    background-color: rgba(76, 180, 156, 0.1);
    margin-bottom: 10px;
    padding: 15px; }
  .mentoring-contract_dialog-wrapper__33-F1 .mentoring-contract_description__3RfbM {
    margin-bottom: 12px; }

.profile-professional-development_white__29EYC {
  color: #f9f9f9; }

.profile-professional-development_black__3vFld {
  color: #000; }

.profile-professional-development_deep-teal__19AyN {
  color: #014660; }

.profile-professional-development_greyish-teal__2yjwC {
  color: #5bdbbd; }

.profile-professional-development_very-light-grey__3Jsqu {
  color: #b9b9b9; }

.profile-professional-development_warm-grey__37UMN {
  color: #55556f; }

.profile-professional-development_simple-grey__2bUg3 {
  color: #d4d4d8; }

.profile-professional-development_dark-grey__1VXq9 {
  color: #222026; }

.profile-professional-development_flat-white__1WT6m {
  color: #fff; }

.profile-professional-development_pastel-red__3rvjP {
  color: #e55e50; }

.profile-professional-development_white-bg__1P6V0 {
  background-color: #f9f9f9; }

.profile-professional-development_deep-teal-bg__CYFWg {
  background-color: #014660;
  color: #fff; }

.profile-professional-development_greyish-teal-bg__B06sv {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-professional-development_very-light-grey-bg__LrKFr {
  background-color: #b9b9b9;
  color: #222026; }

.profile-professional-development_warm-grey-bg__G-im- {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-professional-development_simple-grey-bg__2Hh3b {
  background-color: #d4d4d8;
  color: #222026; }

.profile-professional-development_dark-grey-bg__1zt8j {
  background-color: #222026;
  color: #fff; }

.profile-professional-development_flat-white-bg__2s_xZ {
  background-color: #fff;
  color: #014660; }

.profile-professional-development_pastel-red-bg__3cyH8 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-professional-development_cards-wrapper__ltqtZ {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-professional-development_cards-wrapper__ltqtZ {
      padding-bottom: 50px; }
      .profile-professional-development_cards-wrapper__ltqtZ::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-professional-development_cards-wrapper__ltqtZ .profile-professional-development_title__1YUkl {
        display: none; }
      .profile-professional-development_cards-wrapper__ltqtZ .profile-professional-development_inputs__2dX9w {
        padding: 0; } }

.profile-professional-development_title__1YUkl {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: Questrial, sans-serif; }

@media (max-width: 1024px) {
  .profile-professional-development_cards-wrapper__ltqtZ .profile-professional-development_mobile-header__aT7x7 {
    display: inline-flex; } }

.profile-professional-development_mobile-title__2O2U3 {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px;
  display: none;
  font-family: Questrial, sans-serif;
  margin-bottom: 20px; }
  @media (max-width: 1024px) {
    .profile-professional-development_mobile-title__2O2U3 {
      font-size: 20px; } }

.profile-professional-development_card__26m4m {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px; }
  @media (max-width: 1024px) {
    .profile-professional-development_card__26m4m {
      margin-bottom: 20px;
      margin-top: 0; } }
  .profile-professional-development_card__26m4m .profile-professional-development_button__xR8hu {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-professional-development_card__26m4m .profile-professional-development_button__xR8hu:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-professional-development_card__26m4m .profile-professional-development_button__xR8hu:disabled {
      opacity: 0.5; }
  .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 14px; }
    .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu:disabled {
      opacity: 0.5; }
    .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu:disabled {
      opacity: 0.5; }
  .profile-professional-development_card__26m4m .profile-professional-development_button__xR8hu, .profile-professional-development_card__26m4m .profile-professional-development_button-outlined__1IDuu {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-professional-development_card__26m4m .profile-professional-development_card-title__3_yVP,
  .profile-professional-development_card__26m4m .profile-professional-development_card-content__1H_a2,
  .profile-professional-development_card__26m4m .profile-professional-development_mentoring__3ip_q,
  .profile-professional-development_card__26m4m .profile-professional-development_area-label__3V8zv {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    line-height: 22px; }
    @media (max-width: 1024px) {
      .profile-professional-development_card__26m4m .profile-professional-development_card-title__3_yVP,
      .profile-professional-development_card__26m4m .profile-professional-development_card-content__1H_a2,
      .profile-professional-development_card__26m4m .profile-professional-development_mentoring__3ip_q,
      .profile-professional-development_card__26m4m .profile-professional-development_area-label__3V8zv {
        font-size: 14px; } }
  .profile-professional-development_card__26m4m .profile-professional-development_card-title__3_yVP {
    height: 22px; }
  .profile-professional-development_card__26m4m .profile-professional-development_share__pEBVx {
    align-self: baseline;
    margin-left: auto; }
  .profile-professional-development_card__26m4m .profile-professional-development_card-title__3_yVP,
  .profile-professional-development_card__26m4m .profile-professional-development_mentoring__3ip_q {
    display: flex;
    justify-content: space-between; }
  .profile-professional-development_card__26m4m .profile-professional-development_card-content__1H_a2,
  .profile-professional-development_card__26m4m .profile-professional-development_mentoring__3ip_q {
    color: #55556f;
    margin-top: 10px; }
    .profile-professional-development_card__26m4m .profile-professional-development_card-content__1H_a2 button,
    .profile-professional-development_card__26m4m .profile-professional-development_mentoring__3ip_q button {
      align-self: center;
      max-height: 24px; }
  @media (max-width: 1024px) {
    .profile-professional-development_card__26m4m .profile-professional-development_card-title__3_yVP {
      font-size: 16px;
      line-height: 16px; }
    .profile-professional-development_cards-wrapper__ltqtZ .profile-professional-development_card__26m4m .profile-professional-development_mobile-title__2O2U3 {
      display: block; } }

.profile-professional-development_link__2QIqX {
  color: #014660;
  cursor: pointer; }

.profile-professional-development_area-info__3cPI0 {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 14px; }
  .profile-professional-development_area-info__3cPI0 .profile-professional-development_area-label__3V8zv,
  .profile-professional-development_area-info__3cPI0 .profile-professional-development_area-goal__QG337 {
    color: #55556f; }
  .profile-professional-development_area-info__3cPI0 .profile-professional-development_area-label__3V8zv {
    font-size: 15px; }
  .profile-professional-development_area-info__3cPI0 .profile-professional-development_button__xR8hu {
    padding: 4px 11px; }

.profile-professional-development_setgoals_wrapper__Pyubp {
  display: flex;
  justify-content: flex-end; }
  .profile-professional-development_setgoals_wrapper__Pyubp button {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 14px;
    padding: 6px 12px; }
    .profile-professional-development_setgoals_wrapper__Pyubp button:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-professional-development_setgoals_wrapper__Pyubp button:disabled {
      opacity: 0.5; }

.public-profile_white__121iC {
  color: #f9f9f9; }

.public-profile_black__1keyp {
  color: #000; }

.public-profile_deep-teal__3pPMI {
  color: #014660; }

.public-profile_greyish-teal__wjcc8 {
  color: #5bdbbd; }

.public-profile_very-light-grey__3au7y {
  color: #b9b9b9; }

.public-profile_warm-grey__gC9vz {
  color: #55556f; }

.public-profile_simple-grey__3qUYY {
  color: #d4d4d8; }

.public-profile_dark-grey__1yMJj {
  color: #222026; }

.public-profile_flat-white__3leSf {
  color: #fff; }

.public-profile_pastel-red__1YvaE {
  color: #e55e50; }

.public-profile_white-bg__1vHF7 {
  background-color: #f9f9f9; }

.public-profile_deep-teal-bg__2F9B8 {
  background-color: #014660;
  color: #fff; }

.public-profile_greyish-teal-bg__EdN9A {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.public-profile_very-light-grey-bg__22rre {
  background-color: #b9b9b9;
  color: #222026; }

.public-profile_warm-grey-bg__1py0W {
  background-color: #55556f;
  color: #f9f9f9; }

.public-profile_simple-grey-bg__1iXYT {
  background-color: #d4d4d8;
  color: #222026; }

.public-profile_dark-grey-bg__gP-1j {
  background-color: #222026;
  color: #fff; }

.public-profile_flat-white-bg__B9cJR {
  background-color: #fff;
  color: #014660; }

.public-profile_pastel-red-bg__2nKu6 {
  background-color: #e55e50;
  color: #f9f9f9; }

.public-profile_white__121iC {
  color: #f9f9f9; }

.public-profile_black__1keyp {
  color: #000; }

.public-profile_deep-teal__3pPMI {
  color: #014660; }

.public-profile_greyish-teal__wjcc8 {
  color: #5bdbbd; }

.public-profile_very-light-grey__3au7y {
  color: #b9b9b9; }

.public-profile_warm-grey__gC9vz {
  color: #55556f; }

.public-profile_simple-grey__3qUYY {
  color: #d4d4d8; }

.public-profile_dark-grey__1yMJj {
  color: #222026; }

.public-profile_flat-white__3leSf {
  color: #fff; }

.public-profile_pastel-red__1YvaE {
  color: #e55e50; }

.public-profile_white-bg__1vHF7 {
  background-color: #f9f9f9; }

.public-profile_deep-teal-bg__2F9B8 {
  background-color: #014660;
  color: #fff; }

.public-profile_greyish-teal-bg__EdN9A {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.public-profile_very-light-grey-bg__22rre {
  background-color: #b9b9b9;
  color: #222026; }

.public-profile_warm-grey-bg__1py0W {
  background-color: #55556f;
  color: #f9f9f9; }

.public-profile_simple-grey-bg__1iXYT {
  background-color: #d4d4d8;
  color: #222026; }

.public-profile_dark-grey-bg__gP-1j {
  background-color: #222026;
  color: #fff; }

.public-profile_flat-white-bg__B9cJR {
  background-color: #fff;
  color: #014660; }

.public-profile_pastel-red-bg__2nKu6 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.public-profile_wrapper__1OgqW {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: auto;
  max-height: 100%;
  position: relative; }

@media (max-width: 1024px) {
  .public-profile_layout__2GRAw .public-profile_cards_wrapper__2_0YR {
    padding-bottom: 60px; }
    @supports (-webkit-overflow-scrolling: touch) {
      .public-profile_layout__2GRAw .public-profile_cards_wrapper__2_0YR {
        margin-bottom: 60px;
        padding-bottom: 80px; } } }

.public-profile_break_lines__3rzs7 {
  white-space: pre-wrap; }

.public-profile_modal__hVvA4 {
  max-width: 650px; }
  .public-profile_modal__hVvA4 .public-profile_header__2Z1g3 {
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); }
  @media (max-width: 1024px) {
    .public-profile_modal__hVvA4 .public-profile_header__2Z1g3 {
      border-radius: 0;
      box-shadow: none; }
    @supports (-webkit-overflow-scrolling: touch) {
      .public-profile_modal__hVvA4 .public-profile_cards_wrapper__2_0YR {
        padding-bottom: 120px; } } }

.public-profile_hasError__3u7V0 {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 90vh; }

.public-profile_errorWrapper__2Ug-O {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  margin: 1em;
  padding: 1em; }
  .public-profile_errorWrapper__2Ug-O .public-profile_title__1VaLe {
    display: block; }

.public-profile_title__1VaLe {
  color: #014660;
  font-size: 24px;
  padding: 16px; }

.public-profile_header__2Z1g3 {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#0399be), to(#014660));
  background-image: linear-gradient(0deg, #0399be, #014660);
  display: flex;
  padding: 2em 1em; }
  @media (max-width: 1024px) {
    .public-profile_header__2Z1g3 {
      align-items: center;
      flex-flow: column wrap;
      padding: 1em 1em 2em;
      text-align: center; } }

.public-profile_details__1WPuF {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 20px; }
  @media (max-width: 1024px) {
    .public-profile_details__1WPuF {
      margin-left: 0; } }
  .public-profile_details__1WPuF .public-profile_buttons__19iCM {
    display: flex;
    flex-flow: row wrap; }
    @media (max-width: 1024px) {
      .public-profile_details__1WPuF .public-profile_buttons__19iCM {
        justify-content: center; } }
    .public-profile_details__1WPuF .public-profile_buttons__19iCM button {
      margin: 0.5em;
      position: relative; }
      @media (max-width: 1024px) {
        .public-profile_details__1WPuF .public-profile_buttons__19iCM button {
          margin: 0.3em; } }

.public-profile_username__1OfDl {
  color: #fff;
  font-family: "Questrial", sans-serif;
  font-size: 26px;
  margin: 10px 0; }
  @media (max-width: 1024px) {
    .public-profile_username__1OfDl {
      font-size: 18px; } }

.public-profile_pts__3aO7F {
  align-items: center;
  color: #5bdbbd;
  display: flex;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  margin-bottom: 10px; }
  .public-profile_pts__3aO7F span {
    margin-left: 6px; }
  .public-profile_pts__3aO7F svg {
    height: 22px;
    width: 22px; }

.public-profile_button__d6ngX {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  height: 28px;
  padding: 3px 25px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .public-profile_button__d6ngX:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .public-profile_button__d6ngX:disabled {
    opacity: 0.5; }
  .public-profile_button__d6ngX:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .public-profile_button__d6ngX:disabled {
    opacity: 0.5; }
  .public-profile_button__d6ngX:hover:not(:disabled) {
    background-color: #026b92; }
  .public-profile_button__d6ngX:focus:not(:disabled) {
    background-color: #026b92; }

.public-profile_cards_wrapper__2_0YR {
  overflow: auto;
  padding: 16px 16px 20px; }

.public-profile_card__9ibIH {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  color: #4d4d4d;
  display: grid;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr;
  margin-bottom: 15px;
  padding: 20px 10px; }
  .public-profile_card__9ibIH h4 {
    color: #5bdbbd;
    font-size: 22px; }
  .public-profile_card__9ibIH .public-profile_card_content__3G_mT {
    display: flex;
    flex-direction: column;
    line-height: 20px; }

.public-profile_social_link_wrapper__2cpT- {
  align-items: center;
  display: flex;
  margin-bottom: 5px; }
  .public-profile_social_link_wrapper__2cpT- svg {
    height: 22px;
    margin-right: 10px;
    width: 22px; }

a {
  color: #014660;
  text-decoration: none; }

.public-profile_capitalized__1DCPl {
  text-transform: capitalize; }

.public-profile_avatar_block__16CKJ {
  position: relative; }
  .public-profile_avatar_block__16CKJ [class*=status] {
    bottom: 0;
    height: 20px;
    position: absolute;
    right: 0;
    width: 20px; }
    .public-profile_avatar_block__16CKJ [class*=status][class*=do_not_disturb][class*=offline] {
      background-color: #f9f9f9; }

@media (max-width: 1024px) {
  .public-profile_wrapper__1OgqW {
    max-width: 100vw;
    min-height: 95vh;
    padding: 0; }
  .public-profile_cards_wrapper__2_0YR {
    padding-top: 0;
    position: relative;
    top: -20px;
    z-index: 2; }
  .public-profile_title__1VaLe {
    display: none; } }

[class*=full-screen-modal_wrapper] > [class*=full-screen-modal_body] {
  max-width: 100vw;
  padding: 0; }

::-webkit-scrollbar {
  width: 3px; }

.profile-reset-password_white__1hg1d {
  color: #f9f9f9; }

.profile-reset-password_black__3AtCE {
  color: #000; }

.profile-reset-password_deep-teal__3bk7W {
  color: #014660; }

.profile-reset-password_greyish-teal__1EWMs {
  color: #5bdbbd; }

.profile-reset-password_very-light-grey__j1TaZ {
  color: #b9b9b9; }

.profile-reset-password_warm-grey__asYym {
  color: #55556f; }

.profile-reset-password_simple-grey__2Wghv {
  color: #d4d4d8; }

.profile-reset-password_dark-grey__3I3iH {
  color: #222026; }

.profile-reset-password_flat-white__14X08 {
  color: #fff; }

.profile-reset-password_pastel-red__Igd1o {
  color: #e55e50; }

.profile-reset-password_white-bg__2mQTL {
  background-color: #f9f9f9; }

.profile-reset-password_deep-teal-bg__3OKaC {
  background-color: #014660;
  color: #fff; }

.profile-reset-password_greyish-teal-bg__26HO0 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-reset-password_very-light-grey-bg__Xbt10 {
  background-color: #b9b9b9;
  color: #222026; }

.profile-reset-password_warm-grey-bg__3KFZ4 {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-reset-password_simple-grey-bg__aqvkA {
  background-color: #d4d4d8;
  color: #222026; }

.profile-reset-password_dark-grey-bg__1BSua {
  background-color: #222026;
  color: #fff; }

.profile-reset-password_flat-white-bg__2tbeW {
  background-color: #fff;
  color: #014660; }

.profile-reset-password_pastel-red-bg__wGdWV {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-reset-password_cards-wrapper__LxyYv {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-reset-password_cards-wrapper__LxyYv {
      padding-bottom: 50px; }
      .profile-reset-password_cards-wrapper__LxyYv::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-reset-password_cards-wrapper__LxyYv .profile-reset-password_title__1h68R {
        display: none; }
      .profile-reset-password_cards-wrapper__LxyYv .profile-reset-password_inputs__2Pa3W {
        padding: 0; } }

.profile-reset-password_card__3qxzE {
  background: #f9f9f9; }

.profile-reset-password_title__1h68R {
  color: #55556f;
  font-size: 26px; }

.profile-reset-password_error__2UAko {
  background-color: rgba(229, 94, 80, 0.2);
  color: #e55e50;
  flex: 1 1;
  padding: 20px; }

.profile-reset-password_inputs__2Pa3W {
  padding: 20px 10px; }
  .profile-reset-password_inputs__2Pa3W label {
    font-size: 16px; }
  .profile-reset-password_inputs__2Pa3W input {
    height: 40px;
    width: 100%; }

.profile-reset-password_cards-wrapper__LxyYv .profile-reset-password_validation__26exL {
  color: #222026;
  font-family: OpenSans, sans-serif; }
  .profile-reset-password_cards-wrapper__LxyYv .profile-reset-password_validation__26exL svg {
    height: 18px;
    width: 18px; }

@media (max-width: 1024px) {
  .profile-reset-password_validation__26exL {
    font-size: 14px; } }

.profile-reset-password_button-wraper__3QN9G {
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.profile-settings_white__qDkrz {
  color: #f9f9f9; }

.profile-settings_black__25gyt {
  color: #000; }

.profile-settings_deep-teal__1-vwY {
  color: #014660; }

.profile-settings_greyish-teal__26bxb {
  color: #5bdbbd; }

.profile-settings_very-light-grey__27pQv {
  color: #b9b9b9; }

.profile-settings_warm-grey__BpkQt {
  color: #55556f; }

.profile-settings_simple-grey__3-qd5 {
  color: #d4d4d8; }

.profile-settings_dark-grey__3UMeO {
  color: #222026; }

.profile-settings_flat-white__3rocJ {
  color: #fff; }

.profile-settings_pastel-red__99JoX {
  color: #e55e50; }

.profile-settings_white-bg__lon1J {
  background-color: #f9f9f9; }

.profile-settings_deep-teal-bg__XsYmD {
  background-color: #014660;
  color: #fff; }

.profile-settings_greyish-teal-bg__3dzVh {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-settings_very-light-grey-bg__1rm2J {
  background-color: #b9b9b9;
  color: #222026; }

.profile-settings_warm-grey-bg__3jP6u {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-settings_simple-grey-bg__2HZ_I {
  background-color: #d4d4d8;
  color: #222026; }

.profile-settings_dark-grey-bg__1OMqI {
  background-color: #222026;
  color: #fff; }

.profile-settings_flat-white-bg__1PDgT {
  background-color: #fff;
  color: #014660; }

.profile-settings_pastel-red-bg__CcM0W {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-settings_cards-wrapper__1gSpP {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-settings_cards-wrapper__1gSpP {
      padding-bottom: 50px; }
      .profile-settings_cards-wrapper__1gSpP::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-settings_cards-wrapper__1gSpP .profile-settings_title__3BnWy {
        display: none; }
      .profile-settings_cards-wrapper__1gSpP .profile-settings_inputs__2KQ1Z {
        padding: 0; } }

.profile-settings_title__3BnWy {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px; }

@media (max-width: 1024px) {
  .profile-settings_cards-wrapper__1gSpP > .profile-settings_title__3BnWy {
    background: #fff;
    display: block;
    font-size: 20px;
    margin: 0;
    padding: 20px 15px 0; } }

.profile-settings_card__1WpoI {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  align-items: center;
  background-color: #fff;
  display: block;
  margin-top: 20px;
  padding: 20px 15px; }
  @media (max-width: 1024px) {
    .profile-settings_card__1WpoI {
      margin-bottom: 20px;
      margin-top: 0; } }
  .profile-settings_card__1WpoI .profile-settings_button__20vJj {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    padding: 3px 25px; }
    .profile-settings_card__1WpoI .profile-settings_button__20vJj:hover:not(:disabled) {
      background-color: #00212e; }
    .profile-settings_card__1WpoI .profile-settings_button__20vJj:disabled {
      opacity: 0.5; }
  .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: transparent;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    border: 1px solid #014660;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    padding: 2px 14px; }
    .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0); }
    .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU:disabled {
      opacity: 0.5; }
    .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU:hover:not(:disabled) {
      background-color: #f2f2f2;
      box-shadow: none; }
    .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU:disabled {
      opacity: 0.5; }
  .profile-settings_card__1WpoI .profile-settings_button__20vJj, .profile-settings_card__1WpoI .profile-settings_button-outlined__12wuU {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 10px; }
  .profile-settings_card__1WpoI .profile-settings_card-title__2_LDi {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    line-height: 22px; }
    @media (max-width: 1024px) {
      .profile-settings_card__1WpoI .profile-settings_card-title__2_LDi {
        font-size: 14px; } }
  .profile-settings_card__1WpoI .profile-settings_card-content__3z75r {
    display: flex;
    justify-content: space-between;
    margin-top: 34px; }
  @media (max-width: 1024px) {
    .profile-settings_card__1WpoI .profile-settings_card-title__2_LDi {
      font-size: 16px; } }

.profile-settings_card__1WpoI > div:first-of-type {
  margin-top: 10px; }

.profile-settings_button__20vJj,
.profile-settings_save-button__g57iT {
  color: #f9f9f9;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  padding: 4px 16px; }
  .profile-settings_button__20vJj:hover:not(:disabled),
  .profile-settings_save-button__g57iT:hover:not(:disabled) {
    background-color: #00212e; }
  .profile-settings_button__20vJj:disabled,
  .profile-settings_save-button__g57iT:disabled {
    opacity: 0.5; }

.profile-settings_button-outlined__12wuU {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #014660;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  padding: 3px 14px; }
  .profile-settings_button-outlined__12wuU:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .profile-settings_button-outlined__12wuU:disabled {
    opacity: 0.5; }
  .profile-settings_button-outlined__12wuU:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .profile-settings_button-outlined__12wuU:disabled {
    opacity: 0.5; }

.profile-settings_button__20vJj,
.profile-settings_button-outlined__12wuU,
.profile-settings_save-button__g57iT {
  display: flex;
  font-size: 14px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 10px; }

.profile-settings_save-button__g57iT {
  margin: 0 0 30px auto;
  padding: 4px 12px; }

@media (min-width: 1025px) {
  .profile-settings_save-button__g57iT {
    margin-top: 20px; } }

.profile-settings_switch-input__1_9Tx {
  display: inline-block;
  height: 23px;
  position: relative;
  width: 40px;
  margin-left: 20px;
  min-width: 40px; }
  .profile-settings_switch-input__1_9Tx input {
    height: 0;
    opacity: 0;
    width: 0; }
  .profile-settings_switch-input__1_9Tx .profile-settings_slider__2gsE5 {
    background-color: #ccc;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .profile-settings_switch-input__1_9Tx .profile-settings_slider__2gsE5::before {
    background-color: #fff;
    border-radius: 50%;
    bottom: 2px;
    content: "";
    height: 19px;
    left: 2px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 19px; }
  .profile-settings_switch-input__1_9Tx input:checked + .profile-settings_slider__2gsE5 {
    background-color: #5bdbbd; }
  .profile-settings_switch-input__1_9Tx input:checked + .profile-settings_slider__2gsE5::before {
    -webkit-transform: translateX(16px);
            transform: translateX(16px); }

.profile-skills-and-interests_white__2dJhB {
  color: #f9f9f9; }

.profile-skills-and-interests_black__q4who {
  color: #000; }

.profile-skills-and-interests_deep-teal__27gCm {
  color: #014660; }

.profile-skills-and-interests_greyish-teal__JjqbW {
  color: #5bdbbd; }

.profile-skills-and-interests_very-light-grey__3r2ep {
  color: #b9b9b9; }

.profile-skills-and-interests_warm-grey__26Mud {
  color: #55556f; }

.profile-skills-and-interests_simple-grey__2leKR {
  color: #d4d4d8; }

.profile-skills-and-interests_dark-grey__1YuO2 {
  color: #222026; }

.profile-skills-and-interests_flat-white__CJeKk {
  color: #fff; }

.profile-skills-and-interests_pastel-red__QNPxG {
  color: #e55e50; }

.profile-skills-and-interests_white-bg__31oj5 {
  background-color: #f9f9f9; }

.profile-skills-and-interests_deep-teal-bg__3oA1P {
  background-color: #014660;
  color: #fff; }

.profile-skills-and-interests_greyish-teal-bg__32rwP {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.profile-skills-and-interests_very-light-grey-bg__2bhHc {
  background-color: #b9b9b9;
  color: #222026; }

.profile-skills-and-interests_warm-grey-bg__38NZY {
  background-color: #55556f;
  color: #f9f9f9; }

.profile-skills-and-interests_simple-grey-bg__3i96Z {
  background-color: #d4d4d8;
  color: #222026; }

.profile-skills-and-interests_dark-grey-bg__1oITR {
  background-color: #222026;
  color: #fff; }

.profile-skills-and-interests_flat-white-bg__GD4z3 {
  background-color: #fff;
  color: #014660; }

.profile-skills-and-interests_pastel-red-bg__2mLvP {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.profile-skills-and-interests_cards-wrapper__1S3Dq {
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    .profile-skills-and-interests_cards-wrapper__1S3Dq {
      padding-bottom: 50px; }
      .profile-skills-and-interests_cards-wrapper__1S3Dq::-webkit-scrollbar-thumb {
        background-color: #f9f9f9; }
      .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_title__1sK8q {
        display: none; }
      .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_inputs__2fL2U {
        padding: 0; } }

.profile-skills-and-interests_title__1sK8q {
  color: #55556f;
  font-family: Questrial,sans-serif;
  font-size: 26px;
  margin-bottom: 20px; }

.profile-skills-and-interests_mobile-title__2QgDD {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px; }
  @media (max-width: 1024px) {
    .profile-skills-and-interests_mobile-title__2QgDD {
      font-size: 20px; } }

.profile-skills-and-interests_subtitle__1V2R3 {
  color: #55556f;
  display: flex;
  flex: 2 1;
  font-size: 22px;
  margin-bottom: 12px; }
  @media (max-width: 1024px) {
    .profile-skills-and-interests_subtitle__1V2R3 {
      font-size: 20px; } }

.profile-skills-and-interests_description__3jtcj {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  line-height: 22px; }
  @media (max-width: 1024px) {
    .profile-skills-and-interests_description__3jtcj {
      font-size: 14px; } }

@media (max-width: 1024px) {
  .profile-skills-and-interests_subtitle__1V2R3 {
    font-size: 18px; }
  .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_header__h6bF9 {
    background: #fff;
    padding: 20px 15px; }
    .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_header__h6bF9 .profile-skills-and-interests_title__1sK8q {
      display: block;
      font-size: 20px; }
  .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_select-skills-wrapper__3vpN6 {
    padding: 0; }
  .profile-skills-and-interests_cards-wrapper__1S3Dq .profile-skills-and-interests_skill_name__wJWLY {
    font-size: 15px;
    padding-right: 6px; } }

.profile-skills-and-interests_select-skills-wrapper__3vpN6 {
  margin: 20px 0;
  padding: 20px 15px; }

.profile-skills-and-interests_select-skills-header__2C40V {
  display: inline-flex;
  margin-bottom: 10px;
  width: 100%; }
  .profile-skills-and-interests_select-skills-header__2C40V .profile-skills-and-interests_control__1NWIb {
    display: block;
    text-align: right; }

@media (max-width: 1024px) {
  .profile-skills-and-interests_select-skills-wrapper__3vpN6 {
    margin: 0;
    padding: 20px 0; }
  .profile-skills-and-interests_select-skills-header__2C40V {
    background: #f9f9f9;
    margin: 0;
    padding: 10px; } }

.profile-skills-and-interests_col-desc__28XBn {
  color: #222026;
  font-size: 12px;
  margin-top: 10px; }

.profile-skills-and-interests_button__o2IrD {
  color: #f9f9f9;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: #014660;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center; }
  .profile-skills-and-interests_button__o2IrD:hover:not(:disabled) {
    background-color: #00212e; }
  .profile-skills-and-interests_button__o2IrD:disabled {
    opacity: 0.5; }

.profile-skills-and-interests_outlined-button__3D5wm {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 18.5px;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;
  border: 1px solid #014660;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-weight: 600; }
  .profile-skills-and-interests_outlined-button__3D5wm:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0); }
  .profile-skills-and-interests_outlined-button__3D5wm:disabled {
    opacity: 0.5; }
  .profile-skills-and-interests_outlined-button__3D5wm:hover:not(:disabled) {
    background-color: #f2f2f2;
    box-shadow: none; }
  .profile-skills-and-interests_outlined-button__3D5wm:disabled {
    opacity: 0.5; }

.profile-skills-and-interests_button__o2IrD,
.profile-skills-and-interests_outlined-button__3D5wm {
  font-size: 14px;
  padding: 2px 12px; }

.profile-skills-and-interests_skill__28Hat {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 15px; }
  .profile-skills-and-interests_skill__28Hat svg {
    height: 28px;
    width: 28px; }
  .profile-skills-and-interests_skill_remove__LGNEG {
    cursor: pointer;
    margin-right: 15px; }
  .profile-skills-and-interests_skill_name__wJWLY {
    color: #014660;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    width: 100%; }
  .profile-skills-and-interests_skill_experience__31zq3 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 22px; }
  .profile-skills-and-interests_skill_controls__3lrk8 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 120px; }
    .profile-skills-and-interests_skill_controls__3lrk8 span {
      text-align: center;
      width: 100%; }
    .profile-skills-and-interests_skill_controls__3lrk8 > div {
      cursor: pointer; }

.profile-skills-and-interests_input__3jcey {
  margin: 40px 0; }
  .profile-skills-and-interests_input__3jcey label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .profile-skills-and-interests_input__3jcey input, .profile-skills-and-interests_input__3jcey textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .profile-skills-and-interests_input__3jcey input {
    height: 35px; }
  .profile-skills-and-interests_input__3jcey textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .profile-skills-and-interests_input__3jcey.profile-skills-and-interests_valid__1x57J input, .profile-skills-and-interests_input__3jcey.profile-skills-and-interests_valid__1x57J textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .profile-skills-and-interests_input__3jcey.profile-skills-and-interests_invalid__p9xYI input, .profile-skills-and-interests_input__3jcey.profile-skills-and-interests_invalid__p9xYI textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  .profile-skills-and-interests_input__3jcey .profile-skills-and-interests_label__3BFyK {
    color: #222026;
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: 10px; }
  .profile-skills-and-interests_input__3jcey input {
    font-size: 16px;
    width: 100%; }
  @media (max-width: 1024px) {
    .profile-skills-and-interests_input__3jcey {
      background: #f9f9f9;
      margin: 0;
      padding: 40px 10px; } }

.profile-skills-and-interests_suggested__2S2tZ {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding: 10px; }
  .profile-skills-and-interests_suggested__2S2tZ .profile-skills-and-interests_text__3Kckx {
    margin-bottom: 10px; }
  .profile-skills-and-interests_suggested__2S2tZ .profile-skills-and-interests_skills_wrapper__-JGV6 {
    max-height: 45vh;
    overflow: auto; }
    @media (max-width: 1024px) {
      .profile-skills-and-interests_suggested__2S2tZ .profile-skills-and-interests_skills_wrapper__-JGV6 {
        max-height: 32vh; } }
  .profile-skills-and-interests_suggested__2S2tZ .profile-skills-and-interests_skill_name__wJWLY {
    color: #222026; }

.chats-list_white__3vmRZ {
  color: #f9f9f9; }

.chats-list_black__1dIct {
  color: #000; }

.chats-list_deep-teal__3cfEe {
  color: #014660; }

.chats-list_greyish-teal__1zaqU {
  color: #5bdbbd; }

.chats-list_very-light-grey__2pPFE {
  color: #b9b9b9; }

.chats-list_warm-grey__1SCJG {
  color: #55556f; }

.chats-list_simple-grey__1Agjr {
  color: #d4d4d8; }

.chats-list_dark-grey__1sIa9 {
  color: #222026; }

.chats-list_flat-white__398fX {
  color: #fff; }

.chats-list_pastel-red__2zTXo {
  color: #e55e50; }

.chats-list_white-bg__2a_Pf {
  background-color: #f9f9f9; }

.chats-list_deep-teal-bg__1_inR {
  background-color: #014660;
  color: #fff; }

.chats-list_greyish-teal-bg__YX3aA {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.chats-list_very-light-grey-bg__3-bd6 {
  background-color: #b9b9b9;
  color: #222026; }

.chats-list_warm-grey-bg__3eAZZ {
  background-color: #55556f;
  color: #f9f9f9; }

.chats-list_simple-grey-bg__1wQbu {
  background-color: #d4d4d8;
  color: #222026; }

.chats-list_dark-grey-bg__3siqt {
  background-color: #222026;
  color: #fff; }

.chats-list_flat-white-bg__2mKSb {
  background-color: #fff;
  color: #014660; }

.chats-list_pastel-red-bg__2dM62 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.chats-list_loading__UfY1C {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center; }

.chats-list_list_label__6ejTK {
  border-bottom: 1px solid #d4d4d8;
  color: #55556f;
  font-family: "Questrial", sans-serif;
  font-size: 16px;
  margin: 20px 10px 0;
  padding-bottom: 5px; }

.chats-list_list__VaADO {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  padding: 10px 10px 0; }

.chats-list_chat__13Dj- {
  align-items: center;
  border-bottom: 1px solid rgba(85, 85, 111, 0.2);
  display: flex;
  padding: 10px; }
  .chats-list_chat__13Dj- .chats-list_last_updated__2dg9f,
  .chats-list_chat__13Dj- .chats-list_go_to__3u9pc {
    color: #55556f;
    font-size: 14px;
    min-width: 100px;
    text-align: center; }
  .chats-list_chat__13Dj- .chats-list_go_to__3u9pc {
    display: none; }

.chats-list_info__1cy_U {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 0 10px; }
  .chats-list_info__1cy_U span {
    color: #55556f;
    font-family: OpenSans, sans-serif;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .chats-list_info__1cy_U span:first-of-type {
    color: #014660;
    font-size: 14px;
    margin-bottom: 5px; }

.chats-list_chat__13Dj-:hover {
  background-color: #f2f2f2; }
  .chats-list_chat__13Dj-:hover .chats-list_go_to__3u9pc {
    display: block; }
  .chats-list_chat__13Dj-:hover .chats-list_last_updated__2dg9f {
    display: none; }

.chats-list_top-block__Aw50i {
  border-bottom: 1px solid rgba(85, 85, 111, 0.2);
  padding: 20px; }
  @media (max-width: 1024px) {
    .chats-list_top-block__Aw50i {
      padding: 10px; } }

.chats-list_search__2weSh {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%; }
  .chats-list_search__2weSh label {
    margin-bottom: 10px;
    margin-left: 5px; }
  .chats-list_search__2weSh input, .chats-list_search__2weSh textarea {
    border: 0;
    border-radius: 18.5px;
    box-shadow: 0 0 2px #4d4d4d;
    padding: 5px 13px; }
  .chats-list_search__2weSh input {
    height: 35px; }
  .chats-list_search__2weSh textarea {
    font-family: "Open Sans", sans-serif;
    padding: 15px; }
  .chats-list_search__2weSh.chats-list_valid__2Ot28 input, .chats-list_search__2weSh.chats-list_valid__2Ot28 textarea {
    box-shadow: 0 0 2px rgba(91, 219, 189, 0.6); }
  .chats-list_search__2weSh.chats-list_invalid__1Tlvi input, .chats-list_search__2weSh.chats-list_invalid__1Tlvi textarea {
    box-shadow: 0 0 2px rgba(229, 94, 80, 0.6); }
  @media (max-width: 1024px) {
    .chats-list_search__2weSh {
      margin: 0; } }

.chats-list_box__NDUV9 {
  position: relative;
  width: 100%; }
  .chats-list_box__NDUV9 input {
    border: 1px solid rgba(85, 85, 111, 0.2);
    padding: 0 10px;
    width: 100%; }
  .chats-list_box__NDUV9 input:focus {
    box-shadow: 0 0 5px rgba(91, 219, 189, 0.7); }

.chats-list_clear__3CAVd {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
  position: absolute;
  right: 10px;
  top: 8px; }
  .chats-list_clear__3CAVd svg {
    height: 20px;
    width: 20px; }

.chats-list_no_results__22vOy {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: center;
  padding: 20px;
  text-align: center; }
  .chats-list_no_results__22vOy svg {
    height: 150px;
    margin-bottom: 30px;
    width: 150px; }

.button_white__3g1w4 {
  color: #f9f9f9; }

.button_black__3rI3A {
  color: #000; }

.button_deep-teal__3Fw7- {
  color: #014660; }

.button_greyish-teal__J8luw {
  color: #5bdbbd; }

.button_very-light-grey__2qOBs {
  color: #b9b9b9; }

.button_warm-grey__3kKet {
  color: #55556f; }

.button_simple-grey__2IlMz {
  color: #d4d4d8; }

.button_dark-grey__3O-r2 {
  color: #222026; }

.button_flat-white__o9bPm {
  color: #fff; }

.button_pastel-red__3Vu1S {
  color: #e55e50; }

.button_white-bg__2-ZOS {
  background-color: #f9f9f9; }

.button_deep-teal-bg__FNLkz {
  background-color: #014660;
  color: #fff; }

.button_greyish-teal-bg__UQH4M {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.button_very-light-grey-bg__27U0- {
  background-color: #b9b9b9;
  color: #222026; }

.button_warm-grey-bg__1OHyn {
  background-color: #55556f;
  color: #f9f9f9; }

.button_simple-grey-bg__23wuR {
  background-color: #d4d4d8;
  color: #222026; }

.button_dark-grey-bg__qq6T8 {
  background-color: #222026;
  color: #fff; }

.button_flat-white-bg__2SHL7 {
  background-color: #fff;
  color: #014660; }

.button_pastel-red-bg__2k5CJ {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.button_button__3sdyg, .button_small__Vnhxv, .button_outline__2GBxg {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background: #014660;
  border: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  line-height: 1.35em;
  padding: 0.6em 0.8em;
  position: relative;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  .button_button__3sdyg:disabled, .button_small__Vnhxv:disabled, .button_outline__2GBxg:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    .button_button__3sdyg:disabled:hover, .button_small__Vnhxv:disabled:hover, .button_outline__2GBxg:disabled:hover {
      background: #014660;
      color: #fff; }
  .button_button__3sdyg:hover, .button_small__Vnhxv:hover, .button_outline__2GBxg:hover {
    background: #5bdbbd;
    color: #014660; }
  .button_button__3sdyg svg, .button_small__Vnhxv svg, .button_outline__2GBxg svg {
    flex: 0 0 22px;
    margin-right: 0.5em; }

.button_small__Vnhxv {
  font-size: 0.85em;
  padding: 0.5em 0.7em; }

.button_outline__2GBxg {
  background: #fff;
  border: 1px solid #d4d4d8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  color: #014660; }

.button_badge__25U6P {
  background: #dda700;
  border: 2px solid #fff;
  border-radius: 30px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.3em;
  padding: 0.15em 0.3em;
  position: absolute;
  right: -10px;
  top: -15px; }

.poller_white__3VCPl {
  color: #f9f9f9; }

.poller_black__xeu1- {
  color: #000; }

.poller_deep-teal__3InF8 {
  color: #014660; }

.poller_greyish-teal__nI1Pw {
  color: #5bdbbd; }

.poller_very-light-grey__21Kqx {
  color: #b9b9b9; }

.poller_warm-grey__2hZoe {
  color: #55556f; }

.poller_simple-grey__RdoZG {
  color: #d4d4d8; }

.poller_dark-grey__rY5IT {
  color: #222026; }

.poller_flat-white__3e7V8 {
  color: #fff; }

.poller_pastel-red__fOMnT {
  color: #e55e50; }

.poller_white-bg__2MXEd {
  background-color: #f9f9f9; }

.poller_deep-teal-bg__30ABU {
  background-color: #014660;
  color: #fff; }

.poller_greyish-teal-bg__UdojT {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.poller_very-light-grey-bg__OfSHG {
  background-color: #b9b9b9;
  color: #222026; }

.poller_warm-grey-bg__1AOTV {
  background-color: #55556f;
  color: #f9f9f9; }

.poller_simple-grey-bg__fxN8R {
  background-color: #d4d4d8;
  color: #222026; }

.poller_dark-grey-bg__1ZbSA {
  background-color: #222026;
  color: #fff; }

.poller_flat-white-bg__15fxe {
  background-color: #fff;
  color: #014660; }

.poller_pastel-red-bg__2-FxU {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.poller_wrapper___arVf, .poller_completed__OjmJw {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  border-radius: 20px;
  padding: 1em; }
  .poller_wrapper___arVf h2, .poller_completed__OjmJw h2 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.5em; }

.poller_completed__OjmJw {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .poller_completed__OjmJw h2 {
    margin-bottom: 1.5em; }

.poller_buttons__cVOtQ {
  display: inline-grid;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  margin: 2em 0 1em;
  width: 100%; }
  .poller_buttons__cVOtQ button {
    flex: 1 1; }
  @media (min-width: 1025px) {
    .poller_buttons__cVOtQ {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr; } }

.poller_input-wrapper__2H_jH {
  display: flex;
  flex-flow: column; }
  .poller_input-wrapper__2H_jH .poller_input__FqYUH {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    border: 1px solid #d4d4d8;
    border-radius: 4px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
    flex: 1 1;
    margin: 0 0 0.5em;
    padding: 0.7em 0.8em; }
    @media (min-width: 1025px) {
      .poller_input-wrapper__2H_jH .poller_input__FqYUH {
        margin: 0 0.5em 0 0; } }
  @media (min-width: 1025px) {
    .poller_input-wrapper__2H_jH {
      flex-flow: row nowrap; } }

.header-alerts_white__1kqKA {
  color: #f9f9f9; }

.header-alerts_black__1AG5N {
  color: #000; }

.header-alerts_deep-teal__1RWJa {
  color: #014660; }

.header-alerts_greyish-teal__3CoBD {
  color: #5bdbbd; }

.header-alerts_very-light-grey__2QevV {
  color: #b9b9b9; }

.header-alerts_warm-grey__1Ptmq {
  color: #55556f; }

.header-alerts_simple-grey__1cy_i {
  color: #d4d4d8; }

.header-alerts_dark-grey__2GbCT {
  color: #222026; }

.header-alerts_flat-white__26QAA {
  color: #fff; }

.header-alerts_pastel-red__18X1s {
  color: #e55e50; }

.header-alerts_white-bg__2Vg_5 {
  background-color: #f9f9f9; }

.header-alerts_deep-teal-bg__3uIcs {
  background-color: #014660;
  color: #fff; }

.header-alerts_greyish-teal-bg__136Lx {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header-alerts_very-light-grey-bg__3gXOE {
  background-color: #b9b9b9;
  color: #222026; }

.header-alerts_warm-grey-bg__1rA7- {
  background-color: #55556f;
  color: #f9f9f9; }

.header-alerts_simple-grey-bg__Kcfr4 {
  background-color: #d4d4d8;
  color: #222026; }

.header-alerts_dark-grey-bg__3eFUA {
  background-color: #222026;
  color: #fff; }

.header-alerts_flat-white-bg__2AyIC {
  background-color: #fff;
  color: #014660; }

.header-alerts_pastel-red-bg__1ugKB {
  background-color: #e55e50;
  color: #f9f9f9; }

.header-alerts_white__1kqKA {
  color: #f9f9f9; }

.header-alerts_black__1AG5N {
  color: #000; }

.header-alerts_deep-teal__1RWJa {
  color: #014660; }

.header-alerts_greyish-teal__3CoBD {
  color: #5bdbbd; }

.header-alerts_very-light-grey__2QevV {
  color: #b9b9b9; }

.header-alerts_warm-grey__1Ptmq {
  color: #55556f; }

.header-alerts_simple-grey__1cy_i {
  color: #d4d4d8; }

.header-alerts_dark-grey__2GbCT {
  color: #222026; }

.header-alerts_flat-white__26QAA {
  color: #fff; }

.header-alerts_pastel-red__18X1s {
  color: #e55e50; }

.header-alerts_white-bg__2Vg_5 {
  background-color: #f9f9f9; }

.header-alerts_deep-teal-bg__3uIcs {
  background-color: #014660;
  color: #fff; }

.header-alerts_greyish-teal-bg__136Lx {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header-alerts_very-light-grey-bg__3gXOE {
  background-color: #b9b9b9;
  color: #222026; }

.header-alerts_warm-grey-bg__1rA7- {
  background-color: #55556f;
  color: #f9f9f9; }

.header-alerts_simple-grey-bg__Kcfr4 {
  background-color: #d4d4d8;
  color: #222026; }

.header-alerts_dark-grey-bg__3eFUA {
  background-color: #222026;
  color: #fff; }

.header-alerts_flat-white-bg__2AyIC {
  background-color: #fff;
  color: #014660; }

.header-alerts_pastel-red-bg__1ugKB {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.header-alerts_nav__jmzn3 {
  align-items: center;
  border-radius: 30px;
  display: flex;
  padding: 8px 15px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  @media (max-width: 1024px) {
    .header-alerts_nav__jmzn3 {
      padding: 5px 20px; } }
  .header-alerts_nav__jmzn3:hover {
    background-color: rgba(246, 246, 246, 0.1);
    cursor: pointer;
    text-decoration: none; }
  .header-alerts_nav__jmzn3 > svg {
    height: 25px;
    margin-right: 10px;
    width: 25px; }
    @media (max-width: 1024px) {
      .header-alerts_nav__jmzn3 > svg {
        margin-right: 0; } }
  .header-alerts_nav__jmzn3 > span {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
    @media (max-width: 1024px) {
      .header-alerts_nav__jmzn3 > span {
        display: none; } }

.header-alerts_active__pNRsu {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .header-alerts_active__pNRsu:hover {
    background-color: #fff;
    text-decoration: none; }
  .header-alerts_active__pNRsu > span {
    color: #014660; }

.header-alerts_wrapper__1ECn1 {
  max-height: 80vh;
  position: absolute;
  right: 20px;
  top: 115px;
  width: 400px;
  z-index: 3; }
  .header-alerts_wrapper__1ECn1::before {
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 75px;
    top: -10px;
    width: 0;
    z-index: 1000; }

.header-alerts_alerts_spring__1-_b4 {
  height: 80vh;
  position: absolute;
  right: 20px;
  top: 115px;
  width: 400px;
  z-index: 1000; }

.header-alerts_arrow_up__3Quef {
  display: none; }
  @media (min-width: 1025px) {
    .header-alerts_arrow_up__3Quef {
      border-bottom: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      display: block;
      height: 0;
      position: absolute;
      right: 75px;
      top: -10px;
      width: 0;
      z-index: 1001; } }

.header-alerts_badge__14YMm {
  align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#5fe8c3), to(#43b395));
  background: linear-gradient(#5fe8c3, #43b395);
  border-radius: 18.5px;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 18px;
  justify-content: center;
  left: 25px;
  position: absolute;
  top: 7px;
  width: 18px; }

.header-menu_white__2x4r- {
  color: #f9f9f9; }

.header-menu_black__3pPQ2 {
  color: #000; }

.header-menu_deep-teal__AC-ht {
  color: #014660; }

.header-menu_greyish-teal__3rgyZ {
  color: #5bdbbd; }

.header-menu_very-light-grey__2xCI2 {
  color: #b9b9b9; }

.header-menu_warm-grey__1DJIu {
  color: #55556f; }

.header-menu_simple-grey__1-eRC {
  color: #d4d4d8; }

.header-menu_dark-grey__3saQO {
  color: #222026; }

.header-menu_flat-white__24l1L {
  color: #fff; }

.header-menu_pastel-red__1bybq {
  color: #e55e50; }

.header-menu_white-bg__1UIm0 {
  background-color: #f9f9f9; }

.header-menu_deep-teal-bg__1GgOA {
  background-color: #014660;
  color: #fff; }

.header-menu_greyish-teal-bg__3UTJd {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.header-menu_very-light-grey-bg__2_Bko {
  background-color: #b9b9b9;
  color: #222026; }

.header-menu_warm-grey-bg__2HZ8M {
  background-color: #55556f;
  color: #f9f9f9; }

.header-menu_simple-grey-bg__3h_Wh {
  background-color: #d4d4d8;
  color: #222026; }

.header-menu_dark-grey-bg__5nDfE {
  background-color: #222026;
  color: #fff; }

.header-menu_flat-white-bg__2cE0t {
  background-color: #fff;
  color: #014660; }

.header-menu_pastel-red-bg__1BgU9 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.header-menu_menu-block__1rdMu {
  position: relative; }

.header-menu_menu__2r47o {
  background-color: transparent;
  border: 0; }
  .header-menu_menu__2r47o svg {
    height: 20px;
    width: 4px; }

.header-menu_menu-header__3P_XZ {
  align-items: center;
  color: #5bdbbd;
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 20px;
  justify-content: space-between;
  padding: 10px 20px; }
  .header-menu_menu-header__3P_XZ button,
  .header-menu_menu-header__3P_XZ svg {
    height: 20px;
    width: 20px; }
  .header-menu_menu-header__3P_XZ button {
    background-color: transparent;
    border: 0;
    border-radius: 100%;
    padding: 0; }
  .header-menu_menu-header__3P_XZ svg {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .header-menu_menu-header__3P_XZ button:hover {
    background-color: #e6e6e6; }

.header-menu_link__3kyqS {
  cursor: pointer; }

.header-menu_menu-container__29PKX {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px rgba(34, 32, 38, 0.6);
  min-width: 285px;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: start; }

.header-menu_rule__1gcNK {
  border: 0;
  border-top: 1px solid #d4d4d8; }

.header-menu_open__3qUj2 {
  -webkit-animation: header-menu_show__3estR 0.1s ease-in-out;
          animation: header-menu_show__3estR 0.1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.header-menu_close__E9cws {
  -webkit-animation: header-menu_hide__3tfhJ 0.1s ease-in-out;
          animation: header-menu_hide__3tfhJ 0.1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.header-menu_closed__1ZACY {
  visibility: hidden; }

.header-menu_item__1xZUM {
  padding-left: 0; }

.header-menu_notification-status__2vT3L {
  padding: 10px 20px; }
  .header-menu_notification-status__2vT3L .header-menu_status__2vw94 {
    color: #014660;
    font-size: 18px;
    margin-bottom: 6px; }
  .header-menu_notification-status__2vT3L .header-menu_unmute-date__2SBv2 {
    color: #222026; }

.header-menu_do-not-disturb__3DlNE {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px; }

.header-menu_switch__1xXH9 {
  display: inline-block;
  height: 23px;
  position: relative;
  width: 40px; }
  .header-menu_switch__1xXH9 input {
    height: 0;
    opacity: 0;
    width: 0; }
  .header-menu_switch__1xXH9 .header-menu_slider__31mtg {
    background-color: #ccc;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .header-menu_switch__1xXH9 .header-menu_slider__31mtg::before {
    background-color: #fff;
    border-radius: 50%;
    bottom: 2px;
    content: "";
    height: 19px;
    left: 2px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 19px; }
  .header-menu_switch__1xXH9 input:checked + .header-menu_slider__31mtg {
    background-color: #5bdbbd; }
  .header-menu_switch__1xXH9 input:checked + .header-menu_slider__31mtg::before {
    -webkit-transform: translateX(16px);
            transform: translateX(16px); }

.header-menu_menu-item__23JAb {
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 8px 20px; }

.header-menu_menu-item__23JAb:hover {
  background: #e6f1fd; }

.header-menu_menu-item__23JAb:active {
  background: #ddecfc; }

.header-menu_link__3kyqS {
  color: #014660; }

@-webkit-keyframes header-menu_show__3estR {
  0% {
    opacity: 0;
    visibility: visible; }
  100% {
    opacity: 1; } }

@keyframes header-menu_show__3estR {
  0% {
    opacity: 0;
    visibility: visible; }
  100% {
    opacity: 1; } }

@-webkit-keyframes header-menu_hide__3tfhJ {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes header-menu_hide__3tfhJ {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.shared-goals_white__1vHDw {
  color: #f9f9f9; }

.shared-goals_black__gwntt {
  color: #000; }

.shared-goals_deep-teal__17fol {
  color: #014660; }

.shared-goals_greyish-teal__K8DIn {
  color: #5bdbbd; }

.shared-goals_very-light-grey__1vPoE {
  color: #b9b9b9; }

.shared-goals_warm-grey__1jfIy {
  color: #55556f; }

.shared-goals_simple-grey__LiDJK {
  color: #d4d4d8; }

.shared-goals_dark-grey__3pVZu {
  color: #222026; }

.shared-goals_flat-white__36V2D {
  color: #fff; }

.shared-goals_pastel-red__HQ10m {
  color: #e55e50; }

.shared-goals_white-bg__2yuNn {
  background-color: #f9f9f9; }

.shared-goals_deep-teal-bg__1j-7c {
  background-color: #014660;
  color: #fff; }

.shared-goals_greyish-teal-bg__12oOv {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.shared-goals_very-light-grey-bg__3VL0a {
  background-color: #b9b9b9;
  color: #222026; }

.shared-goals_warm-grey-bg__xR5eB {
  background-color: #55556f;
  color: #f9f9f9; }

.shared-goals_simple-grey-bg__2hi8G {
  background-color: #d4d4d8;
  color: #222026; }

.shared-goals_dark-grey-bg__387Aj {
  background-color: #222026;
  color: #fff; }

.shared-goals_flat-white-bg__1Bg-1 {
  background-color: #fff;
  color: #014660; }

.shared-goals_pastel-red-bg__1r_h1 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.shared-goals_share-goal-modal__1-QAd {
  color: #222026;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background: #f9f9f9;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  max-height: 95%;
  width: 550px; }
  .shared-goals_share-goal-modal__1-QAd w-search {
    margin-bottom: 10px; }
  .shared-goals_share-goal-modal__1-QAd footer {
    display: flex;
    font-size: 16px;
    justify-content: flex-end;
    padding: 20px;
    width: 100%; }
    .shared-goals_share-goal-modal__1-QAd footer button:last-of-type {
      margin-left: 10px; }

.shared-goals_title__2V-_v {
  color: #5bdbbd;
  font-family: Questrial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 10px 16px; }

.shared-goals_card__CATvu {
  background-color: #fff;
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;
  padding: 14px 10px; }
  .shared-goals_card__CATvu .shared-goals_user-info__13EUP {
    color: #55556f;
    flex: 1 1;
    line-height: 21px;
    padding-right: 10px; }
  .shared-goals_card__CATvu .shared-goals_avatar-block__2gQ0g {
    display: flex;
    height: 100%;
    margin-right: 15px; }

.shared-goals_link__34KDD {
  color: #014660;
  cursor: pointer;
  display: inline-block; }

.shared-goals_description__x_CSP {
  padding: 8px 0; }

.shared-goals_body__X5tFN {
  display: contents; }

.shared-goals_user-list__3TbaI {
  flex: 1 1;
  margin-right: 5px;
  overflow: auto;
  -ms-overflow-style: none;
  padding: 2px 10px 15px 15px;
  scrollbar-width: thin; }

.shared-goals_shared_users_list__1iJGI {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  padding: 10px; }

.shared-goals_shared-users__3lNl6 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 130px;
  -ms-overflow-style: none;
  overflow-y: auto;
  scrollbar-width: thin; }
  .shared-goals_shared-users__3lNl6 .shared-goals_shared-item__29Maa {
    margin-bottom: 10px; }

w-checkbox {
  margin: auto 10px; }
  w-checkbox svg {
    height: 22px;
    width: 22px; }

@media (max-width: 1024px) {
  .shared-goals_share-goal-modal__1-QAd {
    max-height: 100%;
    width: 100%; }
    .shared-goals_share-goal-modal__1-QAd w-search {
      margin-bottom: 5px; }
    .shared-goals_share-goal-modal__1-QAd footer {
      padding: 10px 20px; }
  .shared-goals_user-list__3TbaI {
    padding: 6px 0 0; }
  .shared-goals_search__3Zqas {
    padding: 0; }
  .shared-goals_shared-users__3lNl6 {
    max-height: 80px; } }

.alerts_white__3QRRa {
  color: #f9f9f9; }

.alerts_black__2n0qC {
  color: #000; }

.alerts_deep-teal__3JYUK {
  color: #014660; }

.alerts_greyish-teal__3XGST {
  color: #5bdbbd; }

.alerts_very-light-grey__2xlUo {
  color: #b9b9b9; }

.alerts_warm-grey__2xyof {
  color: #55556f; }

.alerts_simple-grey__ZZ2ed {
  color: #d4d4d8; }

.alerts_dark-grey__36ACf {
  color: #222026; }

.alerts_flat-white__2r2W2 {
  color: #fff; }

.alerts_pastel-red__3OmZE {
  color: #e55e50; }

.alerts_white-bg__4-MN6 {
  background-color: #f9f9f9; }

.alerts_deep-teal-bg__30XY4 {
  background-color: #014660;
  color: #fff; }

.alerts_greyish-teal-bg__2uGY- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.alerts_very-light-grey-bg__3sc89 {
  background-color: #b9b9b9;
  color: #222026; }

.alerts_warm-grey-bg__12wl6 {
  background-color: #55556f;
  color: #f9f9f9; }

.alerts_simple-grey-bg__2im3X {
  background-color: #d4d4d8;
  color: #222026; }

.alerts_dark-grey-bg__2GqG2 {
  background-color: #222026;
  color: #fff; }

.alerts_flat-white-bg__1DhVP {
  background-color: #fff;
  color: #014660; }

.alerts_pastel-red-bg__M6qi0 {
  background-color: #e55e50;
  color: #f9f9f9; }

.alerts_white__3QRRa {
  color: #f9f9f9; }

.alerts_black__2n0qC {
  color: #000; }

.alerts_deep-teal__3JYUK {
  color: #014660; }

.alerts_greyish-teal__3XGST {
  color: #5bdbbd; }

.alerts_very-light-grey__2xlUo {
  color: #b9b9b9; }

.alerts_warm-grey__2xyof {
  color: #55556f; }

.alerts_simple-grey__ZZ2ed {
  color: #d4d4d8; }

.alerts_dark-grey__36ACf {
  color: #222026; }

.alerts_flat-white__2r2W2 {
  color: #fff; }

.alerts_pastel-red__3OmZE {
  color: #e55e50; }

.alerts_white-bg__4-MN6 {
  background-color: #f9f9f9; }

.alerts_deep-teal-bg__30XY4 {
  background-color: #014660;
  color: #fff; }

.alerts_greyish-teal-bg__2uGY- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.alerts_very-light-grey-bg__3sc89 {
  background-color: #b9b9b9;
  color: #222026; }

.alerts_warm-grey-bg__12wl6 {
  background-color: #55556f;
  color: #f9f9f9; }

.alerts_simple-grey-bg__2im3X {
  background-color: #d4d4d8;
  color: #222026; }

.alerts_dark-grey-bg__2GqG2 {
  background-color: #222026;
  color: #fff; }

.alerts_flat-white-bg__1DhVP {
  background-color: #fff;
  color: #014660; }

.alerts_pastel-red-bg__M6qi0 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.alerts_alerts-enter__1PhQV {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts_alerts-enter-active__EVeab {
  opacity: 1;
  -webkit-transform: scale(1) translate3d(0, 0, 0);
          transform: scale(1) translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts_alerts-exit__21e9O {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

.alerts_alerts-exit-active__3U540 {
  opacity: 0.01;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); }

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
.alerts_slide-out-left__2e7-g {
  -webkit-animation: alerts_slide-out-left__2e7-g 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: alerts_slide-out-left__2e7-g 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes alerts_slide-out-left__2e7-g {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes alerts_slide-out-left__2e7-g {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
.alerts_slide-in-left__2bcLO {
  -webkit-animation: alerts_slide-in-left__2bcLO 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: alerts_slide-in-left__2bcLO 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes alerts_slide-in-left__2bcLO {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

@keyframes alerts_slide-in-left__2bcLO {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; } }

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
.alerts_wobble-hor-bottom__RSErs {
  -webkit-animation: alerts_wobble-hor-bottom__RSErs 0.8s both;
          animation: alerts_wobble-hor-bottom__RSErs 0.8s both; }

@-webkit-keyframes alerts_wobble-hor-bottom__RSErs {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

@keyframes alerts_wobble-hor-bottom__RSErs {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  15% {
    -webkit-transform: translateX(-5px) rotate(-2deg);
            transform: translateX(-5px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(5px) rotate(2deg);
            transform: translateX(5px) rotate(2deg); }
  45% {
    -webkit-transform: translateX(-3px) rotate(-1.2deg);
            transform: translateX(-3px) rotate(-1.2deg); }
  60% {
    -webkit-transform: translateX(3px) rotate(0.8deg);
            transform: translateX(3px) rotate(0.8deg); }
  75% {
    -webkit-transform: translateX(-2px) rotate(-0.4deg);
            transform: translateX(-2px) rotate(-0.4deg); } }

/**
 * ----------------------------------------
 * animation Show-Hide by changing opacity
 * ----------------------------------------
 */
@-webkit-keyframes alerts_show__2ySX3 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes alerts_show__2ySX3 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes alerts_hide__BILTT {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes alerts_hide__BILTT {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

w-alerts-module {
  flex: 1 1; }

.alerts_placeholder__2ltZl {
  color: #55556f;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  margin: auto;
  text-align: center; }
  .alerts_placeholder__2ltZl svg {
    height: 80px;
    margin-bottom: 30px;
    width: 80px; }
  @media (min-width: 1025px) {
    .alerts_placeholder__2ltZl {
      min-height: 300px; } }
  @media (max-width: 1024px) {
    .alerts_placeholder__2ltZl {
      height: 100%; } }

.alerts_container__2BrDp {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: relative; }
  @supports (-webkit-overflow-scrolling: touch) {
    .alerts_container__2BrDp {
      padding-bottom: 60px; } }
  @media (min-width: 1025px) {
    .alerts_container__2BrDp {
      -webkit-animation: alerts_show__2ySX3 0.5s;
              animation: alerts_show__2ySX3 0.5s;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.19);
      max-height: 80vh;
      padding-bottom: 5px;
      width: 100%; } }
  .alerts_container__2BrDp .alerts_read_all__3TmWg {
    align-items: baseline;
    color: #014660;
    cursor: pointer;
    display: flex;
    font-family: "OpenSans", sans-serif;
    font-size: 14px;
    justify-content: flex-end;
    padding: 15px 15px 0;
    position: -webkit-sticky;
    position: sticky; }
    .alerts_container__2BrDp .alerts_read_all__3TmWg svg {
      height: 17px;
      margin-right: 5px;
      width: 17px; }
    .alerts_container__2BrDp .alerts_read_all__3TmWg.alerts_disabled__3jz9k {
      -webkit-filter: grayscale(50%);
              filter: grayscale(50%);
      opacity: 0.5;
      pointer-events: none; }

.alerts_header__1de1p {
  color: #55556f;
  display: flex;
  font-family: "Questrial", sans-serif;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 15px; }

.alerts_alert__MJ_hp {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "OpenSans", sans-serif;
  margin-bottom: 10px;
  padding: 10px 20px; }
  .alerts_alert__MJ_hp:hover {
    background-color: #f9f9f9; }
  .alerts_alert__MJ_hp > span {
    color: #55556f;
    font-size: 12px;
    margin-bottom: 10px; }
  .alerts_alert__MJ_hp > div {
    display: flex; }
    .alerts_alert__MJ_hp > div h3 {
      color: #014660;
      font-size: 14px;
      margin-bottom: 10px; }
      .alerts_alert__MJ_hp > div h3.alerts_unread__3d2pk {
        font-weight: 600; }
    .alerts_alert__MJ_hp > div p {
      color: #55556f;
      font-size: 12px;
      line-height: 1.2em;
      margin-bottom: 5px; }
    .alerts_alert__MJ_hp > div strong {
      font-weight: bold; }

.alerts_alert_buttons__4lnco {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px; }

.alerts_alerts_list__2UD_I {
  max-height: 100%;
  overflow-y: auto; }
  @media (max-width: 1024px) {
    .alerts_alerts_list__2UD_I {
      margin-bottom: 60px; } }
  @supports (-webkit-overflow-scrolling: touch) {
    .alerts_alerts_list__2UD_I {
      padding-bottom: 60px; } }

.credits-achievements_white__3-pc5 {
  color: #f9f9f9; }

.credits-achievements_black__13vUR {
  color: #000; }

.credits-achievements_deep-teal__1GA2l {
  color: #014660; }

.credits-achievements_greyish-teal__12Je- {
  color: #5bdbbd; }

.credits-achievements_very-light-grey__25paR {
  color: #b9b9b9; }

.credits-achievements_warm-grey__2J_tz {
  color: #55556f; }

.credits-achievements_simple-grey__17nYm {
  color: #d4d4d8; }

.credits-achievements_dark-grey__3DYEH {
  color: #222026; }

.credits-achievements_flat-white__1Lp0S {
  color: #fff; }

.credits-achievements_pastel-red__11wCO {
  color: #e55e50; }

.credits-achievements_white-bg__3Cdhm {
  background-color: #f9f9f9; }

.credits-achievements_deep-teal-bg__1x8ka {
  background-color: #014660;
  color: #fff; }

.credits-achievements_greyish-teal-bg__22jb- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.credits-achievements_very-light-grey-bg__3gXF4 {
  background-color: #b9b9b9;
  color: #222026; }

.credits-achievements_warm-grey-bg__3r_I9 {
  background-color: #55556f;
  color: #f9f9f9; }

.credits-achievements_simple-grey-bg__3XC8Y {
  background-color: #d4d4d8;
  color: #222026; }

.credits-achievements_dark-grey-bg__2uGxm {
  background-color: #222026;
  color: #fff; }

.credits-achievements_flat-white-bg__rWpd_ {
  background-color: #fff;
  color: #014660; }

.credits-achievements_pastel-red-bg__35hQB {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.credits-achievements_title__3_BFz {
  color: #55556f;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  margin-bottom: 15px; }

.credits-achievements_card__2gyRC {
  background: #f9f9f9;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  margin: 10px 15px 20px;
  padding: 0;
  position: relative; }

.credits-achievements_list__2smRE {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
  z-index: 0; }
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
    display: flex;
    flex: 1 1;
    margin: 10px;
    padding: 30px 15px;
    position: relative; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_list_item__text__1R2JX {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      min-width: 110px; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_icon__16zAH {
      height: 160px; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_icon__16zAH > img {
      height: 100%; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay h1, .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay p, .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay span {
      font-family: Questrial, sans-serif; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay h1 {
      color: #55556f;
      font-size: 24px;
      padding: 0; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay p {
      align-items: center;
      color: #55556f;
      display: flex;
      flex: 1 1;
      font-size: 20px;
      padding: 10px 0; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay span {
      color: #5bdbbd;
      font-size: 22px;
      margin-bottom: 10px; }
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:only-child {
    align-self: center;
    flex-direction: row;
    margin: 0 auto;
    max-width: 600px; }
  @media all and (min-width: 361px) and (max-width: 430px) {
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
      align-items: center;
      flex-direction: column-reverse;
      max-width: 45%;
      padding: 15px; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:nth-child(2n) {
      margin-left: auto; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:last-child:nth-child(2n+1) {
      flex-direction: row;
      max-width: 100%;
      padding: 15px; } }
  @media all and (max-width: 360px) {
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
      flex-direction: row;
      margin: 5px 0;
      max-width: 100%;
      padding: 15px; } }
  .credits-achievements_list__2smRE h1,
  .credits-achievements_list__2smRE p {
    margin-bottom: 18px; }
  @media all and (max-width: 1360px) {
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
      background-color: #f9f9f9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
      display: flex;
      flex: 1 1;
      margin: 10px;
      padding: 30px 15px;
      position: relative; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_list_item__text__1R2JX {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        min-width: 110px; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_icon__16zAH {
        height: 140px; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay .credits-achievements_icon__16zAH > img {
        height: 100%; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay h1, .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay p, .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay span {
        font-family: Questrial, sans-serif; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay h1 {
        color: #55556f;
        font-size: 20px;
        padding: 0; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay p {
        align-items: center;
        color: #55556f;
        display: flex;
        flex: 1 1;
        font-size: 16px;
        padding: 10px 0; }
      .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay span {
        color: #5bdbbd;
        font-size: 18px;
        margin-bottom: 10px; }
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:only-child {
      align-self: center;
      flex-direction: row;
      margin: 0 auto;
      max-width: 600px; } }

@media all and (max-width: 1360px) and (min-width: 361px) and (max-width: 430px) {
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
    align-items: center;
    flex-direction: column-reverse;
    max-width: 45%;
    padding: 15px; }
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:nth-child(2n) {
    margin-left: auto; }
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay:last-child:nth-child(2n+1) {
    flex-direction: row;
    max-width: 100%;
    padding: 15px; } }

@media all and (max-width: 1360px) and (max-width: 360px) {
  .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
    flex-direction: row;
    margin: 5px 0;
    max-width: 100%;
    padding: 15px; } }
  @media all and (max-width: 1360px) {
    .credits-achievements_list__2smRE .credits-achievements_achievement_card__1xlay {
      padding: 20px 10px; }
    .credits-achievements_list__2smRE h1,
    .credits-achievements_list__2smRE p {
      margin-bottom: 18px; } }

.credits-achievements_empty-result__2BLPu {
  margin-top: 55px;
  position: relative;
  text-align: center; }
  .credits-achievements_empty-result__2BLPu .credits-achievements_icon__16zAH > svg {
    height: 180px;
    width: 180px; }
  .credits-achievements_empty-result__2BLPu .credits-achievements_title__3_BFz {
    color: #014660;
    font-size: 36px; }
  .credits-achievements_empty-result__2BLPu .credits-achievements_description__1Io0l {
    color: #014660;
    font-size: 26px;
    margin: auto;
    width: 82%; }
  .credits-achievements_empty-result__2BLPu .credits-achievements_button__1MG-1 {
    color: #f9f9f9;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background-color: #014660;
    border: 0;
    border-radius: 18.5px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    text-align: -webkit-center;
    font-size: 14px;
    margin-top: 30px;
    padding: 3px 25px; }
    .credits-achievements_empty-result__2BLPu .credits-achievements_button__1MG-1:hover:not(:disabled) {
      background-color: #00212e; }
    .credits-achievements_empty-result__2BLPu .credits-achievements_button__1MG-1:disabled {
      opacity: 0.5; }

.credits-faq_white__2Bk2i {
  color: #f9f9f9; }

.credits-faq_black__2mLcM {
  color: #000; }

.credits-faq_deep-teal__2lSlG {
  color: #014660; }

.credits-faq_greyish-teal__D7sLj {
  color: #5bdbbd; }

.credits-faq_very-light-grey__1m9pk {
  color: #b9b9b9; }

.credits-faq_warm-grey__NNdhY {
  color: #55556f; }

.credits-faq_simple-grey__Ds8dF {
  color: #d4d4d8; }

.credits-faq_dark-grey__OGTzC {
  color: #222026; }

.credits-faq_flat-white__2hHUp {
  color: #fff; }

.credits-faq_pastel-red__3wH21 {
  color: #e55e50; }

.credits-faq_white-bg__3aU_k {
  background-color: #f9f9f9; }

.credits-faq_deep-teal-bg__24GJ1 {
  background-color: #014660;
  color: #fff; }

.credits-faq_greyish-teal-bg__2A2FC {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.credits-faq_very-light-grey-bg__2k4_h {
  background-color: #b9b9b9;
  color: #222026; }

.credits-faq_warm-grey-bg__1u7I- {
  background-color: #55556f;
  color: #f9f9f9; }

.credits-faq_simple-grey-bg__Skbsi {
  background-color: #d4d4d8;
  color: #222026; }

.credits-faq_dark-grey-bg__yuzD8 {
  background-color: #222026;
  color: #fff; }

.credits-faq_flat-white-bg__2g88I {
  background-color: #fff;
  color: #014660; }

.credits-faq_pastel-red-bg__15NLp {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.credits-faq_categories__2NFeh {
  padding: 0 12px 12px; }

.credits-faq_question-content__1M3_P {
  padding: 0 12px 12px; }

.credits-faq_credits-faq__3a9xh h1 {
  color: #55556f;
  font-size: 22px;
  margin: 30px 14px 14px; }

.credits-faq_credits-faq__3a9xh [class*=collapsable-wrapper], .credits-faq_credits-faq__3a9xh .credits-faq_empty-node__2Rfkl {
  border-top: 1px solid #222026; }
  .credits-faq_credits-faq__3a9xh [class*=collapsable-wrapper] [class*=header-collapsable] > [class*=title]:hover, .credits-faq_credits-faq__3a9xh .credits-faq_empty-node__2Rfkl [class*=header-collapsable] > [class*=title]:hover {
    text-decoration: underline; }

.credits-faq_credits-faq__3a9xh [class*=collapsable-wrapper]:nth-last-child(1), .credits-faq_credits-faq__3a9xh .credits-faq_empty-node__2Rfkl:nth-last-child(1) {
  border-bottom: 1px solid #222026; }

.credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > [class*=collapsable-wrapper] > [class*=header-collapsable] > [class*=title], .credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > .credits-faq_empty-node__2Rfkl > [class*=header-collapsable] > [class*=title] {
  font-size: 22px; }

.credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > [class*=collapsable-wrapper] [class*=collapsable-wrapper]:last-child, .credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > .credits-faq_empty-node__2Rfkl [class*=collapsable-wrapper]:last-child, .credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > [class*=collapsable-wrapper] .credits-faq_empty-node__2Rfkl:last-child, .credits-faq_credits-faq__3a9xh .credits-faq_categories__2NFeh > .credits-faq_empty-node__2Rfkl .credits-faq_empty-node__2Rfkl:last-child {
  margin-bottom: 20px; }

.credits-faq_credits-faq__3a9xh .credits-faq_questions__2zXY3 > [class*=collapsable-wrapper], .credits-faq_credits-faq__3a9xh .credits-faq_questions__2zXY3 > .credits-faq_empty-node__2Rfkl {
  background: rgba(221, 221, 221, 0.2);
  border: 0;
  margin-bottom: 10px;
  padding: 4px 20px; }
  .credits-faq_credits-faq__3a9xh .credits-faq_questions__2zXY3 > [class*=collapsable-wrapper]:nth-last-child(1), .credits-faq_credits-faq__3a9xh .credits-faq_questions__2zXY3 > .credits-faq_empty-node__2Rfkl:nth-last-child(1) {
    margin: 0; }

.credits-faq_question-answer__2F9Zt {
  color: #222026;
  padding: 12px 0 20px; }

.credits-faq_empty-node__2Rfkl {
  align-items: center;
  color: #55556f;
  display: flex;
  font-size: 22px;
  height: 60px; }

.credits-faq_questions__2zXY3 > .credits-faq_empty-node__2Rfkl {
  font-size: 16px; }

.credits-leaderboard_white__16h6L {
  color: #f9f9f9; }

.credits-leaderboard_black__4f_dg {
  color: #000; }

.credits-leaderboard_deep-teal__38wt9 {
  color: #014660; }

.credits-leaderboard_greyish-teal__1vMmS {
  color: #5bdbbd; }

.credits-leaderboard_very-light-grey__klUaW {
  color: #b9b9b9; }

.credits-leaderboard_warm-grey__1Ma6d {
  color: #55556f; }

.credits-leaderboard_simple-grey__zhbH3 {
  color: #d4d4d8; }

.credits-leaderboard_dark-grey__lFFXK {
  color: #222026; }

.credits-leaderboard_flat-white__3u4is {
  color: #fff; }

.credits-leaderboard_pastel-red__2bsk_ {
  color: #e55e50; }

.credits-leaderboard_white-bg__3BkdO {
  background-color: #f9f9f9; }

.credits-leaderboard_deep-teal-bg__3yLsa {
  background-color: #014660;
  color: #fff; }

.credits-leaderboard_greyish-teal-bg__1NMQT {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.credits-leaderboard_very-light-grey-bg__1i1Rt {
  background-color: #b9b9b9;
  color: #222026; }

.credits-leaderboard_warm-grey-bg__iKze0 {
  background-color: #55556f;
  color: #f9f9f9; }

.credits-leaderboard_simple-grey-bg__n6eKT {
  background-color: #d4d4d8;
  color: #222026; }

.credits-leaderboard_dark-grey-bg__1p8Gx {
  background-color: #222026;
  color: #fff; }

.credits-leaderboard_flat-white-bg__1Tqo9 {
  background-color: #fff;
  color: #014660; }

.credits-leaderboard_pastel-red-bg__2HXU- {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.credits-leaderboard_title__2UxnC {
  color: #55556f;
  font-family: Questrial, sans-serif;
  font-size: 22px;
  margin-bottom: 15px; }

.credits-leaderboard_card__3CTX_ {
  background: #f9f9f9;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  margin: 10px 15px 20px;
  padding: 0;
  position: relative; }

.credits-leaderboard_content__21T3g {
  overflow: auto;
  padding-top: 20px;
  position: relative; }

.credits-leaderboard_name__2DsTM {
  color: #014660;
  font-size: 21px;
  margin-bottom: 12px;
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap; }

.credits-leaderboard_blurb__3G4mz {
  color: #222026;
  font-size: 0.95em;
  line-height: 1.3em;
  margin-bottom: 15px; }

.credits-leaderboard_score__1HHrn {
  color: #5bdbbd;
  font-size: 21px; }

.credits-leaderboard_leaders__11uI_ {
  display: flex;
  margin: auto;
  max-width: 700px;
  position: relative;
  width: 100%; }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_leader__2_Bxg {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 74px auto 0;
    text-align: center;
    width: 26%; }
    @media (max-width: 1024px) {
      .credits-leaderboard_leaders__11uI_ .credits-leaderboard_leader__2_Bxg {
        margin: 20px auto 0; } }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_leader__2_Bxg:nth-child(2) {
    margin-top: 34px; }
    @media (max-width: 1024px) {
      .credits-leaderboard_leaders__11uI_ .credits-leaderboard_leader__2_Bxg:nth-child(2) {
        margin-top: 0; } }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_avatar__1V9sm {
    background: #f9f9f9;
    border-radius: 50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .credits-leaderboard_leaders__11uI_ .credits-leaderboard_avatar__1V9sm img {
      padding: 8px; }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_me__3GXRk {
    box-shadow: 0 2px 40px 0 #fccf6b; }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_me__3GXRk.credits-leaderboard_avatar__1V9sm {
    background: -webkit-gradient(linear, left top, left bottom, from(#5fe8c4), to(#43b395));
    background: linear-gradient(to bottom, #5fe8c4, #43b395); }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_place-badge__26cbE {
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    color: #014660;
    font-size: 20px;
    height: 40px;
    margin: -22px auto 16px;
    padding: 11px;
    width: 40px; }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_badge-1__1Go-p {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffe08b), to(#ffc866));
    background: linear-gradient(#ffe08b, #ffc866); }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_badge-2__3mTny {
    background: #ddd; }
  .credits-leaderboard_leaders__11uI_ .credits-leaderboard_badge-3__2madF {
    background: #e19f6a; }

.credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_individual-item__3ixjq {
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  margin: 20px;
  padding: 14px 20px; }

.credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_me__3GXRk {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(4%, #5fe8c3), color-stop(99%, #43b395));
  background: linear-gradient(to bottom, #5fe8c3 4%, #43b395 99%);
  box-shadow: 0 0 20px -1px #fccf6b;
  display: block;
  margin: 20px; }
  .credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_me__3GXRk .credits-leaderboard_individual-item__3ixjq {
    display: flex;
    flex: 1 1;
    margin: 0 8px 0 50px;
    max-width: 100%; }
    .credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_me__3GXRk .credits-leaderboard_individual-item__3ixjq .credits-leaderboard_avatar__1V9sm {
      border: solid 6px #fff;
      border-radius: 50%;
      margin-left: -52px; }

.credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_name__2DsTM {
  margin: 0 20px; }

.credits-leaderboard_individuals-list__1GFBX .credits-leaderboard_score__1HHrn {
  margin: 0 10px 0 auto;
  text-align: center; }

.credits-streaks_white__1sHFA {
  color: #f9f9f9; }

.credits-streaks_black__Rip2Z {
  color: #000; }

.credits-streaks_deep-teal__3l_Dz {
  color: #014660; }

.credits-streaks_greyish-teal__2w9kc {
  color: #5bdbbd; }

.credits-streaks_very-light-grey__15mA7 {
  color: #b9b9b9; }

.credits-streaks_warm-grey__2v8Pq {
  color: #55556f; }

.credits-streaks_simple-grey__99gWA {
  color: #d4d4d8; }

.credits-streaks_dark-grey__1Jobr {
  color: #222026; }

.credits-streaks_flat-white__3fTRY {
  color: #fff; }

.credits-streaks_pastel-red__or5DO {
  color: #e55e50; }

.credits-streaks_white-bg__5fU0E {
  background-color: #f9f9f9; }

.credits-streaks_deep-teal-bg__3jCAo {
  background-color: #014660;
  color: #fff; }

.credits-streaks_greyish-teal-bg__36pzY {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.credits-streaks_very-light-grey-bg__1GegL {
  background-color: #b9b9b9;
  color: #222026; }

.credits-streaks_warm-grey-bg__DSnWx {
  background-color: #55556f;
  color: #f9f9f9; }

.credits-streaks_simple-grey-bg__1W-HO {
  background-color: #d4d4d8;
  color: #222026; }

.credits-streaks_dark-grey-bg__3NzQg {
  background-color: #222026;
  color: #fff; }

.credits-streaks_flat-white-bg__2OCNn {
  background-color: #fff;
  color: #014660; }

.credits-streaks_pastel-red-bg__1eCkF {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.credits-streaks_credits-achievements__1m5jC {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  position: relative; }
  .credits-streaks_credits-achievements__1m5jC .credits-streaks_background-decoration__lBM8e {
    background-color: #5bdbbd;
    border-radius: 0 0 100% 100%;
    height: 100%;
    left: -120px;
    max-height: 500px;
    position: absolute;
    right: -120px;
    top: -160px; }
    @media (max-width: 1024px) {
      .credits-streaks_credits-achievements__1m5jC .credits-streaks_background-decoration__lBM8e {
        height: 64%; } }
  @media (max-width: 1024px) {
    .credits-streaks_credits-achievements__1m5jC {
      padding-bottom: 50px; } }
  .credits-streaks_credits-achievements__1m5jC .credits-streaks_background-decoration__lBM8e {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe08b), to(#ffc866));
    background-image: linear-gradient(to bottom, #ffe08b 0%, #ffc866 100%);
    top: -100px; }
  .credits-streaks_credits-achievements__1m5jC .credits-streaks_background-decoration-bottom__3ihLb {
    height: 330px;
    left: -80px;
    margin-top: -330px;
    overflow: hidden;
    position: absolute;
    right: -80px;
    z-index: 1; }
    .credits-streaks_credits-achievements__1m5jC .credits-streaks_background-decoration-bottom__3ihLb .credits-streaks_background__O93bQ {
      background-image: -webkit-gradient(linear, left top, left bottom, from(#ffe08b), to(#ffc866));
      background-image: linear-gradient(to bottom, #ffe08b 0%, #ffc866 100%);
      border-radius: 100%;
      bottom: -160px;
      height: 100%;
      position: absolute;
      width: 100%; }

.credits-streaks_card__3Lvli {
  background: #f9f9f9;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  margin: 10px 15px 20px;
  padding: 0;
  position: relative; }

.credits-streaks_search__LDJ__ form {
  padding: 26px 15px 40px; }

.credits-streaks_search__LDJ__ label {
  font-size: 16px; }

.credits-streaks_search__LDJ__ [class*="validated-select_select"] > button {
  color: #222026;
  min-height: 20px; }

.credits-streaks_search__LDJ__ [class*="box"] > svg {
  display: none; }

.credits-streaks_streaks-title__lClo2 {
  display: inline-flex;
  margin: 10px auto 14px;
  padding: 0 14px;
  position: relative; }
  .credits-streaks_streaks-title__lClo2 .credits-streaks_icon__29vT3 > svg {
    height: 120px;
    margin-right: 12px;
    width: 120px; }
    @media (max-width: 1024px) {
      .credits-streaks_streaks-title__lClo2 .credits-streaks_icon__29vT3 > svg {
        height: 100px;
        margin-right: 0;
        width: 100px; } }
  .credits-streaks_streaks-title__lClo2 .credits-streaks_title__4rav_ {
    color: #55556f;
    font-family: Questrial, sans-serif;
    font-size: 22px;
    margin-bottom: 15px;
    align-items: center;
    color: #014660;
    display: flex;
    font-size: 36px;
    text-transform: uppercase; }
    @media (max-width: 1024px) {
      .credits-streaks_streaks-title__lClo2 .credits-streaks_title__4rav_ {
        font-size: 26px; } }

.credits-streaks_streaks__3yWNm {
  display: flex;
  position: relative; }

.credits-streaks_streak-counter__FC83s {
  flex: 1 1; }
  .credits-streaks_streak-counter__FC83s .credits-streaks_label__2DlxF {
    color: #222026;
    font-size: 16px;
    margin: 10px 14px 0;
    text-align: center; }
    @media (max-width: 1024px) {
      .credits-streaks_streak-counter__FC83s .credits-streaks_label__2DlxF {
        align-items: center;
        display: flex;
        height: 32px; } }

.credits-streaks_content__20nPJ {
  background: #fff;
  border-top: 16px solid #5bdbbd;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  margin: 14px;
  padding: 10px 20px;
  position: relative; }

.credits-streaks_labels__9EyWG {
  color: #222026;
  margin: -30px 14px 0;
  position: relative;
  text-align: right; }
  .credits-streaks_labels__9EyWG > span {
    min-width: 80px;
    padding: 0 10px; }

.credits-streaks_line__2iJAU {
  align-items: center;
  color: #5bdbbd;
  display: flex;
  font-size: 22px;
  padding: 10px 0; }
  .credits-streaks_line__2iJAU > div {
    min-width: 80px;
    padding: 0 10px;
    text-align: right; }
  .credits-streaks_line__2iJAU > div:nth-of-type(1) {
    color: #55556f;
    flex: 1 1;
    text-align: left; }

.credits-streaks_value__1p8kW {
  color: #5bdbbd;
  font-size: 36px;
  margin: 20px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.credits-streaks_description__3jWm- {
  color: #222026;
  font-size: 16px;
  line-height: 24px;
  margin: 14px 14px 80px; }

.credits-streaks_footer-section__2bCuN {
  margin-top: auto; }

.credits-streaks_footer__DVE7u {
  background: #fff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  margin: auto 14px 20px;
  padding: 20px;
  position: relative;
  z-index: 2; }
  @media (max-width: 1024px) {
    .credits-streaks_footer__DVE7u {
      padding: 10px; } }
  .credits-streaks_footer__DVE7u .credits-streaks_icon-person__2jooZ {
    margin: -85px 12px -20px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    @media (max-width: 1024px) {
      .credits-streaks_footer__DVE7u .credits-streaks_icon-person__2jooZ {
        margin: -36px 10px -10px -4px; } }
    .credits-streaks_footer__DVE7u .credits-streaks_icon-person__2jooZ img {
      height: 165px; }
      @media (max-width: 1024px) {
        .credits-streaks_footer__DVE7u .credits-streaks_icon-person__2jooZ img {
          height: 130px; } }
  .credits-streaks_footer__DVE7u .credits-streaks_label__2DlxF {
    color: #222026;
    display: block;
    font-size: 18px;
    margin-bottom: 10px; }
  .credits-streaks_footer__DVE7u .credits-streaks_quote__SvIjm {
    color: #5bdbbd;
    font-size: 26px; }
    @media (max-width: 1024px) {
      .credits-streaks_footer__DVE7u .credits-streaks_quote__SvIjm {
        font-size: 22px; } }

.sidebar_white__3p0fW {
  color: #f9f9f9; }

.sidebar_black__2QVBD {
  color: #000; }

.sidebar_deep-teal__3fA9n {
  color: #014660; }

.sidebar_greyish-teal__3NVt0 {
  color: #5bdbbd; }

.sidebar_very-light-grey__DI1G9 {
  color: #b9b9b9; }

.sidebar_warm-grey__3tikl {
  color: #55556f; }

.sidebar_simple-grey__1NzFy {
  color: #d4d4d8; }

.sidebar_dark-grey__1WnFY {
  color: #222026; }

.sidebar_flat-white__1xjQy {
  color: #fff; }

.sidebar_pastel-red__3IPJI {
  color: #e55e50; }

.sidebar_white-bg__oXyv1 {
  background-color: #f9f9f9; }

.sidebar_deep-teal-bg__1nSWf {
  background-color: #014660;
  color: #fff; }

.sidebar_greyish-teal-bg__FR3f5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.sidebar_very-light-grey-bg__2zeCQ {
  background-color: #b9b9b9;
  color: #222026; }

.sidebar_warm-grey-bg__3cXgS {
  background-color: #55556f;
  color: #f9f9f9; }

.sidebar_simple-grey-bg__27I_i {
  background-color: #d4d4d8;
  color: #222026; }

.sidebar_dark-grey-bg__36PGP {
  background-color: #222026;
  color: #fff; }

.sidebar_flat-white-bg__3ZpZb {
  background-color: #fff;
  color: #014660; }

.sidebar_pastel-red-bg__3dynf {
  background-color: #e55e50;
  color: #f9f9f9; }

.sidebar_white__3p0fW {
  color: #f9f9f9; }

.sidebar_black__2QVBD {
  color: #000; }

.sidebar_deep-teal__3fA9n {
  color: #014660; }

.sidebar_greyish-teal__3NVt0 {
  color: #5bdbbd; }

.sidebar_very-light-grey__DI1G9 {
  color: #b9b9b9; }

.sidebar_warm-grey__3tikl {
  color: #55556f; }

.sidebar_simple-grey__1NzFy {
  color: #d4d4d8; }

.sidebar_dark-grey__1WnFY {
  color: #222026; }

.sidebar_flat-white__1xjQy {
  color: #fff; }

.sidebar_pastel-red__3IPJI {
  color: #e55e50; }

.sidebar_white-bg__oXyv1 {
  background-color: #f9f9f9; }

.sidebar_deep-teal-bg__1nSWf {
  background-color: #014660;
  color: #fff; }

.sidebar_greyish-teal-bg__FR3f5 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.sidebar_very-light-grey-bg__2zeCQ {
  background-color: #b9b9b9;
  color: #222026; }

.sidebar_warm-grey-bg__3cXgS {
  background-color: #55556f;
  color: #f9f9f9; }

.sidebar_simple-grey-bg__27I_i {
  background-color: #d4d4d8;
  color: #222026; }

.sidebar_dark-grey-bg__36PGP {
  background-color: #222026;
  color: #fff; }

.sidebar_flat-white-bg__3ZpZb {
  background-color: #fff;
  color: #014660; }

.sidebar_pastel-red-bg__3dynf {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.sidebar_wrapper__1tX3a {
  background-color: #f9f9f9;
  border-right: 1px solid #d4d4d8;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%; }

.sidebar_header__27t-t {
  display: flex;
  justify-content: space-between; }
  .sidebar_header__27t-t h1 {
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin: 30px 20px 7px; }
  .sidebar_header__27t-t button {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 10px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 15px;
    padding: 0;
    width: 30px; }
  .sidebar_header__27t-t button:hover,
  .sidebar_header__27t-t button:focus {
    background-color: #b9b9c0; }
  .sidebar_header__27t-t button > svg {
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px; }
  @media (min-width: 1025px) {
    .sidebar_header__27t-t {
      display: none; } }

.sidebar_collective__8hwKH {
  cursor: pointer;
  display: flex;
  margin: 20px auto 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .sidebar_collective__8hwKH img {
    height: 65px;
    width: 65px; }
  @media (max-width: 1024px) {
    .sidebar_collective__8hwKH {
      align-items: center;
      border-radius: 20px;
      margin: 5px 10px;
      padding: 10px; }
      .sidebar_collective__8hwKH:hover, .sidebar_collective__8hwKH:focus {
        background-color: #b9b9c0; } }

.sidebar_collective_name__ai5yR {
  display: none; }
  @media (max-width: 1024px) {
    .sidebar_collective_name__ai5yR {
      display: block;
      font-family: "Questrial", sans-serif;
      font-size: 18px;
      margin-left: 20px; } }
  .sidebar_collective_name__ai5yR.sidebar_active_name__2M48Z {
    color: #5bdbbd; }
  .sidebar_collective_name__ai5yR.sidebar_inactive_name__25lcD {
    color: #014660; }

.sidebar_container__OYtuo {
  height: 100%;
  position: relative;
  width: 100%; }

.sidebar_sidebar__1Z-jW {
  background-color: #d4d4d8;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 80%;
  will-change: left; }
  .sidebar_sidebar__1Z-jW.sidebar_opened__396LZ {
    -webkit-animation: sidebar_open__2uFFw 0.6s;
            animation: sidebar_open__2uFFw 0.6s; }
  .sidebar_sidebar__1Z-jW.sidebar_closed__mTwC_ {
    -webkit-animation: sidebar_close__3Zawh 0.6s;
            animation: sidebar_close__3Zawh 0.6s; }
  .sidebar_sidebar__1Z-jW > h3 {
    color: #55556f;
    font-family: "Questrial", sans-serif;
    font-size: 26px;
    padding: 10px; }
  .sidebar_sidebar__1Z-jW > div {
    align-items: center;
    border-radius: 40px;
    color: #014660;
    cursor: pointer;
    display: flex;
    margin: 10px 0;
    padding: 10px; }
    .sidebar_sidebar__1Z-jW > div.sidebar_active__Rh3Kg {
      background-color: #5bdbbd;
      color: #fff; }
    .sidebar_sidebar__1Z-jW > div img {
      max-width: 40px; }
    .sidebar_sidebar__1Z-jW > div > span {
      margin-left: 10px; }

@-webkit-keyframes sidebar_open__2uFFw {
  from {
    left: -80%; }
  to {
    left: 0%; } }

@keyframes sidebar_open__2uFFw {
  from {
    left: -80%; }
  to {
    left: 0%; } }

@-webkit-keyframes sidebar_close__3Zawh {
  from {
    left: 0%; }
  to {
    left: -80%; } }

@keyframes sidebar_close__3Zawh {
  from {
    left: 0%; }
  to {
    left: -80%; } }

.not-assigned_white__1ljD6 {
  color: #f9f9f9; }

.not-assigned_black__1KGe7 {
  color: #000; }

.not-assigned_deep-teal__3SZkS {
  color: #014660; }

.not-assigned_greyish-teal__1gkw_ {
  color: #5bdbbd; }

.not-assigned_very-light-grey__1SDsy {
  color: #b9b9b9; }

.not-assigned_warm-grey__2uqpI {
  color: #55556f; }

.not-assigned_simple-grey__26IDu {
  color: #d4d4d8; }

.not-assigned_dark-grey__dSlRn {
  color: #222026; }

.not-assigned_flat-white__3-pRs {
  color: #fff; }

.not-assigned_pastel-red__f5ERH {
  color: #e55e50; }

.not-assigned_white-bg__1I3F2 {
  background-color: #f9f9f9; }

.not-assigned_deep-teal-bg__PYFN9 {
  background-color: #014660;
  color: #fff; }

.not-assigned_greyish-teal-bg__Trn6m {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.not-assigned_very-light-grey-bg__3rWZz {
  background-color: #b9b9b9;
  color: #222026; }

.not-assigned_warm-grey-bg__2GZRi {
  background-color: #55556f;
  color: #f9f9f9; }

.not-assigned_simple-grey-bg__30XKC {
  background-color: #d4d4d8;
  color: #222026; }

.not-assigned_dark-grey-bg__2Yyo3 {
  background-color: #222026;
  color: #fff; }

.not-assigned_flat-white-bg__2PyR0 {
  background-color: #fff;
  color: #014660; }

.not-assigned_pastel-red-bg__1ykLo {
  background-color: #e55e50;
  color: #f9f9f9; }

.not-assigned_wrapper__3Yis0 {
  align-items: center;
  background: -webkit-gradient(linear, left top, right top, from(#026684), color-stop(35%, #014660));
  background: linear-gradient(90deg, #026684 0%, #014660 35%);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }

.not-assigned_collective__235ri {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 10px;
  display: flex;
  margin: 5px 0 0;
  padding: 10px;
  width: 100%; }
  .not-assigned_collective__235ri div {
    color: #014660;
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin-left: 10px; }
  .not-assigned_collective__235ri.not-assigned_active__2E6lB {
    background-color: #5bdbbd; }
  .not-assigned_collective__235ri:focus {
    background-color: #ececec; }
  .not-assigned_collective__235ri.not-assigned_active__2E6lB:focus {
    background-color: #46d6b5; }
  .not-assigned_collective__235ri:hover {
    background-color: #ececec; }
  .not-assigned_collective__235ri.not-assigned_active__2E6lB:hover {
    background-color: #46d6b5; }

.not-assigned_collectives_list__2ni0Q {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px; }
  .not-assigned_collectives_list__2ni0Q h1 {
    color: #5bdbbd;
    font-family: "Questrial", sans-serif;
    font-size: 28px; }
  .not-assigned_collectives_list__2ni0Q hr {
    color: #014660; }

.not-assigned_loading__287gc {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center; }

.dash_white__IH459 {
  color: #f9f9f9; }

.dash_black__1-Kzt {
  color: #000; }

.dash_deep-teal__xh5bn {
  color: #014660; }

.dash_greyish-teal__2tM-U {
  color: #5bdbbd; }

.dash_very-light-grey__2nJWE {
  color: #b9b9b9; }

.dash_warm-grey__35Y8v {
  color: #55556f; }

.dash_simple-grey__oyNVc {
  color: #d4d4d8; }

.dash_dark-grey__3ZoAQ {
  color: #222026; }

.dash_flat-white__3t-bx {
  color: #fff; }

.dash_pastel-red__FI0ST {
  color: #e55e50; }

.dash_white-bg__ZKJSV {
  background-color: #f9f9f9; }

.dash_deep-teal-bg__2lN9S {
  background-color: #014660;
  color: #fff; }

.dash_greyish-teal-bg__3S6mV {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash_very-light-grey-bg__37i7O {
  background-color: #b9b9b9;
  color: #222026; }

.dash_warm-grey-bg__ohK5b {
  background-color: #55556f;
  color: #f9f9f9; }

.dash_simple-grey-bg__2Vy_Z {
  background-color: #d4d4d8;
  color: #222026; }

.dash_dark-grey-bg__2PaJo {
  background-color: #222026;
  color: #fff; }

.dash_flat-white-bg__1HHjJ {
  background-color: #fff;
  color: #014660; }

.dash_pastel-red-bg__3S0TA {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash_wrapper__Vc5ts {
  background: #f9f9f9;
  flex: 1 1;
  padding: 2em 1em 3em; }
  .dash_wrapper__Vc5ts h1 {
    font-family: "Questrial", sans-serif;
    font-size: 24px;
    margin-bottom: 1.5em; }
  .dash_wrapper__Vc5ts p {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    line-height: 1.35em;
    margin-bottom: 1em; }
  @media (min-width: 1025px) {
    .dash_wrapper__Vc5ts {
      margin: 0 auto;
      max-width: 1300px;
      padding: 2em; } }

.dash_grid__2Uk-N {
  display: inline-grid;
  grid-row-gap: 1em;
  margin-bottom: 2em; }
  @media (min-width: 1025px) {
    .dash_grid__2Uk-N {
      display: grid;
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      grid-template-columns: 1fr 1fr 1fr; } }
  .dash_grid__2Uk-N > * {
    display: flex; }

w-dash-tracker-module {
  min-width: 0; }
  @media (min-width: 1025px) {
    w-dash-tracker-module {
      grid-column: span 2; } }
  w-dash-tracker-module:nth-child(3n+3) {
    grid-column: span 1; }

.dash_avatars__3fIPC {
  position: absolute;
  right: 10px;
  top: -30px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }
  .dash_avatars__3fIPC > div:last-of-type {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    left: 40px;
    position: absolute;
    top: 0; }

.dash_actionWithButton__3OFCk {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  margin-bottom: 20px; }
  .dash_actionWithButton__3OFCk:last-of-type {
    margin-bottom: 0; }
  .dash_actionWithButton__3OFCk p {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    flex: 1 1;
    margin-bottom: 0; }
  .dash_actionWithButton__3OFCk button {
    margin-left: 10px;
    width: 100px; }

.dash_done__ZCy0P {
  background: #5bdbbd;
  border-radius: 4px;
  color: #014660;
  margin-left: 10px;
  margin-top: 10px;
  max-width: 100px;
  padding: 0.4em 0.6em;
  text-align: center; }
  @media (min-width: 1025px) {
    .dash_done__ZCy0P {
      margin-top: 0; } }

.card_white__28ZF2 {
  color: #f9f9f9; }

.card_black__13Y5p {
  color: #000; }

.card_deep-teal__-o7if {
  color: #014660; }

.card_greyish-teal__1qvjj {
  color: #5bdbbd; }

.card_very-light-grey__1PSqI {
  color: #b9b9b9; }

.card_warm-grey__1XxZj {
  color: #55556f; }

.card_simple-grey__244nQ {
  color: #d4d4d8; }

.card_dark-grey__Oc8VX {
  color: #222026; }

.card_flat-white__2ZzDh {
  color: #fff; }

.card_pastel-red__34L7G {
  color: #e55e50; }

.card_white-bg__1cVZ2 {
  background-color: #f9f9f9; }

.card_deep-teal-bg__2fTZZ {
  background-color: #014660;
  color: #fff; }

.card_greyish-teal-bg__1ZF-_ {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.card_very-light-grey-bg__2AmV- {
  background-color: #b9b9b9;
  color: #222026; }

.card_warm-grey-bg__ly_B3 {
  background-color: #55556f;
  color: #f9f9f9; }

.card_simple-grey-bg__2pev3 {
  background-color: #d4d4d8;
  color: #222026; }

.card_dark-grey-bg__2MeS4 {
  background-color: #222026;
  color: #fff; }

.card_flat-white-bg__acddZ {
  background-color: #fff;
  color: #014660; }

.card_pastel-red-bg__2iXXu {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.card_card__nN2t4 {
  background: #fff;
  border: 1px solid #d4d4d8;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  /* FOR EDGE */ }

.card_header__1eWdS {
  border-bottom: 1px solid #d4d4d8;
  display: flex;
  justify-content: space-between; }
  .card_header__1eWdS .card_label__3M2jI {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    display: block;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding: 0.7em 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap; }

.card_header-select__FXlT7, .card_header-select-full__27xiI {
  color: #014660;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  -webkit-appearance: menulist;
  background: none;
  border: 0;
  border-left: 1px solid #d4d4d8;
  cursor: pointer;
  margin: 0 0.5em;
  padding: 0.5em;
  text-overflow: ellipsis; }

.card_header-select-full__27xiI {
  border-left: 0;
  font-weight: bold;
  width: 100%; }

.card_content__2sm9C {
  display: flex;
  flex: 1 1;
  flex-flow: column;
  padding: 1.2em; }
  .card_content__2sm9C strong {
    font-weight: bold; }

.card_list__1mM3O {
  flex: 1 1; }
  .card_list__1mM3O .card_item__FmLvH {
    align-items: center;
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 0.6em 0; }
    .card_list__1mM3O .card_item__FmLvH:first-of-type {
      border-top: 0; }
    .card_list__1mM3O .card_item__FmLvH p,
    .card_list__1mM3O .card_item__FmLvH a {
      flex: 1 1;
      margin: 0; }
    .card_list__1mM3O .card_item__FmLvH .card_actions__3SDb2 {
      margin-left: 0.5em; }

.dash-connectivity_white__2paH3 {
  color: #f9f9f9; }

.dash-connectivity_black__2Hjpv {
  color: #000; }

.dash-connectivity_deep-teal__2dIry {
  color: #014660; }

.dash-connectivity_greyish-teal__1X28N {
  color: #5bdbbd; }

.dash-connectivity_very-light-grey__MmOu8 {
  color: #b9b9b9; }

.dash-connectivity_warm-grey__2xj_L {
  color: #55556f; }

.dash-connectivity_simple-grey__dNpH5 {
  color: #d4d4d8; }

.dash-connectivity_dark-grey__33WSO {
  color: #222026; }

.dash-connectivity_flat-white__2FVza {
  color: #fff; }

.dash-connectivity_pastel-red__1zzOk {
  color: #e55e50; }

.dash-connectivity_white-bg__3gdTx {
  background-color: #f9f9f9; }

.dash-connectivity_deep-teal-bg__1JjhS {
  background-color: #014660;
  color: #fff; }

.dash-connectivity_greyish-teal-bg__dOxsm {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-connectivity_very-light-grey-bg__3-yp6 {
  background-color: #b9b9b9;
  color: #222026; }

.dash-connectivity_warm-grey-bg__xioHw {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-connectivity_simple-grey-bg__3twM4 {
  background-color: #d4d4d8;
  color: #222026; }

.dash-connectivity_dark-grey-bg__Io3N0 {
  background-color: #222026;
  color: #fff; }

.dash-connectivity_flat-white-bg__3Q3yA {
  background-color: #fff;
  color: #014660; }

.dash-connectivity_pastel-red-bg__3Nnw6 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-connectivity_actions__1LPmb > div {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  margin-bottom: 20px; }
  .dash-connectivity_actions__1LPmb > div:last-of-type {
    margin-bottom: 0; }
  .dash-connectivity_actions__1LPmb > div p {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    flex: 1 1;
    margin-bottom: 0; }
  .dash-connectivity_actions__1LPmb > div button {
    margin-left: 10px;
    width: 100px; }

.dash-connectivity_done__2ZBoV {
  background: #5bdbbd;
  border-radius: 4px;
  color: #014660;
  margin-left: 10px;
  padding: 0.4em 0.6em;
  text-align: center;
  width: 100px; }

.dash-goals_white__LLBj3 {
  color: #f9f9f9; }

.dash-goals_black__28cSm {
  color: #000; }

.dash-goals_deep-teal__1-3sz {
  color: #014660; }

.dash-goals_greyish-teal__2XLSQ {
  color: #5bdbbd; }

.dash-goals_very-light-grey__TSSQz {
  color: #b9b9b9; }

.dash-goals_warm-grey__Iy2Pb {
  color: #55556f; }

.dash-goals_simple-grey__3OudH {
  color: #d4d4d8; }

.dash-goals_dark-grey__1POyz {
  color: #222026; }

.dash-goals_flat-white__2g60N {
  color: #fff; }

.dash-goals_pastel-red__nsD3d {
  color: #e55e50; }

.dash-goals_white-bg__1V-Jm {
  background-color: #f9f9f9; }

.dash-goals_deep-teal-bg__d1yJw {
  background-color: #014660;
  color: #fff; }

.dash-goals_greyish-teal-bg__1REY- {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-goals_very-light-grey-bg__3WVGC {
  background-color: #b9b9b9;
  color: #222026; }

.dash-goals_warm-grey-bg__3a6Iq {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-goals_simple-grey-bg__EgLUC {
  background-color: #d4d4d8;
  color: #222026; }

.dash-goals_dark-grey-bg__2holz {
  background-color: #222026;
  color: #fff; }

.dash-goals_flat-white-bg__2VVKL {
  background-color: #fff;
  color: #014660; }

.dash-goals_pastel-red-bg__13ZB9 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-goals_focus__1bWi_ {
  border-top: 1px solid #e8e8e8;
  padding: 0.4em 0; }
  .dash-goals_focus__1bWi_ h3 {
    color: #55556f;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 0.2em; }
  .dash-goals_focus__1bWi_ .dash-goals_goal__2qxD9 {
    font-size: 14px;
    margin: 0; }

.dash-goals_change-button__2dEsk {
  background: none;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0; }

.dash-goals_buttons__1keXb {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em; }
  .dash-goals_buttons__1keXb button {
    flex: 0 0 48.5%; }

.dash-goals_empty__1y-hF {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 1em;
  text-align: center; }

.dash-leaderboard_white__i68yK {
  color: #f9f9f9; }

.dash-leaderboard_black__1jZAX {
  color: #000; }

.dash-leaderboard_deep-teal__2G6fc {
  color: #014660; }

.dash-leaderboard_greyish-teal__2TLXG {
  color: #5bdbbd; }

.dash-leaderboard_very-light-grey__1wr2u {
  color: #b9b9b9; }

.dash-leaderboard_warm-grey__3OIMI {
  color: #55556f; }

.dash-leaderboard_simple-grey__1nWzZ {
  color: #d4d4d8; }

.dash-leaderboard_dark-grey__3ZAS8 {
  color: #222026; }

.dash-leaderboard_flat-white__AzRBz {
  color: #fff; }

.dash-leaderboard_pastel-red__2tuPi {
  color: #e55e50; }

.dash-leaderboard_white-bg__3cJAE {
  background-color: #f9f9f9; }

.dash-leaderboard_deep-teal-bg__KG_jM {
  background-color: #014660;
  color: #fff; }

.dash-leaderboard_greyish-teal-bg__3PxGs {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-leaderboard_very-light-grey-bg__OS6Vw {
  background-color: #b9b9b9;
  color: #222026; }

.dash-leaderboard_warm-grey-bg__2gY79 {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-leaderboard_simple-grey-bg__CvZPB {
  background-color: #d4d4d8;
  color: #222026; }

.dash-leaderboard_dark-grey-bg__332hY {
  background-color: #222026;
  color: #fff; }

.dash-leaderboard_flat-white-bg__2VlxU {
  background-color: #fff;
  color: #014660; }

.dash-leaderboard_pastel-red-bg__15f9Z {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-leaderboard_leaders__S3s8R {
  display: flex;
  flex: 1 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_leader__fPRvN {
    align-items: center;
    display: flex;
    flex: 0 0 25%;
    flex-direction: column;
    padding-top: 20px;
    text-align: center; }
    .dash-leaderboard_leaders__S3s8R .dash-leaderboard_leader__fPRvN.dash-leaderboard_first__2Hho9 {
      padding-top: 0; }
    .dash-leaderboard_leaders__S3s8R .dash-leaderboard_leader__fPRvN.dash-leaderboard_second__190Ti {
      order: -1; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_name__3yuZC {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 0.3em;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_score__33dEh {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_avatar__VyqWN {
    background: #f9f9f9;
    border-radius: 50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .dash-leaderboard_leaders__S3s8R .dash-leaderboard_avatar__VyqWN img {
      padding: 8px; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_me__-FHR6.dash-leaderboard_avatar__VyqWN {
    background: -webkit-gradient(linear, left top, left bottom, from(#5fe8c4), to(#43b395));
    background: linear-gradient(to bottom, #5fe8c4, #43b395); }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_position-badge__QgT5q {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    height: 30px;
    margin: -30px auto 10px;
    padding: 6px;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    width: 30px; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_badge-1__3lVh9 {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffe08b), to(#ffc866));
    background: linear-gradient(#ffe08b, #ffc866); }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_badge-2__1hzTg {
    background: #ddd; }
  .dash-leaderboard_leaders__S3s8R .dash-leaderboard_badge-3__2BIYK {
    background: #e19f6a; }

.dash-leaderboard_boost-button__3uUQc {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  background: #014660;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  padding: 0.5em;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s; }
  .dash-leaderboard_boost-button__3uUQc:hover {
    color: #5bdbbd; }

.dash-leaderboard_message__n4HHK {
  margin-bottom: 0 !important;
  text-align: center; }

.dash-leaderboard_empty__-jyVT {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  padding: 1em;
  text-align: center; }

.dash-match_white__pUVYz {
  color: #f9f9f9; }

.dash-match_black__1-XN1 {
  color: #000; }

.dash-match_deep-teal__UlZQ_ {
  color: #014660; }

.dash-match_greyish-teal__2MX8O {
  color: #5bdbbd; }

.dash-match_very-light-grey__3f03C {
  color: #b9b9b9; }

.dash-match_warm-grey__2JHV0 {
  color: #55556f; }

.dash-match_simple-grey__3FHA_ {
  color: #d4d4d8; }

.dash-match_dark-grey__3qfew {
  color: #222026; }

.dash-match_flat-white__2jV7Z {
  color: #fff; }

.dash-match_pastel-red__1n65M {
  color: #e55e50; }

.dash-match_white-bg__1XHvS {
  background-color: #f9f9f9; }

.dash-match_deep-teal-bg__3jrO7 {
  background-color: #014660;
  color: #fff; }

.dash-match_greyish-teal-bg__oRr5u {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-match_very-light-grey-bg__5h_dS {
  background-color: #b9b9b9;
  color: #222026; }

.dash-match_warm-grey-bg__DHDnV {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-match_simple-grey-bg__2Aqb9 {
  background-color: #d4d4d8;
  color: #222026; }

.dash-match_dark-grey-bg__2dIPW {
  background-color: #222026;
  color: #fff; }

.dash-match_flat-white-bg__3LXU_ {
  background-color: #fff;
  color: #014660; }

.dash-match_pastel-red-bg__25X-l {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-match_avatars__1twaI {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1em;
  padding-left: 20px; }
  .dash-match_avatars__1twaI > w-avatar:last-of-type {
    left: -20px;
    position: relative; }

.dash-match_active__2b44C {
  align-items: center;
  display: flex;
  flex-flow: column;
  text-align: center; }

.dash-match_buttons__2Rlkg {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .dash-match_buttons__2Rlkg button {
    flex: 0 0 48%; }
    .dash-match_buttons__2Rlkg button:last-of-type {
      flex: 0 0 100%;
      margin-top: 1em; }

.dash-match_no-match__bUlfa {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-flow: column;
  justify-content: center;
  text-align: center; }
  .dash-match_no-match__bUlfa button {
    margin-bottom: 0.5em; }

.dash-prematch_white__QDU9x {
  color: #f9f9f9; }

.dash-prematch_black__3KlLi {
  color: #000; }

.dash-prematch_deep-teal__fKAln {
  color: #014660; }

.dash-prematch_greyish-teal__9LsJz {
  color: #5bdbbd; }

.dash-prematch_very-light-grey__1I1rk {
  color: #b9b9b9; }

.dash-prematch_warm-grey__3w1Bh {
  color: #55556f; }

.dash-prematch_simple-grey__3MYPq {
  color: #d4d4d8; }

.dash-prematch_dark-grey__XDpLf {
  color: #222026; }

.dash-prematch_flat-white__22rjS {
  color: #fff; }

.dash-prematch_pastel-red__3CZVT {
  color: #e55e50; }

.dash-prematch_white-bg__33DpI {
  background-color: #f9f9f9; }

.dash-prematch_deep-teal-bg__Xrnao {
  background-color: #014660;
  color: #fff; }

.dash-prematch_greyish-teal-bg__xFD8w {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-prematch_very-light-grey-bg___v91D {
  background-color: #b9b9b9;
  color: #222026; }

.dash-prematch_warm-grey-bg__32phZ {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-prematch_simple-grey-bg__3jA3M {
  background-color: #d4d4d8;
  color: #222026; }

.dash-prematch_dark-grey-bg__3AAF- {
  background-color: #222026;
  color: #fff; }

.dash-prematch_flat-white-bg__1M3lt {
  background-color: #fff;
  color: #014660; }

.dash-prematch_pastel-red-bg__1_GtT {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-prematch_actions__3qtBk {
  flex: 1 1; }

.dash-prematch_item__23A8M {
  align-items: center;
  border-top: 1px solid #e8e8e8;
  display: flex;
  padding: 0.4em 0; }
  .dash-prematch_item__23A8M p {
    flex: 1 1;
    margin: 0; }
  .dash-prematch_item__23A8M button {
    margin-left: 0.5em; }

.dash-profile-completion_white__1LHKB {
  color: #f9f9f9; }

.dash-profile-completion_black__2JV8J {
  color: #000; }

.dash-profile-completion_deep-teal__8ZTAO {
  color: #014660; }

.dash-profile-completion_greyish-teal__3f93U {
  color: #5bdbbd; }

.dash-profile-completion_very-light-grey__oSCKo {
  color: #b9b9b9; }

.dash-profile-completion_warm-grey__2j6Ut {
  color: #55556f; }

.dash-profile-completion_simple-grey__3UukN {
  color: #d4d4d8; }

.dash-profile-completion_dark-grey__3qTNO {
  color: #222026; }

.dash-profile-completion_flat-white__keCRN {
  color: #fff; }

.dash-profile-completion_pastel-red__3PH8K {
  color: #e55e50; }

.dash-profile-completion_white-bg__l6SfI {
  background-color: #f9f9f9; }

.dash-profile-completion_deep-teal-bg__1w9i8 {
  background-color: #014660;
  color: #fff; }

.dash-profile-completion_greyish-teal-bg__1StHf {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-profile-completion_very-light-grey-bg__3yBnK {
  background-color: #b9b9b9;
  color: #222026; }

.dash-profile-completion_warm-grey-bg__2dBfl {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-profile-completion_simple-grey-bg__Zafdm {
  background-color: #d4d4d8;
  color: #222026; }

.dash-profile-completion_dark-grey-bg__2-Rj3 {
  background-color: #222026;
  color: #fff; }

.dash-profile-completion_flat-white-bg__5P4xg {
  background-color: #fff;
  color: #014660; }

.dash-profile-completion_pastel-red-bg__QKO-0 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-profile-completion_actions__3IWyn {
  flex: 1 1; }

.dash-profile-completion_item__1Qp6n {
  align-items: center;
  border-top: 1px solid #e8e8e8;
  display: flex;
  padding: 0.4em 0; }
  .dash-profile-completion_item__1Qp6n p {
    flex: 1 1;
    margin: 0; }

.dash-profile-completion_done__6qJyI {
  background: #5bdbbd;
  border-radius: 4px;
  color: #014660;
  padding: 0.4em 0.6em; }

.dash-programme_white__1UHU0 {
  color: #f9f9f9; }

.dash-programme_black__2Dog9 {
  color: #000; }

.dash-programme_deep-teal__1oSJU {
  color: #014660; }

.dash-programme_greyish-teal__1QFBZ {
  color: #5bdbbd; }

.dash-programme_very-light-grey__3pCKd {
  color: #b9b9b9; }

.dash-programme_warm-grey__1zHvJ {
  color: #55556f; }

.dash-programme_simple-grey__2Z_9_ {
  color: #d4d4d8; }

.dash-programme_dark-grey__1NYB3 {
  color: #222026; }

.dash-programme_flat-white__15oiI {
  color: #fff; }

.dash-programme_pastel-red__3O1h8 {
  color: #e55e50; }

.dash-programme_white-bg__25TcS {
  background-color: #f9f9f9; }

.dash-programme_deep-teal-bg__3KUBC {
  background-color: #014660;
  color: #fff; }

.dash-programme_greyish-teal-bg__1Go9X {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-programme_very-light-grey-bg__2EFEX {
  background-color: #b9b9b9;
  color: #222026; }

.dash-programme_warm-grey-bg__23AnB {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-programme_simple-grey-bg__2BOh7 {
  background-color: #d4d4d8;
  color: #222026; }

.dash-programme_dark-grey-bg__16Dzr {
  background-color: #222026;
  color: #fff; }

.dash-programme_flat-white-bg__jA36x {
  background-color: #fff;
  color: #014660; }

.dash-programme_pastel-red-bg__2gdWl {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-programme_cardContent__3Kk-i {
  justify-content: space-between;
  text-align: center; }
  .dash-programme_cardContent__3Kk-i .dash-programme_actions__1x6mH {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 1em; }
    .dash-programme_cardContent__3Kk-i .dash-programme_actions__1x6mH .dash-programme_dateWrapper__2uPnP {
      border: 1px solid #5bdbbd;
      border-radius: 5px;
      padding: 5px; }
    .dash-programme_cardContent__3Kk-i .dash-programme_actions__1x6mH p {
      color: #222026;
      font-family: "Inter", sans-serif;
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      flex: 1 1;
      margin-bottom: 0; }
  .dash-programme_cardContent__3Kk-i .dash-programme_buttonWrapper__27j4q {
    display: flex;
    justify-content: center; }
    .dash-programme_cardContent__3Kk-i .dash-programme_buttonWrapper__27j4q .dash-programme_button__17S2J {
      flex: 1 1; }
  .dash-programme_cardContent__3Kk-i .dash-programme_span__OLPRZ {
    cursor: pointer; }
  .dash-programme_cardContent__3Kk-i .dash-programme_border__3yAvC {
    border-top: 1px solid #e8e8e8;
    padding: 0.4em 0; }
  .dash-programme_cardContent__3Kk-i .dash-programme_flex__3gmZm {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center; }
  .dash-programme_cardContent__3Kk-i .dash-programme_done__sckIC {
    background: #5bdbbd;
    border-radius: 4px;
    color: #014660;
    margin-left: 10px;
    padding: 0.4em 0.6em;
    text-align: center;
    width: 100px; }
  .dash-programme_cardContent__3Kk-i .dash-programme_colored__uqxQi {
    color: #014660; }

.dash-resources_white__1GWPi {
  color: #f9f9f9; }

.dash-resources_black__2mHe5 {
  color: #000; }

.dash-resources_deep-teal__2bqVt {
  color: #014660; }

.dash-resources_greyish-teal__21Pzn {
  color: #5bdbbd; }

.dash-resources_very-light-grey__2lQUH {
  color: #b9b9b9; }

.dash-resources_warm-grey__2N1DL {
  color: #55556f; }

.dash-resources_simple-grey__229IG {
  color: #d4d4d8; }

.dash-resources_dark-grey__Dn4F7 {
  color: #222026; }

.dash-resources_flat-white__-jGmn {
  color: #fff; }

.dash-resources_pastel-red__1Q4j7 {
  color: #e55e50; }

.dash-resources_white-bg__34gxz {
  background-color: #f9f9f9; }

.dash-resources_deep-teal-bg__29wKZ {
  background-color: #014660;
  color: #fff; }

.dash-resources_greyish-teal-bg__25jei {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-resources_very-light-grey-bg__LrDd3 {
  background-color: #b9b9b9;
  color: #222026; }

.dash-resources_warm-grey-bg__1-_jq {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-resources_simple-grey-bg__2wGW4 {
  background-color: #d4d4d8;
  color: #222026; }

.dash-resources_dark-grey-bg__1EUOr {
  background-color: #222026;
  color: #fff; }

.dash-resources_flat-white-bg__jHWAA {
  background-color: #fff;
  color: #014660; }

.dash-resources_pastel-red-bg__1Pf6r {
  background-color: #e55e50;
  color: #f9f9f9; }

.dash-resources_underline__3zB98 {
  text-decoration: underline; }

.dash-resources_linksWrapper__gl3VG {
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 10px 0; }

.dash-resources_tiny__1r8ja {
  font-size: 9px; }

.dash-resources_cardContent__33ZDb {
  justify-content: space-between; }

.dash-resources_overflow__qeNca {
  overflow-y: auto; }

.dash-resources_header__3epRr.dash-resources_header__3epRr {
  color: #014660;
  font-weight: 700;
  margin: 0 0.5em;
  padding: 0.5em; }

.dash-session-guides_white__1jtvF {
  color: #f9f9f9; }

.dash-session-guides_black__2wZM5 {
  color: #000; }

.dash-session-guides_deep-teal__7rnKq {
  color: #014660; }

.dash-session-guides_greyish-teal__2pf3i {
  color: #5bdbbd; }

.dash-session-guides_very-light-grey__2NAdb {
  color: #b9b9b9; }

.dash-session-guides_warm-grey__3a0Ds {
  color: #55556f; }

.dash-session-guides_simple-grey__3ebhw {
  color: #d4d4d8; }

.dash-session-guides_dark-grey__17zm9 {
  color: #222026; }

.dash-session-guides_flat-white__X5w1c {
  color: #fff; }

.dash-session-guides_pastel-red__2XmXM {
  color: #e55e50; }

.dash-session-guides_white-bg__1icJY {
  background-color: #f9f9f9; }

.dash-session-guides_deep-teal-bg__2MtjO {
  background-color: #014660;
  color: #fff; }

.dash-session-guides_greyish-teal-bg__3kAJ1 {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-session-guides_very-light-grey-bg__Plb6B {
  background-color: #b9b9b9;
  color: #222026; }

.dash-session-guides_warm-grey-bg__10CkB {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-session-guides_simple-grey-bg__3u2Ql {
  background-color: #d4d4d8;
  color: #222026; }

.dash-session-guides_dark-grey-bg__21-aH {
  background-color: #222026;
  color: #fff; }

.dash-session-guides_flat-white-bg__1XJzI {
  background-color: #fff;
  color: #014660; }

.dash-session-guides_pastel-red-bg__3AWkN {
  background-color: #e55e50;
  color: #f9f9f9; }

.dash-session-guides_underline__HWQKB {
  text-decoration: underline; }

.dash-session-guides_linksWrapper__2darQ {
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 10px 0; }

.dash-session-guides_committed__1ARCI.dash-session-guides_committed__1ARCI {
  margin: 0; }

.dash-session-guides_cardContent__2YNhI {
  justify-content: space-between; }

.dash-session-guides_header__1wj3K.dash-session-guides_header__1wj3K {
  color: #014660;
  font-weight: 700;
  margin: 0 0.5em;
  padding: 0.5em; }

.dash-stats_white__31RCk {
  color: #f9f9f9; }

.dash-stats_black__15MET {
  color: #000; }

.dash-stats_deep-teal__1kHqH {
  color: #014660; }

.dash-stats_greyish-teal__Me1N3 {
  color: #5bdbbd; }

.dash-stats_very-light-grey__1ibRy {
  color: #b9b9b9; }

.dash-stats_warm-grey__2dU3Y {
  color: #55556f; }

.dash-stats_simple-grey__3PB4p {
  color: #d4d4d8; }

.dash-stats_dark-grey__2gQvi {
  color: #222026; }

.dash-stats_flat-white__2MgLU {
  color: #fff; }

.dash-stats_pastel-red__NrJVB {
  color: #e55e50; }

.dash-stats_white-bg__2qRkk {
  background-color: #f9f9f9; }

.dash-stats_deep-teal-bg__mzgb8 {
  background-color: #014660;
  color: #fff; }

.dash-stats_greyish-teal-bg__3EMCu {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-stats_very-light-grey-bg__1IYsv {
  background-color: #b9b9b9;
  color: #222026; }

.dash-stats_warm-grey-bg__1HcWE {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-stats_simple-grey-bg__1T5yO {
  background-color: #d4d4d8;
  color: #222026; }

.dash-stats_dark-grey-bg__3TH12 {
  background-color: #222026;
  color: #fff; }

.dash-stats_flat-white-bg__2-Xjn {
  background-color: #fff;
  color: #014660; }

.dash-stats_pastel-red-bg__3uOEm {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-stats_stats-list__3NKd8 {
  display: flex;
  flex-flow: column; }
  .dash-stats_stats-list__3NKd8 li {
    color: #222026;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0.5em;
    padding: 0.5em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .dash-stats_stats-list__3NKd8 li:last-of-type {
      margin-bottom: 0; }

.dash-stats_header__2o4zO {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: 1em; }
  .dash-stats_header__2o4zO w-avatar {
    margin: 0 1.5em; }
  .dash-stats_header__2o4zO .dash-stats_highlight__3WzET p {
    margin: 0;
    text-align: center; }
    .dash-stats_header__2o4zO .dash-stats_highlight__3WzET p:last-of-type {
      font-size: 19px;
      margin-top: 0.3em; }

.dash-stats_empty__2QKyC {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  padding: 1em;
  text-align: center; }

.dash-tasks_white__3mH0F {
  color: #f9f9f9; }

.dash-tasks_black__3owtm {
  color: #000; }

.dash-tasks_deep-teal__2-Q19 {
  color: #014660; }

.dash-tasks_greyish-teal__3NeFn {
  color: #5bdbbd; }

.dash-tasks_very-light-grey__1SkvW {
  color: #b9b9b9; }

.dash-tasks_warm-grey__32Xnf {
  color: #55556f; }

.dash-tasks_simple-grey__2_UjK {
  color: #d4d4d8; }

.dash-tasks_dark-grey__3iG6e {
  color: #222026; }

.dash-tasks_flat-white__2kfaQ {
  color: #fff; }

.dash-tasks_pastel-red__3ez_g {
  color: #e55e50; }

.dash-tasks_white-bg__2v-cc {
  background-color: #f9f9f9; }

.dash-tasks_deep-teal-bg__3d1oe {
  background-color: #014660;
  color: #fff; }

.dash-tasks_greyish-teal-bg__7bdCx {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-tasks_very-light-grey-bg__3ERM8 {
  background-color: #b9b9b9;
  color: #222026; }

.dash-tasks_warm-grey-bg__1aHP3 {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-tasks_simple-grey-bg__2d_K7 {
  background-color: #d4d4d8;
  color: #222026; }

.dash-tasks_dark-grey-bg__3AV3S {
  background-color: #222026;
  color: #fff; }

.dash-tasks_flat-white-bg__3p3Tn {
  background-color: #fff;
  color: #014660; }

.dash-tasks_pastel-red-bg__1TRSF {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-tasks_empty__3nCvk {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-flow: column wrap;
  justify-content: center;
  padding: 1em;
  text-align: center; }

.dash-tasks_emoji__2dNig {
  display: block;
  font-size: 4em;
  margin-bottom: 0.5em; }

.dash-themes_white__2kg3d {
  color: #f9f9f9; }

.dash-themes_black__3uWyZ {
  color: #000; }

.dash-themes_deep-teal__2UvvD {
  color: #014660; }

.dash-themes_greyish-teal__3JPd- {
  color: #5bdbbd; }

.dash-themes_very-light-grey__3e8DS {
  color: #b9b9b9; }

.dash-themes_warm-grey__39bAr {
  color: #55556f; }

.dash-themes_simple-grey__3jeca {
  color: #d4d4d8; }

.dash-themes_dark-grey__3Kp86 {
  color: #222026; }

.dash-themes_flat-white__1vui0 {
  color: #fff; }

.dash-themes_pastel-red__uKxEa {
  color: #e55e50; }

.dash-themes_white-bg__zGg5I {
  background-color: #f9f9f9; }

.dash-themes_deep-teal-bg__3lSWg {
  background-color: #014660;
  color: #fff; }

.dash-themes_greyish-teal-bg__2Y9jd {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-themes_very-light-grey-bg__z2f4r {
  background-color: #b9b9b9;
  color: #222026; }

.dash-themes_warm-grey-bg__2RJvZ {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-themes_simple-grey-bg__3AxUg {
  background-color: #d4d4d8;
  color: #222026; }

.dash-themes_dark-grey-bg__2aOEx {
  background-color: #222026;
  color: #fff; }

.dash-themes_flat-white-bg__pJjGj {
  background-color: #fff;
  color: #014660; }

.dash-themes_pastel-red-bg__2w-cC {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-themes_rating__3-eIw {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  background: #f9f9f9;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  padding: 0.5em 0.5em 0.5em 1em; }
  .dash-themes_rating__3-eIw span {
    flex: 1 1; }
  .dash-themes_rating__3-eIw .dash-themes_options__2CpI4 {
    background: #fff;
    border: 1px solid #d4d4d8;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden; }
    .dash-themes_rating__3-eIw .dash-themes_options__2CpI4 button {
      background: none;
      border: 0;
      padding: 0.25em 1em;
      -webkit-transition: all 300ms ease-in-out 0s;
      transition: all 300ms ease-in-out 0s; }
      .dash-themes_rating__3-eIw .dash-themes_options__2CpI4 button:last-of-type {
        border-left: 1px solid #d4d4d8; }
      .dash-themes_rating__3-eIw .dash-themes_options__2CpI4 button:hover {
        background: #5bdbbd; }
      .dash-themes_rating__3-eIw .dash-themes_options__2CpI4 button svg {
        width: 22px; }

.dash-tracker_white__3lpVn {
  color: #f9f9f9; }

.dash-tracker_black__YGvDc {
  color: #000; }

.dash-tracker_deep-teal__JY7hZ {
  color: #014660; }

.dash-tracker_greyish-teal__23ciG {
  color: #5bdbbd; }

.dash-tracker_very-light-grey__se0d9 {
  color: #b9b9b9; }

.dash-tracker_warm-grey__1RFzL {
  color: #55556f; }

.dash-tracker_simple-grey__Y6UJU {
  color: #d4d4d8; }

.dash-tracker_dark-grey__1Kz8P {
  color: #222026; }

.dash-tracker_flat-white__2w_I_ {
  color: #fff; }

.dash-tracker_pastel-red__39L5y {
  color: #e55e50; }

.dash-tracker_white-bg__oqiTo {
  background-color: #f9f9f9; }

.dash-tracker_deep-teal-bg__ugVQx {
  background-color: #014660;
  color: #fff; }

.dash-tracker_greyish-teal-bg__3l11r {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-tracker_very-light-grey-bg__2DoRg {
  background-color: #b9b9b9;
  color: #222026; }

.dash-tracker_warm-grey-bg__3ovCQ {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-tracker_simple-grey-bg__PXPYa {
  background-color: #d4d4d8;
  color: #222026; }

.dash-tracker_dark-grey-bg__2Ayha {
  background-color: #222026;
  color: #fff; }

.dash-tracker_flat-white-bg__LK8q0 {
  background-color: #fff;
  color: #014660; }

.dash-tracker_pastel-red-bg__3Lc54 {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-tracker_wrapper__1NPcZ {
  background: #5bdbbd;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex: 1 1;
  flex-flow: column wrap;
  margin: -1px; }

.dash-tracker_switcher__Tbapv {
  align-self: flex-end;
  display: flex;
  margin: 1.2em 1.2em 0 0; }
  .dash-tracker_switcher__Tbapv button {
    color: #014660;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    background: transparent;
    border: 1px solid #014660;
    border-radius: 0;
    padding: 0.3em 0.5em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .dash-tracker_switcher__Tbapv button:first-of-type {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px; }
    .dash-tracker_switcher__Tbapv button:last-of-type {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px; }
  .dash-tracker_switcher__Tbapv .dash-tracker_active__29c2y {
    background: #014660;
    border-color: transparent;
    color: #fff;
    font-weight: bold; }

.dash-tracker_tracker__og3OA {
  color: #222026;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  cursor: -webkit-grab;
  cursor: grab;
  display: flex;
  flex: 1 1;
  flex-flow: row nowrap;
  overflow-x: hidden;
  padding: 1em;
  width: 100%;
  /* FOR EDGE */ }
  .dash-tracker_tracker__og3OA::-webkit-scrollbar {
    max-height: 6px;
    min-height: 6px;
    width: 1px; }
  .dash-tracker_tracker__og3OA .dash-tracker_period__C1bLY {
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    min-height: 150px; }
  .dash-tracker_tracker__og3OA .dash-tracker_actions__2NGBB {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-flow: row wrap;
    justify-content: space-evenly;
    position: relative; }
    .dash-tracker_tracker__og3OA .dash-tracker_actions__2NGBB::after {
      border-right: 1px dashed rgba(255, 255, 255, 0.6);
      bottom: 0;
      content: "";
      position: absolute;
      right: -10px;
      top: 0; }
  .dash-tracker_tracker__og3OA .dash-tracker_promise__3qcBg {
    border-bottom: 2px solid #55556f;
    margin-bottom: 0.5em;
    padding: 2em 0.5em 0.5em;
    position: relative; }
    .dash-tracker_tracker__og3OA .dash-tracker_promise__3qcBg::after {
      background: #55556f;
      border: 5px solid #5bdbbd;
      border-radius: 50%;
      bottom: -10px;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      width: 10px; }
    .dash-tracker_tracker__og3OA .dash-tracker_promise__3qcBg p {
      color: #55556f;
      font-size: 13px;
      font-weight: bold;
      margin: 0;
      position: relative; }
  .dash-tracker_tracker__og3OA h4 {
    padding: 0 0.5em; }

.dash-tracker_bubble__1X_GY {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 0 0 42%;
  font-size: 13px;
  margin: 1em 0 0.5em;
  padding: 0.3em 0.5em;
  position: relative;
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg); }
  .dash-tracker_bubble__1X_GY:nth-of-type(even) {
    margin-top: 1.5em;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  .dash-tracker_bubble__1X_GY .dash-tracker_icon__2okbQ {
    align-items: center;
    display: flex;
    flex: 1 0 25px;
    justify-content: flex-end; }
    .dash-tracker_bubble__1X_GY .dash-tracker_icon__2okbQ svg {
      width: 20px; }
  .dash-tracker_bubble__1X_GY .dash-tracker_points__3Oue0 {
    background: #dda700;
    border: 2px solid #fff;
    border-radius: 30px;
    font-size: 11px;
    font-weight: bold;
    padding: 0.15em 0.3em;
    position: absolute;
    right: -10px;
    top: -10px; }

.dash-tracker_empty__29MWr {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding: 1em; }

.dash-upcoming-events_white__2zDR7 {
  color: #f9f9f9; }

.dash-upcoming-events_black__1grxu {
  color: #000; }

.dash-upcoming-events_deep-teal__nY_mc {
  color: #014660; }

.dash-upcoming-events_greyish-teal__3p5C_ {
  color: #5bdbbd; }

.dash-upcoming-events_very-light-grey__QleiN {
  color: #b9b9b9; }

.dash-upcoming-events_warm-grey__10vvR {
  color: #55556f; }

.dash-upcoming-events_simple-grey__3-hou {
  color: #d4d4d8; }

.dash-upcoming-events_dark-grey__2o-n- {
  color: #222026; }

.dash-upcoming-events_flat-white__2ObQF {
  color: #fff; }

.dash-upcoming-events_pastel-red__3i3dw {
  color: #e55e50; }

.dash-upcoming-events_white-bg__2KTQP {
  background-color: #f9f9f9; }

.dash-upcoming-events_deep-teal-bg__1sves {
  background-color: #014660;
  color: #fff; }

.dash-upcoming-events_greyish-teal-bg__18Eqk {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-upcoming-events_very-light-grey-bg__2uJmw {
  background-color: #b9b9b9;
  color: #222026; }

.dash-upcoming-events_warm-grey-bg__3ZKUL {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-upcoming-events_simple-grey-bg__1oGaR {
  background-color: #d4d4d8;
  color: #222026; }

.dash-upcoming-events_dark-grey-bg__2dLL4 {
  background-color: #222026;
  color: #fff; }

.dash-upcoming-events_flat-white-bg__j23PP {
  background-color: #fff;
  color: #014660; }

.dash-upcoming-events_pastel-red-bg__3arxV {
  background-color: #e55e50;
  color: #f9f9f9; }

/**
 * ----------------------------------------
 * animation Became bordered on focus
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * The default font style
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Generate fluid size property
 * ----------------------------------------
 */
.dash-upcoming-events_events__3UOoJ {
  flex: 1 1; }

.dash-upcoming-events_event-list__rsHc1 {
  margin-bottom: 5px; }
  .dash-upcoming-events_event-list__rsHc1:last-of-type {
    margin-bottom: 0; }

.dash-upcoming-events_event__uO21v {
  display: flex;
  flex-flow: column;
  padding: 0.2em 0; }
  .dash-upcoming-events_event__uO21v p {
    margin-bottom: 0; }
  .dash-upcoming-events_event__uO21v .dash-upcoming-events_date__33toA {
    color: #55556f;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; }
  .dash-upcoming-events_event__uO21v .dash-upcoming-events_now__150Yb span:first-child {
    color: #e55e50;
    font-weight: 600; }
    .dash-upcoming-events_event__uO21v .dash-upcoming-events_now__150Yb span:first-child::before {
      background: #e55e50;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 11px;
      margin-right: 5px;
      width: 11px; }
  .dash-upcoming-events_event__uO21v .dash-upcoming-events_detail__3O9ex {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .dash-upcoming-events_event__uO21v .dash-upcoming-events_detail__3O9ex .dash-upcoming-events_title__1B0Zj {
      font-weight: 600;
      margin-right: 10px; }

.dash-upcoming-events_no-events__1lOmo {
  color: #55556f;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  align-items: center;
  display: flex;
  flex: 1 1;
  padding: 1em;
  text-align: center; }

.dash-workbook_white__3lP6n {
  color: #f9f9f9; }

.dash-workbook_black__33aMF {
  color: #000; }

.dash-workbook_deep-teal__3WAhH {
  color: #014660; }

.dash-workbook_greyish-teal__1mLB1 {
  color: #5bdbbd; }

.dash-workbook_very-light-grey__Gr4QV {
  color: #b9b9b9; }

.dash-workbook_warm-grey__s7ETA {
  color: #55556f; }

.dash-workbook_simple-grey__3m_9M {
  color: #d4d4d8; }

.dash-workbook_dark-grey__lnD1A {
  color: #222026; }

.dash-workbook_flat-white__3Yqqi {
  color: #fff; }

.dash-workbook_pastel-red__3GsfP {
  color: #e55e50; }

.dash-workbook_white-bg__lATLw {
  background-color: #f9f9f9; }

.dash-workbook_deep-teal-bg__Ef_jj {
  background-color: #014660;
  color: #fff; }

.dash-workbook_greyish-teal-bg__DZKPu {
  background-color: #5bdbbd;
  color: #f9f9f9; }

.dash-workbook_very-light-grey-bg__1bs78 {
  background-color: #b9b9b9;
  color: #222026; }

.dash-workbook_warm-grey-bg__1DPNS {
  background-color: #55556f;
  color: #f9f9f9; }

.dash-workbook_simple-grey-bg__3yW2w {
  background-color: #d4d4d8;
  color: #222026; }

.dash-workbook_dark-grey-bg__3gg_V {
  background-color: #222026;
  color: #fff; }

.dash-workbook_flat-white-bg__22QwL {
  background-color: #fff;
  color: #014660; }

.dash-workbook_pastel-red-bg__TBJ7r {
  background-color: #e55e50;
  color: #f9f9f9; }

.dash-workbook_workbookContent__1DmNZ {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between; }

.dash-workbook_buttonWrapper__LezGU {
  display: flex;
  justify-content: center; }
  .dash-workbook_buttonWrapper__LezGU .dash-workbook_button__1b5JS.dash-workbook_button__1b5JS {
    flex: 1 1; }
    .dash-workbook_buttonWrapper__LezGU .dash-workbook_button__1b5JS.dash-workbook_button__1b5JS > a {
      color: #fff; }
      .dash-workbook_buttonWrapper__LezGU .dash-workbook_button__1b5JS.dash-workbook_button__1b5JS > a:hover {
        color: #014660; }

.dash-workbook_header__2Kam-.dash-workbook_header__2Kam- {
  color: #014660;
  font-weight: 700;
  margin: 0 0.5em;
  padding: 0.5em; }

