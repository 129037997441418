@import "styles/mixins/main";

.upload {
  @include v-input();
  flex-direction: row;

  svg {
    align-self: center;
    margin-top: 0;
  }
}

.icon-info {
  align-self: center;
  flex: 1;
  justify-content: flex-start;
  margin: 0 10px;

  span {
    font-size: 14px;
  }

  h3 {
    font-size: 16px;
  }
}

.input {
  align-items: center;
  background-color: $flat-white;
  border: 2px solid $deep-teal;
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
  width: 60px;

  img {
    border-radius: 50%;
    height: 54px;
    width: auto;
  }

  svg {
    height: 40px;
    margin: 0;
    position: absolute;
    width: 40px;
    z-index: 1;
  }

  input {
    height: 50px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 50px;
    z-index: 5;
  }
}
