@import "styles/colors";

.wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  h1 {
    color: $warm-grey;
    font-family: "Questrial", sans-serif;
    font-size: 20px;
  }

  span {
    color: $dark-grey;
    font-family: "OpenSans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    max-width: 300px;
  }

  svg {
    height: 90px;
    width: 90px;
  }
}
