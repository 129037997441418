.skeleton {
  background-repeat: no-repeat;
  background-size: 200px 100%;
  border-radius: 4px;

  height: 15px;
  width: 100%;

  :global {
    animation: 1.2s ease-in-out 0s infinite normal none running skeleton;
  }
}

@keyframes :global(skeleton) {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}
