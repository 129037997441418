@mixin button ($bg: transparent, $color: #{$deep-teal}, $font-size: 14) {
  @include body-style($color);
  background-color: $bg;
  border: 0;
  border-radius: 18.5px;
  font-size: #{$font-size}px;
  font-weight: 600;
  justify-content: center;
  padding: 5px;
  text-align: -webkit-center;

  &:hover:not(:disabled) {
    background-color: darken($bg, 10);
  }

  &:disabled {
    opacity: 0.5;
  }
}

@mixin icon-button () {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  text-align: -webkit-center;
}

@mixin outlined-button($color, $shadow: transparent, $font-size: 14) {
  @include button(transparent, $color, $font-size);

  border: 1px solid $color;
  box-shadow: 0 0 3px rgba($shadow, 0.5);
  font-weight: 600;

  &:hover:not(:disabled) {
    background-color: darken($flat-white, 5);
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}
