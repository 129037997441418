@import "styles/mixins/main";

.top {
  display: block;
  justify-content: center;

  .close {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 20px;
  }

  .close>svg {
    height: 20px;
    width: 30px;
  }
}

.title {
  align-self: center;
}

.wrapper {
  background-color: rgba($flat-white, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}

.header {
  @extend .top;

  height: 60px;

  .close {
    right: 20px;
  }

  .close>svg {
    height: 20px;
    width: 20px;
  }

}

.header-mobile {
  @extend .top;

  align-items: center;
  background-color: $deep-teal;
  color: $flat-white;
  display: block;
  height: 60px;
  padding: 20px;

  position: relative;
  text-align: center;

  @media (max-width: 350px) {
    padding-left: 50px;
  }

  span {
    align-self: center;
    text-transform: uppercase;
  }

  .close {
    left: 10px;

    @supports (-webkit-overflow-scrolling: touch) {
      left: 0;
    }
  }

  .close>svg {
    height: 20px;
    width: 30px;
  }
}

.body {
  align-self: center;
  height: 90%;
  /* fallback if needed */
  height: calc(100% - 60px);
  max-width: 800px;
  padding: 20px;
  width: 100%;

  .title {
    @include font-style($deep-teal, 22);

    align-self: flex-start;
    display: block;

    font-family: "Questrial", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
}
